import {OverlayEventDetail} from "@ionic/core/components";
import {IonButton, IonIcon, useIonAlert, useIonModal} from "@ionic/react";
import {pencilOutline, trashOutline} from "ionicons/icons";
import React from "react";
import {Brand} from "../../../models/brand";
import {BrandEvent} from "../../../models/brandEvent";
import EventUpsert from "../EventUpsert/EventUpsert";

interface ContainerProps {
    onDismissEditEvent: (data: string, role: string) => void,
    deleteEvent: (deletedEvent: BrandEvent) => void
    brand: Brand
    brandEvent: BrandEvent
}

const EditDeleteEvent: React.FC<ContainerProps> = ({onDismissEditEvent, brand, brandEvent, deleteEvent}) => {
    const [presentAlert] = useIonAlert();

    const eventDeleteWarningDetails = (brandEvent: BrandEvent) => {
        presentAlert({
            header: 'Do you want to delete this event?',
            message: 'If you delete the event, the tricks entered will not be deleted, but the feelings of accomplishment and fun from the riders will.',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'OK',
                    role: 'confirm',
                    handler: () => {
                        deleteEvent(brandEvent);
                    },
                },
            ],
        })
    }

    // Event Modal
    const [presentEditEvent, dismissEvent] = useIonModal(EventUpsert, {
        onDismiss: (data: string, role: string) => onDismissEditEventHandler(data, role),
        brand: {...brand},
        brandEvent: {...brandEvent},
    });

    const onDismissEditEventHandler = (data: string, role: string) => {
        dismissEvent();
        onDismissEditEvent(data, role);
    }

    function openEditEventModal() {
        presentEditEvent({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    return (
        <><IonButton onClick={() => eventDeleteWarningDetails(brandEvent)} fill="outline" color="danger"
                     size="small">
            <IonIcon slot="icon-only" icon={trashOutline}></IonIcon>
        </IonButton><IonButton onClick={() => openEditEventModal()} fill="outline"
                               color="favorite-secondary" size="small">
            <IonIcon slot="icon-only" icon={pencilOutline} color="dark"></IonIcon>
        </IonButton></>
    );
};

export default EditDeleteEvent;