import React from 'react';
import {IonButton, IonDatetime} from '@ionic/react';
import {format, subYears} from 'date-fns';
import {useSignUp} from "../SignUpProvider/SignUpProvider";
import OnboardingPageWrapper from "../OnboardingPageWrapper/OnboardingPageWrapper";

interface BirthdayStepProps {
    onNext: () => void;
    title: string;
    showBackButton: boolean;
    showSkipButton: boolean;
    onBack?: () => void;
}

const BirthdayStep: React.FC<BirthdayStepProps> = ({onNext, showSkipButton, showBackButton, title, onBack}) => {
    const {signUpData, updateSignUpData} = useSignUp();
    const maxDate = format(subYears(new Date(), 4), 'yyyy-MM-dd');
    const startDate = format(subYears(new Date(), 18), 'yyyy-MM-dd');
    const earliestDate = new Date(0);
    earliestDate.setUTCFullYear(1); // Set the year to 0001
    const minDate = format(earliestDate, 'yyyy-MM-dd');

    const handleDateChange = async (event: CustomEvent) => {
        const value = event.detail.value;
        if (typeof value === 'string') {
            await updateSignUpData({birthday: value});
        } else if (Array.isArray(value) && value.length > 0) {
            await updateSignUpData({birthday: value[0]});
        }
    };

    return (
        <OnboardingPageWrapper title={title} showBackButton={showBackButton} showSkipButton={showSkipButton}
                               onBack={onBack}>
            <div className="w-full h-full max-w-md space-y-6 flex flex-col items-center">
                <IonDatetime
                    presentation="date"
                    min={minDate}
                    max={maxDate}
                    value={signUpData.birthday || startDate}
                    preferWheel={true}
                    className="ion-datetime-custom bg-primary border border-electric-blue b-2 rounded-lg w-full"
                    color="dark"
                    onIonChange={handleDateChange}
                />
                <IonButton
                    expand="block"
                    className="neon-button-alt w-full normal-case font-bold"
                    onClick={onNext}
                    disabled={!signUpData.birthday}
                >
                    Continue
                </IonButton>
            </div>
        </OnboardingPageWrapper>
    );
};

export default BirthdayStep;