import {IonButton, IonIcon, IonRippleEffect} from "@ionic/react";
import {logInOutline} from "ionicons/icons";
import React from "react";
import useAuthService from "../../../hooks/useAuthService";

interface ContainerProps {
    title?: string;
    username?: string;
    upload?: boolean;
    profile?: boolean;
}

const LoginButton: React.FC<ContainerProps> = (props: ContainerProps) => {
    const {login} = useAuthService();

    return (
        <div className="flex flex-col gap-y-1">
            {props.title &&
                <div className="ion-activatable ripple-parent rounded-rectangle">
                    <IonRippleEffect></IonRippleEffect>
                    <IonButton text-wrap className="ion-text-wrap" expand="block"
                               color="favorite"
                               size="large"
                               onClick={() => login()}
                    >{props.title}
                    </IonButton>
                </div>
            }
            <div className="ion-activatable ripple-parent rounded-rectangle">
                <IonRippleEffect></IonRippleEffect>
                <IonButton color="favorite" expand="block" size="large" onClick={() => login()}>
                    Login/Sign Up
                    <IonIcon slot="end" icon={logInOutline}></IonIcon>
                </IonButton>
            </div>
        </div>
    );
};

export default LoginButton;