import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { FirebaseNotification } from '../models/firebaseNotifications';

const PushNotificationsService = {
  checkPermissions: async () => {
    const result = await FirebaseMessaging.checkPermissions();
    return result.receive;
  },

  requestPermissions: async () => {
    const result = await FirebaseMessaging.requestPermissions();
    return result.receive;
  },

  getToken: async () => {
    const result = await FirebaseMessaging.getToken();
    return result.token;
  },

  deleteToken: async () => {
    await FirebaseMessaging.deleteToken();
  },

  // delivered notifications are the ones that are in the notification center
  getDeliveredNotifications: async () => {
    const result = await FirebaseMessaging.getDeliveredNotifications();
    return result.notifications;
  },

  removeDeliveredNotifications: async (notifications: FirebaseNotification[]) => {
    await FirebaseMessaging.removeDeliveredNotifications({
      notifications: notifications
    });
  },

  removeAllDeliveredNotifications: async () => {
    await FirebaseMessaging.removeAllDeliveredNotifications();
  },

  subscribeToTopic: async (topic: string) => {
    await FirebaseMessaging.subscribeToTopic({ topic: topic });
  },

  unsubscribeFromTopic: async (topic: string) => {
    await FirebaseMessaging.unsubscribeFromTopic({ topic: topic });
  },

  addTokenReceivedListener: async (handler: (event: any) => void) => {
    await FirebaseMessaging.addListener('tokenReceived', event => {
      console.log('tokenReceived', { event });
      handler(event);
    });
  },

  addNotificationReceivedListener: async (handler: (event: any) => void) => {
    await FirebaseMessaging.addListener('notificationReceived', event => {
      console.log('notificationReceived', { event });
      handler(event);
    });
  },

  addNotificationActionPerformedListener: async (handler: (event: any) => void) => {
    await FirebaseMessaging.addListener('notificationActionPerformed', event => {
      console.log('notificationActionPerformed', { event });
      handler(event);
    });
  },

  removeAllListeners: async () => {
    await FirebaseMessaging.removeAllListeners();
  },
};

export default PushNotificationsService;