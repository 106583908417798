import {Browser} from "@capacitor/browser";
import {Capacitor} from "@capacitor/core";
import {IonIcon, IonItem} from "@ionic/react";
import React from "react";

interface ContainerProps {
  svg_path?: string;
  icon?: any;
  value: string;
  link?: string;
}

const SocialProfileLink: React.FC<ContainerProps> = (props: ContainerProps) => {

  const isNative = Capacitor.isNativePlatform();

  const goToLink = (url: string) => {
    if (url) {
      if (isNative) {
        Browser.open({ url: url, presentationStyle: 'popover', 'toolbarColor': '#000000' });
      } else {
        window.open(url, '_blank');
      }
    }
  }

  return (
    <div className="flex-row mt-4">
      <IonItem onClick={() => goToLink(props.link)}>
        <IonIcon icon={props.icon} src={props.svg_path} size="large" className="mr-4"></IonIcon>
        <div>{props.value}</div>
      </IonItem>
    </div>
  );
};

export default SocialProfileLink;
