import {IonButton, IonIcon} from "@ionic/react";
import {funnel} from "ionicons/icons";
import {useEffect} from "react";

interface ContainerProps {
    handleFilterFindTrickDisplay: () => void,
    showFilterOverlay: boolean,
}

const FilterTrickButton: React.FC<ContainerProps> = ({showFilterOverlay, handleFilterFindTrickDisplay}) => {
    useEffect(() => {
    }, [showFilterOverlay]);

    return (
        !showFilterOverlay &&
        // maybe need to change the z-index of this div if it doesn't go over the vids
        <div className="z-100 fixed bottom-5 left-1/2 transform -translate-x-1/2" style={{zIndex: 100}}>
            <IonButton color="dark" fill="solid" shape="round" onClick={handleFilterFindTrickDisplay}>
                <IonIcon icon={funnel}/><span className="ml-2">Filter/Find Tricks</span>
            </IonButton>
        </div>
    );
};

export default FilterTrickButton;
