import axios from "axios";
import {Badge} from "../models/badges";
import {User} from "../models/user";

const BadgesService = {
    getBadgeByID: async (badgeid : string) => {
        const params = {
            badge_id: badgeid
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/badges`, {params: params})
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    reorderUserBadges: async (infd_id: string, badgeIds: string[]) => {
        const params = {
            infd_id: infd_id,
            badge_ids: badgeIds.join(",")
        };

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/badge/reorder`, null, {params: params})
          .then((response) => {
              return response.data;
          }).catch((error) => {
              console.error(error);
              return null;
          });
    }
};

export default BadgesService;