import React from "react";
import {Brand} from "./models/brand";
import {User} from "./models/user";
import {SubscriptionType} from "./models/subscriptionType";
import {UserRole} from "./models/userRole";

export function createCtx<StateType, ActionType>(
    reducer: React.Reducer<StateType, ActionType>,
    initialState: StateType
) {
    const defaultDispatch: React.Dispatch<ActionType> = () => initialState; // we never actually use this
    const ctx = React.createContext({
        state: initialState,
        dispatch: defaultDispatch, // just to mock out the dispatch type and make it not optioanl
    });

    function Provider(props: React.PropsWithChildren<{}>) {
        const [state, dispatch] = React.useReducer<
            React.Reducer<StateType, ActionType>
        >(reducer, initialState);
        return <ctx.Provider value={{state, dispatch}} {...props} />;
    }

    return [ctx, Provider] as const;
}

interface AppState {
    user: User | Brand;
    bisk: number;
    feedSettings: string[];
    mutedSettings: boolean;
    isSubscribed: boolean;
    subscriptionLevel: SubscriptionType;
    userRole: UserRole;
    followers: number;
    following: number;
    push_notification_redirect_url: string;
    blockedUsers: string[];
    blockingUsers: string[];
}

// State is used for things that are needed across the app/multiple components that can change in one place and need to be updated in multiple places
const initialState: AppState = {
    user: {
        name: "",
        username: "",
        email: "",
        profile: {
            profile_pic: null,
            sports: null,
            trick_interests: null,
            score: null,
            home_mountain: null,
            discord: null,
            instagram: null,
            tiktok: null,
            youtube: null,
            website: null,
            website_name: null,
            twitter: null,
            push_notifications: null,
            fcm_token: null,
            badges: null,
            cover_pic: null,
            brand_description: null,
            contact: null,
            brand_type: null,
            setup: null,
        },
        user_interactions: {
            following: [], // DEPRECATED
            followers: [], // DEPRECATED
            members: [],
            posts: [],
            events: [],
        },
        id: "",
        auth_id: ""
    },
    feedSettings: [],
    mutedSettings: true,
    bisk: 0,
    isSubscribed: false,
    subscriptionLevel: SubscriptionType.None,
    userRole: UserRole.USER,
    followers: 0,
    following: 0,
    push_notification_redirect_url: "",
    blockedUsers: [],
    blockingUsers: [],
};

type Action =
    | { type: "setUser"; payload: any }
    | { type: "clearUser"; payload: null }
    | { type: "setFeedSettings"; payload: any }
    | { type: "clearFeedSettings"; payload: null }
    | { type: "setMutedSettings"; payload: any }
    | { type: "clearMutedSettings"; payload: null }
    | { type: "setBisk"; payload: any }
    | { type: "clearBisk"; payload: null }
    | { type: "setIsSubscribed"; payload: any }
    | { type: "clearIsSubscribed"; payload: null }
    | { type: "setSubscriptionLevel"; payload: any }
    | { type: "clearSubscriptionLevel"; payload: null }
    | { type: "setUserType"; payload: any }
    | { type: "clearUserType"; payload: null }
    | { type: "setFollowers"; payload: any }
    | { type: "clearFollowers"; payload: null }
    | { type: "setFollowing"; payload: any }
    | { type: "clearFollowing"; payload: null }
    | { type: "setPushNotificationRedirectUrl"; payload: any }
    | { type: "clearPushNotificationRedirectUrl"; payload: null }
    | { type: "setBlockedUsers"; payload: any }
    | { type: "clearBlockedUsers"; payload: null }
    | { type: "setBlockingUsers"; payload: any }
    | { type: "clearBlockingUsers"; payload: null };


function reducer(state: AppState, action: Action): AppState {
    switch (action.type) {
        case "setUser":
            console.log(action.payload);
            return {
                ...state,
                user: {
                    name: action.payload?.name,
                    email: action.payload.email,
                    username: action.payload.username,
                    profile: {
                        profile_pic: action.payload?.profile?.profile_pic,
                        sports: action.payload?.profile?.sports,
                        trick_interests: action.payload?.profile?.trick_interests,
                        score: action.payload?.profile?.score,
                        home_mountain: action.payload?.profile?.home_mountain,
                        discord: action.payload?.profile?.discord,
                        instagram: action.payload?.profile?.instagram,
                        tiktok: action.payload?.profile?.tiktok,
                        youtube: action.payload?.profile?.youtube,
                        website: action.payload?.profile?.website,
                        website_name: action.payload?.profile?.website_name,
                        twitter: action.payload?.profile?.twitter,
                        push_notifications: action.payload?.profile?.push_notifications,
                        fcm_token: action.payload?.profile?.fcm_token,
                        badges: action.payload?.profile?.badges,
                        cover_pic: action.payload?.profile?.cover_pic,
                        brand_description: action.payload?.profile?.brand_description,
                        contact: action.payload?.profile?.contact,
                        brand_type: action.payload?.profile?.brand_type,
                        setup: action.payload?.profile?.setup
                    },
                    id: action.payload.id,
                    auth_id: action.payload.auth_id,
                    user_interactions: {
                        following: action.payload.user_interactions?.following,
                        followers: action.payload.user_interactions?.followers,
                        members: action.payload.user_interactions?.members,
                        posts: action.payload.user_interactions?.posts,
                        events: action.payload.user_interactions?.events,
                    }
                },
            };
        case "clearUser":
            console.log("Clearing user state");
            return {
                ...state,
                user: {
                    name: "",
                    username: "",
                    email: "",
                    subscriptions: {
                        stripe_customer_id: "",
                        line_items: []
                    },
                    profile: {
                        profile_pic: undefined,
                        sports: undefined,
                        trick_interests: undefined,
                        score: undefined,
                        home_mountain: undefined,
                        discord: undefined,
                        instagram: undefined,
                        tiktok: undefined,
                        youtube: undefined,
                        website: undefined,
                        website_name: undefined,
                        twitter: undefined,
                        push_notifications: undefined,
                        fcm_token: undefined,
                        badges: undefined,
                        cover_pic: undefined,
                        brand_description: undefined,
                        contact: undefined,
                        brand_type: undefined,
                        setup: undefined
                    },
                    id: "",
                    auth_id: "",
                    user_interactions: {
                        following: [],
                        followers: [],
                        members: [],
                        posts: [],
                        events: [],
                    }
                }
            };
        case "setFeedSettings":
            return {
                ...state,
                feedSettings: action.payload,
            };
        case "clearFeedSettings":
            return {
                ...state,
                feedSettings: [],
            };
        case "setMutedSettings":
            return {
                ...state,
                mutedSettings: action.payload,
            };
        case "clearMutedSettings":
            return {
                ...state,
                mutedSettings: false,
            };
        case "setBisk":
            console.log("SETTING BISK TO: " + action.payload);
            return {
                ...state,
                bisk: action.payload,
            };
        case "clearBisk":
            return {
                ...state,
                bisk: 0,
            };
        case "setIsSubscribed":
            console.log("SETTING IS SUBSCRIBED TO: " + action.payload);
            return {
                ...state,
                isSubscribed: action.payload,
            };
        case "clearIsSubscribed":
            return {
                ...state,
                isSubscribed: false,
            };
        case "setSubscriptionLevel":
            console.log("SETTING SUBSCRIPTION LEVEL TO: " + action.payload);
            return {
                ...state,
                subscriptionLevel: action.payload,
            };
        case "clearSubscriptionLevel":
            return {
                ...state,
                subscriptionLevel: SubscriptionType.None,
            };
        case "setUserType":
            return {
                ...state,
                userRole: action.payload,
            };
        case "clearUserType":
            return {
                ...state,
                userRole: UserRole.USER,
            };
        case "setFollowers":
            console.log("SETTING FOLLOWERS COUNT TO: " + action.payload);
            return {
                ...state,
                followers: action.payload,
            };
        case "clearFollowers":
            return {
                ...state,
                followers: 0,
            };
        case "setFollowing":
            console.log("SETTING FOLLOWING COUNT TO: " + action.payload);
            return {
                ...state,
                following: action.payload,
            };
        case "clearFollowing":
            return {
                ...state,
                following: 0,
            };
        case "setPushNotificationRedirectUrl":
            console.log("Setting The Push Notification URL");
            return {
                ...state,
                push_notification_redirect_url: action.payload,
            };
        case "clearPushNotificationRedirectUrl":
            console.log("CLEARING PUSH NOTIFICATION REDIRECT URL");
            return {
                ...state,
                push_notification_redirect_url: "",
            };
        case "setBlockedUsers":
            console.log("Setting Blocked Users");
            return {
                ...state,
                blockedUsers: action.payload,
            };
        case "clearBlockedUsers":
            console.log("CLEARING BLOCKED USERS");
            return {
                ...state,
                blockedUsers: [],
            };
        case "setBlockingUsers":
            console.log("Setting Blocking Users");
            return {
                ...state,
                blockingUsers: action.payload,
            };
        case "clearBlockingUsers":
            console.log("CLEARING BLOCKING USERS");
            return {
                ...state,
                blockingUsers: [],
            };
        default:
            throw new Error("Invalid action type: " + JSON.stringify(action));
    }
}

export const [AppContext, AppStateProvider] = createCtx(
    reducer,
    initialState
);
