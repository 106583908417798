import {IonItem, IonLabel, IonTextarea} from "@ionic/react";

interface ContainerProps {
    label: string;
    value: string;
    placeholder: string
    disabled?: boolean;
    parentCallback: any;
}

const IonLongInputWithLabel: React.FC<ContainerProps> = (props: ContainerProps) => {
    return (
        <IonItem>
            <IonLabel position="stacked">{props.label}</IonLabel>
            <IonTextarea autoGrow={true} placeholder={props.placeholder} value={props.value} disabled={props.disabled}
                         onIonInput={e => props.parentCallback(e.detail.value)}/>
        </IonItem>
    );
};

export default IonLongInputWithLabel;
