import React from "react";
import {motion} from 'framer-motion';
import {IonIcon, useIonAlert} from "@ionic/react";
import biskLogo from "../../../assets/icons/bisk/BISK_ICON_NO_DEGREE.svg";
import {helpCircleOutline} from "ionicons/icons";
import {Browser} from "@capacitor/browser";

interface ContainerProps {
    onBuy?: () => void,
}

const Bisk: React.FC<ContainerProps> = (props: ContainerProps) => {
    const [presentAlert] = useIonAlert();

    const onPresentBiskHelp = () => {
        presentAlert({
            header: 'What is Bisk?',
            subHeader: 'A way to recognize and reward the unknown',
            message: 'Send to those sending it, buy it at the Ecliptic Smoke Shack, and earn it through Ecliptic Events. 100 Bisk = $1. Finally, you can exchange your Bisk out of app if you have over 2500 Bisk at https://ecliptic.day (our web app) for $wag. For example, 2500 Bisk = $25. Soon, you will be able to exchange Bisk for brand partners goods in app. See the terms and conditions for more details.',
            buttons: [
                {
                    text: 'View Terms',
                    handler: () => {
                        Browser.open({
                            url: 'https://idx.style/terms-and-conditions',
                            presentationStyle: "popover",
                            windowName: "_blank"
                        })
                    }
                },
                {
                    text: 'Buy',
                    role: 'confirm',
                    handler: (alertData) => {
                        props.onBuy();
                    }
                }]
        })
    }

    return (
        <div className="flex flex-col relative justify-center items-center">
            <IonIcon icon={helpCircleOutline} className="absolute top-0 right-0" size="large"
                     onClick={onPresentBiskHelp}></IonIcon>
            <motion.div
                className="flex items-center justify-center w-52 h-52 bg-highlight rounded-full"
                animate={{rotateY: 360}}
                transition={{duration: .25, repeat: 1, ease: "linear"}}
            >
                <IonIcon color="dark" src={biskLogo} size="large" className="w-40 h-40"/>
            </motion.div>
        </div>
    );
};

export default Bisk;
