import {Browser} from "@capacitor/browser";
import React from 'react';
import {Announcement} from "../../../models/announcements";

interface RotatingBannerProps {
    initialTextArray: Announcement[];
    currentTextIndex: number;
}

const RotatingBanner: React.FC<RotatingBannerProps> = ({initialTextArray, currentTextIndex}) => {
    const textLinkColors = ['text-green-300', 'text-blue-300', 'text-pink-300', 'text-yellow-300']

    const handleLinkClick = async (url: string) => {
        console.log('Opening browser to', url);
        try {
            await Browser.open({
                url,
                presentationStyle: 'popover'
            });
        } catch (error) {
            console.error('Error opening the browser', error);
        }
    };

    return (
        <div className='h-6 bg-neutral-900 rounded-b-lg'>
            {initialTextArray.length > 0 ? (
                <div className="relative">
                    {initialTextArray.map((text, index) => (
                        <div
                            key={index}
                            className={`text-center text-white bg-neutral-900 rounded-b-lg py-2 absolute top-0 left-0 w-full transition-opacity ${
                                index === currentTextIndex ? 'opacity-100' : 'opacity-0'
                            }`}
                        >
                            {initialTextArray[currentTextIndex].link !== null ? (
                                <a
                                    className={`cursor-pointer ${textLinkColors[index % textLinkColors.length]}`}
                                    onClick={() => handleLinkClick(initialTextArray[currentTextIndex].link)}
                                >
                                    {initialTextArray[currentTextIndex].text}
                                </a>
                            ) : (
                                <span>{initialTextArray[currentTextIndex].text}</span>
                            )}
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default RotatingBanner;