import axios from "axios";
import {Badge} from "../models/badges";
import {User} from "../models/user";
import {Preferences} from "@capacitor/preferences";

const PaymentsService = {
    getSubscriptionCheckoutSession: async (userId: string, email: string, priceKey: string, bisk: number, mode: string) => {
        const params: any = {
            infd_id: userId,
            customer_email: email,
            price_id: priceKey,
            old_bisk: bisk,
            host: `${process.env.REACT_APP_REDIRECT_URI}`,
            mode: mode
        }

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/stripe/create-checkout-session-app`,null, {params: params})
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getCustomerPortalSession: async (auth_id: string) => {
        console.log("auth_id: " + auth_id);
        const params: any = {
            auth_id: auth_id,
            host: `${process.env.REACT_APP_REDIRECT_URI}`
        }

        return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/stripe/create-customer-portal-session-new`,null, {params: params})
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getActiveProducts: async () => {
        const params = {
            active: true
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/products`, {params: params})
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    }
};

export default PaymentsService;