import {IonButton} from "@ionic/react";
import React from "react";

interface ContainerProps {
    reportUserId: string,
    onReportUser: (report_id: string) => void,
    username: string,
    loggedIn: boolean,
}

const ReportUser: React.FC<ContainerProps> = ({onReportUser, reportUserId, username, loggedIn}) => {
    return (
        loggedIn &&
        <IonButton expand="block" color="danger" shape="round" fill="outline"
                   onClick={() => onReportUser(reportUserId)}>{`Report ${username}`}</IonButton>
    );
};

export default ReportUser;