import {Browser} from "@capacitor/browser";
import {Clipboard} from '@capacitor/clipboard';
import {Capacitor} from "@capacitor/core";
import {Share} from "@capacitor/share";
import {OverlayEventDetail} from "@ionic/core/components";
import {
    IonButton,
    IonContent,
    IonIcon,
    IonImg,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonSearchbar,
    IonSpinner,
    RefresherEventDetail,
    useIonAlert,
    useIonModal,
    useIonToast
} from "@ionic/react";
import {
    alertCircleOutline,
    briefcaseOutline,
    checkmarkCircleOutline,
    createOutline,
    globeOutline,
    helpCircleOutline,
    linkOutline,
    pencilOutline,
    personRemoveOutline,
    shareOutline,
} from "ionicons/icons";
import React, {useContext, useEffect, useState} from "react";
import {RouteComponentProps, useHistory} from "react-router";
import {ReactSortable} from "react-sortablejs";
import CaseModal from "../components/ComponentsProfile/CaseModal/CaseModal";
import EditProfileModal from "../components/ComponentsProfile/EditProfileModal/EditProfileModal";
import ProfileSocial from "../components/ComponentsProfile/ProfileSocial/ProfileSocial";
import ToolbarSearchBar from "../components/ComponentsUI/ToolbarSearchBar/ToolbarSearchBar";
import ViewProfileFollowInfoButton
    from "../components/ComponentsUI/ViewProfileFollowInfoButton/ViewProfileFollowInfoButton";
import "../global.css";
import {Badge} from "../models/badges";
import {ClipType} from "../models/clipType";
import {SportType} from "../models/sportType";
import {User} from "../models/user";
import {UserTrick} from "../models/user-trick";
import {UserTrickSearch} from "../models/user-trick-search";
import {UserPreferences} from "../models/userPreferences";
import {UserPreferencesConstants} from "../models/userPreferencesConstants";
import {UserType} from "../models/usertype";
import badgesService from "../services/badges.service";
import BadgesService from "../services/badges.service";
import TricksService from "../services/tricks.service";
import userService from "../services/user.service";
import UserService from "../services/user.service";
import {analyzeUserTrickResForUnlisted, arrangeTricksByPreferences} from "../services/utils";
import {AppContext} from "../AppStateProvider";
import ProfilePicture from "../components/ComponentsProfile/ProfilePicture/ProfilePicture";
import BiskProfile from "../components/ComponentsBisk/BiskProfile/BiskProfile";
import UsernameDisplay from "../components/ComponentsUI/Username/UsernameDisplay";
import CustomHelmet from "../components/ComponentsFunctional/CustomHelmet/CustomHelmet";
import ProfileTrickCard from "../components/ComponentsProfile/ProfileTrickCard/ProfileTrickCard";
import BlockUser from "../components/ComponentsProfile/BlockUser/BlockUser";
import ReturnToProfile from "../components/ComponentsProfile/ReturnToProfile/ReturnToProfile";
import useAuthService from "../hooks/useAuthService";
import SettingsService from "../services/settings.service";
import IonSpinnerMainContent from "../components/ComponentsUI/IonSpinnerMainContent/IonSpinnerMainContent";
import {useAuth} from "../AuthProvider";
import LoadingProfileComponent from "../components/ComponentsProfile/LoadingProfileComponent/LoadingProfileComponent";
import LoginButtonPage from "./LoginButtonPage";

interface ProfilePageProps
    extends RouteComponentProps<{
        username: string;
    }> {
}


const Profile: React.FC<ProfilePageProps> = ({match}) => {
    const {state, dispatch} = useContext(AppContext);
    const history = useHistory();
    const {isAuthenticated, user, isLoading} = useAuth();
    const {login} = useAuthService();
    const {username} = match.params;

    //use effect to tricksservice call to getsavedtricks
    const [presentAlert] = useIonAlert();

    const [presentToast] = useIonToast();

    const tabs = ['Clips', 'Lines', 'Edits', 'Saved'];
    const [activeTab, setActiveTab] = useState(tabs[0]);


    // Set Up user being searched for
    const [profileLoading, setProfileLoading] = useState(false);
    const [userProfile, setProfile] = useState(undefined);
    const [userProfileUrl, setUserProfileUrl] = useState(undefined);
    const [editProfile, setEditProfile] = useState(undefined);
    const [profileError, setProfileError] = useState(false);

    const [userTricks, setUserTricks] = useState([]);
    const [editSortingUserTricks, setEditSortingUserTricks] = useState(false);

    const [userLines, setUserLines] = useState([]);
    const [editSortingUserLines, setEditSortingUserLines] = useState(false);

    const [userEdits, setUserEdits] = useState([]);
    const [editSortingUserEdits, setEditSortingUserEdits] = useState(false);

    const [savedVideos, setSavedVideos] = useState([]);
    const [claimProfile, setClaimProfile] = useState(false);
    const [followingUser, setFollowingUser] = useState(false);
    const [followingUserLoading, setFollowingUserLoading] = useState(false);

    const [bagOfTricksFilter, setBagOfTricksFilter] = useState<string>("");

    const [stashOfLinesFilter, setStashOfLinesFilter] = useState<string>("");

    const [galleryOfEditsFilter, setGalleryOfEditsFilter] = useState<string>("");

    const [savedVideoFilter, setSavedVideoFilter] = useState<string>("");
    const [badges, setBadges] = useState<Badge[]>([]);

    const [followersCount, setFollowersCount] = useState<number>(0);
    const [followingCount, setFollowingCount] = useState<number>(0);

    const [clipsLinesEditsLoading, setClipsLinesEditsLoading] = useState(false);
    const [savesLoading, setSavesLoading] = useState(false);

    const [userBlocked, setUserBlocked] = useState(false);
    const [profileBlocking, setProfileBlocking] = useState(false);

    const isNative = Capacitor.isNativePlatform();

    const onEditProfileSubmit = async (name: string, username: string, homeMountain: string, sports: string[],
                                       trickInterests: string[], instagram: string, tiktok: string, youtube: string,
                                       website: string, website_label: string, discord: string, twitter: string,
                                       push_notifications: string, setup: string) => {
        const returnedUser: User = await userService.upsertUserProfileInformation(state.user.id,
            name, username, homeMountain, sports, trickInterests, instagram, tiktok, youtube, website, website_label,
            discord, twitter, push_notifications, setup);
        dispatch({type: 'setUser', payload: returnedUser});
        history.push(`/profile/${returnedUser.username}`);
        await getUserProfile(returnedUser.username);
    }

    const onClickTrickFromSuggestedTricks = async (trick: string) => {
        history.push(`/search/${trick}`);
    }

    const onClickPersonUserInteractions = async (usernameCrew: string, userType: string) => {
        if (userType === UserType.USER) {
            console.log("User Type is User: " + usernameCrew)
            history.push(`/profile/${usernameCrew}`);
        } else if (userType === UserType.BRAND) {
            history.push(`/crews/${usernameCrew}`);
        } else {
            history.push(`/profile/${usernameCrew}`);
        }
    }

    const setUserBadgesFromSorting = async (userBadgesSorted: Badge[]) => {
        setBadges(userBadgesSorted);
        const badgeIds = userBadgesSorted.map(badge => badge.id);

        await badgesService.reorderUserBadges(state.user.id, badgeIds);
    }

    const shopRedirect = () => {
        history.push('/shop');
    }

    const blockUser = async (blocked_id: string, block: boolean) => {
        const res = await UserService.blockUser(state.user.id, blocked_id, block);
        setUserBlocked(res);
        const blockedUsers: string[] = await UserService.getBlockedUsers(state.user.id);
        dispatch({type: 'setBlockedUsers', payload: blockedUsers});
    }

    const reportUser = async (reported_id: string) => {
        const res = await SettingsService.sendFeedback(state.user.id, "Username: " + state.user.username + " wishes to report " + userProfile?.username + " with the id : " + reported_id);
        await presentToastHandler(`${userProfile?.username} has been reported`);
    }

    const getIfBlockedUser = async (user_id: string, blocked_id: string) => {
        const res = await UserService.checkIfBlockedUser(user_id, blocked_id);
        setUserBlocked(res);
    }

    const getIfProfileBlocking = async (user_id: string, blocker_id: string) => {
        const res = await UserService.checkIfBlockingUser(user_id, blocker_id);
        setProfileBlocking(res);
    }

    const goToSelfProfile = (username: string) => {
        history.push(`/profile/${username}`);
    }

    const [presentSocial, dismissSocial] = useIonModal(ProfileSocial, {
        onDismiss: (data: string, role: string) => dismissSocial(data, role),
        userProfileSocial: {...userProfile},
        displayBlock: !editProfile,
        userBlocked: userBlocked,
        onBlockUser: blockUser,
        onReportUser: reportUser,
        loggedIn: isAuthenticated,
    });

    const [presentTrophy, dismissTrophy] = useIonModal(CaseModal, {
        onDismiss: (data: string, role: string) => dismissTrophy(data, role),
        badges: badges,
        caseTitle: editProfile ? "Your Case" : `${userProfile?.username}'s Case`,
        sports: userProfile?.profile?.sports,
        editProfile: editProfile,
        userProfileId: userProfile?.id,
        isNative: isNative,
        username: userProfile?.username,
        setBadgeOrder: setUserBadgesFromSorting,
        onClickTrickHandler: onClickTrickFromSuggestedTricks,
        shopRedirect: shopRedirect,
    });

    const [presentEditProfileModal, dismissEditProfileModal] = useIonModal(EditProfileModal, {
        onDismiss: (data: string, role: string) => dismissEditProfileModal(data, role),
        onEditProfileSubmit: onEditProfileSubmit,
        userProfileEdit: {...userProfile}
    });

    const [presentFollowing, dismissFollowing] = useIonModal(ViewProfileFollowInfoButton, {
        onDismiss: (data: string, role: string) => dismissFollowing(data, role),
        userProfileId: userProfile?.id,
        followers: false,
        onClickResult: onClickPersonUserInteractions,
    })

    const [presentFollowers, dismissFollowers] = useIonModal(ViewProfileFollowInfoButton, {
        onDismiss: (data: string, role: string) => dismissFollowers(data, role),
        userProfileId: userProfile?.id,
        followers: true,
        onClickResult: onClickPersonUserInteractions,
    })

    const writeProfileUrlToClipboard = async () => {
        if (isNative) {
            let shareOptions: any = {
                title: "Ecliptic // " + userProfile?.username,
                dialogTitle: "Ecliptic // " + userProfile?.username,
                url: userProfileUrl,
            }
            await Share.share(shareOptions)
        } else {
            await Clipboard.write({
                url: userProfileUrl
            })
            await presentToastHandler("Copied Link to Profile!");
        }
    };

    const getUserSavedTricks = async (user_id: string) => {
        setSavesLoading(true);
        const userSavedTricks = await UserService.getUserSavedVideos(user_id);
        setSavedVideos(userSavedTricks);
        setSavesLoading(false);
    }

    const getUserBadges = async (userBadges: string[]) => {
        const currentBadgesMap = new Map<string, Badge>();
        if (userBadges) {
            for (let i = 0; i < userBadges.length; i++) {
                const badge: Badge = await BadgesService.getBadgeByID(userBadges[i]);
                if (currentBadgesMap.has(badge.id)) {
                } else {
                    currentBadgesMap.set(badge.id, badge);
                }
            }
        }

        setBadges(Array.from(currentBadgesMap.values()));
    }

    const resetProfile = async () => {
        console.log("resetting userprofile");
        setProfile(undefined);

        setProfileError(false);
        setClaimProfile(false);
        setFollowingUser(false);
        setFollowingUserLoading(false);

        setEditProfile(undefined);

        setUserTricks([]);
        setEditSortingUserTricks(false);

        setUserLines([]);
        setEditSortingUserLines(false);

        setUserEdits([]);
        setEditSortingUserEdits(false);

        setUserProfileUrl(undefined);
        setSavedVideos([]);
        setBagOfTricksFilter("");
        setStashOfLinesFilter("");
        setGalleryOfEditsFilter("");
        setSavedVideoFilter("");
        setBadges([]);
        setProfileLoading(false);

        setFollowersCount(0);
        setFollowingCount(0);
        setActiveTab(tabs[0]);
        setClipsLinesEditsLoading(false);
        setSavesLoading(false);

        setUserBlocked(false);
        setProfileBlocking(false);
    }

    const checkIfFollowingUser = async (loggedInUserId: string, responseId: string) => {
        const isFollowing = await UserService.checkIfUserFollowingOtherUser(loggedInUserId, responseId);
        setFollowingUser(isFollowing);
    }

    const getUserProfile = async (currentUsername: string) => {
        // reset profile
        await resetProfile();

        setProfileLoading(true);
        console.log("Getting user with this username: " + currentUsername);

        setClipsLinesEditsLoading(true);

        const response: User = await UserService.getUserByUsername(currentUsername);

        if (!response || Object.keys(response).length === 0) {
            console.log("No user found with this username: " + currentUsername);
            setProfileError(true);
            setProfileLoading(false);
            return;
        }

        console.log(response);
        setProfile(response);
        setUserProfileUrl(`${process.env.REACT_APP_IDX_URL}/profile/${response.username}`);
        if (response) {
            const followersCountRes = await UserService.getFollowCount(response.id, true);
            const followingCountRes = await UserService.getFollowCount(response.id, false);
            setFollowersCount(followersCountRes);
            setFollowingCount(followingCountRes);

            setProfileLoading(false);
            if (response.auth_id === null || response.auth_id === undefined || response.auth_id === '') {
                setClaimProfile(true);
            } else {
                setClaimProfile(false);
            }

            let editProfileRes = false;

            if (!isAuthenticated) {
                setEditProfile(false);
            } else {
                if (response.auth_id === user.uid) {
                    setEditProfile(true);
                    editProfileRes = true;
                } else {
                    console.log("I got nothing to do!")
                }

                await checkIfFollowingUser(state.user.id, response.id);
                await getIfBlockedUser(state.user.id, response.id);
                await getIfProfileBlocking(response.id, state.user.id);
            }

            const userTricksRes: UserTrick[] = await TricksService.getUserTricks(response.id)
            const userPreferencesRes: UserPreferences = await UserService.getUserPreferences(response.id)
            if (userTricksRes.length > 0) {
                {/* edit profile will ultimately be a boolean that is passed in from the parent component */
                }
                // SORT HERE LINES + EDITS BEFORE ANALYZING UNLISTED

                const userTricksFiltered: UserTrick[] = userTricksRes.filter((userTrick: UserTrick) => userTrick.clip_type === ClipType.CLIP || (userTrick.clip_type !== ClipType.LINE && userTrick.clip_type !== ClipType.EDIT));
                const userLinesRes: UserTrick[] = userTricksRes.filter((userTrick: UserTrick) => userTrick.clip_type === ClipType.LINE);
                const userEditRes: UserTrick[] = userTricksRes.filter((userTrick: UserTrick) => userTrick.clip_type === ClipType.EDIT);

                // remove lines and edits from userTricksRes
                const userTricksToDisplay: UserTrick[] = analyzeUserTrickResForUnlisted(userTricksFiltered, editProfileRes);
                const userLinesToDisplay: UserTrick[] = analyzeUserTrickResForUnlisted(userLinesRes, editProfileRes);
                const userEditsToDisplay: UserTrick[] = analyzeUserTrickResForUnlisted(userEditRes, editProfileRes);

                if (userPreferencesRes) {
                    const userTrickPreferences: Map<string, number> = userPreferencesRes[UserPreferencesConstants.TRICKS] ? new Map(Object.entries(userPreferencesRes[UserPreferencesConstants.TRICKS])) : new Map();
                    const userLinesPreferences: Map<string, number> = userPreferencesRes[UserPreferencesConstants.LINES] ? new Map(Object.entries(userPreferencesRes[UserPreferencesConstants.LINES])) : new Map();
                    const userEditsPreferences: Map<string, number> = userPreferencesRes[UserPreferencesConstants.EDITS] ? new Map(Object.entries(userPreferencesRes[UserPreferencesConstants.EDITS])) : new Map();

                    if (userTrickPreferences.size > 0) {
                        const sortedTricks = arrangeTricksByPreferences(userTricksToDisplay, userTrickPreferences);
                        setUserTricks(sortedTricks);
                    } else {
                        setUserTricks(userTricksToDisplay);
                    }

                    if (userLinesPreferences.size > 0) {
                        const sortedLines = arrangeTricksByPreferences(userLinesToDisplay, userLinesPreferences);
                        setUserLines(sortedLines);
                    } else {
                        setUserLines(userLinesToDisplay);
                    }

                    if (userEditsPreferences.size > 0) {
                        const sortedEdits = arrangeTricksByPreferences(userEditsToDisplay, userEditsPreferences);
                        setUserEdits(sortedEdits);
                    } else {
                        setUserEdits(userEditsToDisplay);
                    }
                } else {
                    setUserTricks(userTricksToDisplay);
                    setUserLines(userLinesToDisplay);
                    setUserEdits(userEditsToDisplay);
                }
            } else {
                setUserTricks(userTricksRes);
            }

            setClipsLinesEditsLoading(false);

            await getUserBadges(response.profile.badges);
            await getUserSavedTricks(response.id);
        } else {
            setProfileError(true);
            setProfileLoading(false);
        }
        window.prerenderReady = true;
    }

    useEffect(() => {
        if (!isLoading) {
            if (isAuthenticated) {
                if (!username && state.user.username) {
                    console.log("No username in URL, but user is logged in");
                    history.push(`/profile/${state.user.username}`);
                    history.go(0);
                } else if (username) {
                    getUserProfile(username);
                }
            }
        }
    }, [isLoading, username, user?.uid, isAuthenticated, state.user.id, state.user.username, history]);

    function openSocialModal() {
        presentSocial({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    function openTrophyModal() {
        presentTrophy({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    function openEditProfileModal() {
        presentEditProfileModal({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    function openFollowingModal() {
        presentFollowing({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    function openFollowersModal() {
        presentFollowers({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    const onSocialProfileClick = async () => {
        // Open Modal;
        openSocialModal();
    };

    const onTrophyProfileClick = async () => {
        // Open Modal;
        openTrophyModal();
    }

    const onFollowingProfileClick = async () => {
        // Open Modal;
        openFollowingModal();
    };

    const onFollowersProfileClick = async () => {
        // Open Modal;
        openFollowersModal();
    };

    const onFollowUser = async (followingUserState: boolean) => {
        if (isAuthenticated) {
            setFollowingUserLoading(true);

            const response: User = await UserService.followUserV2(state.user.id, userProfile.id, !followingUserState);
            if (response) {
                setFollowingUserLoading(false);
                setFollowingUser(!followingUserState);

                const followersCountRes = await UserService.getFollowCount(userProfile.id, true);
                const followingCountRes = await UserService.getFollowCount(userProfile.id, false);

                setFollowersCount(followersCountRes);
                setFollowingCount(followingCountRes);

                const followCountForLoggedInUser = await UserService.getFollowCount(state.user.id, false);
                dispatch({type: 'setFollowing', payload: followCountForLoggedInUser});
            }
        } else {
            await presentAlert({
                header: 'Login Required',
                message: 'You must be logged in to follow a user',
                buttons: [
                    'Cancel',
                    {
                        text: 'Login',
                        handler: async () => {
                            await login();
                        }
                    }
                ]
            });
        }
    }

    const onTrickSelection = (trick: UserTrickSearch): void => {
        history.push('/clip/' + trick.user_id + '/' + trick.trick_id);
    }

    const onResetProfile = async (event: CustomEvent<RefresherEventDetail>) => {
        await getUserProfile(username);
        event.detail.complete();
    }

    const goToProfileLink = async () => {
        if (isNative) {
            await Browser.open({
                url: userProfile.profile.website,
                presentationStyle: 'popover',
                'toolbarColor': '#000000'
            });
        } else {
            window.open(userProfile.profile.website, '_blank');
        }
    }

    const handleSortingUserTricks = async (sortableTricks: UserTrick[], segment: string) => {
        if (editProfile) {
            const trickIds: string[] = sortableTricks.map((trick) => trick.trick_id);
            const positions: number[] = Array.from(sortableTricks.entries()).map(([index]) => index);
            await UserService.upsertUserPreferences(user.uid, trickIds, positions, segment).then((response) => {
                if (response) {
                    console.log('Successfully updated user trick preferences');
                    console.log(response);
                }
            });

            if (segment === UserPreferencesConstants.TRICKS) {
                setUserTricks(sortableTricks);
            }

            if (segment === UserPreferencesConstants.LINES) {
                setUserLines(sortableTricks);
            }

            if (segment === UserPreferencesConstants.EDITS) {
                setUserEdits(sortableTricks);
            }

        }
    }

    const presentToastHandler = async (message: string) => {
        await presentToast({
            message: message,
            duration: 5000,
            position: 'top',
            icon: alertCircleOutline,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                        console.log('Dismiss clicked');
                    },
                },
            ],
            onDidDismiss: (e: CustomEvent) => console.log(`Dismissed with role: ${e.detail.role}`)
        });
    };

    if (isLoading || profileLoading || (isAuthenticated && !userProfile && !profileError)) {
        return (
            <IonPage>
                <CustomHelmet
                    title={userProfile?.username ? "Ecliptic // " + userProfile?.username : "Ecliptic // View Profile"}
                    description={userProfile?.username ? `View ${userProfile?.username}'s Bag of Tricks` : "Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed"}
                    image={userProfile?.profile?.profile_pic ? userProfile.profile.profile_pic : "https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                    url={`https://ecliptic.day/profile/${username}`}/>
                <ToolbarSearchBar></ToolbarSearchBar>
                <IonContent>
                    <LoadingProfileComponent/>
                </IonContent>
            </IonPage>
        );
    }

    if (!isAuthenticated) {
        return (<LoginButtonPage
            title="Create Your Profile to Build Your Bag of Tricks and Interact With the Ecliptic Community"
            upload={false} profile={true} brand={false}/>)
    }

    if (profileError) {
        return (
            <IonPage>
                <ToolbarSearchBar></ToolbarSearchBar>
                <IonContent>
                    <div className="flex flex-col gap-2 m-4 space-y-4 font-bold text-2xl">
                        <div className="flex-col text-center">
                            <div>There was an error loading this profile.</div>
                        </div>
                        <div className="flex-col text-center">
                            <IonButton onClick={() => {
                                history.push('/home');
                                history.go(0);
                            }} color="favorite">Go Back to Ecliptic</IonButton>
                        </div>
                    </div>
                </IonContent>
            </IonPage>
        )
    }

    return (
        <IonPage>
            <CustomHelmet
                title={userProfile?.username ? "Ecliptic // " + userProfile?.username : "Ecliptic // View Profile"}
                description={userProfile?.username ? `View ${userProfile?.username}'s Bag of Tricks` : "Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed"}
                image={userProfile?.profile?.profile_pic ? userProfile.profile.profile_pic : "https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                url={`https://ecliptic.day/profile/${username}`}/>
            <ToolbarSearchBar></ToolbarSearchBar>
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={onResetProfile}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <div className="flex flex-col mx-2 mt-2 mb-0 gap-3 font-bold text-2xl">
                    <div className="grid grid-cols-[auto,1fr,auto] gap-x-4 items-center w-full">
                        {/* Box 1: Pic + Name + Sport + Location */}
                        <div className="flex flex-col items-center">
                            <ProfilePicture src={userProfile?.profile?.profile_pic} editProfile={editProfile}
                                            isNative={isNative}></ProfilePicture>
                        </div>
                        <div className="flex flex-col gap-y-0.5">
                            {/* User Info */}
                            <div className="flex flex-row text-2xl gap-x-1 items-center">
                                <UsernameDisplay username={userProfile?.username} className={""}
                                                 userId={userProfile?.id} loggedInId={state.user?.id}/>
                                {
                                    claimProfile &&
                                    <IonIcon slot="icon-only" color="dark" icon={helpCircleOutline}
                                             onClick={() => presentToastHandler("Is This You? DM Us on Instagram @idx.style or Email Us at claim@idx.style to claim your profile")}/>
                                }
                            </div>
                            <div className="text-base">{userProfile?.name}</div>
                            <div className="text-base">{userProfile?.profile?.home_mountain}</div>
                            <div className="flex flex-row items-center gap-x-1">
                                {/* Website Link - TODO: ADD SPONSORS HERE */}
                                {userProfile?.profile?.website && (
                                    <IonIcon icon={linkOutline} size="small"></IonIcon>)}
                                {userProfile?.profile?.website && (
                                    <div className="flex flex-row gap-2 font-normal text-base"
                                         onClick={goToProfileLink}>
                                        <a>{userProfile?.profile?.website_name || userProfile?.profile?.website}</a>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col items-end space-y-0.5">
                            <div className="flex flex-col items-end justify-start space-y-0.5">
                                <div className="flex flex-col gap-0.5 items-end">
                                    <IonButton fill="outline" color="favorite-secondary" size="small"
                                               onClick={() => onSocialProfileClick()}>
                                                <span
                                                    className="hidden md:inline-flex md:pt-0.5 md:pr-2 md:pl-1 text-white">Info</span>
                                        <IonIcon color="dark" icon={globeOutline}/>
                                    </IonButton>
                                    <IonButton fill="outline" color="favorite-secondary" size="small"
                                               onClick={() => onTrophyProfileClick()}>
                                                <span
                                                    className="hidden md:inline-flex md:pt-0.5 md:pr-2 md:pl-1 text-white">Case</span>
                                        <IonIcon color="dark" icon={briefcaseOutline}/>
                                    </IonButton>
                                    <BiskProfile senderId={state?.user?.id} recipientId={userProfile?.id}
                                                 recipientUsername={userProfile?.username}
                                                 isAuthed={isAuthenticated} component="profile"
                                                 email={state?.user?.email}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center gap-x-4">
                        <div>
                            {/* Sports Icons */}
                            <div className="flex flex-row items-end gap-x-4">
                                {userProfile?.profile?.sports?.includes(SportType.SNOWBOARDING) && (
                                    <button
                                        className="w-[1.05em] h-[1.05em] rounded-full overflow-hidden flex items-center justify-center">
                                        <span role="img" aria-label="Snowboarding" className="text-lg">🏂</span>
                                    </button>
                                )}
                                {userProfile?.profile?.sports?.includes(SportType.SKIING) && (
                                    <button
                                        className="w-[1.05em] h-[1.05em] rounded-full overflow-hidden flex items-center justify-center">
                                        <span role="img" aria-label="Skiing" className="text-lg">⛷️</span>
                                    </button>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className="flex flex-row gap-x-4 items-center">
                                {
                                    badges.slice(0, 8 - userProfile?.profile?.sports.length).map((entry, index) => {
                                        return (
                                            <button
                                                className="w-[1.05em] h-[1.05em] rounded-full overflow-hidden"
                                                onClick={openTrophyModal} key={index}>
                                                <img src={entry.link} alt={entry.name}
                                                     className="object-cover w-full h-full"></img>
                                            </button>
                                        );
                                    })
                                }
                                {
                                    badges.length > (8 - userProfile?.profile?.sports.length) ?
                                        <button className="w-[1.05em] h-[1.05em]" onClick={openTrophyModal}>
                                                    <span role="img" aria-label="More Badges"
                                                          style={{color: 'white'}}>…</span>
                                        </button> : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row mb-3 justify-between items-center flex-nowrap">
                        <div className="flex flex-nowrap">
                            <IonButton fill="solid" size="small" color="favorite"
                                       onClick={() => onFollowingProfileClick()}>
                                Following: {followingCount}
                            </IonButton>
                            {
                                editProfile || (state.isSubscribed) ?
                                    <IonButton fill="solid" size="small" color="favorite"
                                               onClick={() => onFollowersProfileClick()}>
                                        Followers: {followersCount}
                                    </IonButton>
                                    :
                                    null
                            }
                        </div>
                        <div className="flex flex-nowrap">
                            <IonButton id="share-trigger-profile" fill="outline" color="favorite" size="small"
                                       onClick={() => writeProfileUrlToClipboard()}>
                                        <span
                                            className="hidden md:inline-flex md:pt-0.5 md:pl-1 pr-2 text-white">Share</span>
                                <IonIcon color="dark" className="md:pb-0.5" icon={shareOutline}/>
                            </IonButton>
                            {editProfile ?
                                <IonButton fill="solid" color="favorite" size="small"
                                           onClick={() => openEditProfileModal()}>
                                    <IonIcon slot="icon-only" icon={pencilOutline}/>
                                </IonButton> :
                                followingUserLoading ?
                                    <IonButton fill="clear" size="small">
                                        <IonSpinner name="bubbles" color="theme-alternative"></IonSpinner>
                                    </IonButton> :
                                    (
                                        !followingUser || !isAuthenticated ?
                                            <IonButton fill="solid" color="favorite" size="small"
                                                       onClick={() => onFollowUser(followingUser)}>
                                                Follow
                                            </IonButton> :
                                            <IonButton fill="solid" color="favorite" size="small"
                                                       onClick={() => onFollowUser(followingUser)}>
                                                <IonIcon slot="icon-only" icon={personRemoveOutline}/>
                                            </IonButton>
                                    )
                            }
                        </div>
                    </div>
                </div>
                {
                    userBlocked || profileBlocking ?
                        <div className="flex flex-row my-2 justify-center">
                            {
                                userBlocked &&
                                <BlockUser blockedUserId={userProfile?.id} userBlocked={userBlocked}
                                           onBlockUser={blockUser} username={userProfile?.username}
                                           loggedIn={isAuthenticated}/>
                            }
                            {
                                profileBlocking &&
                                <ReturnToProfile username={state?.user?.username}
                                                 usernameCallback={goToSelfProfile}/>
                            }
                        </div>
                        :
                        <div>
                            <div
                                className="flex justify-center space-x-4 border-b-2 border-gray-500 text-base font-bold">
                                {tabs.map((tab, index) => (
                                    <div key={index}
                                         className={activeTab === tab ? 'border-b-4 border-[#5963A7]' : ''}>
                                        <button
                                            key={tab}
                                            className={`py-2 px-2.5 text-xl font-bold ${activeTab === tab ? 'border-b-4 border-[#5963A7] text-white' : 'text-gray-500 hover:text-white'}`}
                                            onClick={() => {
                                                setSavedVideoFilter("");
                                                setBagOfTricksFilter("");
                                                setStashOfLinesFilter("");
                                                setGalleryOfEditsFilter("");
                                                setActiveTab(tab)
                                            }}>
                                            {tab} {!clipsLinesEditsLoading && <span
                                            className="text-sm">{tab === 'Clips' ? ` ${userTricks.length}` : tab === 'Lines' ? ` ${userLines.length}` : tab === 'Edits' ? ` ${userEdits.length}` : ''}</span>}
                                        </button>
                                    </div>

                                ))}
                            </div>
                            <div
                                className="flex flex-row justify-between items-center mb-0.5">
                                <IonSearchbar
                                    onIonInput={(e) => {
                                        if (activeTab === 'Clips') {
                                            setBagOfTricksFilter(e.detail.value)
                                        } else if (activeTab === 'Lines') {
                                            setStashOfLinesFilter(e.detail.value)
                                        } else if (activeTab === 'Edits') {
                                            setGalleryOfEditsFilter(e.detail.value)
                                        } else if (activeTab === 'Saves') {
                                            setSavedVideoFilter(e.detail.value)
                                        } else {
                                            return;
                                        }
                                    }}
                                    showClearButton="focus"
                                    showCancelButton="focus"
                                    style={{paddingTop: 0, paddingBottom: 0}}
                                    id="searchText"
                                    debounce={0}
                                    animated={true}></IonSearchbar>
                                {isNative && editProfile && userTricks.length > 0 && activeTab === 'Clips' ?
                                    <IonButton fill="outline"
                                               color={!editSortingUserTricks ? "favorite-secondary" : "success"}
                                               size="small"
                                               onClick={() => setEditSortingUserTricks(!editSortingUserTricks)}>
                                        <IonIcon slot="icon-only" color="dark"
                                                 icon={!editSortingUserTricks ? createOutline : checkmarkCircleOutline}/>
                                    </IonButton>
                                    : null
                                }
                                {isNative && editProfile && userLines.length > 0 && activeTab === 'Lines' ?
                                    <IonButton fill="outline"
                                               color={!editSortingUserLines ? "favorite-secondary" : "success"}
                                               size="small"
                                               onClick={() => setEditSortingUserLines(!editSortingUserLines)}>
                                        <IonIcon slot="icon-only" color="dark"
                                                 icon={!editSortingUserLines ? createOutline : checkmarkCircleOutline}/>
                                    </IonButton>
                                    : null
                                }
                                {isNative && editProfile && userEdits.length > 0 && activeTab === 'Edits' ?
                                    <IonButton fill="outline"
                                               color={!editSortingUserEdits ? "favorite-secondary" : "success"}
                                               size="small"
                                               onClick={() => setEditSortingUserEdits(!editSortingUserEdits)}>
                                        <IonIcon slot="icon-only" color="dark"
                                                 icon={!editSortingUserEdits ? createOutline : checkmarkCircleOutline}/>
                                    </IonButton>
                                    : null
                                }
                            </div>
                            <div className="p-1">
                                {
                                    activeTab === 'Clips' &&
                                    <>
                                        {
                                            !clipsLinesEditsLoading ?
                                                (
                                                    userTricks.length > 0 ?
                                                        <>
                                                            <div>
                                                                <ReactSortable
                                                                    disabled={isNative ? (!editSortingUserTricks || bagOfTricksFilter.length > 0 || !editProfile) : (bagOfTricksFilter.length > 0 || !editProfile)}
                                                                    list={userTricks}
                                                                    setList={(newState, sortable, store) => {
                                                                        if (store.dragging && store.dragging.props && JSON.stringify(store.dragging.props.list) !== JSON.stringify(newState)) {
                                                                            handleSortingUserTricks(newState, UserPreferencesConstants.TRICKS);
                                                                        }
                                                                    }}
                                                                    className="grid grid-flow-row auto-row-max xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-2"
                                                                    delay={100} delayOnTouchOnly={isNative}
                                                                    sort={true}>
                                                                    {userTricks.filter(
                                                                        (trick) => trick.trick_name.toLowerCase().includes(bagOfTricksFilter.toLowerCase()) ||
                                                                            (trick.location && trick.location.toLowerCase().includes(bagOfTricksFilter.toLowerCase()))).map((trick: UserTrickSearch, i) => (
                                                                        <div key={trick.trick_id}
                                                                             onClick={() => onTrickSelection(trick)}
                                                                             className={trick.tags.includes("Unlisted") ? "opacity-25" : ""}>
                                                                            <ProfileTrickCard trick={trick}/>
                                                                        </div>
                                                                    ))}
                                                                </ReactSortable>
                                                            </div>
                                                        </> :
                                                        <div
                                                            className="flex flex-col gap-2 m-4 space-y-4 text-base text-center">
                                                            <div>{editProfile ? "Upload to build your bag of tricks 🤑" : `Looks like ${userProfile?.username}'s Bag of Tricks is empty 😔`}</div>
                                                        </div>
                                                )
                                                :
                                                <div
                                                    className="flex flex-col gap-2 m-4 space-y-4 justify-center items-center">
                                                    <IonSpinnerMainContent className={"my-2"}
                                                                           size="medium"/> {/* You can choose other spinner names as well */}
                                                </div>
                                        }
                                    </>
                                }
                                {
                                    activeTab === 'Lines' &&
                                    <>
                                        {
                                            !clipsLinesEditsLoading ?
                                                (
                                                    userLines.length > 0 ?
                                                        <>
                                                            <div>
                                                                <ReactSortable
                                                                    disabled={isNative ? (!editSortingUserLines || stashOfLinesFilter.length > 0 || !editProfile) : (stashOfLinesFilter.length > 0 || !editProfile)}
                                                                    list={userLines}
                                                                    setList={(newState, sortable, store) => {
                                                                        if (store.dragging && store.dragging.props && JSON.stringify(store.dragging.props.list) !== JSON.stringify(newState)) {
                                                                            handleSortingUserTricks(newState, UserPreferencesConstants.LINES)
                                                                        }
                                                                    }}
                                                                    className="grid grid-flow-row auto-row-max xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-2"
                                                                    delay={100} delayOnTouchOnly={isNative}
                                                                    sort={true}>
                                                                    {userLines.filter(
                                                                        (trick) =>
                                                                            trick.trick_name.toLowerCase().includes(stashOfLinesFilter.toLowerCase()) ||
                                                                            (trick.location && trick.location.toLowerCase().includes(stashOfLinesFilter.toLowerCase())))
                                                                        .map((trick: UserTrickSearch, i) => (
                                                                            <div key={trick.trick_id}
                                                                                 onClick={() => onTrickSelection(trick)}
                                                                                 className={trick.tags.includes("Unlisted") ? "opacity-25" : ""}>
                                                                                <ProfileTrickCard
                                                                                    trick={trick}/>
                                                                            </div>
                                                                        ))}
                                                                </ReactSortable>
                                                            </div>
                                                        </> :
                                                        <div
                                                            className="flex flex-col gap-2 m-4 space-y-4 text-base text-center">
                                                            <div>{editProfile ? "Upload to build your stash of lines 😮‍💨" : `Looks like ${userProfile?.username}'s Stash of Lines is empty 😥`}</div>
                                                        </div>
                                                )
                                                :
                                                <div
                                                    className="flex flex-col gap-2 m-4 space-y-4 justify-center items-center">
                                                    <IonSpinnerMainContent className={"my-2"}
                                                                           size="medium"/> {/* You can choose other spinner names as well */}

                                                </div>
                                        }
                                    </>
                                }
                                {
                                    activeTab === 'Edits' &&
                                    <>
                                        {
                                            !clipsLinesEditsLoading ?
                                                (
                                                    userEdits.length > 0 ?
                                                        <>
                                                            <div>
                                                                <ReactSortable
                                                                    disabled={isNative ? (!editSortingUserEdits || galleryOfEditsFilter.length > 0 || !editProfile) : (galleryOfEditsFilter.length > 0 || !editProfile)}
                                                                    list={userEdits}
                                                                    setList={(newState, sortable, store) => {
                                                                        if (store.dragging && store.dragging.props && JSON.stringify(store.dragging.props.list) !== JSON.stringify(newState)) {
                                                                            handleSortingUserTricks(newState, UserPreferencesConstants.EDITS)
                                                                        }
                                                                    }}
                                                                    className="grid grid-flow-row auto-row-max xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-2"
                                                                    delay={100} delayOnTouchOnly={isNative}
                                                                    sort={true}>
                                                                    {userEdits.filter(
                                                                        (trick) =>
                                                                            trick.trick_name.toLowerCase().includes(galleryOfEditsFilter.toLowerCase()) ||
                                                                            (trick.location && trick.location.toLowerCase().includes(galleryOfEditsFilter.toLowerCase())))
                                                                        .map((trick: UserTrickSearch, i) => (
                                                                            <div key={trick.trick_id}
                                                                                 onClick={() => onTrickSelection(trick)}
                                                                                 className={trick.tags.includes("Unlisted") ? "opacity-25" : ""}>
                                                                                <ProfileTrickCard
                                                                                    trick={trick}/>
                                                                            </div>
                                                                        ))}
                                                                </ReactSortable>
                                                            </div>
                                                        </> :
                                                        <div
                                                            className="flex flex-col gap-2 m-4 space-y-4 text-base text-center">
                                                            <div>{editProfile ? "Upload to build your gallery of edits 🤯" : `Looks like ${userProfile?.username}'s Gallery of Edits is empty 😲`}</div>
                                                        </div>
                                                )
                                                :
                                                <div
                                                    className="flex flex-col gap-2 m-4 space-y-4 justify-center items-center">
                                                    <IonSpinnerMainContent className={"my-2"}
                                                                           size="medium"/> {/* You can choose other spinner names as well */}

                                                </div>
                                        }
                                    </>
                                }
                                {
                                    activeTab === 'Saved' &&
                                    <>
                                        {
                                            !savesLoading ?
                                                (
                                                    savedVideos.length > 0 ?
                                                        <>
                                                            <div
                                                                className="grid grid-flow-row auto-row-max xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-2">
                                                                {savedVideos.filter(
                                                                    (trick: UserTrickSearch) =>
                                                                        trick.trick_name.toLowerCase().includes(savedVideoFilter.toLowerCase())
                                                                        || (trick.location && trick.location.toLowerCase().includes(savedVideoFilter.toLowerCase()))
                                                                        || (trick.username && trick.username.toLowerCase().includes(savedVideoFilter.toLowerCase()))
                                                                        || (trick.name && trick.name.toLowerCase().includes(savedVideoFilter.toLowerCase())))
                                                                    .map((trick: UserTrickSearch, i) => (
                                                                        <div key={trick.trick_id}
                                                                             onClick={() => onTrickSelection(trick)}>
                                                                            <div
                                                                                className="cursor-pointer p-0 m-0 border border-gray-900 bg-black rounded-md font-bold">
                                                                                <IonImg
                                                                                    className="object-cover min-w-full min-h-full h-full aspect-video"
                                                                                    src={trick?.thumbnail}
                                                                                    alt={trick?.trick_name}/>
                                                                                <div className="bg-black p-4">
                                                                                    <div
                                                                                        className="text-base text-white">{trick.trick_name}</div>
                                                                                    <div
                                                                                        className="text-sm">{trick.username ? trick.username : trick.name}</div>
                                                                                    {/* TODO: this would be the username of someone or their name */}
                                                                                    {trick.location && trick.location !== "" && (
                                                                                        <div
                                                                                            className="text-sm">{trick.location}</div>)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        </>
                                                        :
                                                        <div
                                                            className="flex flex-col gap-2 m-4 space-y-4 text-base text-center">
                                                            <div>{editProfile ? "Save videos to help with your progression 🅿️" : `It appears ${userProfile?.username} hasn't saved any videos 😵‍💫`}</div>
                                                        </div>
                                                ) :
                                                <div
                                                    className="flex flex-col gap-2 m-4 space-y-4 justify-center items-center">
                                                    <IonSpinnerMainContent className={"my-2"}
                                                                           size="medium"/> {/* You can choose other spinner names as well */}
                                                </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                }
            </IonContent>
        </IonPage>
    );
};

export default Profile;