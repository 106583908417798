import React, {useEffect} from "react";
import {Redirect, Route, useHistory} from "react-router-dom";
import {IonRouterOutlet} from "@ionic/react";
import Welcome from "./components/ComponentsOnboarding/Welcome/Welcome";
import WelcomeLogin from "./components/ComponentsOnboarding/WelcomeLogin/WelcomeLogin";
import WelcomeSignUp from "./components/ComponentsOnboarding/WelcomeSignUp/WelcomeSignUp";
import {useDeepLink} from "./DeepLinkProvider";
import PreferencesService from "./services/preferences.service";
import {useAuth} from "./AuthProvider";
import {protectedRoutes} from "./services/utils";
import {OnboardingStatus} from "./models/onboardingStatus";

interface AppOnboardingProps {
    appOnboardingStatus: string;
}

const AppOnboarding: React.FC<AppOnboardingProps> = ({appOnboardingStatus}) => {
    const {deepLinkData, clearDeepLinkData} = useDeepLink();
    const {isLoading} = useAuth();

    const history = useHistory();

    const handleRedirect = (appOnboardingStatusInput: string) => {
        console.log("Handling redirect to proper sign up page with status: " + appOnboardingStatusInput);
        if (appOnboardingStatusInput) {
            if (appOnboardingStatusInput === OnboardingStatus.INIT) {
                return (<Redirect to='/flow'/>)
            } else if (appOnboardingStatusInput.includes(OnboardingStatus.SIGNUP)) {
                return (<Redirect to='/flow/signup'/>)
            } else if (appOnboardingStatusInput.includes(OnboardingStatus.LOGIN)) {
                return (<Redirect to='/flow/login'/>)
            } else {
                return (<Redirect to='/flow'/>)
            }
        } else {
            return (<Redirect to='/flow'/>)
        }
    };

    useEffect(() => {
        const handleDeepLink = async () => {
            if (isLoading) return;
            if (deepLinkData.slug) {
                console.log("Handling deep link in onboarding:", deepLinkData.slug);
                if (deepLinkData.slug.startsWith('/share') && deepLinkData.referrer) {
                    // Store referrer for later use
                    await PreferencesService.setReferrer(deepLinkData.referrer);
                    console.log(`Referral from: ${deepLinkData.referrer}`);
                    history.push('/flow/signup');
                } else if (protectedRoutes.some(route => deepLinkData.slug.startsWith(route))) {
                    // If it's a protected route, store it for later and continue with onboarding
                    await PreferencesService.setIntendedDestination(deepLinkData.slug);
                    console.log('Protected route deep link stored for after onboarding:', deepLinkData.slug);
                } else {
                    // Handle other onboarding-specific deep links
                    switch (deepLinkData.slug) {
                        case '/signup':
                            history.push('/flow/signup');
                            break;
                        case '/login':
                            history.push('/flow/login');
                            break;
                        default:
                            console.log('Unhandled deep link in onboarding:', deepLinkData.slug);
                    }
                }

                // Clear the deep link data after handling, but keep the referrer
                await clearDeepLinkData();
            }
        };

        handleDeepLink();
    }, [deepLinkData, isLoading, clearDeepLinkData, history]);

    return (
        <IonRouterOutlet animated={false}>
            <Route path="/" exact={true} render={() => handleRedirect(appOnboardingStatus)}/>
            <Route path="/flow" component={Welcome} exact={true}/>
            <Route path="/flow/signup" component={WelcomeSignUp} exact={true}/>
            <Route path="/flow/login" component={WelcomeLogin} exact={true}/>
            <Route render={() => <Redirect to={'/'}/>}/>
        </IonRouterOutlet>
    );
};

{/*
Notes:

Profile Route:
like instagram, if logged in, then go to your profile with username,
if not then assume it's another profile, and use that to get profile information.
Should get profile information from the username that is supplied
*/
}

export default AppOnboarding;