import React, {memo, useEffect} from 'react';
import HomeTrickCard from "../HomeTrickCard/HomeTrickCard";

interface TrickCardItemProps {
    trick: any;  // Replace 'any' with the actual type of your item
    isAuthed: boolean;
    isCapacitor: boolean;
    userType: string;
    userId: string;
    publicId: string;
    trickId: string;
    scrollSpeed: number;
    isScrolling: boolean;
    setNumberOfActivePlayersCallback?: (num: number) => void;
}

const HomeTrickCardContainer: React.FC<TrickCardItemProps> = memo((props: TrickCardItemProps) => {
    const {
        trick,
        isAuthed,
        isCapacitor,
        userId,
        publicId,
        userType,
        trickId,
        setNumberOfActivePlayersCallback
    } = props;

    useEffect(() => {
        // Increment the number of active players when the component mounts
        if (setNumberOfActivePlayersCallback) {
            setNumberOfActivePlayersCallback(1);
        }

        // Decrement the number of active players when the component unmounts
        return () => {
            if (setNumberOfActivePlayersCallback) {
                setNumberOfActivePlayersCallback(-1);
            }
        };
    }, [setNumberOfActivePlayersCallback]);

    return (
        <div className="flex flex-col justify-center items-center content-center">
            <HomeTrickCard
                trick={trick}
                isAuthed={isAuthed}
                isCapacitor={isCapacitor}
                userId={userId}
                publicId={publicId}
                trickId={trick.id}
                userType={userType}
                isScrolling={props.isScrolling}
                scrollSpeed={props.scrollSpeed}
            />
        </div>
    );
});

export default HomeTrickCardContainer;
