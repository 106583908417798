// components/SparkGrid.tsx
import React, {useEffect, useState} from 'react';
import '../Welcome.css';

const brandColors = [
    '#5861AC', '#324E80', '#8FBC8F', '#ff6ad5', '#0affff'
];

interface SparkGridProps {
    animate?: boolean;
    height?: string;
}

interface SparkProps {
    isHorizontal: boolean;
    startPosition: number;
    endPosition: number;
    color: string;
    animate: boolean;
}

const Spark: React.FC<SparkProps> = ({isHorizontal, startPosition, endPosition, color, animate}) => {
    const duration = animate ? Math.random() * 10 + 5 : 0;

    const style: React.CSSProperties = {
        position: 'absolute',
        [isHorizontal ? 'top' : 'left']: `${startPosition}%`,
        [isHorizontal ? 'left' : 'top']: animate ? '-20%' : `${endPosition}%`,
        width: isHorizontal ? (animate ? '20%' : `${Math.abs(endPosition - startPosition)}%`) : '1px',
        height: isHorizontal ? '1px' : (animate ? '20%' : `${Math.abs(endPosition - startPosition)}%`),
        backgroundColor: color,
        opacity: 0.6,
        boxShadow: `0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px ${color}, 0 0 35px ${color}, 0 0 40px ${color}, 0 0 50px ${color}, 0 0 75px ${color}`
    };

    if (animate) {
        style.animation = `spark${isHorizontal ? 'Horizontal' : 'Vertical'} ${duration}s linear infinite`;
    }

    return <div style={style}/>;
};

const SparkGrid: React.FC<SparkGridProps> = ({animate = true, height}) => {
    const [sparks, setSparks] = useState<JSX.Element[]>([]);

    useEffect(() => {
        const createSpark = () => {
            const isHorizontal = Math.random() < 0.5;
            const startPosition = Math.floor(Math.random() * 100);
            const endPosition = Math.floor(Math.random() * 100);
            const color = brandColors[Math.floor(Math.random() * brandColors.length)];

            return (
                <Spark
                    key={Math.random()}
                    isHorizontal={isHorizontal}
                    startPosition={startPosition}
                    endPosition={endPosition}
                    color={color}
                    animate={animate}
                />
            );
        };

        const newSparks = Array(10).fill(null).map(createSpark);
        setSparks(newSparks);
    }, [animate]);

    return (
        <div className="absolute inset-0 bg-grid-pattern overflow-hidden"
             style={{minHeight: height ? `${height}` : '100%'}}>
            {sparks}
        </div>
    );
};

export default SparkGrid;