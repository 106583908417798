import React from "react";
import MultiSelect from "../MultiSelect/MultiSelect";

interface ContainerProps {
    feature: string,
    parentAddTag: (tag: string, feature: string) => void,
    parentRemoveTag: (tag: string, feature: string) => void,
    options: string[],
    selectedOptions: string[],
    invalid?: boolean,
}

const TagDropdown: React.FC<ContainerProps> = (props: ContainerProps) => {

    const addTag = (tag: string) => {
        props.parentAddTag(tag, props.feature);
    }

    const removeTag = (tag: string) => {
        props.parentRemoveTag(tag, props.feature);
    }

    return (
        <>
            <div className="w-full">
                {props.feature}
                {props.invalid &&
                    <p className="text-red-500 text-xs italic">
                        Please select only one {props.feature} tag. If you want to select a different tag, please remove
                        your current tag first.
                    </p>
                }
                <MultiSelect
                    options={props.options}
                    selectedOptions={props.selectedOptions}
                    addTag={addTag}
                    removeTag={removeTag}
                    showDropdown={false}
                    placeholder={props.feature ? `Click to add/edit ${props.feature} tags` : "Click to add/edit tags"}
                />
            </div>
        </>
    );
};

export default TagDropdown;
