import {IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import React from "react";

interface ContainerProps {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void,
    description: string,
    title: string,
}

const BrandDescriptionModal: React.FC<ContainerProps> = ({onDismiss, description, title}) => {

    async function confirm() {
        onDismiss();
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="flex flex-row">
                    <IonButtons slot="start">
                        <IonButton strong={true} onClick={() => onDismiss(null, 'cancel')}>
                            Exit
                        </IonButton>
                    </IonButtons>
                    <IonTitle className="items-center text-center">{title} Info</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => confirm()}>
                            Close
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <div>
                    {description.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br/>
                        </React.Fragment>
                    ))}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default BrandDescriptionModal;