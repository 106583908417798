import React, {useEffect, useState} from 'react';
import {IonContent, IonImg, IonPage, IonProgressBar} from '@ionic/react';

const LoadingSplash: React.FC = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const duration = 500; // 0.5 seconds
        const interval = 10; // Update every 10ms
        const steps = duration / interval;
        let currentStep = 0;

        const timer = setInterval(() => {
            currentStep++;
            setProgress(currentStep / steps);

            if (currentStep >= steps) {
                clearInterval(timer);
            }
        }, interval);

        return () => clearInterval(timer);
    }, []);

    const gradientStyle = {
        background: 'radial-gradient(circle at center, #1a1a1a 0%, #000000 50%, #000000 100%)',
    };

    return (
        <IonPage>
            <IonContent>
                <div className="flex flex-col items-center justify-center h-full p-20" style={gradientStyle}>
                    <div className="mb-4 w-full sm:max-w-1/2 md:max-w-1/3 lg:max-w-1/4">
                        <IonImg src="/assets/logo/ECLIPTIC_NEW_WHITE_PNG_NO_PADDING.png"/>
                    </div>
                    <IonProgressBar value={progress} color="tertiary" className="w-3/4 h-2 text-highlight"/>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default LoadingSplash;