import React, {useEffect} from "react";
import '../Welcome.css'
import {OnboardingStatus} from "../../../models/onboardingStatus";
import {useHistory} from "react-router-dom";
import WelcomeLoginUsername from "../WelcomeLoginUsername/WelcomeLoginUsername";
import {OnboardingStepConfig} from "../../../models/onboardingStepConfig";
import WelcomeLoginAuth from "../WelcomeLoginAuth/WelcomeLoginAuth";
import {useSignUp} from "../SignUpProvider/SignUpProvider";

interface ContainerProps {
}

const WelcomeLogin: React.FC<ContainerProps> = ({}) => {
    const history = useHistory();
    const {step, setStep} = useSignUp();

    const stepConfigState: Record<string, OnboardingStepConfig> = {
        [OnboardingStatus.IN_PROGRESS_LOGIN]: {
            titleText: 'Log In to Ecliptic',
            showBackButton: true,
            showSkipButton: false,
            onBack: async () => {
                await navigateBackToInitial();
            }
        },
        [OnboardingStatus.IN_PROGRESS_LOGIN_USERNAME]: {
            titleText: 'Enter Email or Username and Password',
            showSkipButton: false,
            showBackButton: true,
            onBack: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_LOGIN);
            },
        }
    };

    const navigateBackToInitial = async () => {
        await handleOnboardingStatus(OnboardingStatus.INIT);
        history.push('/flow');
    }

    const handleOnboardingStatus = async (status: string) => {
        await setStep(status);
    }

    useEffect(() => {
        const redirectBasedOnStep = async () => {
            console.log("Step: " + step);
            if (step === OnboardingStatus.IN_PROGRESS_LOGIN_INIT) {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_LOGIN);
            }
        };

        redirectBasedOnStep();
    }, [step]);

    useEffect(() => {
        const initializeStep = async () => {
            if (step) {
                if (step.includes(OnboardingStatus.LOGIN)) {
                    await handleOnboardingStatus(step);
                } else {
                    console.log("INVALID STATUS: GOING TO FLOW");
                    await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_LOGIN_INIT);
                }
            } else {
                await handleOnboardingStatus(OnboardingStatus.INIT);
                history.push("/flow");
            }
        };

        console.log("Loading Login Flow App Page");
        initializeStep();
    }, []);  // Empty dependency array means this runs once on mount

    const renderStep = () => {
        switch (step) {
            case OnboardingStatus.IN_PROGRESS_LOGIN:
                return (
                    <WelcomeLoginAuth onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN].onBack}
                                      showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN].showBackButton}
                                      showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN].showSkipButton}
                                      title={stepConfigState[OnboardingStatus.IN_PROGRESS_LOGIN].titleText}
                                      handleAuthedStep={() => handleOnboardingStatus(OnboardingStatus.COMPLETED)}
                                      handleEmailAuthStep={() => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_LOGIN_USERNAME)}/>
                );
            case OnboardingStatus.IN_PROGRESS_LOGIN_USERNAME:
                return (
                    <WelcomeLoginUsername
                        handleGoBackStateChange={() => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_LOGIN)}
                        handleLoginCompletedStateChange={() => handleOnboardingStatus(OnboardingStatus.COMPLETED)}
                    />
                )
            default:
                return null;
        }
    }

    return renderStep();
};

export default WelcomeLogin;