import {IonSpinner} from "@ionic/react";
import React from "react";

interface ContainerProps {
    className: string;
    size?: string;
}

const IonSpinnerMainContent: React.FC<ContainerProps> = ({className, size}) => {
    return (
        <IonSpinner className={className}
                    color="theme-secondary"
                    style={{transform: size === "medium" ? 'scale(1.5)' : "large" ? 'scale(2)' : "xl" ? "scale(2.5)" : 'scale(1)'}}/>
    );
};

export default IonSpinnerMainContent;
