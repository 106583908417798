import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonHeader,
    IonImg,
    IonPage,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import EventUpsert from "../components/ComponentsEvent/EventUpsert/EventUpsert";
import PostUpsert from "../components/ComponentsCrewProfile/PostUpsert/PostUpsert";
import {Brand} from "../models/brand";
import BrandsService from "../services/brand.service";
import {useAuth} from "../AuthProvider";
import LoadingPage from "./LoadingPage";
import LoginButtonPage from "./LoginButtonPage";

const CrewUpload: React.FC = () => {
    // brand details
    const {user, isLoading, isAuthenticated} = useAuth();
    const [brand, setBrand] = useState<Brand>(undefined);
    const [brandError, setBrandError] = useState<boolean>(false);

    const [createEvent, setCreateEvent] = useState<boolean>(false);
    const [createPost, setCreatePost] = useState<boolean>(false);

    const history = useHistory();

    const eventPics = [
        '/assets/photos/create-event-snowboard.jpeg',
        '/assets/photos/create-event-ski.webp',
    ]

    const resetCrewUploadPage = () => {
        // Brand
        setBrandError(false);
        setBrand(null);

        // General
        setCreateEvent(false);
        setCreatePost(false);
    }


    useEffect(() => {
        console.log("On the upload page and rendering");
        resetCrewUploadPage();

        const getBrandProfile = async () => {
            console.log("getting brand profile")
            const response: Brand = await BrandsService.getBrandByAuthId(user?.uid);
            setBrand(response);

            if (response === null) {
                setBrandError(true);
            }
        }

        if (!isLoading) {
            if (isAuthenticated) {
                getBrandProfile();
            }
        }

    }, [isLoading, user?.uid, isAuthenticated]);

    useEffect(() => {
        console.log("On the upload page and rendering");
        setCreatePost(false);
        setCreateEvent(false);
    }, [isLoading, isAuthenticated, history]);

    const goToPostUpload = () => {
        setCreatePost(true);
        setCreateEvent(false);
    }

    const goToEventUpload = () => {
        setCreateEvent(true);
        setCreatePost(false);
    }

    if (isLoading) {
        return (<LoadingPage/>);
    } else if (!isAuthenticated) {
        return (<LoginButtonPage title="Log Into Ecliptic" upload={false} profile={false}/>);
    }
    if (brandError) {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Upload</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <div className="flex flex-row justify-center items-center w-full mt-8">
                        <IonButton color="favorite" onClick={() => history.push('/home')}>
                            Return to the home page
                        </IonButton>
                    </div>
                </IonContent>
            </IonPage>
        )
    }

    const revertToTheBeginning = (creatingPost: boolean, creatingEvent: boolean) => {
        setCreateEvent(creatingEvent);
        setCreatePost(creatingPost);
    }

    if (createEvent) {
        return (
            <EventUpsert brand={brand} revertToTheMean={revertToTheBeginning}></EventUpsert>)
    }

    if (createPost) {
        return (<PostUpsert revertToTheMean={revertToTheBeginning} brand={brand}></PostUpsert>)
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <div className="flex flex-nowrap text-2xl items-center justify-center mx-10">
                        Create a post or event for {brand?.name}
                    </div>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <div className="flex flex-nowrap text-4xl items-center justify-center mx-10">
                            Create a post or event for {brand?.name}
                        </div>
                    </IonToolbar>
                </IonHeader>
                <div className="grid grid-cols-2 gap-x-2 justify-center items-end">
                    <div onClick={() => goToEventUpload()}>
                        <IonCard>
                            <IonImg alt="Ski and Snowboard Event" className="object-contain aspect-video"
                                    src={eventPics[Math.floor(Math.random() * eventPics.length)]}/>
                            <IonCardHeader>
                                <IonCardTitle className="text-center">Create an Event</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent className="text-center">
                                This could be a virtual trick challenge, a place to gain entry into a real life rail jam
                                or competition, a spot for people to showcase their best lines, or whatever else you can
                                think of so that real recognizes real.
                            </IonCardContent>
                        </IonCard>
                    </div>
                    <div onClick={() => goToPostUpload()}>
                        <IonCard>
                            <IonImg alt="Ski and Snowboard Event" className="object-contain aspect-video"
                                    src="/assets/photos/usps.jpeg"/>
                            <IonCardHeader>
                                <IonCardTitle className="text-center">Create a Post</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent className="text-center">
                                Create a post to highlight what's new with your crew, any announcements, or product
                                launches
                            </IonCardContent>
                        </IonCard>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default CrewUpload;