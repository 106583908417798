import axios, {AxiosError} from "axios"
import {CountryData} from "../models/countryData";


const LocationService = {
    getLocationFromPlacesAPI: async (query: string, sessionId: string) => {
        const params = {
            query: query,
            sessiontoken: sessionId,
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/metadata/location`, {params: params})
            .then((response: any) => {
                return response.data;
            }).catch((error: any): any => {
                console.error(error);
                return null;
            });
    },

    getLocationFromPlacesAPIOnboarding: async (query: string, sessionId: string) => {
        const params = {
            query: query,
            sessiontoken: sessionId,
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/metadata/location`, {params: params})
            .then((response: any) => {
                return response.data;
            }).catch((error: any): any => {
                console.error(error);
                return null;
            });
    },

    getLocationFromTextPlacesAPI: async (query: string) => {
        const params = {
            query: query
        }

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/metadata/location/search`, {params: params})
            .then((response: any) => {
                return response.data;
            }).catch((error: any): any => {
                console.error(error);
                return null;
            });
    },

    getAllLocationTricks: async () => {
        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/tricks/location/all`)
            .then((response: any) => {
                return response.data;
            }).catch((error: any): any => {
                console.error(error);
                return null;
            });
    },

    getTrickLocation: async (trickId: string) => {
        const params = {
            trick_id: trickId,
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/trick/location`, {params: params})
            .then((response: any) => {
                return response.data;
            }).catch((error: any): any => {
                console.error(error);
                return null;
            });
    },

    getCountryFromLocationAPI: async (lat: number, lng: number, sessiontoken: string): Promise<CountryData | null> => {
        const params = {
            lat,
            lng,
            sessiontoken
        };

        try {
            const response = await axios.get<CountryData>(
                `${process.env.REACT_APP_MCTWIST_USER_SERVER}/user/location/country`,
                {params}
            );

            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.response) {
                    console.error('Error response:', axiosError.response.data);
                    console.error('Error status:', axiosError.response.status);
                    if (axiosError.response.status === 404) {
                        console.log('Country not found for the given coordinates');
                    }
                } else if (axiosError.request) {
                    console.error('No response received:', axiosError.request);
                } else {
                    console.error('Error setting up request:', axiosError.message);
                }
            } else {
                console.error('Unexpected error:', error);
            }
            return null;
        }
    }
};

export default LocationService;
