import {Clipboard} from "@capacitor/clipboard";
import {Capacitor} from "@capacitor/core";
import {Share} from "@capacitor/share";
import {OverlayEventDetail} from "@ionic/core/components";
import {
    IonButton,
    IonContent,
    IonHeader,
    IonIcon,
    IonImg,
    IonPage,
    IonPopover,
    IonRefresher,
    IonRefresherContent,
    IonSearchbar,
    IonTitle,
    IonToolbar,
    RefresherEventDetail,
    useIonAlert,
    useIonModal
} from "@ionic/react";
import {shareOutline,} from "ionicons/icons";
import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {useHistory, useLocation} from "react-router-dom";
import BrandDescriptionModal from "../components/ComponentsCrewProfile/BrandDescriptionModal/BrandDescriptionModal";
import EditDeleteEvent from "../components/ComponentsEvent/EditDeleteEvent/EditDeleteEvent";
import ToolbarSearchBar from "../components/ComponentsUI/ToolbarSearchBar/ToolbarSearchBar";
import {Brand} from "../models/brand";
import {BrandEvent} from "../models/brandEvent";
import {UserTrickSearch} from "../models/user-trick-search";
import BrandsService from "../services/brand.service";
import brandService from "../services/brand.service";

import {format, parseISO} from 'date-fns';
import {handleTagClickUtil} from "../services/utils";
import {AppContext} from "../AppStateProvider";
import CustomHelmet from "../components/ComponentsFunctional/CustomHelmet/CustomHelmet";
import BrandEventInfoCard from "../components/ComponentsCrewProfile/BrandEventInfoCard/BrandEventInfoCard";
import IDXChip from "../components/ComponentsUI/IDXChip/IDXChip";
import {useAuth} from "../AuthProvider";

interface EventPageProps
    extends RouteComponentProps<{
        id: string;
    }> {
}


const BrandEventInfo: React.FC<EventPageProps> = ({match}) => {
    const history = useHistory();
    const location = useLocation();
    const {state} = React.useContext(AppContext);

    const {user, isLoading, isAuthenticated} = useAuth();

    const [eventError, setEventError] = useState(false);
    const [editBrand, setEditBrand] = useState<boolean>(false);
    const [brand, setBrand] = useState<Brand>(undefined);
    const [brandEvent, setBrandEvent] = useState<BrandEvent>(undefined);
    const [brandEventUrl, setBrandEventUrl] = useState<string>(undefined);

    const [eventTricks, setEventTricks] = useState<UserTrickSearch[]>([])
    const [eventTricksFilter, setEventTricksFilter] = useState<string>("")

    const tabs = ['Event Bag']
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const [clipsLoading, setClipsLoading] = useState(false);


    const [presentAlert] = useIonAlert();

    const isNative = Capacitor.isNativePlatform();

    const [presentEventDescriptionModal, dismissEventDescriptionModal] = useIonModal(BrandDescriptionModal, {
        onDismiss: (data: string, role: string) => dismissEventDescriptionModal(data, role),
        description: brandEvent?.description,
        title: brandEvent?.name
    });

    const getBrandById = async (brandId: string) => {
        const brandResponse: Brand = await brandService.getBrandById(brandId);
        if (brandResponse) {
            setBrand(brandResponse);
            if (!isAuthenticated) {
                setEditBrand(false);
            } else {
                if (brandResponse.auth_id === user.uid) {
                    setEditBrand(true);
                }
            }
        }
    }

    const getTricksForEvent = async (currEvent: BrandEvent) => {
        setClipsLoading(true);
        const eventTricks: UserTrickSearch[] = await brandService.getEventTricks(currEvent.id, currEvent.start_date,
            currEvent.end_date, currEvent.event_tag, currEvent.sports, currEvent.features, currEvent.event_trick_tags,
            currEvent.event_tricks)
        if (eventTricks) {
            setEventTricks(eventTricks)
            setClipsLoading(false);
        }
    }

    const getEventById = async (eventId: string) => {
        const eventResponse: BrandEvent = await brandService.getEventById(eventId)
        if (eventResponse) {
            setBrandEvent(eventResponse);
            await getBrandById(eventResponse.brand_id);
            await getTricksForEvent(eventResponse);
        } else {
            setEventError(true);
        }
        window.prerenderReady = true;
    }

    const handleTagClick = (tag: string) => {
        const newTag = handleTagClickUtil(tag)
        history.push('/search/' + newTag);
    }

    const onRefreshBrandEvent = async (event: CustomEvent<RefresherEventDetail>) => {
        await getEventById(match.params.id);
        event.detail.complete();
    }

    useEffect(() => {
        console.log("Loading the event page");
        setEventTricksFilter("");
        getEventById(match.params.id).catch((e) => console.log(e));
        setBrandEventUrl(`${process.env.REACT_APP_IDX_URL}/event/${match.params.id}`);
    }, [history, match.params.id])

    if (eventError) {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Event</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <div className="flex flex-row justify-center items-center w-full mt-8">
                        <IonButton color="favorite" onClick={() => history.push('/home')}>
                            Event not found, return to the home page
                        </IonButton>
                    </div>
                </IonContent>
            </IonPage>
        )
    }

    const goToUploadTrick = (brandEvent: BrandEvent) => {
        if (editBrand) {
            presentAlert({
                header: `Error`,
                message: `You cannot upload a trick if you are a brand`,
                buttons: [
                    {
                        text: 'Exit',
                        role: 'cancel',
                    },
                ],
            })
        } else {
            history.push(`/upload?tag=${brandEvent.event_tag.replace("#", '')}`)
        }
    }

    const onDismissEditEvent = async (data: string, role: string) => {
        if (role === 'success') {
            console.log("refreshing events");
            await getEventById(brandEvent.id)
        }
    }

    const onDeleteEvent = async (deletedEvent: BrandEvent) => {
        const response: BrandEvent = await BrandsService.deleteBrandEvent(brandEvent.brand_id, brandEvent.id);
        if (response) {
            history.push(`/crews/${brand.username}`)
        }
    }

    const onClickBrandName = () => {
        history.push(`/crews/${brand.username}`)
    }

    const onClickTrick = (user_id: string, trick_id: string) => {
        history.push('/clip/' + user_id + '/' + trick_id);
    }

    const writeBrandEventUrlToClipboard = async () => {
        if (isNative) {
            let shareOptions: any = {
                title: "Ecliptic // " + brand?.username + " // " + brandEvent?.name,
                dialogTitle: "Ecliptic // " + brand?.username + " // " + brandEvent?.name,
                url: brandEventUrl,
            }
            await Share.share(shareOptions)
        } else {
            await Clipboard.write({
                url: brandEventUrl
            })
        }
    }

    function openBrandDescriptionModal() {
        presentEventDescriptionModal({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    return (
        <IonPage>
            <CustomHelmet
                title={brand?.username && brandEvent?.name ? "Ecliptic // " + brand.username + " // " + brandEvent.name : "idx // View Event}"}
                description={brandEvent?.description ? brandEvent.description : "Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed"}
                image={brandEvent?.cover_pic ? brandEvent?.cover_pic : "https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                url={`https://ecliptic.day/event/${match.params.id}`}/>
            <ToolbarSearchBar></ToolbarSearchBar>
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={onRefreshBrandEvent}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <div>
                    <div className="flex flex-row">
                        <div className="object-contain w-full grow">
                            <IonImg src={brandEvent?.cover_pic}
                                    alt={brandEvent?.name}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row mx-3 my-2 justify-between">
                        <div className="flex flex-col gap-y-1 items-start basis-3/4">
                            <div className="text-xl mx-1 font-bold">
                                {brandEvent?.name}
                            </div>
                            <div className="text-lg mx-1 font-bold" onClick={onClickBrandName}>
                                {brand?.name}
                            </div>
                            <div className="flex flex-row gap-2 text-lg mx-1 items-center">
                                {brandEvent?.start_date && brandEvent?.end_date ? (`${format(parseISO(brandEvent?.start_date), 'MMM d, yyyy')} - ${format(parseISO(brandEvent?.end_date), 'MMM d, yyyy')}`) : null}
                            </div>
                            <div className="text-xl flex flex-row gap-x-2">
                                {(brandEvent?.start_date && brandEvent?.end_date) && (new Date(new Date().toDateString()).toISOString() >= brandEvent?.start_date && new Date(new Date().toDateString()).toISOString() <= brandEvent?.end_date ?
                                    <IDXChip
                                        bgColor="bg-highlight-secondary/50 hover:bg-highlight-secondary/95 click:bg-highlight-secondary/95"
                                        borderColor="border-2 border-highlight-secondary" text={"Live"}/> : <IDXChip
                                        bgColor="bg-highlight-danger/50 hover:bg-highlight-danger/95 click:bg-highlight-danger/95"
                                        borderColor="border-2 border-highlight-danger" text={"Closed"}/>)}
                                {brandEvent?.location && <IDXChip bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                                                  borderColor="border-2 border-highlight"
                                                                  text={brandEvent?.location}/>}
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-1 items-end basis-1/4">
                            <IonButton fill="solid" color="favorite" size="small"
                                       onClick={() => goToUploadTrick(brandEvent)}>
                                Upload to Enter
                            </IonButton>
                            <IonButton fill="solid" color="favorite-secondary" size="small"
                                       onClick={() => openBrandDescriptionModal()}>
                                Rules
                            </IonButton>
                            <div className="flex flex-row-reverse gap-x-2">
                                {editBrand ?
                                    <EditDeleteEvent onDismissEditEvent={onDismissEditEvent} deleteEvent={onDeleteEvent}
                                                     brandEvent={brandEvent} brand={brand}/> : null}
                            </div>
                            <div className="flex">
                                <IonButton id="share-trigger-event" fill="outline" color="favorite" size="small"
                                           onClick={() => writeBrandEventUrlToClipboard()}>
                                    <IonIcon slot="icon-only" color="dark" icon={shareOutline}/>
                                </IonButton>
                                {
                                    !isNative ?
                                        <IonPopover trigger="share-trigger-event" side="left" alignment="start"
                                                    size="auto">
                                            <IonContent class="ion-padding">Copied Link to Event!</IonContent>
                                        </IonPopover> : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row mx-3 my-1">
                        <div className="flex flex-wrap gap-x-2 gap-y-2">
                            {brandEvent?.event_tag &&
                                <IDXChip bgColor="bg-transparent hover:bg-slate-500/50 click:bg-slate-500/50"
                                         borderColor="border-2 border-primary-secondary" text={brandEvent?.event_tag}/>}
                            {brandEvent?.sports?.map((tag, index) => (
                                <IDXChip key={index} bgColor="hover:bg-highlight-alt/95 click:bg-highlight-alt/95"
                                         borderColor="border-2 border-highlight-alt" text={tag}
                                         onClickChip={(tag) => handleTagClick(tag)}/>))}
                            {brandEvent?.features?.map((tag, index) => (
                                <IDXChip key={index} bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                         borderColor="border-2 border-highlight"
                                         onClickChip={(tag) => handleTagClick(tag)} text={tag}/>))}
                            {brandEvent?.event_trick_tags?.map((tag, index) => (
                                <IDXChip text={tag} key={index} bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                         borderColor="border-2 border-highlight"
                                         onClickChip={(tag) => handleTagClick(tag)}/>))}
                            {brandEvent?.event_tricks?.map((trick, index) => (
                                <IDXChip key={index} text={trick.join(" ")}
                                         bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                         borderColor="border-2 border-highlight"
                                         onClickChip={(tag) => handleTagClick(tag)}/>))}
                        </div>
                    </div>
                    <div className="flex justify-center space-x-4 border-b-2 border-gray-500 text-base font-bold">
                        {tabs.map(tab => (
                            <div className={activeTab === tab ? 'border-b-4 border-[#5963A7]' : ''}>
                                <button
                                    key={tab}
                                    className={`py-2 px-2.5 text-xl ${activeTab === tab ? 'border-b-4 border-[#5963A7] text-white' : 'text-gray-500 hover:text-white'}`}
                                    onClick={() => setActiveTab(tab)}
                                >
                                    {tab} {!clipsLoading && <span
                                    className="text-sm">{tab === 'Event Bag' ? ` ${eventTricks.length}` : ''}</span>}
                                </button>
                            </div>

                        ))}
                    </div>
                    <div className="p-1">
                        {
                            activeTab === "Event Bag" &&
                            <>
                                <div className="flex flex-row justify-between items-center mb-0.5">
                                    <IonSearchbar onIonInput={(e) => setEventTricksFilter(e.detail.value)}
                                                  showClearButton="focus"
                                                  showCancelButton="focus"
                                                  style={{paddingTop: 0, paddingBottom: 0}}
                                                  id="searchText"
                                                  debounce={0}
                                                  animated={true}></IonSearchbar>
                                </div>
                                <div
                                    className="grid grid-flow-row auto-row-max xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-2">
                                    {eventTricks.filter(
                                        (trick) => trick.trick_name.toLowerCase().includes(eventTricksFilter.toLowerCase()) ||
                                            (trick.location && trick.location.toLowerCase().includes(eventTricksFilter.toLowerCase()))
                                            || trick.username && trick.username.toLowerCase().includes(eventTricksFilter.toLowerCase()))
                                        .map((trick: UserTrickSearch, i) => (
                                            <div key={i} onClick={() => onClickTrick(trick.user_id, trick.trick_id)}>
                                                <BrandEventInfoCard trick={trick}></BrandEventInfoCard>
                                            </div>
                                        ))}
                                </div>
                            </>
                        }
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default BrandEventInfo;