import React from 'react';
import OnboardingPageWrapper from "../OnboardingPageWrapper/OnboardingPageWrapper";
import AuthenticationButtons from "../AuthenticationButtons/AuthenticationButtons";
import {useAppState} from "../../../AppListenerProvider";
import {useAuth} from "../../../AuthProvider";
import AuthService from "../../../services/auth.service";

interface WelcomeSignUpAuthProps {
    title: string;
    showBackButton: boolean;
    showSkipButton: boolean;
    onBack: () => void;
    handleAuthedStep: () => void;
    handleEmailAuthStep: () => void;
}

const WelcomeSignUpAuth: React.FC<WelcomeSignUpAuthProps> = ({
                                                                 showSkipButton,
                                                                 showBackButton,
                                                                 title,
                                                                 onBack,
                                                                 handleAuthedStep,
                                                                 handleEmailAuthStep
                                                             }) => {
    const {isActive, isNative, platform} = useAppState();
    const {user, isLoading, isAuthenticated, firebaseApp, auth} = useAuth();
    const authService = AuthService({user, isLoading, isAuthenticated, firebaseApp, auth}, {
        isActive,
        isNative,
        platform
    });

    return (
        <OnboardingPageWrapper title={title} showBackButton={showBackButton} showSkipButton={showSkipButton}
                               onBack={onBack}>
            <AuthenticationButtons
                login={true}
                authService={authService}
                platform={platform}
                handleAuthedStep={handleAuthedStep}
                handleEmailAuthStep={handleEmailAuthStep}
            />
        </OnboardingPageWrapper>
    );
};

export default WelcomeSignUpAuth;