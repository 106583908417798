import React, {useState} from 'react';
import {IonActionSheet, IonIcon} from "@ionic/react";
import {ellipsisHorizontalOutline} from "ionicons/icons";
import ReportReason from "../../ComponentsProfile/ReportReason/ReportReason";

interface ReportCommentProps {
    text: string;
    usernameToReport: string; // Tailwind class for background color, optional
    reportingUsername: string; // Tailwind class for border color, optional
    idToReport: string; // Optional image URL
    reportingId: string; // Optional click handler
    reportedId: string; // Tailwind class for border color, optional
}

const ReportComment: React.FC<ReportCommentProps> = ({
                                                         text,
                                                         usernameToReport,
                                                         reportingUsername,
                                                         idToReport,
                                                         reportingId,
                                                         reportedId
                                                     }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isChildOpen, setIsChildOpen] = useState(false);

    const handleIsChildOpen = (isOpen: boolean) => {
        setIsChildOpen(isOpen);
    }

    const handleActionSheet = (e: CustomEvent) => {
        if (e.detail.data) {
            if (e.detail.data.action === 'delete') {
                handleIsChildOpen(true);
            }
        }

        setIsOpen(false);
    }

    return (
        <>
            <IonIcon icon={ellipsisHorizontalOutline} onClick={() => setIsOpen(true)}/>
            <ReportReason isOpenParent={isChildOpen} text={text} usernameToReport={usernameToReport}
                          reportingUsername={reportingUsername} objectIdToReport={idToReport} reportingId={reportingId}
                          reportedId={reportedId}
                          handleIsOpen={handleIsChildOpen}/>
            <IonActionSheet
                isOpen={isOpen}
                buttons={[
                    {
                        text: 'Report',
                        role: 'destructive',
                        data: {
                            action: 'delete',
                        },
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        data: {
                            action: 'cancel',
                        },
                    },
                ]}
                onDidDismiss={(e) => handleActionSheet(e)}
            />
        </>
    );
};

export default ReportComment;