import {Capacitor} from "@capacitor/core";
import {IonSearchbar} from "@ionic/react";
import {useFormik} from "formik";
import React, {useEffect, useState} from "react";

interface ContainerProps {
    placeholder: string;
    mobilePlaceholder?: string;
    webPlaceholder?: string;
    onSearch: any;
    onSubmit: any;
    showOnMap: boolean;
    searchPlaceholder?: string;
}

const MapSearchBar: React.FC<ContainerProps> = (props: ContainerProps) => {
    const [placeholder, setPlaceholder] = useState('');

    useEffect(() => {

    }, []);

    // This isn't the marmaque, this is the formikque
    const formik = useFormik({
        initialValues: {
            searchText: props.searchPlaceholder
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log("Submitted: " + values.searchText);
            if (values.searchText === "undefined" || values.searchText === undefined) {
                props.onSubmit("");
            } else {
                props.onSubmit(values.searchText);
            }
            formik.values.searchText = '';
        },
    });

    useEffect(() => {
        setPlaceholder(Capacitor.getPlatform() !== 'web' ? (props.mobilePlaceholder ? props.mobilePlaceholder : props.placeholder) : (props.webPlaceholder ? props.webPlaceholder : props.placeholder))
    }, [props]);

    // TODO: animate placeholder for search bar when clicked on but nothing typed in

    return (
        <>
            {
                props.showOnMap ?
                    <div className="w-5/6">
                        <form onSubmit={formik.handleSubmit}>
                            <IonSearchbar
                                value={formik.values.searchText}
                                placeholder={Capacitor.getPlatform() !== 'web' ? (props.mobilePlaceholder ? props.mobilePlaceholder : props.placeholder) : (props.webPlaceholder ? props.webPlaceholder : props.placeholder)}
                                onIonInput={formik.handleChange}
                                animated={false}
                                showClearButton="always"
                                showCancelButton="never"
                                id="searchText"
                                debounce={0}
                                color="tertiary"
                            />
                        </form>
                    </div> : null
            }
        </>
    );
};

export default MapSearchBar;
