import {Capacitor} from '@capacitor/core';
import {useHistory} from "react-router-dom";

interface AuthServiceHook {
    login: () => Promise<void>;
}

const useAuthService = (): AuthServiceHook => {
    const history = useHistory();

    const isNative = Capacitor.isNativePlatform();

    const login = async (): Promise<void> => {
        if (isNative) {
            history.push('/login');
        } else {
            history.push('/login');
        }
    };

    return {
        login,
    };
}

export default useAuthService;