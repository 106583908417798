import React, {useEffect, useState} from "react";
import {OnboardingStatus} from "../../../models/onboardingStatus";
import {useAppState} from "../../../AppListenerProvider";
import {useHistory} from "react-router-dom";
import WelcomeSignUpEmail from "../WelcomeSignUpEmail/WelcomeSignUpEmail";
import {useSignUp} from "../SignUpProvider/SignUpProvider";
import BirthdayStep from "../BirthdayStep/BirthdayStep";
import NameInput from "../NameInput/NameInput";
import UsernameInput from "../UsernameInput/UsernameInput";
import SportSelection from "../SportSelection/SportSelection";
import SportSkillSelection from "../SportSkillTerrainSelection/SportSkillTerrainSelection";
import {SkillLevel} from "../../../models/skillLevel";
import {SkillLevels, Terrain} from "../../../models/signUpData";
import LocationPermissions from "../LocationPermissions/LocationPermissions";
import LocationSearch from "../LocationSearch/LocationSearch";
import {LocationOnboarding} from "../../../models/locationOnboarding";
import UserService from "../../../services/user.service";
import WelcomeSignUpAuth from "../WelcomeSignUpAuth/WelcomeSignUpAuth";
import {OnboardingStepConfig} from "../../../models/onboardingStepConfig";
import LocationService from "../../../services/location.service";
import {v4 as uuidv4} from "uuid";
import PhoneNumberInput from "../PhoneNumberInput/PhoneNumberInput";

interface ContainerProps {
}

const WelcomeSignUp: React.FC<ContainerProps> = ({}) => {
    const history = useHistory();
    const {isNative} = useAppState();
    const {signUpData, updateSignUpData, step, setStep, resetSignUp} = useSignUp();
    const [defaultLocations, setDefaultLocations] = useState<LocationOnboarding[]>([]);
    const [googlePlacesSessionToken, setGooglePlacesSessionToken] = useState<string | null>(null);

    const stepConfigState: Record<string, OnboardingStepConfig> = {
        [OnboardingStatus.IN_PROGRESS_SIGNUP_INIT]: {
            titleText: '',
            showBackButton: false,
            showSkipButton: false,
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_BIRTHDAY]: {
            titleText: 'Enter your birthday 🥳',
            showSkipButton: false,
            showBackButton: true,
            onBack: async () => {
                await navigateBackToInitial();
            },
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_HUMAN_NAME]: {
            titleText: "What's your name?",
            showSkipButton: false,
            showBackButton: false,
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_NAME]: {
            titleText: `${signUpData.firstName}, pick your username`,
            showSkipButton: false,
            showBackButton: true,
            onBack: () => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_HUMAN_NAME),
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS]: {
            titleText: `${signUpData.username}, choose your sports`,
            showSkipButton: false,
            showBackButton: true,
            onBack: () => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_NAME),
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN]: {
            titleText: signUpData.sports.length > 0 ? signUpData.displaySkill ? `What's your ${signUpData.sports[signUpData.sportIndex].toLowerCase()} skill level?` : `What terrain are you hitting when ${signUpData.sports[signUpData.sportIndex].toLowerCase()}?` : signUpData.displaySkill ? `What's your skill level?` : `What terrain are you hitting?`,
            showSkipButton: true,
            showBackButton: true,
            onBack: async () => {
                await updateSignUpData({sportIndex: 0, displaySkill: true, terrain: null, skills: null});
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS)
            },
            onSkip: async () => {
                if (signUpData.sports.length > 0) {
                    // Retrieve existing skills and terrain
                    const existingSkills = signUpData.skills || {};
                    const existingTerrain = signUpData.terrain || {};

                    // Update skill and terrain only for skipped sports
                    const newSkills = signUpData.sports.reduce((acc: SkillLevels, sport) => {
                        if (!existingSkills[sport]) {
                            acc[sport] = SkillLevel.FAN;
                        } else {
                            acc[sport] = existingSkills[sport];
                        }
                        return acc;
                    }, {});

                    const newTerrain = signUpData.sports.reduce((acc: Terrain, sport) => {
                        if (!existingTerrain[sport]) {
                            acc[sport] = [];
                        } else {
                            acc[sport] = existingTerrain[sport];
                        }
                        return acc;
                    }, {});

                    await updateSignUpData({
                        skills: {...existingSkills, ...newSkills},
                        terrain: {...existingTerrain, ...newTerrain}
                    });
                }
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT)
            },
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT]: {
            titleText: 'Tell us your home base',
            showSkipButton: true,
            showBackButton: true,
            onBack: async () => {
                await updateSignUpData({displaySkill: true});
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN)
            },
            onSkip: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_SEARCH);
            },
            onSkipDelay: 2500,
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_SEARCH]: {
            titleText: 'Search for your base',
            showSkipButton: true,
            showBackButton: true,
            onBack: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT);
            },
            onSkip: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER);
            },
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER]: {
            titleText: 'Enter your phone number',
            showSkipButton: true,
            onSkip: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_AUTH);
            },
            onSkipDelay: 2500,
            showBackButton: true,
            onBack: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_SEARCH);
            },
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_AUTH]: {
            titleText: 'Finish Signing Up for Ecliptic!',
            showBackButton: true,
            showSkipButton: false,
            onBack: async () => {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_SEARCH);
            }
        },
        [OnboardingStatus.IN_PROGRESS_SIGNUP_USERNAME]: {
            titleText: 'Provide an Email and Password',
            showBackButton: false,
            showSkipButton: false,
        },
    };

    const navigateBackToInitial = async () => {
        await handleOnboardingStatus(OnboardingStatus.INIT);
        history.push('/flow');
    }

    const handleOnboardingStatus = async (status: string) => {
        await setStep(status);
    }

    useEffect(() => {
        const currGooglePlacesToken = uuidv4();
        console.log("Setting Google Places Token: " + currGooglePlacesToken);
        setGooglePlacesSessionToken(currGooglePlacesToken);
    }, []);

    useEffect(() => {
        const initializeStep = async () => {
            if (step) {
                if (step.includes(OnboardingStatus.SIGNUP)) {
                    await handleOnboardingStatus(step);
                } else {
                    console.log("INVALID STATUS: GOING TO FLOW");
                    await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_INIT);
                }
            } else {
                await handleOnboardingStatus(OnboardingStatus.INIT);
                history.push("/flow");
            }
        };

        console.log("Loading Sign Up Flow App Page");
        initializeStep();
    }, []);  // Empty dependency array means this runs once on mount

    useEffect(() => {
        const loadRelevantLocations = async () => {
            if (signUpData.latitude && signUpData.longitude && googlePlacesSessionToken) {
                const country = await LocationService.getCountryFromLocationAPI(signUpData.latitude, signUpData.longitude, googlePlacesSessionToken);
                if (country) {
                    await updateSignUpData({countryData: country});
                }

                console.log("LOADING RELEVANT LOCATIONS");
                try {
                    const locations: LocationOnboarding[] = await UserService.getUserLocationsOnboardingLocation(1, signUpData.latitude, signUpData.longitude);
                    setDefaultLocations(locations);
                } catch (error) {
                    console.error("Error loading locations: ", error);
                    setDefaultLocations([]);
                }
            } else {
                console.log("LOADING DEFAULT LOCATIONS");
                try {
                    const locations: LocationOnboarding[] = await UserService.getUserLocationsOnboarding(1);
                    setDefaultLocations(locations);
                } catch (error) {
                    console.error("Error loading locations: ", error);
                    setDefaultLocations([]);
                }
            }
        }

        loadRelevantLocations();
    }, [signUpData.latitude, signUpData.longitude, googlePlacesSessionToken]);

    useEffect(() => {
        const redirectBasedOnStep = async () => {
            console.log("Step: " + step);
            if (isNative && step === OnboardingStatus.IN_PROGRESS_SIGNUP_INIT) {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_BIRTHDAY);
            }

            if (!isNative && step === OnboardingStatus.IN_PROGRESS_SIGNUP_INIT) {
                await handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_BIRTHDAY);
            }
        };

        redirectBasedOnStep();
    }, [step, isNative]);

    const renderStep = () => {
        // State Change Controlled In This Logic
        switch (step) {
            case OnboardingStatus.IN_PROGRESS_SIGNUP_BIRTHDAY:
                return (
                    <BirthdayStep
                        showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_BIRTHDAY].showSkipButton}
                        showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_BIRTHDAY].showBackButton}
                        title={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_BIRTHDAY].titleText}
                        onNext={() => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_HUMAN_NAME)}
                        onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_BIRTHDAY].onBack}/>
                );
            case OnboardingStatus.IN_PROGRESS_SIGNUP_HUMAN_NAME:
                return (
                    <NameInput
                        showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_HUMAN_NAME].showSkipButton}
                        showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_HUMAN_NAME].showBackButton}
                        title={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_HUMAN_NAME].titleText}
                        onNext={() => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_NAME)}/>
                );
            case OnboardingStatus.IN_PROGRESS_SIGNUP_NAME:
                return (
                    <UsernameInput
                        onNext={() => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS)}
                        showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_NAME].showBackButton}
                        showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_NAME].showSkipButton}
                        title={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_NAME].titleText}
                        onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_NAME].onBack}
                    />
                );
            case OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS:
                return (
                    <SportSelection
                        showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS].showBackButton}
                        showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS].showSkipButton}
                        title={`${signUpData.username}, choose your sports`}
                        onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS].onBack}
                        onNext={() => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN)}/>
                );
            case OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN:
                return (
                    <SportSkillSelection
                        showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN].showBackButton}
                        showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN].showSkipButton}
                        title={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN].titleText}
                        onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN].onBack}
                        onSkip={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN].onSkip}
                        onNext={() => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT)}
                    />
                )
            case OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT:
                return (
                    <LocationPermissions
                        showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT].showBackButton}
                        showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT].showSkipButton}
                        title={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT].titleText}
                        onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT].onBack}
                        onSkip={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT].onSkip}
                        onSkipDelay={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_PROMPT].onSkipDelay}
                        onNext={() => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_SEARCH)}/>
                )
            case OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_SEARCH:
                return (
                    <LocationSearch
                        onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_SEARCH].onBack}
                        defaultLocations={defaultLocations}
                        defaultIsLoading={defaultLocations.length === 0}
                        onNext={() => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER)}
                        onSkip={() => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER)}
                        sessionToken={googlePlacesSessionToken}/>
                )
            case OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER:
                return (
                    <PhoneNumberInput
                        showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER].showBackButton}
                        showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER].showSkipButton}
                        title={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER].titleText}
                        onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER].onBack}
                        onSkip={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER].onSkip}
                        onSkipDelay={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_PHONE_NUMBER].onSkipDelay}
                        onNext={() => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_LOCATION_SEARCH)}/>
                )
            case OnboardingStatus.IN_PROGRESS_SIGNUP_AUTH:
                return (
                    <WelcomeSignUpAuth handleAuthedStep={() => handleOnboardingStatus(OnboardingStatus.COMPLETED)}
                                       handleEmailAuthStep={() => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_USERNAME)}
                                       onBack={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_AUTH].onBack}
                                       showBackButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_AUTH].showBackButton}
                                       showSkipButton={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_AUTH].showSkipButton}
                                       title={stepConfigState[OnboardingStatus.IN_PROGRESS_SIGNUP_AUTH].titleText}
                    />
                )
            case OnboardingStatus.IN_PROGRESS_SIGNUP_USERNAME:
                return (
                    <WelcomeSignUpEmail
                        handleSignUpGoBack={() => handleOnboardingStatus(OnboardingStatus.IN_PROGRESS_SIGNUP_AUTH)}
                        handleSignUpLogin={() => handleOnboardingStatus(OnboardingStatus.COMPLETED)}
                    />
                );
            default:
                return null;
        }
    }

    return renderStep();
};

export default WelcomeSignUp;