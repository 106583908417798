import React, {useEffect, useState} from "react";
import {IonButton, IonIcon} from "@ionic/react";
import {chevronBackOutline} from "ionicons/icons";

interface NavigationButtonsProps {
    onBack?: () => void;
    onSkip?: () => void;
    onSkipDelay?: number;
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({onBack, onSkip, onSkipDelay}) => {
    const [showSkip, setShowSkip] = useState(false);

    useEffect(() => {
        if (onSkipDelay === undefined && onSkip) {
            console.log("Setting show skip to true");
            setShowSkip(true);
        } else if (onSkipDelay === undefined && !onSkip) {
            console.log("Setting show skip to false");
            setShowSkip(false);
        } else {
            console.log("Setting timeout for skip button");
            const timer = setTimeout(() => {
                console.log("Setting show skip to true");
                setShowSkip(true);
            }, onSkipDelay);
            return () => clearTimeout(timer);
        }
    }, []);


    return (
        <div className="absolute top-8 left-0 right-0 flex justify-between items-center p-0.5 z-20">
            {onBack && (
                <IonButton fill="clear" onClick={onBack} className="text-primary-alt normal-case font-bold">
                    <IonIcon slot="icon-only" icon={chevronBackOutline} className="text-primary-alt"/>
                </IonButton>
            )}
            {!onBack && (
                <IonButton fill="clear" className="text-primary-alt">
                </IonButton>
            )}
            {onSkip && showSkip && (
                <IonButton fill="clear" onClick={onSkip} className="text-primary-alt normal-case font-bold">
                    Skip
                </IonButton>
            )}
            {!onSkip && (
                <IonButton fill="clear" className="text-primary-alt">
                </IonButton>
            )}
        </div>
    );
};

export default NavigationButtons;