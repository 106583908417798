import {IonButton} from "@ionic/react";
import React, {useEffect} from "react";

interface ContainerProps {
    blockedUserId: string,
    userBlocked: boolean,
    onBlockUser: (blocked_id: string, block: boolean) => void,
    username: string,
    loggedIn: boolean,
}

const BlockUser: React.FC<ContainerProps> = ({userBlocked, onBlockUser, blockedUserId, username, loggedIn}) => {
    const [isUserBlocked, setIsUserBlocked] = React.useState(userBlocked);

    useEffect(() => {
        setIsUserBlocked(userBlocked);
    }, [userBlocked]);

    return (
        loggedIn &&
        <IonButton expand="block" color="danger" shape="round"
                   onClick={() => onBlockUser(blockedUserId, !isUserBlocked)}>{isUserBlocked ? `Unblock ${username}` : `Block ${username}`}</IonButton>
    );
};

export default BlockUser;