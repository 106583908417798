import React, {useEffect} from "react";
import TrickFilterOptionsService from "../../../services/trickFilterOptions.service";
import TagDropdown from "../TagMultiSelect/TagMultiSelect";

interface ContainerProps {
    sport: string[],
    feature: string[],
    parentAddTag: (tag: string) => void,
    parentRemoveTag: (tag: string) => void,
    tagsCombinedToOne?: boolean,
    tagsCombinedToOneTitle?: string,
    tagsDisabled?: boolean,
    currentTags?: string[]
    currentTrickTags?: string[]
    currentEventTags?: string[][]
}

interface TrickAttribute {
    name: string,
    lowercase: string,
    transitions: string,
}

interface TrickOptions {
    name: string,
    tricks: string[],
    nameForApi: string,
}

// TODO: prob need to shorten these names
const attributes: TrickAttribute[] = [
    {
        name: "Direction",
        lowercase: "direction",
        transitions: "btn-1",
    },
    {
        name: "Flavor",
        lowercase: "flavor",
        transitions: "btn-1",
    },
    {
        name: "Degree",
        lowercase: "degree",
        transitions: "btn-1",
    },
    {
        name: "Axis",
        lowercase: "axis",
        transitions: "btn-1",
    },
    {
        name: "Grab",
        lowercase: "grab",
        transitions: "btn-1",
    },
];

const railAttributes: TrickAttribute[] = [
    {
        name: "Style",
        lowercase: "style",
        transitions: "btn-1",
    },
    {
        name: "Feature",
        lowercase: "feature",
        transitions: "btn-1",
    },
];

const trickshotAttributes: TrickAttribute[] = [
    {
        name: "Activity",
        lowercase: "activity",
        transitions: "btn-1",
    },
    {
        name: "Flavor",
        lowercase: "flavor",
        transitions: "btn-1",
    },
];

// direction, axis, degree, grab, and then flavor

const TagSelectOnUpload: React.FC<ContainerProps> = (props: ContainerProps) => {

    const [directionTags, setDirectionTags] = React.useState([]);
    const [flavorTags, setFlavorTags] = React.useState([]);
    const [degreeTags, setDegreeTags] = React.useState([]);
    const [axisTags, setAxisTags] = React.useState([]);
    const [grabTags, setGrabTags] = React.useState([]);
    const [styleTags, setStyleTags] = React.useState([]);
    const [featureTags, setFeatureTags] = React.useState([]);
    const [activityTags, setActivityTags] = React.useState([]);

    const [allTags, setAllTags] = React.useState([]);

    const [directionTagsSelected, setDirectionTagsSelected] = React.useState([]);
    const [flavorTagsSelected, setFlavorTagsSelected] = React.useState([]);
    const [degreeTagsSelected, setDegreeTagsSelected] = React.useState([]);
    const [axisTagsSelected, setAxisTagsSelected] = React.useState([]);
    const [grabTagsSelected, setGrabTagsSelected] = React.useState([]);
    const [styleTagsSelected, setStyleTagsSelected] = React.useState([]);
    const [featureTagsSelected, setFeatureTagsSelected] = React.useState([]);
    const [activityTagsSelected, setActivityTagsSelected] = React.useState([]);
    const [allTagsSelected, setAllTagsSelected] = React.useState([]);

    const [featureApiCalls, setFeatureApiCalls] = React.useState([]);

    const [invalidDirection, setInvalidDirection] = React.useState(false);
    const [invalidAxis, setInvalidAxis] = React.useState(false);

    const getTagList = (response: TrickOptions[], attributeName: string, curr_ftr: string) => {
        if (response === undefined || response == null) {
            return [];
        }

        if (response.length === 0) {
            return [];
        }

        let tags: string[] = [];

        if (attributeName === 'Axis') {
            let axisOptions = response[0].tricks;
            response.forEach((item: any) => {
                if (item.name === 'Standard') {
                    tags = [...tags, ...axisOptions];
                } else {
                    let concatOptions = axisOptions.map((axis) => item.name + ' ' + axis);
                    tags = [...tags, ...concatOptions];
                }
            });
        } else if (attributeName === 'Degree' && curr_ftr === 'Rail/Jib') {
            response.forEach((item: any) => {
                if (item.name === 'On' || item.name === 'Out') {
                    // e.g. 180 On
                    let concatOptions = item.tricks.map((degree: string) => degree + ' ' + item.name);
                    tags = [...tags, ...concatOptions];
                } else if (item.name === "Lip On" || item.name === "Tails On" || item.name === "Blind Out") {
                    // e.g. Lip 180 On
                    let degreeName = item.name.split(' ');
                    let concatOptions = item.tricks.map((degree: string) => degreeName[0] + ' ' + degree + ' ' + degreeName[1]);
                    tags = [...tags, ...concatOptions];
                } else {
                    // e.g. Pretzel 180, Hardway 180, etc.
                    tags = [...tags, item.name];
                    let concatOptions = item.tricks.map((degree: string) => item.name + ' ' + degree);
                    tags = [...tags, ...concatOptions];
                }
            });
        } else if (attributeName === 'Style') {
            let styleOptions = response[0].tricks;
            response.forEach((item: any) => {
                if (item.name === 'Tricks') {
                    tags = [...tags, ...styleOptions];
                } else {
                    let concatOptions = item.tricks.map((style: string) => item.name + ' ' + style);
                    tags = [...tags, ...concatOptions];
                }
            });
        } else {
            response.forEach((item: any) => {
                console.log(item);
                tags = [...tags, ...item.tricks];
            });
        }

        return tags;
    };

    const getTrickOptions = async (sprt_api_calls: string[], feature_api_calls: string[], attribute_option_calls: TrickAttribute[]) => {
        let direction_tags: string[] = []
        let flavor_tags: string[] = []
        let degree_tags: string[] = []
        let axis_tags: string[] = []
        let grab_tags: string[] = []
        let style_tags: string[] = []
        let feature_tags: string[] = []
        let activity_tags: string[] = []

        for (const attribute of attribute_option_calls) {
            for (const sprt of sprt_api_calls) {
                for (const ftr of feature_api_calls) {
                    const response = await TrickFilterOptionsService.getTrickFilterOptions(sprt, ftr, attribute.lowercase);
                    const tags = getTagList(response, attribute.name, ftr);

                    if (props.tagsCombinedToOne) {
                        setAllTagsSelected(props.currentTags);
                    }

                    if (attribute.name === "Direction") {
                        direction_tags = [...direction_tags, ...tags];
                    } else if (attribute.name === "Flavor") {
                        flavor_tags = [...flavor_tags, ...tags];
                    } else if (attribute.name === "Degree") {
                        degree_tags = [...degree_tags, ...tags];
                    } else if (attribute.name === "Axis") {
                        axis_tags = [...axis_tags, ...tags];
                    } else if (attribute.name === "Grab") {
                        grab_tags = [...grab_tags, ...tags];
                    } else if (attribute.name === "Style") {
                        style_tags = [...style_tags, ...tags];
                    } else if (attribute.name === "Feature") {
                        feature_tags = [...feature_tags, ...tags];
                    } else if (attribute.name === "Activity") {
                        activity_tags = [...activity_tags, ...tags];
                    }
                }
            }
        }

        direction_tags = [...new Set(direction_tags)];
        flavor_tags = [...new Set(flavor_tags)];
        degree_tags = [...new Set(degree_tags)];
        axis_tags = [...new Set(axis_tags)];
        grab_tags = [...new Set(grab_tags)];
        style_tags = [...new Set(style_tags)];
        feature_tags = [...new Set(feature_tags)];
        activity_tags = [...new Set(activity_tags)];

        setDirectionTags(direction_tags);
        setFlavorTags(flavor_tags);
        setDegreeTags(degree_tags);
        setAxisTags(axis_tags);
        setGrabTags(grab_tags);
        setStyleTags(style_tags);
        setFeatureTags(feature_tags);
        setActivityTags(activity_tags);
        setAllTags([...direction_tags, ...flavor_tags, ...degree_tags, ...axis_tags, ...grab_tags, ...style_tags, ...feature_tags, ...activity_tags]);

        if (props.currentTags && !props.tagsCombinedToOne) {
            const curr_direction_tags = props.currentTags.filter((tag: string) => direction_tags.includes(tag));
            const curr_flavor_tags = props.currentTags.filter((tag: string) => flavor_tags.includes(tag));
            const curr_degree_tags = props.currentTags.filter((tag: string) => degree_tags.includes(tag));
            const curr_axis_tags = props.currentTags.filter((tag: string) => axis_tags.includes(tag));
            const curr_grab_tags = props.currentTags.filter((tag: string) => grab_tags.includes(tag));
            const curr_style_tags = props.currentTags.filter((tag: string) => style_tags.includes(tag));
            const curr_feature_tags = props.currentTags.filter((tag: string) => feature_tags.includes(tag));
            const curr_activity_tags = props.currentTags.filter((tag: string) => activity_tags.includes(tag));

            setDirectionTagsSelected(curr_direction_tags);
            setFlavorTagsSelected(curr_flavor_tags);
            setDegreeTagsSelected(curr_degree_tags);
            setAxisTagsSelected(curr_axis_tags);
            setGrabTagsSelected(curr_grab_tags);
            setStyleTagsSelected(curr_style_tags);
            setFeatureTagsSelected(curr_feature_tags);
            setActivityTagsSelected(curr_activity_tags);
        }
    }

    useEffect(() => {
        const feature_api_calls: string[] = [];
        const sprt_api_calls: string[] = [];

        console.log("Calling the USED EFFECT");

        let selected_sports = props.sport;

        if (!props.sport || props.sport.length === 0) {
            selected_sports = ['ski', 'board'];
        }

        if ((props.sport.length === 1 && props.sport[0] === null) || (props.sport.length === 1 && props.sport[0] === "")) {
            selected_sports = ['ski', 'board'];
        }

        let selected_features = props.feature;

        if (!props.feature) {
            selected_features = ['rail', 'mountain', 'jump', 'halfpipe'];
        }

        if ((props.feature.length === 1 && props.feature[0] === null) || (props.feature.length === 1 && props.feature[0] === "")) {
            selected_features = ['rail', 'mountain', 'jump', 'halfpipe'];
        }

        // getting the tag options for each attribute once
        for (const sprt of selected_sports) {
            let sport_api = sprt === "Skiing" ? "ski" : sprt === "Snowboarding" ? "board" : "other";
            sprt_api_calls.push(sport_api);

            for (const ftr of selected_features) {
                let feature_api = ftr.toLowerCase();
                if (ftr === 'Off Mountain') {
                    feature_api = "mountain";
                } else if (ftr === 'Rail/Jib') {
                    feature_api = "rail";
                } else if (ftr === 'Freeride') {
                    feature_api = "mountain";
                } else if (ftr === 'All Mountain') {
                    feature_api = "mountain";
                }
                feature_api_calls.push(feature_api);
            }
        }

        setFeatureApiCalls(feature_api_calls);

        let attributeOptions: TrickAttribute[] = [];

        for (const ftr of selected_features) {
            if (ftr === "Rail/Jib") {
                attributeOptions = [...attributes, ...railAttributes];
            } else if (ftr === "Trickshot") {
                attributeOptions = [...trickshotAttributes];
            } else {
                attributeOptions = [...attributes];
            }
        }

        if (attributeOptions.length === 0) {
            attributeOptions = [...attributes, ...railAttributes];
        }

        if (sprt_api_calls.length > 0 && feature_api_calls.length > 0) {
            if (props.tagsCombinedToOne) {
                setAllTags([]);
                setAllTagsSelected(props.currentTags);
            }

            getTrickOptions(sprt_api_calls, feature_api_calls, attributeOptions).catch((err) => {
                console.log("Error getting trick options: ", err);
            });
        }

    }, [props.sport, props.feature]);

    const addTag = (tag: string, feature: string) => {
        let invalid = false;

        if (props.tagsCombinedToOne) {
            setAllTagsSelected(allTagsSelected.concat(tag));
            let filtered = allTags.filter((option: any) => option !== tag);
            setAllTags(filtered);
        }

        if (feature === "Direction") {
            setDirectionTagsSelected(directionTagsSelected.concat(tag));
            let filtered = directionTags.filter((option: any) => option !== tag);
            setDirectionTags(filtered);
        } else if (feature === "Flavor") {
            setFlavorTagsSelected(flavorTagsSelected.concat(tag));
            let filtered = flavorTags.filter((option: any) => option !== tag);
            setFlavorTags(filtered);
        } else if (feature === "Degree") {
            setDegreeTagsSelected(degreeTagsSelected.concat(tag));
            let filtered = degreeTags.filter((option: any) => option !== tag);
            setDegreeTags(filtered);
        } else if (feature === "Axis") {
            setAxisTagsSelected(axisTagsSelected.concat(tag));
            let filtered = axisTags.filter((option: any) => option !== tag);
            setAxisTags(filtered);
        } else if (feature === "Grab") {
            setGrabTagsSelected(grabTagsSelected.concat(tag));
            let filtered = grabTags.filter((option: any) => option !== tag);
            setGrabTags(filtered);
        } else if (feature === "Style") {
            setStyleTagsSelected(styleTagsSelected.concat(tag));
            let filtered = styleTags.filter((option: any) => option !== tag);
            setStyleTags(filtered);
        } else if (feature === "Feature") {
            setFeatureTagsSelected(featureTagsSelected.concat(tag));
            let filtered = featureTags.filter((option: any) => option !== tag);
            setFeatureTags(filtered);
        } else if (feature === "Activity") {
            setActivityTagsSelected(activityTagsSelected.concat(tag));
            let filtered = activityTags.filter((option: any) => option !== tag);
            setActivityTags(filtered);
        }

        if (!invalid) {
            props.parentAddTag(tag);
        }
    };

    const removeTag = (tag: string, feature: string) => {
        if (props.tagsCombinedToOne) {
            let filtered = allTagsSelected.filter((e: any) => e !== tag);
            setAllTagsSelected(filtered);
            setAllTags(allTags.concat(tag));
        }

        if (feature === "Direction") {
            let filtered = directionTagsSelected.filter((e: any) => e !== tag);
            setDirectionTagsSelected(filtered);
            setDirectionTags(directionTags.concat(tag));
        } else if (feature === "Flavor") {
            let filtered = flavorTagsSelected.filter((e: any) => e !== tag);
            setFlavorTagsSelected(filtered);
            setFlavorTags(flavorTags.concat(tag));
        } else if (feature === "Degree") {
            let filtered = degreeTagsSelected.filter((e: any) => e !== tag);
            setDegreeTagsSelected(filtered);
            setDegreeTags(degreeTags.concat(tag));
        } else if (feature === "Axis") {
            let filtered = axisTagsSelected.filter((e: any) => e !== tag);
            setAxisTagsSelected(filtered);
            setAxisTags(axisTags.concat(tag));
        } else if (feature === "Grab") {
            let filtered = grabTagsSelected.filter((e: any) => e !== tag);
            setGrabTagsSelected(filtered);
            setGrabTags(grabTags.concat(tag));
        } else if (feature === "Style") {
            let filtered = styleTagsSelected.filter((e: any) => e !== tag);
            setStyleTagsSelected(filtered);
            setStyleTags(styleTags.concat(tag));
        } else if (feature === "Feature") {
            let filtered = featureTagsSelected.filter((e: any) => e !== tag);
            setFeatureTagsSelected(filtered);
            setFeatureTags(featureTags.concat(tag));
        } else if (feature === "Activity") {
            let filtered = activityTagsSelected.filter((e: any) => e !== tag);
            setActivityTagsSelected(filtered);
            setActivityTags(activityTags.concat(tag));
        }

        props.parentRemoveTag(tag);
    };

    // direction, axis, degree, grab, and then flavor
    return (
        props.tagsCombinedToOne ?
            <div>
                <div className="flex flex-row items-center mt-4">
                    <TagDropdown
                        parentAddTag={addTag}
                        parentRemoveTag={removeTag}
                        feature={props.tagsCombinedToOneTitle}
                        options={allTags}
                        selectedOptions={allTagsSelected}
                    />
                </div>
            </div>
            :
            // if sport only contains other and then feature is trickshot, then implement this
            props.sport.length === 1 && props.feature.length === 1 && props.sport[0] === "Other" && props.feature[0] === "Trickshot"
                ?
                <div>
                    <div className="flex flex-row items-center mt-4">
                        <TagDropdown
                            parentAddTag={addTag}
                            parentRemoveTag={removeTag}
                            feature="Activity"
                            options={activityTags}
                            selectedOptions={activityTagsSelected}
                        />
                    </div>
                    <div className="flex flex-row items-center mt-2">
                        <TagDropdown
                            parentAddTag={addTag}
                            parentRemoveTag={removeTag}
                            feature="Flavor"
                            options={flavorTags}
                            selectedOptions={flavorTagsSelected}
                        />
                    </div>
                </div>
                :
                <div>
                    <div className="flex flex-row items-center mt-4">
                        <TagDropdown
                            parentAddTag={addTag}
                            parentRemoveTag={removeTag}
                            feature="Direction"
                            options={directionTags}
                            selectedOptions={directionTagsSelected}
                        />
                    </div>
                    <div className="flex flex-row items-center mt-2">
                        <TagDropdown
                            parentAddTag={addTag}
                            parentRemoveTag={removeTag}
                            feature="Axis"
                            options={axisTags}
                            selectedOptions={axisTagsSelected}
                        />
                    </div>
                    <div className="flex flex-row items-center mt-2">
                        <TagDropdown
                            parentAddTag={addTag}
                            parentRemoveTag={removeTag}
                            feature="Degree"
                            options={degreeTags}
                            selectedOptions={degreeTagsSelected}
                        />
                    </div>
                    <div className="flex flex-row items-center mt-2">
                        <TagDropdown
                            parentAddTag={addTag}
                            parentRemoveTag={removeTag}
                            feature="Grab"
                            options={grabTags}
                            selectedOptions={grabTagsSelected}
                        />
                    </div>
                    <div className="flex flex-row items-center mt-2">
                        <TagDropdown
                            parentAddTag={addTag}
                            parentRemoveTag={removeTag}
                            feature="Flavor"
                            options={flavorTags}
                            selectedOptions={flavorTagsSelected}
                        />
                    </div>
                    {featureApiCalls.includes("rail") &&
                        <>
                            <div className="flex flex-row items-center mt-2">
                                <TagDropdown
                                    parentAddTag={addTag}
                                    parentRemoveTag={removeTag}
                                    feature="Style"
                                    options={styleTags}
                                    selectedOptions={styleTagsSelected}
                                />
                            </div>
                            <div className="flex flex-row items-center mt-2">
                                <TagDropdown
                                    parentAddTag={addTag}
                                    parentRemoveTag={removeTag}
                                    feature="Feature"
                                    options={featureTags}
                                    selectedOptions={featureTagsSelected}
                                />
                            </div>
                        </>
                    }
                </div>
    );
};

export default TagSelectOnUpload;
