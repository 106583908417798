import React, {useEffect, useState} from 'react';
import {IonActionSheet, useIonToast} from "@ionic/react";
import {skullOutline} from "ionicons/icons";
import SettingsService from "../../../services/settings.service";
import UserService from "../../../services/user.service";
import {AppContext} from "../../../AppStateProvider";

interface ReportReasonProps {
    text: string;
    usernameToReport: string; // Tailwind class for background color, optional
    reportedId: string; // Tailwind class for border color, optional
    reportingUsername: string; // Tailwind class for border color, optional
    reportingId: string; // Optional click handler
    objectIdToReport: string; // Optional image URL
    handleIsOpen: (isOpen: boolean) => void;
    isOpenParent: boolean;

}

const ReportReason: React.FC<ReportReasonProps> = ({
                                                       text,
                                                       usernameToReport,
                                                       reportingUsername,
                                                       objectIdToReport,
                                                       reportingId,
                                                       isOpenParent,
                                                       handleIsOpen,
                                                       reportedId
                                                   }) => {

    const {state, dispatch} = React.useContext(AppContext);
    const [isOpen, setIsOpen] = useState(isOpenParent);
    const [present] = useIonToast();

    useEffect(() => {
        setIsOpen(isOpenParent);
    }, [isOpenParent]);


    const handleActionSheet = async (e: CustomEvent) => {
        console.log("handleActionSheet: " + JSON.stringify(e.detail));
        if (e.detail.data) {
            if (e.detail.data.action === 'delete') {
                await processReportUser(e.detail.data.reason);
            } else if (e.detail.data.action === 'block') {
                await processBlockUser(e.detail.data.reason);
            }
        }

        setIsOpen(false);
        handleIsOpen(false);
    }

    const blockUser = async (blocked_id: string, block: boolean) => {
        console.log("Blocking user: " + blocked_id + " with block: " + block + " from user: " + state.user.id);
        await UserService.blockUser(state.user.id, blocked_id, block);
        const blockedUsers: string[] = await UserService.getBlockedUsers(state.user.id);
        dispatch({type: 'setBlockedUsers', payload: blockedUsers});
    }


    const processReportUser = async (reason: string) => {
        await present({
            message: "The Ecliptic team has been notified of this user and content and will take appropriate action.",
            duration: 4000,
            position: 'top',
            icon: skullOutline,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                        console.log('Dismiss clicked');
                    },
                },
            ],
            onDidDismiss: (e: CustomEvent) => console.log(`Dismissed with role: ${e.detail.role}`)
        });
        try {
            await SettingsService.sendFeedback(reportingId, "Username: " + reportingUsername + " wishes to report " + usernameToReport + " with the id : " + objectIdToReport + " with the comment: " + text + " for the reason: " + reason);
        } catch (e) {
            console.error(e);
        }

        return;
    }

    const processBlockUser = async (reason: string) => {
        await present({
            message: "This user has been blocked and removed from your Ecliptic experience",
            duration: 4000,
            position: 'top',
            icon: skullOutline,
            buttons: [
                {
                    text: 'Unblock',
                    role: 'block',
                    handler: async () => {
                        await blockUser(reportedId, false);
                    },
                },
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                        console.log('Dismiss clicked');
                    },
                },
            ],
            onDidDismiss: (e: CustomEvent) => console.log(`Dismissed with role: ${e.detail.role}`)
        });
        try {
            await blockUser(reportedId, true);
        } catch (e) {
            console.error(e);
        }

        return;
    }

    return (
        <>
            <IonActionSheet
                isOpen={isOpen}
                buttons={[
                    {
                        text: 'Too Much Gas',
                        role: 'destructive',
                        data: {
                            reason: 'Too Much Gas',
                            action: 'delete',
                        },
                    },
                    {
                        text: 'My Significant Other is on Ecliptic',
                        role: 'destructive',
                        data: {
                            reason: 'My Significant Other is on Ecliptic',
                            action: 'delete',
                        },
                    },
                    {
                        text: 'My Mom is on Ecliptic',
                        role: 'destructive',
                        data: {
                            reason: 'My Significant Other is on Ecliptic',
                            action: 'delete',
                        },
                    },
                    {
                        text: 'My Dad is on Ecliptic',
                        role: 'destructive',
                        data: {
                            reason: 'My Significant Other is on Ecliptic',
                            action: 'delete',
                        },
                    },
                    {
                        text: 'Too Cooked',
                        role: 'destructive',
                        data: {
                            reason: 'Too Cooked',
                            action: 'delete',
                        },
                    },
                    {
                        text: 'Technical Overload',
                        role: 'destructive',
                        data: {
                            reason: 'Technical Overload',
                            action: 'delete',
                        },
                    },
                    {
                        text: 'Hate/Abuse/Spam',
                        role: 'destructive',
                        data: {
                            reason: 'Hate/Abuse/Spam',
                            action: 'delete',
                        },
                    },
                    {
                        text: `Report ${usernameToReport}`,
                        role: 'destructive',
                        data: {
                            reason: `Report ${usernameToReport}`,
                            action: 'delete',
                        },
                    },
                    {
                        text: `Block ${usernameToReport}`,
                        role: 'destructive',
                        data: {
                            reason: 'block',
                            action: 'block',
                        },
                    },
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        data: {
                            reason: 'Cancel',
                            action: 'cancel',
                        },
                    },
                ]}
                onDidDismiss={(e) => handleActionSheet(e)}
            />
        </>
    );
};

export default ReportReason;