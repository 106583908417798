import {IonButton, IonHeader, IonIcon, IonToolbar, useIonToast} from "@ionic/react";
import {cogOutline, notificationsOutline, searchOutline, storefrontOutline} from "ionicons/icons";
import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useAuth} from "../../../AuthProvider";
import useAuthService from "../../../hooks/useAuthService";
import PreferencesService from "../../../services/preferences.service";
import biskLogo from "../../../assets/icons/bisk/BISK_ICON_NO_DEGREE.svg";

interface ToolbarSearchBarCommunityProps {
    handleSegmentChange: (value: string) => void;
}

const ToolbarSearchBarCommunity: React.FC<ToolbarSearchBarCommunityProps> = ({handleSegmentChange}) => {
    const history = useHistory();
    const {isAuthenticated} = useAuth();
    const {login} = useAuthService()
    const [present] = useIonToast();
    const [segmentValue, setSegmentValue] = React.useState<string>("bisk");

    const openSettings = () => {
        history.push("/settings");
    }
    const openSearch = () => {
        history.push("/search");
    }

    const presentToast = (message: string, duration: number, icon: string) => {
        present({
            message: message,
            duration: duration,
            position: 'top',
            icon: icon,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                        console.log('Dismiss clicked');
                    },
                },
            ],
            onDidDismiss: (e: CustomEvent) => console.log(`Dismissed with role: ${e.detail.role}`)
        });
    };

    const handleSegmentChildChange = async (segmentChange: string) => {
        await PreferencesService.setCommunityTab(segmentChange);
        setSegmentValue(segmentChange);
        handleSegmentChange(segmentChange);
    }

    const handleSmokeShack = () => {
        history.push("/shop");
    }

    useEffect(() => {
        const getSegmentPreferences = async () => {
            const result = await PreferencesService.getCommunityTab();
            if (result) {
                setSegmentValue(result);
                handleSegmentChange(result);
            } else {
                setSegmentValue("bisk");
                handleSegmentChange("bisk");
            }
        }

        getSegmentPreferences();
    }, []);

    const options = [
        {value: 'bisk', label: 'Bisk', icon: <IonIcon className="pb-0.5" icon={biskLogo}></IonIcon>},
        {value: 'board', label: 'Boards'},
        {value: 'crews', label: 'Crews'},
        {value: 'events', label: 'Events'}
    ];

    return (
        <>
            <IonHeader className="bg-grey">
                <IonToolbar>
                    <div className="flex flex-row justify-between items-center">
                        <div className="flex order-first">
                            <div>
                                <IonButton fill="clear" size="small" color="dark" onClick={openSearch}>
                                    <IonIcon slot="icon-only" icon={searchOutline}/>
                                </IonButton>
                            </div>
                            <div>
                                <IonButton fill="clear" size="small" color="dark" onClick={() => handleSmokeShack()}>
                                    <IonIcon slot="icon-only" icon={storefrontOutline}/>
                                </IonButton>
                            </div>
                        </div>
                        <div className="flex-1 flex justify-center">
                            <a href="/"><img alt="ID Logo" src="/assets/photos/logo_white.png"></img></a>
                        </div>
                        <div className="flex order-last">
                            <div className="opacity-0">
                                <IonButton fill="clear" size="small" color="dark"
                                           onClick={() => presentToast("Keeping track of alerts is almost here!", 3500, notificationsOutline)}>
                                    <IonIcon slot="icon-only" icon={notificationsOutline}/>
                                </IonButton>
                            </div>
                            <div>
                                <IonButton fill="clear" size="small" color="dark"
                                           onClick={() => isAuthenticated ? openSettings() : login()}>
                                    <IonIcon slot="icon-only" icon={cogOutline}/>
                                </IonButton>
                            </div>
                        </div>
                    </div>
                </IonToolbar>
                <div className="flex min-h-12">
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={`flex-1 py-3 px-4 text-center text-sm uppercase ${segmentValue === option.value ? 'bg-[#111111] text-white border-b-2 border-white font-bold' : 'bg-[#111111] text-[#9c9c9c]'}`}
                            onClick={(e) => {
                                handleSegmentChildChange(option.value)
                            }}
                        >
                                                        <span
                                                            className={option.icon ? 'flex items-center justify-center gap-x-2' : ''}>
                                {option.label}
                                                            {option.icon}
                            </span>
                        </div>
                    ))}
                </div>
            </IonHeader>
        </>
    );
};

export default ToolbarSearchBarCommunity;
