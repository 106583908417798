import React, {useEffect, useRef, useState} from "react";
import Dropdown from '../Dropdown/Dropdown';

interface ContainerProps {
    options: any[],
    selectedOptions: any[],
    addTag: any,
    removeTag: any,
    showDropdown: boolean,
    disable?: boolean;
    placeholder?: string;
}

const MultiSelect: React.FC<ContainerProps> = ({
                                                   options,
                                                   selectedOptions,
                                                   addTag,
                                                   removeTag,
                                                   showDropdown,
                                                   disable,
                                                   placeholder
                                               }) => {
    const [userInput, setUserInput] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [dropdown, setDropdown] = useState(showDropdown);

    const closeDropdown = useRef(null);

    const closeOpenMenus = (e: any) => {
        if (closeDropdown.current && dropdown && !closeDropdown.current.contains(e.target)) {
            setDropdown(false);
        }
    }

    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);

    useEffect(() => {
        document.addEventListener("mousedown", closeOpenMenus);
        return () => {
            document.removeEventListener("mousedown", closeOpenMenus);
        }
    }, [dropdown]);

    const addItem = (item: any) => {
        addTag(item);
        setUserInput("");
        setDropdown(false);
    }

    return (<div className="autcomplete-wrapper" ref={closeDropdown} onClick={() => setDropdown(true)}>
        <div className="autcomplete">
            <div className="w-full flex flex-col items-center mx-auto">
                <div className="w-full">
                    <div className="flex flex-col items-center relative">
                        <div className="w-full">
                            <div
                                className="my-2 p-1 flex shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                <div className="flex flex-auto flex-wrap">
                                    {
                                        selectedOptions.map((tag: any, index: any) => {
                                            return (
                                                <div key={index}
                                                     className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-teal-700 bg-teal-100 border border-teal-300 ">
                                                    <div
                                                        className="text-xs font-normal leading-none max-w-full flex-initial">{tag}</div>
                                                    <div className="flex flex-auto flex-row-reverse">
                                                        <div onClick={() => removeTag(tag)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="100%"
                                                                 height="100%" fill="none" viewBox="0 0 24 24"
                                                                 stroke="currentColor" strokeWidth="2"
                                                                 strokeLinecap="round" strokeLinejoin="round"
                                                                 className="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-4 h-4 ml-2">
                                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>)
                                        })
                                    }
                                    <div className="flex-1">
                                        <input
                                            value={userInput}
                                            placeholder={placeholder ? placeholder : "See our trick filtering options in the search for tags"}
                                            className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800"
                                            onChange={(e) => {
                                                const input = e.target.value;

                                                // Update the userInput state with the current value of the input field.
                                                setUserInput(input);

                                                // Filter the options based on the current input.
                                                const filteredOptions = options.filter((optionName) =>
                                                    optionName.toLowerCase().includes(input.toLowerCase())
                                                );
                                                setFilteredOptions(filteredOptions);

                                                // Show or hide the dropdown based on the filtered options and input length.
                                                if (filteredOptions.length > 0 && input.length > 0) {
                                                    setDropdown(true);
                                                } else {
                                                    setDropdown(false);
                                                }
                                            }}
                                            disabled={!disable ? false : (options.length === 0 && selectedOptions.length === 0)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' && userInput) {
                                                    e.preventDefault();
                                                    addItem(userInput);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {dropdown ? <Dropdown list={filteredOptions} addItem={addItem}></Dropdown> : null}
                </div>
            </div>

        </div>
    </div>)
};

export default MultiSelect;
