import {IonButton, IonButtons, IonContent, IonHeader, IonPage, IonSpinner, IonTitle, IonToolbar} from "@ionic/react";
import React, {useEffect, useState} from "react";
import {PictureUploadType} from "../../../models/pictureUploadType";
import Cropper from "react-easy-crop";
import {v4 as uuidv4} from 'uuid';


interface ContainerProps {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void,
    onProfilePictureSubmit: (selectedFile: any) => void,
    src: string,
    pictureUploadType: PictureUploadType,
    preview: any
}

const ProfilePictureModal: React.FC<ContainerProps> = ({
                                                           onDismiss,
                                                           onProfilePictureSubmit,
                                                           src,
                                                           pictureUploadType,
                                                           preview
                                                       }) => {
    const [crop, setCrop] = useState({x: 0, y: 0});
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const [profilePicModalPreview, setProfilePicModalPreview] = useState<any>(null);

    const [profilePhotoUploading, setProfilePhotoUploading] = useState(false);

    useEffect(() => {
        if (preview) {
            setProfilePicModalPreview(preview);
        }
    }, [preview]);

    const setDefaultParameters = () => {
        setCrop({x: 0, y: 0});
        setZoom(1);
        setCroppedAreaPixels(null);
        setProfilePicModalPreview(null);
        setProfilePhotoUploading(false);
    }

    const readFile = async (file: any) => {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const rotateSize = (width: any, height: any, rotation: any) => {
        const rotRad = getRadianAngle(rotation)

        return {
            width:
                Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
            height:
                Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
        }
    }

    const getRadianAngle = (degreeValue: any) => {
        return (degreeValue * Math.PI) / 180
    }


    const getCroppedImg = async (
        image: any,
        pixelCrop: any,
        rotation = 0,
        flip = {horizontal: false, vertical: false}
    ) => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        if (!ctx) {
            return null
        }

        const rotRad = getRadianAngle(rotation)


        // calculate bounding box of the rotated image
        const {width: bBoxWidth, height: bBoxHeight} = rotateSize(
            image.width,
            image.height,
            rotation
        )

        // set canvas size to match the bounding box
        canvas.width = bBoxWidth
        canvas.height = bBoxHeight

        // translate canvas context to a central location to allow rotating and flipping around the center
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
        ctx.rotate(rotRad)
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
        ctx.translate(-image.width / 2, -image.height / 2)

        // draw rotated image
        ctx.drawImage(image, 0, 0)

        const croppedCanvas = document.createElement('canvas')

        const croppedCtx = croppedCanvas.getContext('2d')

        if (!croppedCtx) {
            return null
        }

        // Set the size of the cropped canvas
        croppedCanvas.width = pixelCrop.width
        croppedCanvas.height = pixelCrop.height

        // Draw the cropped image onto the new canvas
        croppedCtx.drawImage(
            canvas,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        )

        // As Base64 string
        // return croppedCanvas.toDataURL('image/jpeg');

        // As a blob
        return new Promise((resolve, reject) => {
            croppedCanvas.toBlob(blob => {
                if (blob) {
                    const fileName = `${uuidv4().toString()}.png`; // Generate a UUID for the file name
                    const newFile = new File([blob], fileName, {type: 'image/png'});
                    resolve(newFile);
                } else {
                    reject(new Error('Canvas to Blob conversion failed'));
                    return;
                }
            }, 'image/png');
        });
    }

    async function confirm() {
        setProfilePhotoUploading(true);

        // Create an image element to use with the getCroppedImg function
        const image = new Image();
        image.src = profilePicModalPreview;

        // Ensure the image is loaded before proceeding to crop
        image.onload = async () => {
            // Crop the image with the specified crop area
            try {
                const croppedImageFile = await getCroppedImg(image, croppedAreaPixels, 0);
                await onProfilePictureSubmit(croppedImageFile);

                // Clean up the UI state after submission
                setProfilePhotoUploading(false);
                setDefaultParameters();
                onDismiss();
            } catch (e) {
                console.log(e);
                setProfilePhotoUploading(false);
                setDefaultParameters();
                onDismiss();
            }

        };

        image.onerror = () => {
            console.error("The image could not be loaded.");
            setDefaultParameters();
            setProfilePhotoUploading(false);
        };
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="flex flex-row">
                    <IonButtons slot="start">
                        <IonButton strong={true} onClick={() => {
                            setDefaultParameters();
                            onDismiss(null, 'cancel')
                        }
                        }>
                            Cancel
                        </IonButton>
                    </IonButtons>
                    <IonTitle
                        className="items-center text-center">{!src ? (!pictureUploadType ? "Upload Profile Picture" : "Upload " + pictureUploadType + " Picture") : !pictureUploadType ? "Change Profile Picture" : "Change " + pictureUploadType + " Picture"}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => confirm()}>
                            Done
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <>
                    {
                        !profilePhotoUploading && preview ?
                            <>
                                <div className="flex flex-col items-center w-full h-screen">
                                    <div className="w-full h-full py-1">
                                        <Cropper
                                            image={profilePicModalPreview}
                                            crop={crop}
                                            zoom={zoom}
                                            rotation={0}
                                            aspect={1}
                                            onCropChange={setCrop}
                                            onZoomChange={setZoom}
                                            objectFit={'horizontal-cover'}
                                            onCropComplete={onCropComplete}
                                        />
                                    </div>
                                </div>
                            </> :
                            <div className="flex justify-center items-center" style={{height: '360px', width: '100%'}}>
                                <IonSpinner name="crescent" color="theme-secondary"
                                            style={{transform: 'scale(2.5)'}}/> {/* You can choose other spinner names as well */}
                            </div>
                    }
                </>
            </IonContent>
        </IonPage>
    );
};

export default ProfilePictureModal;