import {Capacitor} from "@capacitor/core";
import {
    IonButton,
    IonContent,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    RefresherEventDetail
} from "@ionic/react";
import {gridOutline, listOutline} from "ionicons/icons";
import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import CrewCard from "../components/ComponentsCrew/CrewCard/CrewCard";
import CrewList from "../components/ComponentsCrew/CrewList/CrewList";
import EventCard from "../components/ComponentsEvent/EventCard/EventCard";
import EventList from "../components/ComponentsEvent/EventList/EventList";
import {Brand} from "../models/brand";
import {BrandEvent} from "../models/brandEvent";
import BrandsService from "../services/brand.service";
import {getDateNowUTCISO, getSlangTerm} from "../services/utils";
import BiskService from "../services/bisk.service";
import {Virtuoso} from "react-virtuoso";
import {BiskTransactionType} from "../models/biskTransactionType";
import BiskActivityCard from "../components/ComponentsBisk/BiskActivityCard/BiskActivityCard";
import {AppContext} from "../AppStateProvider";
import {SubscriptionType} from "../models/subscriptionType";
import ShopRedirect from "../components/ComponentsPurchases/ShopRedirect/ShopRedirect";
import {BoardType} from "../models/boardType";
import CustomHelmet from "../components/ComponentsFunctional/CustomHelmet/CustomHelmet";
import {Board} from "../models/board";
import BoardUserBiskCard from "../components/ComponentsBoard/BoardUserBiskCard/BoardUserBiskCard";
import {BoardIdentifierNumber} from "../models/boardUserBisk";
import BoardIDXInfoCard from "../components/ComponentsBoard/BoardIDXInfoCard/BoardIDXInfoCard";
import LoginButton from "../components/ComponentsLogin/LoginButton/LoginButton";
import {BiskTransactionView} from "../models/biskTransactionView";
import {useAuth} from "../AuthProvider";
import ToolbarSearchBarCommunity from "../components/ComponentsUI/ToolbarSearchBarCommunity/ToolbarSearchBarCommunity";

const Community: React.FC = () => {
    const history = useHistory();

    const isNative = Capacitor.isNativePlatform();

    const {state} = React.useContext(AppContext);

    const {isAuthenticated} = useAuth();

    const [segmentValue, setSegmentValue] = React.useState(undefined);

    const [eventCrewSearch, setEventCrewSearch] = React.useState(undefined);

    const [crews, setCrews] = React.useState([]);
    const [crewsFilter, setCrewsFilter] = React.useState<string>("");
    const [events, setEvents] = React.useState([]);
    const [eventsFilter, setEventsFilter] = React.useState<string>("");

    const [boards, setBoards] = React.useState<Board[]>([]);
    const [boardFullSelected, setBoardFullSelected] = React.useState<Board>();
    const [boardSelected, setBoardSelected] = React.useState<string>("");
    const [boardPeriodSelected, setBoardPeriodSelected] = React.useState<string>("");
    const [boardSortSelected, setBoardSortSelected] = React.useState<string>("");
    const [boardPage, setBoardPage] = React.useState<number>(0);
    const [boardResults, setBoardResults] = React.useState<BoardIdentifierNumber[]>([]);
    const [boardResultsInfiniteScrollDisabled, setBoardResultsInfiniteScrollDisabled] = React.useState<boolean>(false);
    const [boardResultsLoading, setBoardResultsLoading] = React.useState<boolean>(false);
    const numberOfBoardTransactions = Capacitor.isNativePlatform() ? 10 : 15;

    const [biskTransactions, setBiskTransactions] = React.useState<BiskTransactionView[]>([]);
    const [biskTransactionsInfiniteScrollDisabled, setBiskTransactionsInfiniteScrollDisabled] = React.useState<boolean>(false);
    const [biskTransactionsLoading, setBiskTransactionsLoading] = React.useState<boolean>(false);
    const [biskFilter, setBiskFilter] = React.useState<string>("");
    const numberOfBiskTransactions = Capacitor.isNativePlatform() ? 10 : 15;
    const slangTerm = getSlangTerm();

    const [eventsGrid, setEventsGrid] = React.useState<boolean>(!isNative);
    const [crewGrid, setCrewGrid] = React.useState<boolean>(!isNative);

    const [blurred, setBlurred] = React.useState<boolean>(true);
    const [blurredClass, setBlurredClass] = React.useState<string>("");

    const BiskFooterContainer = () => {
        return (
            <IonInfiniteScroll
                disabled={biskTransactions.length == 0 ? true : biskTransactionsInfiniteScrollDisabled}>
                <IonInfiniteScrollContent
                    loadingSpinner="bubbles"
                    loadingText={"It's just biskness"}
                ></IonInfiniteScrollContent>
            </IonInfiniteScroll>
        )
    }

    const BoardFooterContainer = () => {
        return (
            <IonInfiniteScroll
                disabled={boardResults.length == 0 ? true : boardResultsInfiniteScrollDisabled}>
                <IonInfiniteScrollContent
                    loadingSpinner="bubbles"
                    loadingText={"It's just biskness"}
                ></IonInfiniteScrollContent>
            </IonInfiniteScroll>
        )
    }

    const BiskHeaderContainer = () => {
        return (
            <div>
                <IonRefresher onIonRefresh={resetBiskTransactions} slot="fixed">
                    <IonRefresherContent refreshingText={"Refreshing the bisk"}></IonRefresherContent>
                </IonRefresher>
            </div>
        )
    }

    const BoardHeaderContainer = () => {
        return (
            <div>
                <IonRefresher onIonRefresh={resetBoardResults} slot="fixed">
                    <IonRefresherContent refreshingText={"Refreshing the board"}></IonRefresherContent>
                </IonRefresher>
            </div>
        )
    }

    const BiskHeaderEmpty = () => {
        return (
            biskTransactionsLoading ?
                <div className="m-4 text-center flex flex-row align-bottom">
                    <div>{"Loading the latest biskness " + slangTerm}</div>
                    <IonSpinner name="dots"></IonSpinner>
                </div> :
                <div className="text-xl m-4 text-center">Looks like there is no biskness present :(</div>
        )
    }

    const BoardHeaderEmpty = () => {
        return (
            boardResultsLoading ?
                <div className="m-4 text-center flex flex-row align-bottom">
                    <div>{"Loading the latest biskness " + slangTerm}</div>
                    <IonSpinner name="dots"></IonSpinner>
                </div> :
                <div className="text-xl m-4 text-center">Looks like there is no data for this board :(</div>
        )
    }

    const getInitBiskTransactions = async () => {
        setBiskTransactionsInfiniteScrollDisabled(false);
        setBiskTransactionsLoading(true);
        const actualDate = getDateNowUTCISO();
        let initBiskTransactions: BiskTransactionView[] = [];
        if (biskFilter !== "") {
            initBiskTransactions = await BiskService.getBiskTransactionsByType(biskFilter, numberOfBiskTransactions, actualDate);
        } else {
            initBiskTransactions = await BiskService.getBiskTransactionsAll(numberOfBiskTransactions, actualDate);
        }
        setBiskTransactions(initBiskTransactions);
        setBiskTransactionsLoading(false);
        console.log("Bisk Transactions: ", initBiskTransactions);
    }

    const getInitialBoardResults = async (boardName: string, period: string, sort: string, pageNumber: number) => {
        console.log("Getting INITIAL board results");
        setBoardResultsInfiniteScrollDisabled(false);
        setBoardResultsLoading(true);
        setBoardResults([]);
        const boardResultsRes = await BiskService.getBoardResult(boardName, period, sort, numberOfBoardTransactions, pageNumber);

        if (boardResultsRes.length === 0 || boardResultsRes.length < numberOfBoardTransactions) {
            console.log("MORE DISABLED");
            setBoardResultsInfiniteScrollDisabled(true);
        }

        console.log("Board INITIAL Results: ", boardResultsRes);
        setBoardResults(boardResultsRes);
        setBoardPage(pageNumber + 1);

        setBoardResultsLoading(false);
    }

    const getBoards = async () => {
        console.log("Getting boards");
        const boards = await BiskService.getBoards();
        console.log("Boards: ", boards);
        setBoards(boards);
        boards.length > 0 && setBoardSelected(boards[0].name);
        boards.length > 0 && setBoardPeriodSelected(boards[0].periods[0]);
        boards.length > 0 && setBoardSortSelected(boards[0].sort[0]);
        boards.length > 0 && setBoardFullSelected(boards[0]);
        boards.length > 0 && await getInitialBoardResults(boards[0].name, boards[0].periods[0], boards[0].sort[0], 0);
    }

    const getMoreBiskTransactions = async () => {
        console.log("Getting more following tricks with date: " + biskTransactions[biskTransactions.length - 1].date);
        let newBiskTransactions: BiskTransactionView[] = [];
        if (biskFilter !== "") {
            newBiskTransactions = await BiskService.getBiskTransactionsByType(biskFilter, numberOfBiskTransactions, biskTransactions[biskTransactions.length - 1].date);
        } else {
            newBiskTransactions = await BiskService.getBiskTransactionsAll(numberOfBiskTransactions, biskTransactions[biskTransactions.length - 1].date);
        }

        if (newBiskTransactions.length === 0) {
            setBiskTransactionsInfiniteScrollDisabled(true);
        }

        setBiskTransactions([
            ...biskTransactions,
            ...newBiskTransactions
        ]);

        console.log('Loaded more following tricks');
    }

    const getMoreBoardResults = async () => {
        console.log("Getting more board results");
        const newBoardResults = await BiskService.getBoardResult(boardSelected, boardPeriodSelected, boardSortSelected, numberOfBoardTransactions, boardPage);
        console.log("New MORE Board Results: ", newBoardResults);

        if (newBoardResults.length === 0 || newBoardResults.length < numberOfBoardTransactions) {
            console.log("MORE DISABLED");
            setBoardResultsInfiniteScrollDisabled(true);
        }

        setBoardResults([
            ...boardResults,
            ...newBoardResults
        ]);
        setBoardPage(boardPage + 1);
    }

    const resetBiskTransactions = async (event: CustomEvent<RefresherEventDetail>) => {
        await getInitBiskTransactions();
        event.detail.complete();
    }

    const resetBoardResults = async (event: CustomEvent<RefresherEventDetail>) => {
        await getInitialBoardResults(boardSelected, boardPeriodSelected, boardSortSelected, 0);
        event.detail.complete();
    }

    const handleBiskFilterChange = async (biskFilter: CustomEvent) => {
        console.log("Bisk Filter Changed: " + biskFilter.detail.value);
        setBiskFilter(biskFilter.detail.value);
        setBiskTransactionsInfiniteScrollDisabled(false);
        setBiskTransactionsLoading(true);
        const actualDate = getDateNowUTCISO();
        let initBiskTransactions: BiskTransactionView[] = [];
        if (biskFilter.detail.value !== "") {
            initBiskTransactions = await BiskService.getBiskTransactionsByType(biskFilter.detail.value, numberOfBiskTransactions, actualDate);
        } else {
            initBiskTransactions = await BiskService.getBiskTransactionsAll(numberOfBiskTransactions, actualDate);
        }
        setBiskTransactions(initBiskTransactions);
        setBiskTransactionsLoading(false);
    }

    const handleBoardChange = async (boardFilter: CustomEvent) => {
        console.log("Board Changed: " + boardFilter.detail.value);
        setBoardSelected(boardFilter.detail.value);
        setBoardFullSelected(boards.find((board: Board) => board.name === boardFilter.detail.value));
        const period = boards.find((board: Board) => board.name === boardFilter.detail.value)?.periods[0];
        const sort = boards.find((board: Board) => board.name === boardFilter.detail.value)?.sort[0];
        const pageNumber = 0;
        setBoardPeriodSelected(period);
        setBoardSortSelected(sort);
        setBoardPage(pageNumber);
        await getInitialBoardResults(boardFilter.detail.value, period, sort, pageNumber);
    }

    const handleBoardPeriodChange = async (boardPeriod: CustomEvent) => {
        console.log("Board Changed: " + boardPeriod.detail.value);
        setBoardPeriodSelected(boardPeriod.detail.value);
        const sort = boards.find((board: Board) => board.name === boardSelected)?.sort[0];
        setBoardSortSelected(sort);
        const pageNumber = 0;
        setBoardPage(pageNumber);
        await getInitialBoardResults(boardSelected, boardPeriod.detail.value, boardSortSelected, pageNumber);
    }

    const handleBoardSortChange = async (boardSort: CustomEvent) => {
        console.log("Board Sort Changed: " + boardSort.detail.value);
        setBoardSortSelected(boardSort.detail.value);
        const pageNumber = 0;
        setBoardPage(pageNumber);
        await getInitialBoardResults(boardSelected, boardPeriodSelected, boardSort.detail.value, pageNumber);
    }

    useEffect(() => {
        console.log("Loading the community page");
        window.prerenderReady = true;
    }, [history]);

    useEffect(() => {
        console.log("Initializing crews")
        setCrewsFilter("");
        setEventsFilter("");
        setBiskFilter("");

        const getInitAllCrews = async () => {
            const initAllCrews: Brand[] = await BrandsService.getAllBrands();
            setCrews(initAllCrews);
        }

        const getInitEvents = async () => {
            const initEvents: Brand[] = await BrandsService.getAllEvents();
            setEvents(initEvents);
        }

        getInitBiskTransactions();
        getInitAllCrews();
        getInitEvents();
        getBoards();
    }, []);

    useEffect(() => {
        if (state.isSubscribed) {
            console.log("Subscribed");
            if (state.subscriptionLevel === SubscriptionType.Premium) {
                setBlurred(false);
                setBlurredClass("");
            } else if (state.subscriptionLevel === SubscriptionType.Basic) {
                setBlurred(true);
                setBlurredClass("blur-lg");
            }
        } else {
            console.log("Not Subscribed");
            setBlurred(true);
            setBlurredClass("blur-lg");
        }
    }, [state.isSubscribed, state.subscriptionLevel]);

    const handleSegmentChange = async (value: string) => {
        setSegmentValue(value);
        setCrewsFilter("");
        setEventsFilter("");
    }

    const onCommunitySearch = async (search: string) => {
        console.log("We ain't searching for this yet! " + search);
        history.push('/search/' + search);
        setEventCrewSearch('');
    }

    const toggleEventsGrid = () => {
        setEventsGrid(!eventsGrid);
    }

    const toggleCrewGrid = () => {
        setCrewGrid(!crewGrid);
    }

    const onShopRedirect = () => {
        history.push('/shop');
    }

    return (
        <IonPage>
            <CustomHelmet title={"Ecliptic // Community"}
                          description={"View the Ecliptic community and see all the different park crews around the world"}
                          image={"https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                          url={`https://ecliptic.day/community`}/>
            <ToolbarSearchBarCommunity handleSegmentChange={handleSegmentChange}></ToolbarSearchBarCommunity>
            <IonContent>
                {
                    segmentValue === 'events' ?
                        <>
                            <div className="flex flex-row items-center mx-4 mt-2 justify-end">
                                <div className="flex-1">
                                    <IonSearchbar onIonInput={(e) => setEventsFilter(e.detail.value)}
                                                  showClearButton="focus"
                                                  showCancelButton="focus"
                                                  style={{padding: 0}}
                                                  id="searchText"
                                                  debounce={0}
                                                  animated={true}></IonSearchbar>
                                </div>
                                <div>
                                    {
                                        eventsGrid ?
                                            <IonButton size="small" fill="clear" color="theme-secondary"
                                                       onClick={toggleEventsGrid}>
                                                <IonIcon icon={listOutline} slot="icon-only"></IonIcon>
                                            </IonButton> :
                                            <IonButton size="small" fill="clear" color="theme-secondary"
                                                       onClick={toggleEventsGrid}>
                                                <IonIcon icon={gridOutline} slot="icon-only"></IonIcon>
                                            </IonButton>
                                    }
                                </div>
                            </div>
                            {
                                !isAuthenticated
                                    ? <div className="m-4"><LoginButton
                                        title="Upload to Ecliptic and Enter Events to Get Recognized and See the Biskness"
                                        upload={false} profile={true}/></div> :
                                    eventsGrid ?
                                        <div
                                            className="grid grid-flow-row auto-row-max xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 gap-2 p-2">
                                            {events?.length === 0 ?
                                                <div className="m-4 text-center flex flex-row align-bottom">
                                                    <div>{"Retrieving events for you "}</div>
                                                    <IonSpinner name="dots"></IonSpinner>
                                                </div>
                                                :
                                                events?.filter((event: BrandEvent) =>
                                                    event.name.toLowerCase().includes(eventsFilter.toLowerCase())
                                                ).map((brandEvent: BrandEvent, i) => <EventCard key={brandEvent.id}
                                                                                                brandEvent={brandEvent}/>)}
                                        </div> :
                                        <div
                                            className="grid grid-cols-1 grid-rows-1 divide-y divide-slate-500 gap-y-2">
                                            {events?.length === 0 ?
                                                <div className="m-4 text-center flex flex-row align-bottom">
                                                    <div>{"Retrieving events for you "}</div>
                                                    <IonSpinner name="dots"></IonSpinner>
                                                </div>
                                                :
                                                events?.filter((event: BrandEvent) =>
                                                    event.name.toLowerCase().includes(eventsFilter.toLowerCase())
                                                ).map((brandEvent: BrandEvent, i) =>
                                                    <EventList key={brandEvent.id} brandEvent={brandEvent}/>
                                                )}
                                        </div>
                            }
                        </>
                        :
                        segmentValue === 'crews' ?
                            <>
                                <div className="flex flex-row items-center mx-4 mt-2 justify-end mb-0.5">
                                    <div className="flex-1">
                                        <IonSearchbar onIonInput={(e) => setCrewsFilter(e.detail.value)}
                                                      showClearButton="focus"
                                                      showCancelButton="focus"
                                                      style={{padding: 0}}
                                                      id="searchText"
                                                      debounce={0}
                                                      animated={true}></IonSearchbar>
                                    </div>
                                    <div>
                                        {
                                            crewGrid ?
                                                <IonButton size="small" fill="clear" color="theme-secondary"
                                                           onClick={toggleCrewGrid}>
                                                    <IonIcon icon={listOutline} slot="icon-only"></IonIcon>
                                                </IonButton> :
                                                <IonButton size="small" fill="clear" color="theme-secondary"
                                                           onClick={toggleCrewGrid}>
                                                    <IonIcon icon={gridOutline} slot="icon-only"></IonIcon>
                                                </IonButton>
                                        }
                                    </div>
                                </div>
                                {
                                    !isAuthenticated
                                        ? <div className="m-4"><LoginButton
                                            title="View The Crews, Squads, Communities, and Sponsors on Ecliptic"
                                            upload={false}
                                            profile={true}/></div> :
                                        crewGrid ?
                                            <div
                                                className="grid grid-flow-row auto-row-max xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 gap-2 p-2">
                                                {crews.length === 0 ?
                                                    <div className="m-4 text-center flex flex-row align-bottom">
                                                        <div>{"Retrieving crews for you " + getSlangTerm()}</div>
                                                        <IonSpinner name="dots"></IonSpinner>
                                                    </div>
                                                    :
                                                    crews.filter((crew: Brand) =>
                                                        crew.name.toLowerCase().includes(crewsFilter.toLowerCase())
                                                    ).map((crew: Brand, i) => <CrewCard key={crew.id} crew={crew}/>)}
                                            </div> :
                                            <div
                                                className="grid grid-cols-1 grid-rows-1 divide-y divide-slate-500 gap-y-2">
                                                {crews.length === 0 ?
                                                    <div className="m-4 text-center flex flex-row align-bottom">
                                                        <div>{"Retrieving crews for you " + getSlangTerm()}</div>
                                                        <IonSpinner name="dots"></IonSpinner>
                                                    </div>
                                                    :
                                                    crews.filter((crew: Brand) =>
                                                        crew.name.toLowerCase().includes(crewsFilter.toLowerCase())
                                                    ).map((crew: Brand, i) => <CrewList key={crew.id} crew={crew}/>)}
                                            </div>
                                }

                            </>
                            :
                            segmentValue === 'bisk' ?
                                <>
                                    <div className="items-center my-4 mx-8 justify-center">
                                        <IonSelect
                                            label="The Biskness"
                                            interface="popover"
                                            placeholder="Select transaction type"
                                            style={{ionBackgroundColor: "none"}}
                                            onIonChange={(e) => handleBiskFilterChange(e)}
                                        >
                                            <IonSelectOption value="">All</IonSelectOption>
                                            <IonSelectOption value={BiskTransactionType.SEND}>Sent</IonSelectOption>
                                            <IonSelectOption value={BiskTransactionType.AWARD}>Awarded</IonSelectOption>
                                            <IonSelectOption value={BiskTransactionType.BUY}>Bought</IonSelectOption>
                                            <IonSelectOption
                                                value={BiskTransactionType.WITHDRAW}>Withdrew</IonSelectOption>
                                            <IonSelectOption
                                                value={BiskTransactionType.REDEEM}>Redeemed</IonSelectOption>
                                        </IonSelect>
                                    </div>
                                    {
                                        <IonContent scrollY={false}>
                                            <Virtuoso
                                                className={"ion-content-scroll-host"}
                                                data={biskTransactions}
                                                endReached={() => {
                                                    if (!biskTransactionsInfiniteScrollDisabled) {
                                                        getMoreBiskTransactions();
                                                    }
                                                }}
                                                increaseViewportBy={150}
                                                overscan={{main: 0, reverse: 0}}
                                                itemContent={(i, biskTransaction) => {
                                                    if (i === 0) {
                                                        return (
                                                            <BiskActivityCard transaction={biskTransaction}
                                                                              blurred={false}
                                                                              isAuthed={isAuthenticated}/>
                                                        );
                                                    }

                                                    if (i === 1) {
                                                        return (
                                                            blurred ?
                                                                <div
                                                                    className="flex flex-col align-middle items-center my-1 mx-4">
                                                                    <ShopRedirect
                                                                        title={"Head to the Smoke Shack and Sign Up for the Tow Rope Pass to View Leaderboards!"}
                                                                        shopRedirect={onShopRedirect}/>
                                                                </div> : <BiskActivityCard transaction={biskTransaction}
                                                                                           blurred={blurred}
                                                                                           isAuthed={isAuthenticated}/>
                                                        )
                                                    }
                                                    return (
                                                        <BiskActivityCard transaction={biskTransaction}
                                                                          blurred={blurred} isAuthed={isAuthenticated}/>
                                                    );
                                                }}
                                                components={{
                                                    Footer: BiskFooterContainer,
                                                    EmptyPlaceholder: BiskHeaderEmpty,
                                                    Header: BiskHeaderContainer
                                                }}/>
                                        </IonContent>
                                    }
                                </>
                                : segmentValue === 'board' ?
                                    <>
                                        <div className="flex flex-row items-center justify-between my-4 mx-8">
                                            <div>
                                                <IonSelect
                                                    aria-label="Board"
                                                    interface="popover"
                                                    placeholder="Board"
                                                    style={{ionBackgroundColor: "none"}}
                                                    value={boardSelected}
                                                    onIonChange={(e) => handleBoardChange(e)}
                                                >
                                                    {
                                                        boards.map((board: Board) => {
                                                            return (
                                                                <IonSelectOption key={board.id}
                                                                                 value={board.name}>{board.name}</IonSelectOption>
                                                            )
                                                        })
                                                    }
                                                </IonSelect>
                                            </div>
                                            <div>
                                                <IonSelect
                                                    aria-label="Period"
                                                    interface="popover"
                                                    placeholder="Period"
                                                    style={{ionBackgroundColor: "none"}}
                                                    value={boardPeriodSelected}
                                                    onIonChange={(e) => handleBoardPeriodChange(e)}
                                                >
                                                    {
                                                        boardSelected && // Check if boardSelected is defined and not empty
                                                        boards.find((board: Board) => board.name === boardSelected)?.periods?.map((option: string) => (
                                                            // Ensure period is not undefined and map over it
                                                            <IonSelectOption key={option}
                                                                             value={option}>{option}</IonSelectOption>
                                                        ))
                                                    }
                                                </IonSelect>
                                            </div>
                                            <div>
                                                <IonSelect
                                                    aria-label="Sort"
                                                    interface="popover"
                                                    placeholder="Sort"
                                                    style={{ionBackgroundColor: "none"}}
                                                    value={boardSortSelected}
                                                    onIonChange={(e) => handleBoardSortChange(e)}
                                                >
                                                    {
                                                        boardSelected && // Check if boardSelected is defined and not empty
                                                        boards.find((board: Board) => board.name === boardSelected)?.sort?.map((option: string) => {
                                                                return (
                                                                    <IonSelectOption key={option}
                                                                                     value={option}>{option}</IonSelectOption>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </IonSelect>
                                            </div>
                                        </div>
                                        {
                                            <IonContent scrollY={false}>
                                                <Virtuoso
                                                    className={"ion-content-scroll-host"}
                                                    data={boardResults}
                                                    endReached={() => {
                                                        if (!boardResultsInfiniteScrollDisabled) {
                                                            getMoreBoardResults();
                                                        }
                                                    }}
                                                    increaseViewportBy={0}
                                                    overscan={{main: 0, reverse: 0}}
                                                    itemContent={(i, boardResult) => {
                                                        if (i === 0) {
                                                            return (
                                                                boardFullSelected.board_type === BoardType.IDX_INFO ?
                                                                    <BoardIDXInfoCard result={boardResult}
                                                                                      blurred={false}/> :
                                                                    <BoardUserBiskCard result={boardResult} blurred={false}
                                                                                       isAuthed={isAuthenticated}/>
                                                            );
                                                        }

                                                        if (i === 1) {
                                                            return (
                                                                blurred && !boardFullSelected.public ?
                                                                    <div
                                                                        className="flex flex-col align-middle items-center my-1 mx-4">
                                                                        <ShopRedirect
                                                                            title={"Head to the Smoke Shack and Sign Up for the Tow Rope Pass to View Leaderboards!"}
                                                                            shopRedirect={onShopRedirect}/>
                                                                    </div> :
                                                                    (
                                                                        boardFullSelected.board_type === BoardType.IDX_INFO ?
                                                                            <BoardIDXInfoCard result={boardResult}
                                                                                              blurred={boardFullSelected.public ? false : blurred}/> :
                                                                            <BoardUserBiskCard result={boardResult}
                                                                                               blurred={boardFullSelected.public ? false : blurred}
                                                                                               isAuthed={isAuthenticated}/>
                                                                    )
                                                            )
                                                        }
                                                        return (
                                                            boardFullSelected.board_type === BoardType.IDX_INFO ?
                                                                <BoardIDXInfoCard result={boardResult}
                                                                                  blurred={boardFullSelected.public ? false : blurred}/> :
                                                                <BoardUserBiskCard result={boardResult}
                                                                                   blurred={boardFullSelected.public ? false : blurred}
                                                                                   isAuthed={isAuthenticated}/>
                                                        );
                                                    }}
                                                    components={{
                                                        Footer: BoardFooterContainer,
                                                        EmptyPlaceholder: BoardHeaderEmpty,
                                                        Header: BoardHeaderContainer
                                                    }}/>
                                            </IonContent>
                                        }
                                    </> :
                                    <>
                                        {
                                            <IonContent scrollY={false}>
                                                {
                                                    blurred ?
                                                        <div className="flex flex-col align-middle items-center my-1 mx-4">
                                                            <ShopRedirect
                                                                title={"Head to the Smoke Shack and Sign Up for the Tow Rope Pass to View the Leaderboards!"}
                                                                shopRedirect={onShopRedirect}/>
                                                        </div> : <div></div>
                                                }
                                                <div className={blurredClass + " flex flex-row justify-center m"}>
                                                    Coming soon!
                                                </div>
                                            </IonContent>
                                        }
                                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default Community;