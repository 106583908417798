import axios from "axios";

const TrickFilterOptionsService = {
  getTrickFilterOptions: async (sport: string, feature: string, attribute: string) => {

    return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/metadata/tricks/filter_options/${sport}/${feature}/${attribute}`)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        console.error(error);
        return null;
      });
  }
};

export default TrickFilterOptionsService;
