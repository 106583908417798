import {IonImg} from "@ionic/react";
import React from "react";
import {useHistory} from "react-router-dom";
import {BrandEvent} from "../../../models/brandEvent";
import {handleTagClickUtil} from "../../../services/utils";
import IDXChip from "../../ComponentsUI/IDXChip/IDXChip";

interface ContainerProps {
    brandEvent: BrandEvent;
}

const EventCard: React.FC<ContainerProps> = (props: ContainerProps) => {
    const history = useHistory();

    const onClickCard = (event_id: string) => {
        history.push('/event/' + event_id);
    }

    const handleTagClick = (tag: string) => {
        const newTag = handleTagClickUtil(tag)
        history.push('/search/' + newTag);
    }

    return (
        <div className="cursor-pointer p-0 m-0 border-2 border-gray-900 bg-black rounded-md font-bold"
             key={props.brandEvent.id}>
            <IonImg className="object-contain aspect-video" onClick={() => onClickCard(props.brandEvent.id)}
                    src={props.brandEvent.cover_pic ? props.brandEvent.cover_pic : "https://ionicframework.com/docs/img/demos/card-media.png"}
                    alt={props.brandEvent.cover_pic ? `${props.brandEvent.name}` : "Silhouette of mountains"}
            />
            <div className="bg-black p-4">
                <div className="text-xl text-white"
                     onClick={() => onClickCard(props.brandEvent.id)}>{props.brandEvent.name}</div>
                <div className="flex flex-wrap font-bold text-xl my-2 gap-x-2 gap-y-2">
                    <IDXChip bgColor="bg-transparent hover:bg-slate-500/50 click:bg-slate-500/50"
                             borderColor="border-2 border-primary-secondary" text={props.brandEvent.event_tag}/>
                    {props.brandEvent.sports?.map((tag, index) => (
                        <IDXChip key={index} bgColor="hover:bg-highlight-alt/95 click:bg-highlight-alt/95"
                                 borderColor="border-2 border-highlight-alt" text={tag}
                                 onClickChip={(tag) => handleTagClick(tag)}/>))}
                    {props.brandEvent.features?.map((tag, index) => (
                        <IDXChip key={index} bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                 borderColor="border-2 border-highlight" onClickChip={(tag) => handleTagClick(tag)}
                                 text={tag}/>))}
                    {props.brandEvent.event_trick_tags?.map((tag, index) => (
                        <IDXChip text={tag} key={index} bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                 borderColor="border-2 border-highlight" onClickChip={(tag) => handleTagClick(tag)}/>))}
                    {props.brandEvent.event_tricks?.map((trick, index) => (
                        <IDXChip key={index} text={trick.join(" ")}
                                 bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                 borderColor="border-2 border-highlight" onClickChip={(tag) => handleTagClick(tag)}/>))}
                    {new Date(new Date().toDateString()).toISOString() >= props.brandEvent.start_date && new Date(new Date().toDateString()).toISOString() <= props.brandEvent.end_date ?
                        <IDXChip
                            bgColor="bg-highlight-secondary/50 hover:bg-highlight-secondary/95 click:bg-highlight-secondary/95"
                            borderColor="border-2 border-highlight-secondary" text={"Live"}/> : <IDXChip
                            bgColor="bg-highlight-danger/50 hover:bg-highlight-danger/95 click:bg-highlight-danger/95"
                            borderColor="border-2 border-highlight-danger" text={"Closed"}/>}
                </div>
            </div>
        </div>
    )
};

export default EventCard;