import {IonItem, IonLabel, IonSelect, IonSelectOption} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import {SelectOptions} from "../../../models/profileSelectOptions";

interface ContainerProps {
    options: any[]
    value: string;
    label: string;
    parentCallback: (value: any) => void;
}


const compareWith = (o1: SelectOptions, o2: SelectOptions) => {
    if (!o1 || !o2) {
        return o1 === o2;
    }

    if (Array.isArray(o2)) {
        return o2.some((o) => o.name === o1.name);
    }

    return o1.name === o2.name;
};

const EditTrickSelect: React.FC<ContainerProps> = (props: ContainerProps) => {
    const [selected, setSelected] = useState<SelectOptions>();

    useEffect(() => {
        if (props.value) {
            for (let i = 0; i < props.options.length; i++) {
                for (let j = 0; j < props.value.length; j++) {
                    if (props.options[i].name === props.value) {
                        setSelected(props.options[i]);
                    }
                }
            }
        }

    }, [props.value])

    const setCurrentSelectHandler = (value: any) => {
        setSelected(value);
        console.log(value.name);
        props.parentCallback(value.name);
    }

    return (
        <IonItem>
            <IonLabel position="stacked">{props.label}</IonLabel>
            <IonSelect
                placeholder={`Select a ${props.label}`}
                interface="popover"
                compareWith={compareWith}
                onIonChange={(ev) => setCurrentSelectHandler(ev.detail.value)}
                value={selected}
            >
                {props.options.map((option) => (
                    <IonSelectOption key={option.id} value={option}>
                        {option.name}
                    </IonSelectOption>
                ))}
            </IonSelect>
        </IonItem>
    );
};

export default EditTrickSelect;