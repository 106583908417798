import React, {useState} from "react";
import {Instagram} from '@awesome-cordova-plugins/instagram';
import {IonButton, IonIcon, IonItem, IonLabel, IonSpinner} from "@ionic/react";
import {logoInstagram} from "ionicons/icons";

declare var IGStory: any; // this is the plugin object

interface ContainerProps {
    backgroundVideoURL: string;
    stickerImageURL: string;
    size: string; // sm, md, lg
    list?: boolean;
}

const InstagramShare: React.FC<ContainerProps> = (props) => {
    const [instagramInstalled, setInstagramInstalled] = useState(false);
    const [loading, setLoading] = useState(false);

    const checkInstagram = async () => {
        setLoading(true);
        const isInstalled = await Instagram.isInstalled();
        if (isInstalled) {
            setInstagramInstalled(true);
            // do story share stuff
            (IGStory).shareVideoToStory(
                {
                    backgroundVideo: props.backgroundVideoURL ? props.backgroundVideoURL : '',
                    stickerImage: props.stickerImageURL ? props.stickerImageURL : "",
                    attributionURL: "",
                    backgroundTopColor: "",
                    backgroundBottomColor: "",
                },
                (success: any) => {
                    console.log(success);
                },
                (err: any) => {
                    console.error(err);
                }
            );
        }
        setLoading(false);
    }

    return (
        // maybe need to change the z-index of this div if it doesn't go over the vids
        // make this an ion-item
        <>
            {
                props.list ?
                    <IonItem button={true} detail={false} onClick={checkInstagram}>
                        <IonIcon aria-hidden="true" icon={logoInstagram} slot="start"></IonIcon>
                        <IonLabel>Share to IG</IonLabel>
                    </IonItem>
                    :
                    props.size === "sm" ?
                        <IonButton onClick={checkInstagram} fill="clear" size="small" color="dark">
                            <IonSpinner name="crescent" hidden={!loading}/>
                            <IonIcon slot="icon-only" icon={logoInstagram} hidden={loading}/>
                        </IonButton>
                        :
                        <IonButton onClick={checkInstagram} color="favorite">
                            <IonSpinner name="crescent" hidden={!loading}/>
                            <IonIcon icon={logoInstagram} hidden={loading}/>
                            {props.size === "lg" ? <IonLabel className="pl-2">Share to Story</IonLabel> : null}
                        </IonButton>
            }
        </>
    );
};

export default InstagramShare;
