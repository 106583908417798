import axios from "axios";

const AnnouncementsService = {
    getActiveAnnouncements: async () => {
        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/announcements/active`, {})
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return [];
            });
    },
};

export default AnnouncementsService;