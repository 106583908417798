import React, {useEffect, useRef, useState} from 'react';
import {IonButton, IonContent, IonIcon, IonInput} from '@ionic/react';
import {eyeOffOutline, eyeOutline} from 'ionicons/icons';
import {isEmail} from 'validator';
import {useAppState} from "../../../AppListenerProvider";
import {useAuth} from "../../../AuthProvider";
import AuthService from "../../../services/auth.service";
import {useHistory} from "react-router-dom";
import '../LoginFlow.css'

const EmailSignupFlow: React.FC = () => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const {isActive, isNative, platform} = useAppState();
    const {user, isLoading, isAuthenticated, firebaseApp, auth} = useAuth();
    const authService = AuthService({user, isLoading, isAuthenticated, firebaseApp, auth}, {
        isActive,
        isNative,
        platform
    });

    const userInput = useRef<HTMLIonInputElement>(null);
    const passwordInput = useRef<HTMLIonInputElement>(null);
    const confirmPasswordInput = useRef<HTMLIonInputElement>(null);

    const signUpWithEmailPasswordHandler = async (email: string, password: string) => {
        try {
            const res = await authService.signUpWithEmailAndPassword(email, password);
        } catch (error) {
            console.error("Error signing in with Google: ", error);
            setError("We were unable to sign you up. Please try again or reach out to Liz or Brendan on IG @ecliptic.snow, email ecliptic.founders@gmail.com, or text us at 1-855-MAX-BISK (1-855-629-2475) and we will get this sorted ASAP!");
        }
    }

    const handleLogin = async () => {
        // remove focus from input fields
        userInput.current?.blur();
        passwordInput.current?.blur();
        confirmPasswordInput.current?.blur();

        if (!email) {
            setError('Email is required');
            return;
        }
        if (!password) {
            setError('Password is required');
            return;
        }

        if (!confirmPassword) {
            setError('Confirm Password is required');
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        if (isEmail(email)) {
            console.log('Signing up with email:', email);
            const res = await signUpWithEmailPasswordHandler(email, password);
        } else {
            setError('Email is invalid');
        }
    };

    const handleGoBack = () => {
        setEmail('');
        setPassword('');
        setError('');
        setShowPassword(false);
        setConfirmPassword('');
        setShowConfirmPassword(false);
        history.push("/signup");
    }

    useEffect(() => {
        console.log("Loading Login In Flow Page");
        setError(null);
        setEmail('');
        setPassword('');
        setShowPassword(false);
        setConfirmPassword('');
        setShowConfirmPassword(false);
    }, [history]);

    const onKeyPress = async (e: any) => {
        if (e.key === 'Enter') {
            await handleLogin();
        }
    }

    const handleSetEmailOrUsername = (email: string) => {
        if (error) setError(null);
        setEmail(email);
    }

    const handleSetPassword = (password: string) => {
        if (error) setError(null);
        setPassword(password);
    }

    const handleSetConfirmPassword = (password: string) => {
        if (error) setError(null);
        setConfirmPassword(password);
    }

    return (
        <IonContent className="ion-padding h-full flex justify-center items-center login-form">
            <div className="w-full">
                <div className="w-full">
                    <h1 className="text-2xl font-bold text-center mt-8 text-black">Join Ecliptic</h1>
                    <div className="mt-4 space-y-2 font-bold">
                        <div>
                            <IonInput
                                ref={userInput}
                                type="text"
                                color="light"
                                placeholder="Email"
                                value={email}
                                onIonInput={e => handleSetEmailOrUsername(e.detail.value!)}
                                className="border-b border-gray-300 p-2 rounded w-full"
                            />
                        </div>
                        <div>
                            <div className="relative">
                                <IonInput
                                    ref={passwordInput}
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Password"
                                    color="light"
                                    value={password}
                                    onIonInput={e => handleSetPassword(e.detail.value!)}
                                    className="border-b border-gray-300 p-2 rounded w-full"
                                />
                                <IonIcon
                                    icon={showPassword ? eyeOutline : eyeOffOutline}
                                    className="absolute right-3 top-3.5 cursor-pointer z-50"
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="relative">
                                <IonInput
                                    ref={confirmPasswordInput}
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    placeholder="Confirm Password"
                                    color="light"
                                    value={confirmPassword}
                                    onIonInput={e => handleSetConfirmPassword(e.detail.value!)}
                                    className="border-b border-gray-300 p-2 rounded w-full"
                                    onKeyPress={onKeyPress}
                                />
                                <IonIcon
                                    icon={showConfirmPassword ? eyeOutline : eyeOffOutline}
                                    className="absolute right-3 top-3.5 cursor-pointer z-50"
                                    onClick={() => setShowConfirmPassword(!confirmPassword)}
                                />
                            </div>
                        </div>
                        {error && <div className="text-red-500">{error}</div>}
                        <div className="mt-2">
                            <IonButton expand="block" disabled={email === "" || password === ""}
                                       color={(email === "" || password === "") ? "medium" : "favorite"}
                                       onClick={handleLogin} className="mt-4">
                                <span className="text-lg normal-case font-bold">Sign up</span>
                            </IonButton>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-2">
                    <IonButton expand="block" fill="clear" color="light"
                               onClick={handleGoBack}>
                        <span className="text-sm normal-case font-bold">&lt; Go back</span>
                    </IonButton>
                </div>
            </div>
        </IonContent>
    );
};

export default EmailSignupFlow;