import {IonButton, IonImg,} from "@ionic/react";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Brand} from "../../../models/brand";

interface ContainerProps {
    crew: Brand;
}

const crewLookIntoTerms: string[] = [
    "Examine",
    "Study",
    "Probe",
    "View",
    "Explore",
    "Survey",
];

const CrewList: React.FC<ContainerProps> = (props: ContainerProps) => {
    const history = useHistory();

    const [crewCallToAction, setCrewCallToAction] = useState<string>('Crew');

    const onClickCard = (username: string) => {
        history.push('/crews/' + username);
    }

    useEffect(() => {
        setCrewCallToAction(crewLookIntoTerms[Math.floor(Math.random() * crewLookIntoTerms.length)]);
    }, [props.crew]);

    return (
        <>
            <div key={props.crew.id} className="grid grid-cols-3 gap-x-0.5 mx-2 py-4 items-center"
                 onClick={() => onClickCard(props.crew.username)}>
                <IonImg
                    src={props.crew.profile.cover_pic ? props.crew.profile.cover_pic : "https://ionicframework.com/docs/img/demos/card-media.png"}
                    alt={props.crew.profile.cover_pic ? `${props.crew.name}'s cover pic` : "Silhouette of mountains"}
                    className="w-20 w-20 rounded-lg"/>
                <div className="text-md justify-items-center">
                    {props.crew.name}
                </div>
                <div className="justify-self-end">
                    <IonButton fill="solid" color="favorite" size="small"
                               onClick={() => onClickCard(props.crew.username)}>
                        {crewLookIntoTerms[Math.floor(Math.random() * crewLookIntoTerms.length)]}
                    </IonButton>
                </div>
            </div>
        </>
    )
};

export default CrewList;