import React, {useEffect, useRef, useState} from 'react';
import {IonButton, IonIcon, IonInput} from '@ionic/react';
import {eyeOffOutline, eyeOutline} from 'ionicons/icons';
import {isEmail} from 'validator';
import '../Welcome.css'
import {useAppState} from "../../../AppListenerProvider";
import {useAuth} from "../../../AuthProvider";
import AuthService from "../../../services/auth.service";

interface WelcomeSignUpEmailProps {
    handleSignUpGoBack: () => void;
    handleSignUpLogin: () => void;
}

const WelcomeSignUpEmail: React.FC<WelcomeSignUpEmailProps> = ({handleSignUpGoBack, handleSignUpLogin}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const {isActive, isNative, platform} = useAppState();
    const {user, isLoading, isAuthenticated, firebaseApp, auth} = useAuth();
    const authService = AuthService({user, isLoading, isAuthenticated, firebaseApp, auth}, {
        isActive,
        isNative,
        platform
    });

    const userInput = useRef<HTMLIonInputElement>(null);
    const passwordInput = useRef<HTMLIonInputElement>(null);
    const confirmPasswordInput = useRef<HTMLIonInputElement>(null);

    const signUpWithEmailPasswordHandler = async (email: string, password: string) => {
        try {
            const res = await authService.signUpWithEmailAndPassword(email, password);
            if (res) {
                handleSignUpLogin();
            }
        } catch (error) {
            console.error("Error signing in with Google: ", error);
            setError("We were unable to sign you up. Please try again or reach out to Liz or Brendan on IG @ecliptic.snow, email ecliptic.founders@gmail.com, or text us at 1-855-MAX-BISK (1-855-629-2475) and we will get this sorted ASAP!");
        }
    }

    const handleLogin = async () => {
        // remove focus from input fields
        userInput.current?.blur();
        passwordInput.current?.blur();
        confirmPasswordInput.current?.blur();

        if (!email) {
            setError('Email is required');
            return;
        }
        if (!password) {
            setError('Password is required');
            return;
        }

        if (!confirmPassword) {
            setError('Confirm Password is required');
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        if (isEmail(email)) {
            console.log('Signing up with email:', email);
            await signUpWithEmailPasswordHandler(email, password);
        } else {
            setError('Email is invalid');
        }
    };

    useEffect(() => {
        console.log("Loading Login In Flow Page");
        setError(null);
        setEmail('');
        setPassword('');
        setShowPassword(false);
        setConfirmPassword('');
        setShowConfirmPassword(false);
    }, []);

    const onKeyPress = async (e: any) => {
        if (e.key === 'Enter') {
            await handleLogin();
        }
    }

    const handleSetEmailOrUsername = (email: string) => {
        if (error) setError(null);
        setEmail(email);
    }

    const handleSetPassword = (password: string) => {
        if (error) setError(null);
        setPassword(password);
    }

    const handleSetConfirmPassword = (password: string) => {
        if (error) setError(null);
        setConfirmPassword(password);
    }

    return (
        <>
            <div className="w-full px-4">
                <IonInput
                    ref={userInput}
                    type="text"
                    color="light"
                    placeholder="Email"
                    value={email}
                    onIonInput={e => handleSetEmailOrUsername(e.detail.value!)}
                    className="border-b border-primary-secondary p-2 rounded w-full bg-transparent"
                />
            </div>
            <div className="w-full px-4">
                <div className="relative">
                    <IonInput
                        ref={passwordInput}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        color="light"
                        value={password}
                        onIonInput={e => handleSetPassword(e.detail.value!)}
                        className="border-b border-primary-secondary p-2 rounded w-full bg-transparent"
                    />
                    <IonIcon
                        icon={showPassword ? eyeOutline : eyeOffOutline}
                        className="absolute right-3 top-3.5 cursor-pointer z-50 text-primary-secondary"
                        onClick={() => setShowPassword(!showPassword)}
                    />
                </div>
            </div>
            <div className="w-full px-4">
                <div className="relative">
                    <IonInput
                        ref={confirmPasswordInput}
                        type={showConfirmPassword ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        color="light"
                        value={confirmPassword}
                        onIonInput={e => handleSetConfirmPassword(e.detail.value!)}
                        className="border-b border-primary-secondary p-2 rounded w-full bg-transparent"
                        onKeyPress={onKeyPress}
                    />
                    <IonIcon
                        icon={showConfirmPassword ? eyeOutline : eyeOffOutline}
                        className="absolute right-3 top-3.5 cursor-pointer z-50 text-primary-secondary"
                        onClick={() => setShowConfirmPassword(!confirmPassword)}
                    />
                </div>
            </div>
            {error && <div className="text-highlight-danger font-bold text-center">{error}</div>}
            <IonButton expand="block"
                       disabled={email === "" || password === "" || confirmPassword === ""}
                       className="mt-4 neon-button w-64 h-12"
                       onClick={handleLogin}>
                <span className="text-lg normal-case font-bold">Continue to Ecliptic</span>
            </IonButton>
            <div className="text-center mt-4">
                <IonButton expand="block" fill="clear" color="light"
                           onClick={async () => {
                               handleSignUpGoBack();
                           }}>
                                <span
                                    className="text-sm normal-case font-bold text-primary-secondary">&lt; Go back</span>
                </IonButton>
            </div>
        </>
    );
};

export default WelcomeSignUpEmail;