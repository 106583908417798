import React, {useEffect} from "react";
import "./FeatureSelect.css";

interface ContainerProps {
    sport: string,
    feature: string,
    selectFeature: (trick: string, featureSearchValue: string) => void,
    setShowFeatureOptions: (show: boolean) => void,
    showOptions: boolean,
}

interface Feature {
    name: string,
    lowercase: string,
    transitions: string,
    searchValue: string,
}

// TODO: prob need to shorten these names -> we could just supply an icon
const features: Feature[] = [
    {
        name: "Back",
        lowercase: "",
        transitions: "",
        searchValue: "",
    },
    {
        name: "Jump",
        lowercase: "jump",
        transitions: "btn-1",
        searchValue: "Jump",
    },
    {
        name: "Rail/Jib",
        lowercase: "rail",
        transitions: "btn-1",
        searchValue: "Rail/Jib",
    },
    {
        name: "Halfpipe",
        lowercase: "halfpipe",
        transitions: "btn-1",
        searchValue: "Halfpipe",
    },
    {
        name: "Freeride",
        lowercase: "mountain",
        transitions: "btn-1",
        searchValue: "Freeride Mountain",
    },
    {
        name: "Tramp",
        lowercase: "mountain",
        transitions: "btn-1",
        searchValue: "Off Mountain",
    },
];

const otherFeatures: Feature[] = [];

const FeatureSelect: React.FC<ContainerProps> = ({
                                                     sport,
                                                     feature,
                                                     selectFeature,
                                                     setShowFeatureOptions,
                                                     showOptions
                                                 }) => {
    useEffect(() => {
        // TODO: call api to get trick options based on the sport and trick attribute (i.e. based on grab)
        console.log("Waiting for props to be mounted");
        console.log(sport);
    }, [sport]);

    if (showOptions) {
        return (
            // maybe need to change the z-index of this div if it doesn't go over the vids
            <div className="z-100 m-2">
                {sport === "other" ?
                    otherFeatures.map((feature: Feature, key: any) =>
                        <div
                            key={key}
                            className={`w-[55px] h-[55px] rounded-full flex justify-center items-center circle-button text-xs text-black mb-2 ${feature.transitions}`}
                            onClick={() => {
                                if (feature.name !== "Back") {
                                    selectFeature(feature.lowercase, feature.searchValue);
                                }
                                setShowFeatureOptions(false);
                            }}
                        >
                            {feature.name}
                        </div>)
                    :
                    features.map((feature: Feature, key: any) =>
                        <div
                            key={key}
                            className={`w-[55px] h-[55px] rounded-full flex justify-center items-center circle-button text-xs text-black mb-2 ${feature.transitions}`}
                            onClick={() => {
                                if (feature.name !== "Back") {
                                    selectFeature(feature.lowercase, feature.searchValue);
                                }
                                setShowFeatureOptions(false);
                            }}
                        >
                            {feature.name}
                        </div>
                    )}
            </div>
        );
    }

    return (
        // maybe need to change the z-index of this div if it doesn't go over the vids
        <div className="z-100 m-2">
            <div
                className="w-[55px] h-[55px] rounded-full flex justify-center items-center circle-button text-xs text-black">
                <div onClick={() => setShowFeatureOptions(true)}>
                    {feature ? feature.charAt(0).toUpperCase() + feature.slice(1) : "Feature"}
                </div>
            </div>
        </div>
    );
};

export default FeatureSelect;
