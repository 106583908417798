import WelcomeHeader from "../WelcomeHeader/WelcomeHeader";
import NavigationButtons from "../NavigationButtons/NavigationButtons";
import SparkGrid from "../SparkGrid/SparkGrid";
import {IonContent, IonPage} from "@ionic/react";

interface OnboardingPageWrapperProps {
    children: React.ReactNode;
    title: string;
    showBackButton: boolean;
    showSkipButton: boolean;
    onBack?: () => void;
    onSkip?: () => void;
    onSkipDelay?: number;
}

const OnboardingPageWrapper: React.FC<OnboardingPageWrapperProps> = ({
                                                                         children,
                                                                         title,
                                                                         onBack,
                                                                         onSkip,
                                                                         onSkipDelay = 0,
                                                                         showBackButton = false,
                                                                         showSkipButton = false
                                                                     }) => {
    return (
        <IonPage>
            <IonContent className="bg-grid-pattern">
                <div className="relative h-full w-full">
                    <SparkGrid/>
                    <NavigationButtons
                        onBack={showBackButton ? onBack : undefined}
                        onSkip={showSkipButton ? onSkip : undefined}
                        onSkipDelay={onSkipDelay}
                    />
                    <div className="relative z-10 h-full w-full flex flex-col justify-start items-center">
                        <WelcomeHeader text={title} animate={false}/>
                        <div className="font-bold px-4 w-full max-w-md">
                            {children}
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default OnboardingPageWrapper;