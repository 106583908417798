import {IonPage} from "@ionic/react";
import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import ToolbarSearchBar from "../components/ComponentsUI/ToolbarSearchBar/ToolbarSearchBar";
import {useAuth} from "../AuthProvider";
import LoadingPage from "./LoadingPage";
import EmailUsernameLoginFlow from "../components/ComponentsLogin/EmailUsernameLoginFlow/EmailUsernameLoginFlow";
import EmailSignupFlow from "../components/ComponentsLogin/EmailSignupFlow/EmailSignupFlow";

interface ContainerProps {
    signUp: boolean;
    login: boolean;
}

const LoginEmailUsernameWebPage: React.FC<ContainerProps> = (props: ContainerProps) => {
    const history = useHistory();
    const {isLoading, isAuthenticated, user} = useAuth();
    const [showSignUp, setShowSignUp] = React.useState<boolean>(false);
    const [showLogin, setShowLogin] = React.useState<boolean>(false);

    useEffect(() => {
        if (!isLoading) {
            if (isAuthenticated) {
                history.push("/profile/");
                history.go(0);
            } else {
                console.log("Loading Login In App Page");
                if (props.signUp) {
                    setShowSignUp(true);
                } else if (props.login) {
                    setShowLogin(true);
                } else {
                    setShowSignUp(false);
                    setShowLogin(true);
                }
            }

        }

    }, [history, props.signUp, props.login, isLoading, isAuthenticated]);

    if (isLoading) {
        return (<LoadingPage/>)
    }

    return (
        <IonPage>
            <ToolbarSearchBar></ToolbarSearchBar>
            {
                showSignUp && <EmailSignupFlow/>
            }
            {
                showLogin && <EmailUsernameLoginFlow/>
            }
        </IonPage>
    );
};

export default LoginEmailUsernameWebPage;