import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonModal,
    IonPage,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonTextarea,
    IonTitle,
    IonToolbar,
    useIonAlert,
    useIonToast
} from "@ionic/react";
import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {alertCircleOutline, chevronBackOutline, helpCircleOutline} from "ionicons/icons";
import biskLogo from "../assets/icons/bisk/BISK_ICON_NO_DEGREE.svg";
import biskSvg from "../assets/icons/bisk/BISK_ICON_NO_DEGREE.svg";
import idXPremiumBadge from "../assets/icons/system/idXPremiumBadge.svg";
import idXBasicBadge from "../assets/icons/system/idXBasicBadge.svg";
import {Browser} from "@capacitor/browser";
import {AppContext} from "../AppStateProvider";
import {SubscriptionType} from "../models/subscriptionType";
import {Capacitor} from "@capacitor/core";
import PaymentsService from "../services/payments.service";
import {PRODUCT_CATEGORY, Purchases, PURCHASES_ERROR_CODE} from "@revenuecat/purchases-capacitor";
import {Product} from "../models/product";
import CustomHelmet from "../components/ComponentsFunctional/CustomHelmet/CustomHelmet";
import BiskService from "../services/bisk.service";
import {StoreUserPlatformProduct} from "../models/storeUserPlatformProduct";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation'; // for navigation buttons
import 'swiper/css/pagination';
import {UserRole} from "../models/userRole";
import BrandsService from "../services/brand.service";
import {Brand} from "../models/brand";
import UserService from "../services/user.service";
import {User} from "../models/baseUserInfo";
import {UserBisk} from "../models/userBisk";
import {BiskTransaction} from "../models/biskTransaction";
import useAuthService from "../hooks/useAuthService";
import {useAuth} from "../AuthProvider";
import PreferencesService from "../services/preferences.service";

const Shop: React.FC = () => {
    const {state, dispatch} = React.useContext(AppContext);
    const history = useHistory();
    const {login} = useAuthService();

    const [presentAlert] = useIonAlert();
    const [presentToast] = useIonToast();


    const [platformStoreProducts, setPlatformStoreProducts] = useState<StoreUserPlatformProduct[]>([]);
    const [viewablePlatformStoreProducts, setViewablePlatformStoreProducts] = useState<StoreUserPlatformProduct[]>([]);
    const [productTypes, setProductTypes] = useState<string[]>(['All']);
    const [crewTypes, setCrewTypes] = useState<any[]>([{value: '', display: 'All', photo: '', email: ''}]);
    const [searchProductText, setSearchProductText] = useState<string>('');
    const [selectedCrewText, setSelectedCrewText] = useState<string>('');
    const [selectedTypeText, setSelectedTypeText] = useState<string>('');

    const [selectedStoreProduct, setSelectedStoreProduct] = useState<StoreUserPlatformProduct>(undefined);
    const [selectedSize, setSelectedSize] = useState<string>('');
    const [selectedShippingAddress, setSelectedShippingAddress] = useState<string>('');
    const [selectedShippingOption, setSelectedShippingOption] = useState<string>('');
    const [selectedInfo, setSelectedInfo] = useState<string>('');
    const [isSelectedProductInfoValid, setIsSelectedProductInfoValid] = useState<boolean>(false);
    // TODO: Have a shipping type and add that on at checkout and retrieve shipping ids with the product

    const [biskForUser, setBiskForUser] = useState<number>(0);

    const {isAuthenticated, user, isLoading} = useAuth();

    const [products, setProducts] = React.useState<Product[]>([]);

    const isNative = Capacitor.isNativePlatform();
    const platform = Capacitor.getPlatform();

    const [isTowRopeDisabled, setIsTowRopeDisabled] = React.useState<boolean>(false);
    const [isBackcountryDisabled, setIsBackcountryDisabled] = React.useState<boolean>(false);


    const [userId, setUserId] = React.useState<string>("");

    const [productsLoadingMap, setProductsLoadingMap] = useState(new Map<string, boolean>());
    const [productToCurrencyCodeMap, setProductToCurrencyCodeMap] = React.useState<Map<string, string>>(new Map<string, string>());
    const [productToPriceMap, setProductToPriceMap] = React.useState<Map<string, number>>(new Map<string, number>());

    const modalMap = useRef(new Map<string, React.RefObject<HTMLIonModalElement>>());

    const [viewPhotoFullScreen, setViewPhotoFullScreen] = useState<boolean>(false);
    const [currentPhoto, setCurrentPhoto] = useState<string>(undefined);

    const options = [
        {value: 'passes', label: 'Subscribe'},
        {value: 'bisk', label: 'Bisk', icon: <IonIcon className="pb-0.5" icon={biskLogo}></IonIcon>}, // Replace YourIconComponent with your actual icon component
        {value: 'shop', label: 'Shop'},
    ];

    const [segmentValue, setSegmentValue] = React.useState<string>(undefined);

    useEffect(() => {
        const getSegmentPreferences = async () => {
            const result = await PreferencesService.getShopTab();
            if (result) {
                setSegmentValue(result);
            } else {
                setSegmentValue("passes")
            }
        }

        getSegmentPreferences();
    }, []);

    // NOT USED
    const getCurrencyCodePriceForProduct = async (productName: string, productCategory: PRODUCT_CATEGORY, iosProductIdentifier: string, androidProductIdentifer: string, webProductIdentifer: string) => {
        let productIdentifier = "";

        if (platform === "ios") {
            productIdentifier = iosProductIdentifier;
        } else if (platform === "android") {
            productIdentifier = androidProductIdentifer;
        } else {
            productIdentifier = webProductIdentifer;
        }

        console.log("PRODUCT IDENTIFER: ", productIdentifier);

        const revenueCatProducts = await Purchases.getProducts({
            productIdentifiers: [productIdentifier],
            type: productCategory
        });
        return [revenueCatProducts.products[0].currencyCode, revenueCatProducts.products[0].price];
    }

    const getStoreProducts = async (platform: string) => {
        const storeProducts: StoreUserPlatformProduct[] = await BiskService.getStoreProducts(true, platform, undefined, undefined);
        console.log(storeProducts)
        for (const storeProduct of storeProducts) {
            modalMap.current.set(storeProduct.product_id, React.createRef<HTMLIonModalElement>());
        }
        setPlatformStoreProducts(storeProducts);
        setViewablePlatformStoreProducts(storeProducts);
        const uniqueProductTypes = new Set(storeProducts.map(storeProduct => storeProduct.product_type));
        const productTypesArray = Array.from(uniqueProductTypes);
        setProductTypes([...productTypes, ...productTypesArray])

        const uniqueUserIds = new Set(storeProducts.map(storeProduct => storeProduct.id));
        const uniqueUserIdsArray = Array.from(uniqueUserIds);

        const crewArray = []

        for (const uniqueUserId of uniqueUserIdsArray) {
            const role = storeProducts.find(x => x.id === uniqueUserId).role;
            if (role == UserRole.USER) {
                const user: User = await UserService.getUserPublicById(uniqueUserId);
                crewArray.push({
                    value: uniqueUserId,
                    display: user.username,
                    photo: user.profile.profile_pic,
                    email: user.email
                })
            }

            if (role == UserRole.GROUP) {
                const brand: Brand = await BrandsService.getBrandById(uniqueUserId);
                crewArray.push({
                    value: uniqueUserId,
                    display: brand.name,
                    photo: brand.profile.profile_pic,
                    email: brand.email
                })
            }
        }

        setCrewTypes([...crewTypes, ...crewArray]);
    }

    const getUserBisk = async (userId: string) => {
        const bisk: UserBisk = await BiskService.getBiskForUser(userId);
        if (bisk) {
            setBiskForUser(bisk.bisk);
        } else {
            setBiskForUser(state.bisk);
        }
    }

    useEffect(() => {
        if (state.subscriptionLevel === SubscriptionType.Premium) {
            setIsTowRopeDisabled(true);
        }

        if (state.subscriptionLevel === SubscriptionType.Basic) {
            setIsBackcountryDisabled(true);
        }
    }, [state.isSubscribed, state.subscriptionLevel]);

    // Validation check for form submission
    useEffect(() => {
        if (selectedStoreProduct) {
            const sizeValid = selectedStoreProduct.size ? selectedSize !== '' : true;
            const shippingAddressValid = selectedStoreProduct.shipping ? selectedShippingAddress.trim() !== '' : true;
            const shippingOptionValid = selectedStoreProduct.shipping_options ? selectedShippingOption !== '' : true;
            const infoValid = selectedStoreProduct.info ? selectedInfo.trim() !== '' : true;

            const isValid = sizeValid && shippingAddressValid && shippingOptionValid && infoValid;
            setIsSelectedProductInfoValid(isValid);
        }

    }, [selectedStoreProduct, selectedSize, selectedShippingAddress, selectedShippingOption, selectedInfo]);

    useEffect(() => {
        setUserId(state.user.id);
        console.log("User id: " + state.user.id);

        if (state.bisk) {
            setBiskForUser(state.bisk)
            getUserBisk(state.user.id)
        } else {
            setBiskForUser(state.bisk);
        }
    }, [state.user.id, state.bisk]);

    useEffect(() => {
        getStoreProducts(platform);
    }, [platform]);

    useEffect(() => {
        const applyFilters = () => {
            let result = platformStoreProducts;

            if (searchProductText) {
                result = result.filter(storeProduct => storeProduct.name.toLowerCase().includes(searchProductText.toLowerCase()));
            }

            if (selectedCrewText) {
                result = result.filter(storeProduct => storeProduct.id === selectedCrewText);
            }

            if (selectedTypeText) {
                result = result.filter(storeProduct => storeProduct.product_type === selectedTypeText);
            }

            setViewablePlatformStoreProducts(result);
        };

        applyFilters();
    }, [searchProductText, selectedCrewText, selectedTypeText, platformStoreProducts]);


    useEffect(() => {
        const getActiveProductsForShop = async () => {
            const products: Product[] = await PaymentsService.getActiveProducts();
            console.log("Products: ", products);
            if (products) {
                setProducts(products);
                const mapForUILoadingDBProducts = new Map<string, boolean>();
                const mapForCurrencyCode = new Map<string, string>();
                const mapForPrice = new Map<string, number>();

                for (const product of products) {
                    mapForUILoadingDBProducts.set(product.product_name, false);
                }

                setProductsLoadingMap(mapForUILoadingDBProducts);
                setProductToCurrencyCodeMap(mapForCurrencyCode);
                setProductToPriceMap(mapForPrice);
                console.log(mapForCurrencyCode);
                console.log(mapForPrice);
            }
            window.prerenderReady = true;
        }

        getActiveProductsForShop();
    }, []);

    const processPurchaseStoreProduct = async (storeProduct: StoreUserPlatformProduct) => {
        await modalMap.current.get(storeProduct.product_id).current?.dismiss();

        if (!isAuthenticated) {
            await presentToastLoginHandler('You must be logged in to purchase a product');
            return;
        }

        if (userId === "") {
            await presentToastDangerHandler('Give us a few seconds, were still trying to gather your info');
            return;
        }

        let typeOfObject = "Store Product";
        if (storeProduct.product_type) {
            typeOfObject += ` ${storeProduct.product_type}`
        }

        const recipientId = storeProduct.id;
        const originId = userId;

        let notes = `${storeProduct.name}:`
        if (storeProduct.size) {
            notes += ` ${selectedSize}`
        }

        if (storeProduct.shipping) {
            if (storeProduct.shipping_options) {
                if (storeProduct.shipping_options.length > 0) {
                    notes += ` ${selectedShippingOption}`
                }
            }
            notes += ` ${selectedShippingAddress}`
        }

        if (storeProduct.info) {
            notes += ` ${selectedInfo}`
        }

        if (biskForUser < storeProduct.price_bisk) {
            await presentToastDangerHandler(`You do not have enough bisk to redeem for ${storeProduct.name}`);
            return;
        }

        // Redeem Bisk for Object
        try {
            const result: BiskTransaction = await BiskService.redeemBiskForUserForObject(originId, recipientId, storeProduct.price_bisk, storeProduct.product_id, typeOfObject, notes);
            console.log("Result: ", result);
            await presentToast({
                message: `Successfully redeemed ${storeProduct.price_bisk} Bisk for ${storeProduct.name}! Check back for updates in 24-48 hours.`,
                duration: 5000, // Duration in milliseconds
                icon: biskSvg,
                position: "top",
                color: "success",
                buttons: [
                    {
                        text: 'Ok',
                        role: 'cancel'
                    }
                ]
            });
            const currentBisk: UserBisk = await BiskService.getBiskForUser(userId);
            setBiskForUser(currentBisk.bisk);
            dispatch({type: "setBisk", payload: currentBisk.bisk});
            const crewUsername = crewTypes.find(x => x.value === storeProduct.id).display
            const crewEmail = crewTypes.find(x => x.value === storeProduct.id).email
            await BiskService.sendBiskRedeemObjectAlert(state.user?.name, state.user?.username, state.user?.email, storeProduct.price_bisk, notes, storeProduct.name, storeProduct.product_id, crewUsername, crewEmail, result.transaction_id);
        } catch (e) {
            console.log("Error redeeming Bisk: ", e);
            await presentToast({
                message: `Failed to redeem Bisk for ${storeProduct.price_bisk}! Error: ${e}`,
                duration: 5000, // Duration in milliseconds
                icon: alertCircleOutline,
                position: "top",
                color: "danger",
                buttons: [
                    {
                        text: 'Ok',
                        role: 'cancel'
                    }
                ]
            });
        }

        setSelectedStoreProduct(undefined);
        setSelectedShippingAddress('');
        setSelectedInfo('');
        setSelectedShippingOption('');
        setSelectedSize('');
    }

    const handleBack = () => {
        console.log("Back button clicked");
        history.go(-1);
    }

    const presentToastLoginHandler = async (message: string) => {
        await presentToast({
            message: message,
            duration: 3000, // Duration in milliseconds
            icon: alertCircleOutline,
            position: "top",
            color: 'danger',
            buttons: [
                {
                    text: 'Login',
                    handler: async () => {
                        await login();
                    }
                },
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ]
        });
    };

    const presentToastDangerHandler = async (message: string) => {
        await presentToast({
            message: message,
            duration: 3000, // Duration in milliseconds
            icon: alertCircleOutline,
            color: 'danger',
            buttons: [
                {
                    text: 'Ok',
                    role: 'cancel'
                }
            ]
        });
    };

    const onPresentBiskHelp = async () => {
        await presentAlert({
            header: 'What is Bisk?',
            subHeader: 'A way to recognize and reward the unknown',
            message: 'Send to those sending it, buy it at the Ecliptic Smoke Shack, and earn it through Ecliptic Events. 100 Bisk = $1. Finally, you can exchange your Bisk out of app if you have over 2500 Bisk at https://ecliptic.day (our web app) for $wag. For example, 2500 Bisk = $25. Soon, you will be able to exchange Bisk for brand partners goods in app. See the terms and conditions for more details.',
            buttons: [
                {
                    text: 'View Terms',
                    handler: () => {
                        Browser.open({
                            url: 'https://idx.style/terms-and-conditions',
                            presentationStyle: "popover",
                            windowName: "_blank"
                        })
                    }
                }
            ]
        })
    }

    const onPresentTerms = async () => {
        await presentAlert({
            header: 'Terms and Conditions',
            buttons: [
                {
                    text: 'View Terms',
                    handler: () => {
                        Browser.open({
                            url: 'https://idx.style/terms-and-conditions',
                            presentationStyle: "popover",
                            windowName: "_blank"
                        })
                    }
                },
                {
                    text: 'Privacy Policy',
                    handler: () => {
                        Browser.open({
                            url: 'https://idx.style/private-policy',
                            presentationStyle: "popover",
                            windowName: "_blank"
                        })
                    }
                }
            ]
        })
    }

    const handleSelect = async (value: string) => {
        setSegmentValue(value);
        await PreferencesService.setShopTab(value);
    };

    const presentToastForErrorInMobilePurchase = async (message: string) => {
        await presentToast({
            message: message,
            duration: 3000, // Duration in milliseconds
            icon: alertCircleOutline,
            buttons: [
                {
                    text: 'Ok',
                    role: 'cancel'
                }
            ]
        });
    }

    const navigateToPurchasePage = (currentUserId: string, currentOldBisk: number) => {
        // Define your query parameters
        const queryParams = new URLSearchParams({
            infd_id: currentUserId,
            old_bisk: currentOldBisk.toString()
        });

        console.log("going to purchase page");
        // Use the `push` method on the history object to navigate with query parameters
        history.push(`/purchase?${queryParams.toString()}`);
    }

    const setAProductLoadingForPayment = (productName: string, isLoading: boolean) => {
        setProductsLoadingMap(new Map(productsLoadingMap.set(productName, isLoading)));
    };

    const handleCheckout = async (productName: string) => {
        if (!isAuthenticated) {
            await presentToastLoginHandler('You must be logged in to purchase a subscription');
            return;
        }

        if (!isNative) {
            if (userId === "") {
                await presentToastLoginHandler('Give us a few seconds, were still trying to gather your info');
                return;
            }

            const product = products.find(product => product.product_name === productName);

            const stripeUrl = await PaymentsService.getSubscriptionCheckoutSession(userId, state.user.email, product.web_identifier, state.bisk, 'subscription');
            if (stripeUrl) {
                window.location.href = stripeUrl
            } else {
                return;
            }
        } else {
            try {
                const user_id_purchasing_product = userId;
                const old_bisk = state.bisk;
                setAProductLoadingForPayment(productName, true);

                const product = products.find(product => product.product_name === productName);
                let productIdentifier = "";

                if (platform === "ios") {
                    productIdentifier = product.ios_identifier;
                } else if (platform === "android") {
                    productIdentifier = product.android_identifer;
                }

                console.log("PRODUCT IDENTIFER: ", productIdentifier);

                let type: PRODUCT_CATEGORY = PRODUCT_CATEGORY.UNKNOWN;

                if (platform === "ios") {
                    type = PRODUCT_CATEGORY.SUBSCRIPTION;
                }

                if (platform === "android") {
                    type = PRODUCT_CATEGORY.SUBSCRIPTION
                }

                const revenueCatProducts = await Purchases.getProducts({
                    productIdentifiers: [productIdentifier],
                    type: type
                });
                const purchaseResult = await Purchases.purchaseStoreProduct({
                    product: revenueCatProducts.products[0],

                });
                if (typeof purchaseResult.customerInfo.entitlements.active[product.entitlement_identifier_ext] !== "undefined") {
                    if (product.entitlement_identifier_sys == SubscriptionType.Premium) {
                        console.log("User purchased premium subscription");
                        dispatch({type: 'setSubscriptionLevel', payload: SubscriptionType.Premium});
                        dispatch({type: 'setIsSubscribed', payload: true});
                        setAProductLoadingForPayment(productName, false);
                        navigateToPurchasePage(user_id_purchasing_product, old_bisk);
                    }

                    if (product.entitlement_identifier_sys == SubscriptionType.Basic) {
                        console.log("User purchased basic subscription");
                        dispatch({type: 'setSubscriptionLevel', payload: SubscriptionType.Basic});
                        dispatch({type: 'setIsSubscribed', payload: true});
                        setAProductLoadingForPayment(productName, false);
                        navigateToPurchasePage(user_id_purchasing_product, old_bisk);
                    }
                } else {
                    console.log("No entitlements found for product")
                    console.log(purchaseResult.customerInfo.entitlements);
                    setAProductLoadingForPayment(productName, false);
                    navigateToPurchasePage(user_id_purchasing_product, old_bisk);
                }

            } catch (error: any) {
                if (error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
                    setAProductLoadingForPayment(productName, false);
                    console.log(":( user cancelled")
                } else if (error.code === PURCHASES_ERROR_CODE.PRODUCT_NOT_AVAILABLE_FOR_PURCHASE_ERROR || error.code === PURCHASES_ERROR_CODE.INVALID_APPLE_SUBSCRIPTION_KEY_ERROR) {
                    setAProductLoadingForPayment(productName, false);
                    await presentToastForErrorInMobilePurchase('The product is not available for purchase yet');
                } else if (error.code === PURCHASES_ERROR_CODE.CUSTOMER_INFO_ERROR || error.code === PURCHASES_ERROR_CODE.INVALID_APP_USER_ID_ERROR) {
                    setAProductLoadingForPayment(productName, false);
                    await presentToastForErrorInMobilePurchase('We could not get your user id, try logging back into Ecliptic');
                } else {
                    console.log(error);
                    setAProductLoadingForPayment(productName, false);
                    await presentToastForErrorInMobilePurchase('There was an error processing your purchase. Please try again.');
                }
            }
        }
    }

    const handlePurchaseCheckout = async (productName: string) => {
        if (!isAuthenticated) {
            await presentToastLoginHandler('You must be logged in to purchase a subscription, bisk, or a product');
            return;
        }

        if (!isNative) {
            if (userId === "") {
                await presentToastLoginHandler('Give us a few seconds, were still trying to gather your info');
                return;
            }

            const product = products.find(product => product.product_name === productName);

            const stripeUrl = await PaymentsService.getSubscriptionCheckoutSession(userId, state.user.email, product.web_identifier, state.bisk, 'payment');
            if (stripeUrl) {
                window.location.href = stripeUrl
            } else {
                return;
            }
        } else {
            try {
                const user_id_purchasing_product = userId;
                const old_bisk = state.bisk;

                setAProductLoadingForPayment(productName, true);
                const product = products.find(product => product.product_name === productName);
                let productIdentifier = "";

                if (platform === "ios") {
                    productIdentifier = product.ios_identifier;
                } else if (platform === "android") {
                    productIdentifier = product.android_identifer;
                }

                console.log("PRODUCT IDENTIFER: ", productIdentifier);

                let type: PRODUCT_CATEGORY = PRODUCT_CATEGORY.UNKNOWN;

                if (platform === "ios") {
                    type = PRODUCT_CATEGORY.NON_SUBSCRIPTION;
                }

                if (platform === "android") {
                    type = PRODUCT_CATEGORY.NON_SUBSCRIPTION
                }

                const revenueCatProducts = await Purchases.getProducts({
                    productIdentifiers: [productIdentifier],
                    type: type
                });

                const purchaseResult = await Purchases.purchaseStoreProduct({
                    product: revenueCatProducts.products[0],
                });
                console.log("purchaseResult: ", purchaseResult);
                setAProductLoadingForPayment(productName, false);
                navigateToPurchasePage(user_id_purchasing_product, old_bisk);
            } catch (error: any) {
                if (error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
                    console.log(":( user cancelled")
                    setAProductLoadingForPayment(productName, false);
                } else if (error.code === PURCHASES_ERROR_CODE.PRODUCT_NOT_AVAILABLE_FOR_PURCHASE_ERROR || error.code === PURCHASES_ERROR_CODE.INVALID_APPLE_SUBSCRIPTION_KEY_ERROR) {
                    setAProductLoadingForPayment(productName, false);
                    await presentToastForErrorInMobilePurchase('The product is not available for purchase yet');
                } else if (error.code === PURCHASES_ERROR_CODE.CUSTOMER_INFO_ERROR || error.code === PURCHASES_ERROR_CODE.INVALID_APP_USER_ID_ERROR) {
                    setAProductLoadingForPayment(productName, false);
                    await presentToastForErrorInMobilePurchase('We could not get your user id, try logging back into Ecliptic');
                } else {
                    console.log(error);
                    setAProductLoadingForPayment(productName, false);
                    await presentToastForErrorInMobilePurchase('There was an error processing your purchase. Please try again.');
                }
            }
        }
    }

    const handleSelectStoreProduct = async (storeProduct: StoreUserPlatformProduct) => {
        setSelectedStoreProduct(storeProduct)
        setSelectedInfo('');
        setSelectedSize('');
        setSelectedShippingOption('');
        setSelectedShippingAddress('');
        setIsSelectedProductInfoValid(false);
    }

    const resetSelectStoreProduct = (e: any) => {
        console.log(e);
        setSelectedStoreProduct(undefined);
        setSelectedInfo('');
        setSelectedSize('');
        setSelectedShippingOption('');
        setSelectedShippingAddress('');
        setIsSelectedProductInfoValid(false);
    }

    const handleClickShopPhoto = (photo: string) => {
        setCurrentPhoto(photo);
        setViewPhotoFullScreen(true);
    }

    const handleCloseShopPhoto = () => {
        setViewPhotoFullScreen(false);
        setCurrentPhoto(undefined);
    }

    return (
        <IonPage>
            <CustomHelmet title={"Ecliptic // Shop"}
                          description={"View the Ecliptic Shop, where you can buy your Ecliptic Subscription, Bisk, or exchange for ski and snowboard good that supports the people that make the community!"}
                          image={"https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                          url={`https://ecliptic.day/shop`}/>
            <IonHeader>
                <IonToolbar style={{paddingTop: `calc(0.5rem + var(--ion-safe-area-top, 0))`, paddingBottom: "0.5rem"}}
                            mode="ios">
                    <IonButtons slot="start" onClick={handleBack}>
                        <IonButton color="theme-alternative">
                            <IonIcon slot="start" icon={chevronBackOutline}></IonIcon>
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Ecliptic Smoke Shack</IonTitle>
                </IonToolbar>
                <div>
                    <div className="flex min-h-12">
                        {options.map((option, index) => (
                            <div
                                key={index}
                                className={`flex-1 py-3 px-4 text-center text-sm uppercase ${segmentValue === option.value ? 'bg-[#111111] text-white border-b-2 border-white font-bold' : 'bg-[#111111] text-[#9c9c9c]'}`}
                                onClick={() => handleSelect(option.value)}
                            >
                            <span className={option.icon ? 'flex items-center justify-center gap-x-2' : ''}>
                                {option.label}
                                {option.icon}
                            </span>
                            </div>
                        ))}
                    </div>
                </div>
            </IonHeader>
            <IonContent style={{'--background': '#FFFFFF', '--color': '#000000'}}>
                {
                    segmentValue === "passes" &&
                    <div className="relative mx-auto p-1 pt-4 md:p-4">
                        <div className="absolute inset-0 bg-contain bg-no-repeat sm:bg-center"
                             style={{backgroundImage: 'url(/assets/photos/skiantowropepass.png)', opacity: 0.5}}>

                        </div>
                        <div className="relative">
                            <div className="flex flex-col gap-y-4">
                                <div className="flex flex-row justify-center items-center gap-x-2">
                                    <div className="text-3xl text-center font-bold text-black">Discover Ecliptic's best.
                                    </div>
                                    <IonIcon icon={helpCircleOutline} size="large" onClick={onPresentTerms}></IonIcon>
                                </div>
                                <div className="text-xl text-center text-black">Unlock your full progression potential
                                    and earn the recognition you and your homies deserve.
                                </div>
                            </div>
                            <div className="relative">
                                <div className="plans grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 my-4">
                                    {/* Pro Plan */}
                                    <div className="plan border p-4 rounded-lg bg-black text-white">
                                        <div className="font-bold text-3xl text-center">Tow Rope Pass</div>
                                        <p className="font-light text-center sm:text-lg text-gray-400 my-2">Your
                                            favorite snowboarder's favorite skier's favorite park crew's favorite
                                            subscription.</p>
                                        <div className="flex justify-center items-baseline my-2">
                                            {<span className="mr-2 text-2xl font-extrabold">$9.99</span>}
                                            {<span className="text-gray-500 dark:text-gray-400">/month</span>}
                                        </div>
                                        <ul role="list" className="space-y-3 text-left">
                                            <li className="flex items-center space-x-3">
                                                <svg
                                                    className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                                    fill="currentColor" viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd"
                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                          clipRule="evenodd"></path>
                                                </svg>
                                                <span>540 Bisk (a $5.40 value)</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg
                                                    className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                                    fill="currentColor" viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd"
                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                          clipRule="evenodd"></path>
                                                </svg>
                                                <span>Unlock all Boards and Bisk Activity</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg
                                                    className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                                    fill="currentColor" viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd"
                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                          clipRule="evenodd"></path>
                                                </svg>
                                                <div className="flex flex-row gap-x-2 items-center">
                                                    <span>Get Verified</span>
                                                    <span className="w-5 h-5"><IonIcon icon={idXPremiumBadge}></IonIcon></span>
                                                </div>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg
                                                    className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                                    fill="currentColor" viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd"
                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                          clipRule="evenodd"></path>
                                                </svg>
                                                <span>Create your Own Crew and Events on Ecliptic</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg
                                                    className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                                    fill="currentColor" viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd"
                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                          clipRule="evenodd"></path>
                                                </svg>
                                                <span>Download any Ecliptic Clip to your Camera Roll</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg
                                                    className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                                    fill="currentColor" viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd"
                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                          clipRule="evenodd"></path>
                                                </svg>
                                                <span>View Personalized Suggested Tricks powered by Ecliptic's A.I. Technology Featured in X Games</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg
                                                    className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                                    fill="currentColor" viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd"
                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                          clipRule="evenodd"></path>
                                                </svg>
                                                <span>Full Map Access to For Spots and Areas!</span>
                                            </li>
                                        </ul>
                                        {
                                            productsLoadingMap.get('Tow Rope Pass') &&
                                            <div className="flex flex-row justify-center">
                                                <IonSpinner name="crescent" color="favorite"/>
                                            </div>
                                        }
                                        <div className="flex flex-row justify-center mt-2">
                                            <IonButton size="default" disabled={isTowRopeDisabled} shape="round"
                                                       color="favorite"
                                                       onClick={() => handleCheckout('Tow Rope Pass')}>{isBackcountryDisabled ? "Upgrade" : 'Join Now'}</IonButton>
                                        </div>
                                    </div>

                                    {/* Basic Plan */}
                                    <div className="plan border p-4 rounded-lg bg-black text-white">
                                        <div className="font-bold text-3xl text-center">Backcountry Pass</div>
                                        <p className="font-light text-center sm:text-lg text-gray-400 my-2">Don't forget
                                            the essentials (like speed, wax on a rail, and the backcountry pass)</p>
                                        <div className="flex justify-center items-baseline my-2">
                                            {<span className="mr-2 text-2xl font-extrabold">$4.99</span>}
                                            {<span className="text-gray-500 dark:text-gray-400">/month</span>}
                                        </div>
                                        <ul role="list" className="space-y-3 text-left">
                                            <li className="flex items-center space-x-3">
                                                <svg
                                                    className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                                    fill="currentColor" viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd"
                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                          clipRule="evenodd"></path>
                                                </svg>
                                                <span>180 Bisk (a $1.80 value) Every Month</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg
                                                    className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                                    fill="currentColor" viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd"
                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                          clipRule="evenodd"></path>
                                                </svg>
                                                <span>Full Map Access for all Precise Clip Locations instead of relative locations to Discover Spots and Areas!</span>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg
                                                    className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                                    fill="currentColor" viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd"
                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                          clipRule="evenodd"></path>
                                                </svg>
                                                <div className="flex flex-row gap-x-2 items-center">
                                                    <span>Get Verified</span>
                                                    <span className="w-5 h-5"><IonIcon
                                                        icon={idXBasicBadge}></IonIcon></span>
                                                </div>
                                            </li>
                                            <li className="flex items-center space-x-3">
                                                <svg
                                                    className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                                    fill="currentColor" viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd"
                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                          clipRule="evenodd"></path>
                                                </svg>
                                                <span>See Everyone's Followers</span>
                                            </li>
                                        </ul>
                                        {
                                            productsLoadingMap.get('Backcountry Pass') &&
                                            <div className="flex flex-row justify-center">
                                                <IonSpinner name="crescent" color="favorite"/>
                                            </div>
                                        }
                                        <div className="flex flex-row justify-center mt-2">
                                            <IonButton disabled={isBackcountryDisabled} size="default" shape="round"
                                                       color="favorite"
                                                       onClick={() => handleCheckout('Backcountry Pass')}>{isTowRopeDisabled ? "Downgrade" : 'Join Now'}</IonButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    segmentValue === "bisk" &&
                    <div className="relative mx-auto p-1 pt-4 md:p-4">
                        <div className="absolute inset-0 bg-contain bg-no-repeat sm:bg-center"
                             style={{backgroundImage: 'url(/assets/photos/theliftytowropepass.png)', opacity: 0.5}}>
                        </div>
                        <div className="relative">
                            <div className="flex flex-col gap-y-4">
                                <div className="flex flex-row justify-center items-center gap-x-2">
                                    <div className="text-3xl text-center font-bold text-black">The Biskness.</div>
                                    <IonIcon icon={helpCircleOutline} size="large"
                                             onClick={onPresentBiskHelp}></IonIcon>
                                </div>
                                <div className="text-xl text-center text-black">Buy Bisk to send via Ecliptic to the
                                    skiers,
                                    snowboarders, and crews you feel deserve recognition and support.
                                </div>
                            </div>
                            <div className="relative">
                                <div
                                    className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 md:gap-4 my-4">
                                    <div className="plan border p-4 rounded-lg bg-black text-white">
                                        <div className="font-bold text-3xl text-center">630 Bisk</div>
                                        <IonImg src='/assets/offerings/bisk-coin-product-1-transparent.png'></IonImg>
                                        {
                                            !isNative && <div className="flex justify-center items-baseline my-2">
                                                <span className="mr-2 text-2xl font-extrabold">$12.99</span>
                                            </div>
                                        }
                                        {
                                            productsLoadingMap.get('630 Bisk') &&
                                            <div className="flex flex-row justify-center">
                                                <IonSpinner name="crescent" color="favorite"/>
                                            </div>
                                        }
                                        <div className="flex flex-row justify-center">
                                            <IonButton size="default" shape="round" color="favorite"
                                                       onClick={() => handlePurchaseCheckout("630 Bisk")}>Buy
                                                Now</IonButton>
                                        </div>
                                    </div>
                                    <div className="plan border p-4 rounded-lg bg-black text-white">
                                        <div className="font-bold text-3xl text-center">360 Bisk</div>
                                        <IonImg src='/assets/offerings/bisk-coin-product-2-transparent.png'></IonImg>
                                        {
                                            !isNative && <div className="flex justify-center items-baseline my-2">
                                                <span className="mr-2 text-2xl font-extrabold">$7.99</span>
                                            </div>
                                        }
                                        {
                                            productsLoadingMap.get('360 Bisk') &&
                                            <div className="flex flex-row justify-center">
                                                <IonSpinner name="crescent" color="favorite"/>
                                            </div>
                                        }
                                        <div className="flex flex-row justify-center">
                                            <IonButton size="default" shape="round" color="favorite"
                                                       onClick={() => handlePurchaseCheckout("360 Bisk")}>Buy
                                                Now</IonButton>
                                        </div>
                                    </div>
                                    <div className="plan border p-4 rounded-lg bg-black text-white">
                                        <div className="font-bold text-3xl text-center">270 Bisk</div>
                                        <IonImg src='/assets/offerings/bisk-coin-product-3-transparent.png'></IonImg>
                                        {
                                            !isNative && <div className="flex justify-center items-baseline my-2">
                                                <span className="mr-2 text-2xl font-extrabold">$5.99</span>
                                            </div>
                                        }
                                        {
                                            productsLoadingMap.get('270 Bisk') &&
                                            <div className="flex flex-row justify-center">
                                                <IonSpinner name="crescent" color="favorite"/>
                                            </div>
                                        }
                                        <div className="flex flex-row justify-center">
                                            <IonButton size="default" shape="round" color="favorite"
                                                       onClick={() => handlePurchaseCheckout("270 Bisk")}>Buy
                                                Now</IonButton>
                                        </div>
                                    </div>
                                    <div className="plan border p-4 rounded-lg bg-black text-white">
                                        <div className="font-bold text-3xl text-center">180 Bisk</div>
                                        <IonImg src='/assets/offerings/bisk-coin-product-4-transparent.png'></IonImg>
                                        {
                                            !isNative && <div className="flex justify-center items-baseline my-2">
                                                <span className="mr-2 text-2xl font-extrabold">$3.99</span>
                                            </div>
                                        }
                                        {
                                            productsLoadingMap.get('180 Bisk') &&
                                            <div className="flex flex-row justify-center">
                                                <IonSpinner name="crescent" color="favorite"/>
                                            </div>
                                        }
                                        <div className="flex flex-row justify-center">
                                            <IonButton size="default" shape="round" color="favorite"
                                                       onClick={() => handlePurchaseCheckout("180 Bisk")}>Buy
                                                Now</IonButton>
                                        </div>
                                    </div>
                                    <div className="plan border p-4 rounded-lg bg-black text-white">
                                        <div className="font-bold text-3xl text-center">90 Bisk</div>
                                        <IonImg src='/assets/offerings/bisk-coin-product-5-transparent.png'></IonImg>
                                        {
                                            !isNative &&
                                            <div className="flex justify-center items-baseline my-2">
                                                <span className="mr-2 text-2xl font-extrabold">$1.99</span>
                                            </div>
                                        }
                                        {
                                            productsLoadingMap.get('90 Bisk') &&
                                            <div className="flex flex-row justify-center">
                                                <IonSpinner name="crescent" color="favorite"/>
                                            </div>
                                        }
                                        <div className="flex flex-row justify-center">
                                            <IonButton size="default" shape="round" color="favorite"
                                                       onClick={() => handlePurchaseCheckout("90 Bisk")}>Buy
                                                Now</IonButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    segmentValue === "shop" &&
                    <div className="relative mx-auto p-1 md:p-4">
                        <div className="relative">
                            <div className="flex flex-col gap-y-0.5 px-2">
                                <IonSearchbar color="theme" className="p-0.5"
                                              onIonInput={e => setSearchProductText(e.detail.value)}
                                              debounce={0}></IonSearchbar>
                                <div className="flex flex-row justify-between items-center gap-x-2">
                                    <div>
                                        <IonSelect
                                            aria-label="Crew"
                                            interface="popover"
                                            placeholder="Crew"
                                            style={{ionBackgroundColor: "none"}}
                                            onIonChange={e => setSelectedCrewText(e.detail.value)}
                                        >
                                            {
                                                crewTypes.map((crewType, i) => {
                                                    return (<IonSelectOption key={i}
                                                                             value={crewType.value}>{crewType.display}</IonSelectOption>)
                                                })
                                            }
                                        </IonSelect>
                                    </div>
                                    <div>
                                        <IonSelect
                                            aria-label="Type"
                                            interface="popover"
                                            placeholder="Type"
                                            style={{ionBackgroundColor: "none"}}
                                            onIonChange={e => setSelectedTypeText(e.detail.value)}>
                                            {
                                                productTypes.map((productType, i) => {
                                                    return (<IonSelectOption key={i}
                                                                             value={productType == "All" ? '' : productType}>{productType}</IonSelectOption>)
                                                })
                                            }
                                        </IonSelect>
                                    </div>
                                </div>
                            </div>
                            <div className="relative">
                                {
                                    viewPhotoFullScreen &&
                                    <>
                                        <div
                                            className="fixed inset-0 z-40 flex items-center justify-center p-4"
                                            onClick={() => handleCloseShopPhoto()}
                                        >
                                            <div className="absolute inset-0 bg-black opacity-75"></div>
                                            {/* Separate div for background with opacity */}
                                            <div className="flex flex-col items-center justify-center">
                                                <img
                                                    src={currentPhoto}
                                                    alt="Full Screen"
                                                    className="z-10 rounded-lg w-full md:w-5/6 lg:w-1/2 xl:w-1/2 object-contain"
                                                    onClick={(e) => e.stopPropagation()} // Prevent the modal from closing when the image is clicked
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                <div
                                    className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 md:gap-4">
                                    {
                                        viewablePlatformStoreProducts.map((storeProduct, i) => {
                                            return (
                                                <div
                                                    className="plan border p-4 rounded-lg bg-black text-white"
                                                    key={i}>
                                                    <div
                                                        className="font-bold text-2xl text-center mb-1">{storeProduct.name}</div>
                                                    <Swiper
                                                        modules={[Pagination]}
                                                        spaceBetween={0}
                                                        slidesPerView={1}
                                                        onSlideChange={() => console.log('slide change')}
                                                        pagination={{clickable: true}}
                                                        onSwiper={(swiper: any) => console.log(swiper)}
                                                    >
                                                        {
                                                            storeProduct.photos.map((photo, j) => {
                                                                return (
                                                                    <SwiperSlide
                                                                        onClick={() => handleClickShopPhoto(photo)}
                                                                        key={j}><img alt={'Shop'} src={photo}
                                                                                     className="rounded w-full h-full"></img></SwiperSlide>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            crewTypes.find(x => x.value === storeProduct.id) &&
                                                            <SwiperSlide
                                                                onClick={() => handleClickShopPhoto(crewTypes.find(x => x.value === storeProduct.id).photo)}><img
                                                                src={crewTypes.find(x => x.value === storeProduct.id).photo}
                                                                alt={'Shop'}
                                                                className="rounded w-full h-full"></img></SwiperSlide>
                                                        }
                                                    </Swiper>
                                                    {
                                                        <div
                                                            className="flex flex-row justify-center items-center mt-1 gap-x-1">
                                                            <IonIcon icon={biskLogo}></IonIcon>
                                                            <span
                                                                className="text-2xl font-extrabold">{storeProduct.price_bisk}</span>
                                                        </div>
                                                    }
                                                    <div className="flex flex-col justify-center mt-2">
                                                        {
                                                            isAuthenticated ?
                                                                <IonButton shape="round" size="default" color="favorite"
                                                                           id={"open-modal-" + storeProduct.product_id}
                                                                           onClick={(e) => handleSelectStoreProduct(storeProduct)}>Checkout</IonButton>
                                                                :
                                                                <IonButton shape="round" size="default" color="favorite"
                                                                           onClick={() => handlePurchaseCheckout("Store")}>Checkout</IonButton>
                                                        }
                                                    </div>
                                                    <IonModal ref={modalMap.current.get(storeProduct.product_id)}
                                                              trigger={"open-modal-" + storeProduct.product_id}
                                                              initialBreakpoint={0.75} breakpoints={[0.5, .75, 1]}
                                                              onDidDismiss={(e) => resetSelectStoreProduct(e)}>
                                                        <div className="p-4">
                                                            <div
                                                                className="flex flex-row justify-center items-center mb-4">
                                                                <div
                                                                    className="text-2xl font-bold">Checkout {storeProduct.name}</div>
                                                            </div>
                                                            <div className="flex flex-col justify-start mb-4 gap-y-2">
                                                                <div className="text-xl font-bold">Product Description
                                                                </div>
                                                                <div>{storeProduct.product_description}</div>
                                                            </div>

                                                            {/* Size Selection */}
                                                            {
                                                                storeProduct.size &&
                                                                <IonItem>
                                                                    <IonLabel>Size Options</IonLabel>
                                                                    <IonSelect value={selectedSize}
                                                                               onIonChange={e => setSelectedSize(e.detail.value)}>
                                                                        {storeProduct.size_options.map((option, index) => (
                                                                            <IonSelectOption key={index}
                                                                                             value={option}>{option}</IonSelectOption>
                                                                        ))}
                                                                    </IonSelect>
                                                                </IonItem>
                                                            }

                                                            {/* Shipping Selection */}
                                                            {
                                                                storeProduct.shipping &&
                                                                <div>
                                                                    <IonItem>
                                                                        <IonLabel position="stacked">Shipping
                                                                            Address</IonLabel>
                                                                        <IonTextarea value={selectedShippingAddress}
                                                                                     autoGrow={true}
                                                                                     placeholder={storeProduct.shipping_details}
                                                                                     onIonInput={e => setSelectedShippingAddress(e.detail.value)}></IonTextarea>
                                                                    </IonItem>

                                                                    {
                                                                        storeProduct.shipping_options &&
                                                                        <IonItem>
                                                                            <IonLabel>Shipping Options</IonLabel>
                                                                            <IonSelect value={selectedShippingOption}
                                                                                       onIonChange={e => setSelectedShippingOption(e.detail.value)}>
                                                                                {storeProduct.shipping_options.map((option, index) => (
                                                                                    <IonSelectOption key={index}
                                                                                                     value={option}>{option}</IonSelectOption>
                                                                                ))}
                                                                            </IonSelect>
                                                                        </IonItem>
                                                                    }
                                                                </div>
                                                            }


                                                            {/* Information Details Input */}
                                                            {
                                                                storeProduct.info &&
                                                                <IonItem>
                                                                    <IonLabel position="stacked">Information
                                                                        Details</IonLabel>
                                                                    <IonTextarea value={selectedInfo} autoGrow={true}
                                                                                 onIonInput={e => setSelectedInfo(e.detail.value || '')}
                                                                                 placeholder={storeProduct.info_details}></IonTextarea>
                                                                </IonItem>
                                                            }

                                                            {/* Submit Button */}
                                                            <IonButton expand="block" className="mt-4" color="favorite"
                                                                       disabled={!isSelectedProductInfoValid}
                                                                       onClick={(e) => processPurchaseStoreProduct(storeProduct)}>
                                                                <span
                                                                    className="text-2xl font-extrabold">Redeem For {storeProduct.price_bisk} Bisk</span>
                                                            </IonButton>
                                                        </div>
                                                    </IonModal>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </IonContent>
        </IonPage>
    );
};

export default Shop;