import {Capacitor} from "@capacitor/core";
import {
    IonButton,
    IonContent,
    IonIcon,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    RefresherEventDetail,
} from "@ionic/react";
import {format, parseISO} from 'date-fns';
import {arrowBack,} from "ionicons/icons";
import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {useHistory, useLocation} from "react-router-dom";
import HomePageVideoPlayer from "../components/ComponentsVideo/HomePageVideo/HomePageVideo";
import ToolbarSearchBar from "../components/ComponentsUI/ToolbarSearchBar/ToolbarSearchBar";
import VideoTagDisplay from "../components/ComponentsUI/VideoTagDisplay/VideoTagDisplay";
import TrickLike from "../components/ComponentsUI/TrickLike/TrickLike";
import TrickMapLocation from "../components/ComponentsUI/TrickMapLocation/TrickMapLocation";
import {User} from "../models/user";
import {UserTrick} from "../models/user-trick";
import {UserType} from "../models/usertype";
import TricksService from "../services/tricks.service";
import UserService from "../services/user.service";
import {handleTagClickUtil, splitString,} from "../services/utils";
import {AppContext} from "../AppStateProvider";
import TrickSaveComponent from "../components/ComponentsUI/TrickSaveComponent/TrickSaveComponent";
import TrickShare from "../components/ComponentsShare/TrickShare/TrickShare";
import TrickDownload from "../components/ComponentsUI/TrickDownload/TrickDownload";
import TrickCardExtraPopover from "../components/ComponentsUI/TrickCardExtraPopover/TrickCardExtraPopover";
import {LocationTrick} from "../models/locationTrick";
import LocationService from "../services/location.service";
import BiskHome from "../components/ComponentsBisk/BiskHome/BiskHome";
import UsernameDisplay from "../components/ComponentsUI/Username/UsernameDisplay";
import CustomHelmet from "../components/ComponentsFunctional/CustomHelmet/CustomHelmet";
import {VideoProperties} from "../models/videoProperties";
import IDXChip from "../components/ComponentsUI/IDXChip/IDXChip";
import ReportReason from "../components/ComponentsProfile/ReportReason/ReportReason";
import CommentDisplay from "../components/ComponentsComments/CommentDisplay/CommentDisplay";
import {TrickMetadataStats} from "../models/trickMetadataStats";
import {useAuth} from "../AuthProvider";
import PreferencesService from "../services/preferences.service";
import {LoadSuggestedTricksStatus} from "../models/loadSuggestedTricksStatus";

interface TrickVideoPageProps
    extends RouteComponentProps<{
        id: string;
        user_id: string;
    }> {
}

const TrickVideo: React.FC<TrickVideoPageProps> = ({match}) => {
    const isCapacitor = Capacitor.isNativePlatform();

    // Must see if a user is logged in to see if they can like a trick, post a comment, save a trick, etc.
    // use state to get user profile information
    const {state} = useContext(AppContext);
    const {isLoading} = useAuth();

    const [trick, setTrick] = React.useState<UserTrick>(undefined);
    const [profile, setProfile] = React.useState<User>(undefined);
    const [videoProperties, setVideoProperties] = React.useState<VideoProperties>(undefined);
    const [editTrick, setEditTrick] = React.useState(false);
    const [isCached, setIsCached] = useState<boolean>(false);

    const [userType, setUserType] = React.useState(UserType.UNKNOWN);

    const [formattedDate, setFormattedDate] = React.useState('');

    const [trickCommentsCount, setTrickCommentsCount] = React.useState<number>(0);
    const [isAuthed, setIsAuthed] = React.useState(undefined);

    const [trickLoading, setTrickLoading] = React.useState(false);

    const [isSubscribed, setIsSubscribed] = React.useState<boolean>(false);
    const [subscriptionLevel, setSubscriptionLevel] = React.useState<string>('');

    const [reportOpen, setReportOpen] = useState(false);

    const [trickMetadataStats, setTrickMetadataStats] = useState<undefined | TrickMetadataStats>(undefined);

    const history = useHistory();
    const location = useLocation();

    const handleIsChildOpen = (isOpen: boolean) => {
        setReportOpen(isOpen);
    }

    const handleReportClick = () => {
        handleIsChildOpen(true);
    }

    const getTrick = async (trick_id: string, user_id: string) => {
        setTrickLoading(true);
        const trick: UserTrick = await TricksService.getUserTrick(trick_id, user_id);
        const user: User = await UserService.getUserPublicById(user_id);
        const videoProperties: VideoProperties = await TricksService.getTrickVideoProperties(trick_id);

        setTrick(trick);
        setProfile(user);
        setVideoProperties(videoProperties);

        const trickMetadataStats: TrickMetadataStats = await TricksService.getTrickInteractionsCount(trick_id);
        setTrickMetadataStats(trickMetadataStats);
        setTrickCommentsCount(trickMetadataStats.total_comment_count);

        setTrickLoading(false);
        window.prerenderReady = true;

        if (trick === undefined || user === undefined || trick === null || user === null) {
            history.push('/home');
        }

        parseFilmDate(trick.date);
    }

    const deleteTrickCallback = async () => {
        await UserService.deleteTrick(match.params.id, match.params.user_id);
        await PreferencesService.setLoadSuggestedTricks(LoadSuggestedTricksStatus.ONE)
        history.push('/home');
    }

    const editTrickCallback = async (trick: UserTrick) => {
        setTrick(trick);
        history.go(0);
    }

    const onRefreshTrick = async (event: CustomEvent<RefresherEventDetail>) => {
        await getTrick(match.params.id, match.params.user_id);
        // TODO: TRIGGER REFRESH IN COMMENTS await getTrickComments(match.params.id);
        event.detail.complete();
    }

    useLayoutEffect(() => {
        console.log("Loading Trick Video Page");
        getTrick(match.params.id, match.params.user_id);

    }, [history, match.params.id, match.params.user_id]);
    // want to keep this fast so just gonna ignore auth0 bs for now and just reference state

    const parseFilmDate = (date: string) => {
        const formattedFilmDate = format(parseISO(date), 'MMM d, yyyy');
        setFormattedDate(formattedFilmDate);
    }

    useEffect(() => {
        if (state.isSubscribed) {
            setIsSubscribed(true);
            setSubscriptionLevel(state.subscriptionLevel);
        } else {
            setIsSubscribed(false);
            setSubscriptionLevel(state.subscriptionLevel);
        }
    }, [state.isSubscribed, state.subscriptionLevel]);

    useLayoutEffect(() => {
        setEditTrick(false);

        const getLoggedInUserProfile = async () => {
            const auth_id = await PreferencesService.getUserUid();
            if (!auth_id) {
                setIsAuthed(false);
            } else {
                setIsAuthed(true);
                const user_type = await UserService.getUserType(auth_id);
                setUserType(user_type);
            }

            if (userType === UserType.USER) {
                const response: User = await UserService.getUserByAuthId(auth_id);
                if (response) {
                    const isUserGodRes = await UserService.getIsUserGod(auth_id);
                    const isUserGod = isUserGodRes['result'];
                    if (response.id === match.params.user_id || isUserGod) {
                        setEditTrick(true);
                    }
                } else {
                    setEditTrick(false);
                }
            }
        }

        if (state.user.auth_id) {
            // doing this check for security reasons so that we don't allow others to edit others tricks
            if (!isLoading) {
                getLoggedInUserProfile();
            }
        } else {
            setEditTrick(false);
        }

    }, [state.user, isLoading, match.params.user_id, match.params.id, userType, state.blockedUsers.length, state.blockingUsers.length])

    const handleBack = () => {
        console.log("Back button clicked");
        history.go(-1);
    }

    const handleTagClick = (tag: string) => {
        const newTag = handleTagClickUtil(tag);
        history.push('/search/' + newTag);
    }

    const onClickPerson = (username: string) => {
        history.push("/profile/" + username);
    }

    // TODO: make into a component
    const addTaggedUserToComments = (comment: string) => {
        const parsedComment = splitString(comment, /(\@[A-Za-z0-9_.-]+)/);
        return (
            <>
                {parsedComment.map((phrase, index) => {
                    if (phrase.at(0) === '@') {
                        return <a className="text-indigo-300" href={`/profile/${phrase.substring(1)}`}
                                  key={index}>{phrase}</a>
                    } else {
                        return <span key={index}>{phrase}</span>
                    }
                })}
            </>
        );
    }

    // Could use this for edit trick
    const handleTrickLocationClick = async (locationInfo: string) => {
        // search for location
        const res: LocationTrick = await LocationService.getTrickLocation(match.params.id)
        // check if res is not null and res is not {}
        if (res && Object.keys(res).length !== 0 && res.name !== undefined) {
            const lng = res.google_long.toString()
            const lat = res.google_lat.toString()
            history.push(`/map?lat=${lat}&long=${lng}`)
        } else {
            const res = await LocationService.getLocationFromTextPlacesAPI(locationInfo)
            if (res) {
                const searched_location = res.result.geometry.location;
                const lng = parseFloat(searched_location.lng)
                const lat = parseFloat(searched_location.lat)
                history.push(`/map?lat=${lat}&long=${lng}`)
            } else {
                const lat = 40.74490596382429;
                const lng = -111.79303497769095;
                history.push(`/map?lat=${lat}&long=${lng}`)
            }
        }
    }

    return (
        trickLoading ?
            <IonPage>
                <CustomHelmet
                    title={trick?.trick_name && profile?.username ? "Ecliptic // " + profile?.username + " // " + trick?.trick_name : "Ecliptic // View Clip"}
                    description={profile?.username ? `Watch ${profile.username}'s ${trick?.trick_name}` : "Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed"}
                    image={trick?.thumbnail ? trick.thumbnail : "https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                    url={`https://ecliptic.day/clip/${match.params.user_id}/${match.params.id}`}/>
                <ToolbarSearchBar></ToolbarSearchBar>
                <IonContent>
                    <div className="flex flex-col gap-2 m-4 space-y-4 font-bold text-2xl">
                    </div>
                </IonContent>
            </IonPage> :
            <IonPage className="overflow-x-auto">
                <CustomHelmet
                    title={trick?.trick_name && profile?.username ? "Ecliptic // " + profile?.username + " // " + trick?.trick_name : "Ecliptic // View Clip"}
                    description={profile?.username ? `Watch ${profile.username}'s ${trick?.trick_name}` : "Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed"}
                    image={trick?.thumbnail ? trick.thumbnail : "https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                    url={`https://ecliptic.day/clip/${match.params.user_id}/${match.params.id}`}/>
                <ToolbarSearchBar></ToolbarSearchBar>
                <IonContent>
                    <IonRefresher slot="fixed" onIonRefresh={onRefreshTrick}>
                        <IonRefresherContent></IonRefresherContent>
                    </IonRefresher>
                    <div className="flex flex-col flex-nowrap gap-2">
                        <div className="flex flex-row justify-between">
                            <div>
                                {location.key ?
                                    <IonButton fill="default" size="small" onClick={() => handleBack()}>
                                        <IonIcon slot="icon-only" icon={arrowBack}/>
                                    </IonButton> : <div></div>
                                }
                            </div>
                            <div>
                                <ReportReason isOpenParent={reportOpen}
                                              text={"User ID: " + state.user?.id + " wishes to report " + trick?.user_id + " with trick id " + trick?.trick_id}
                                              usernameToReport={profile?.username} reportingUsername={state.user?.id}
                                              objectIdToReport={trick?.trick_id} reportingId={state.user?.id}
                                              reportedId={trick?.user_id}
                                              handleIsOpen={handleIsChildOpen}/>
                                <TrickCardExtraPopover isCapacitor={isCapacitor} trick={trick} isAuthed={isAuthed}
                                                       userId={state.user?.auth_id} publicId={state.user?.id}
                                                       trickId={trick?.trick_id} userType={userType}
                                                       displayTrickShare={false} displayTrickInstagramShare={true}
                                                       displayTrickDownload={false}
                                                       userOnDisplayUsername={state.user?.username} size={"small"}
                                                       displayEditTrick={true} editTrick={editTrick}
                                                       editTrickCallback={editTrickCallback}
                                                       deleteTrickCallback={deleteTrickCallback}
                                                       onReportTrick={handleReportClick}></TrickCardExtraPopover>
                            </div>
                        </div>
                        {trick && videoProperties ?
                            <HomePageVideoPlayer isCapacitor={isCapacitor} src={trick.video} thumbnail={trick.thumbnail}
                                                 id={trick.trick_id} isCached={isCached}
                                                 isHorizontal={!videoProperties.is_vertical}
                                                 isExternal={videoProperties.is_external}
                                                 aspectRatio={videoProperties.aspect_ratio}
                                                 isAirtime={videoProperties.is_airtime}
                                                 isVimeo={videoProperties.is_vimeo}
                                                 isYoutube={videoProperties.is_youtube}
                                                 isScrolling={false} scrollSpeed={0}/>
                            : <div></div>}
                        <div className="flex flex-row font-bold text-2xl mx-3">
                            {trick?.trick_name}
                        </div>
                        <div className="flex flex-row ml-4 space-x-4 items-center cursor-pointer">
                            <img onClick={() => onClickPerson(profile?.username)}
                                 className="shrink w-12 h-12 rounded-full"
                                 src={profile?.profile?.profile_pic ? profile?.profile?.profile_pic : '/assets/photos/defaultProPic.png'}
                                 alt="User Avatar"></img>
                            <div className="flex flex-col space-y-0.5">
                                <UsernameDisplay username={profile?.username ? profile.username : profile?.name}
                                                 className={"font-bold text-xl"} onUsernameClickHandler={onClickPerson}
                                                 userId={trick?.user_id} loggedInId={state?.user?.id}></UsernameDisplay>
                                {trick?.location && trick.location != "" && <div className="text-zinc-300 text-xs"
                                                                                 onClick={() => handleTrickLocationClick(trick.location)}>{trick.location}</div>}
                            </div>
                        </div>
                        {<div className="flex flex-row font-bold text-base overflow-x-auto mx-4">
                            <div className="flex flex-col">
                                {trick?.description ? <div>{addTaggedUserToComments(trick.description)}</div> :
                                    <div></div>}
                                <div>{formattedDate}</div>
                            </div>
                        </div>}
                        <div className="flex flex-row font-bold text-3xl ml-2 justify-between overflow-x-auto">
                            <div className="flex flex-row font-bold text-3xl items-center">
                                <TrickLike trickId={trick?.trick_id} isAuthed={isAuthed} publicId={state?.user?.id}
                                           userType={userType} count={trickMetadataStats?.votes}/>
                                <TrickMapLocation trick_id={trick?.trick_id}/>
                                <TrickDownload isCapacitor={isCapacitor} trickId={trick?.trick_id} trick={trick}
                                               isSubscribed={isSubscribed} subscriptionLevel={subscriptionLevel}/>
                                <BiskHome senderId={state?.user?.id} isAuthed={isAuthed} recipientId={trick?.user_id}
                                          trickId={trick?.trick_id} trickName={trick?.trick_name}
                                          recipientUsername={profile?.username}/>
                            </div>
                            <div className="flex flex-row font-bold text-3xl items-center">
                                <TrickShare isCapacitor={isCapacitor} trickId={trick?.trick_id} trick={trick}
                                            list={false}></TrickShare>
                                <TrickSaveComponent trickId={trick?.trick_id} isAuthed={isAuthed}
                                                    publicId={state?.user?.id} userType={userType}/>
                            </div>
                        </div>
                        <VideoTagDisplay trick={trick} tags={trick?.tags} handleTagClick={handleTagClick}
                                         isAuthed={isAuthed}/>
                        <div className="flex flex-row mx-5 gap-x-2 items-center">
                            <div>Comments</div>
                            <IDXChip bgColor="bg-transparent hover:bg-slate-500/50 click:bg-slate-500/50"
                                     borderColor="border border-primary-secondary" text={trickCommentsCount}/>
                        </div>
                        <CommentDisplay trickId={trick?.trick_id} publicId={state?.user?.id} isAuthed={isAuthed}
                                        onClickPerson={onClickPerson} onSetTrickCommentCount={setTrickCommentsCount}/>
                    </div>
                </IonContent>
            </IonPage>
    );
};

export default TrickVideo