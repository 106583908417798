import React from "react";


interface ContainerProps {
    list: any[],
    addItem: (item: any) => void,
    accessProperty?: boolean,
    accessPropertyString?: string,
}

const Dropdown: React.FC<ContainerProps> = ({list, addItem, accessProperty, accessPropertyString}) => {
    return (<div id="dropdown"
                 className="absolute shadow top-100 bg-white text-black z-40 w-10/12 lg:w-8/12 lef-0 rounded h-56 overflow-y-auto">
        <div className="flex flex-col w-full">
            {list.map((item: any, key: any) => {
                return <div key={key}
                            className="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100"
                            onClick={() => addItem(item)}>
                    <div
                        className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100">
                        <div className="w-full items-center flex">
                            <div className="mx-2 leading-6">
                                {accessProperty ? item[accessPropertyString] : item}
                            </div>
                        </div>
                    </div>
                </div>
            })}
        </div>
    </div>);
};

export default Dropdown;
