import {IonButton, IonContent, IonHeader, IonPage, IonPopover, IonTitle, IonToolbar} from "@ionic/react";
import React, {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";

// @ts-ignore
import {Auth0Lock} from 'auth0-lock';

const CrewClaim: React.FC = () => {
    const [name, setName] = React.useState('');
    const [username, setUsername] = React.useState('');
    const [loggingIn, setLoggingIn] = React.useState(false);

    const history = useHistory();

    const location = useLocation();

    // @ts-ignore
    var accessToken = null;
    var profile = null;
    // @ts-ignore
    var lock = null;

    const initializeLock = () => {
        lock = new Auth0Lock(process.env.REACT_APP_AUTH_CLIENT_ID, process.env.REACT_APP_AUTH_EXTERNAL_DOMAIN_LOCK, {
            auth: {
                redirectUrl: process.env.REACT_APP_REDIRECT_URI + '/community',
                responseType: 'token',
            },
            additionalSignUpFields: [
                {
                    type: "hidden",
                    name: "id_user_type",
                    value: "brand"
                },
                {
                    type: "hidden",
                    name: "id_name",
                    value: name
                },
                {
                    type: "hidden",
                    name: "id_username",
                    value: username
                }
            ],
            allowLogin: false,
            allowSignUp: true,
            allowedConnections: ['Username-Password-Authentication', 'google-oauth2', 'apple'],
            theme: {
                logo: "https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG.png",
                primaryColor: "#5963A7",
            },
            container: "auth0-lock-container",
            showTerms: false,
            forceAutoHeight: true
        });
        lock.show();

        // @ts-ignore
        lock.on('authenticated', function (authResult) {
            // @ts-ignore
            lock.getUserInfo(authResult.accessToken, function (error, profileResult) {
                if (error) {
                    // Handle error
                    return;
                }

                accessToken = authResult.accessToken;
                console.log('Access Token: ' + accessToken);
                profile = profileResult;
                console.log('Profile: ' + JSON.stringify(profile));
                history.push('/crews')
            });
        });
    }

    useEffect(() => {
        console.log("Loading Login Claim Page!");
    }, [history, location.search, lock]);

    if (loggingIn) {
        return (
            <IonPage>
                <IonContent>
                    <div className="flex flex-col gap-2 m-4 space-y-4 text-center">
                        <div>Create your crew for Ecliptic!!</div>
                        <div>Thanks for signing up, your input and participation in Ecliptic will truly impact Ecliptic
                            in an
                            extremely positive way, help shape skiing and snowboarding the way you want, and hopefully
                            the inspire next generation, regardless of where they live or who they are
                        </div>
                        <div>You will be able to use this login for Ecliptic across all platforms (iOS, Android, and
                            Web)
                        </div>
                        <div id='auth0-lock-container' style={{flexGrow: "unset"}}></div>
                        <IonButton className="" color="favorite" onClick={() => {
                            setLoggingIn(false);
                        }}>Back</IonButton>
                        <div>Hopefully with your involvement, we can start to see more skiers, snowboarders, coaches,
                            halfpipes, terrain parks, films, broadcasts, and competitions around the world. - Brendan,
                            Cindy, and Tagg
                        </div>
                    </div>
                </IonContent>
            </IonPage>)
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Create your crew</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <div className="flex flex-nowrap text-4xl items-center justify-center mx-10">
                            Create your crew
                        </div>
                    </IonToolbar>
                </IonHeader>
                <div className="flex justify-center items-center w-full mt-8">
                    <form className="w-10/12 lg:w-8/12">
                        <h2 className="text-2xl font-bold tracking-wide">Crew Details</h2>
                        <div className="mb-6">
                            <div className="flex flex-row items-center">
                                <label className="block mb-2 text-lg font-medium">
                                    Crew Name
                                </label>
                                <IonPopover trigger="trick-info-trigger" triggerAction="click" side="right"
                                            alignment="center">
                                    <IonContent class="ion-padding">Name the trick whatever you want!</IonContent>
                                </IonPopover>
                            </div>

                            <input
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder={'ie: Infinite Degrees, Nightlights, Steve Snowboards'}
                                required
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                    // setOptions(e.target.value.split(" "));
                                }}
                            />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-lg font-medium">Crew Username</label>
                            <input
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="idx, stevesnowboards, etc.."
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        <IonButton className="" color="favorite" onClick={() => {
                            setLoggingIn(true);
                            initializeLock();
                        }}>Next</IonButton>
                    </form>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default CrewClaim;