import React, {useEffect, useRef, useState} from 'react';
import {IonButton, IonInput, IonSelect, IonSelectOption} from '@ionic/react';
import {Keyboard} from "@capacitor/keyboard";
import {LocationOnboarding} from "../../../models/locationOnboarding";
import {AsYouType, CountryCode, getExampleNumber, parsePhoneNumberFromString, PhoneNumber} from "libphonenumber-js";
import {countries} from "countries-list";
import examples from "libphonenumber-js/examples.mobile.json";

interface PhoneInputProps {
    signUp: boolean
    sizeClass?: string;
    location?: LocationOnboarding;
    onSubmit: (phoneNumberObj: PhoneNumber) => void;
    countryData?: { country_code: string; };
    isActive: boolean;
    isNative: boolean;
}

function getFlagEmoji(countryCode: string) {
    const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char => 127397 + char.charCodeAt(0));
    return String.fromCodePoint(...codePoints);
}

const PhoneInput: React.FC<PhoneInputProps> = ({
                                                   signUp,
                                                   sizeClass = 'text-2xl',
                                                   location,
                                                   countryData,
                                                   onSubmit,
                                                   isActive,
                                                   isNative
                                               }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState<CountryCode>('US');
    const [error, setError] = useState<string | null>(null);
    const [phoneNumberPlaceholder, setPhoneNumberPlaceholder] = useState<PhoneNumber | null>(null);

    const phoneInputRef = useRef<HTMLIonInputElement>(null);

    const countryList = Object.entries(countries).map(([code, info]) => ({
        code,
        name: info.name,
        phoneCode: info.phone,
        flag: getFlagEmoji(code)
    })).sort((a, b) => a.name.localeCompare(b.name));

    const selectedCountry = countryList.find(country => country.code === countryCode);
    useEffect(() => {
        const focusInput = async () => {
            setTimeout(async () => {
                await phoneInputRef.current?.setFocus();
                if (isNative) {
                    await Keyboard.show();
                }
            }, 300);
        };

        focusInput();

        return () => {
            if (isNative) {
                Keyboard.hide();
            }
        };
    }, [isNative, phoneInputRef, isActive]);

    useEffect(() => {
        if (countryData) {
            setCountryCode(countryData.country_code as CountryCode);
            setPhoneNumberPlaceholder(getExampleNumber(countryData.country_code as CountryCode, examples))
        }
    }, [countryData]);

    const handlePhoneChange = (event: CustomEvent) => {
        const value = event.detail.value || '';
        const formatter = new AsYouType(countryCode);
        const formattedNumber = formatter.input(value);
        setPhoneNumber(formattedNumber);
    };

    const handleCountryChange = (event: CustomEvent) => {
        setCountryCode(event.detail.value);
        setPhoneNumber(''); // Reset phone number when country changes
        // Focus on phone input after country selection
        setPhoneNumberPlaceholder(getExampleNumber(event.detail.value, examples))
        setTimeout(() => phoneInputRef.current?.setFocus(), 300);
    };

    const handleSubmit = async () => {
        const selectedCountry = countryList.find(country => country.code === countryCode);
        const fullNumber = `+${selectedCountry?.phoneCode}${phoneNumber}`;
        const phoneNumberObj = parsePhoneNumberFromString(fullNumber, countryCode);

        if (!phoneNumberObj || !phoneNumberObj.isValid()) {
            setError('Please enter a valid phone number');
            return;
        }

        onSubmit(phoneNumberObj);
    }


    return (
        <>
            <div className="relative w-full flex justify-center items-center">
                <IonSelect
                    value={countryCode}
                    onIonChange={handleCountryChange}
                    className="bg-transparent text-primary-alt max-w-10"
                >
                    {countryList.map(country => (
                        <IonSelectOption key={country.code} value={country.code}>
                            <div>
                                <span>{country.flag} </span>
                                <span>{country.name} (+{country.phoneCode})</span>
                            </div>
                        </IonSelectOption>
                    ))}
                </IonSelect>
                <div className={"bg-transparent px-2 text-primary-alt " + sizeClass}>
                    +{selectedCountry?.phoneCode}
                </div>
                <IonInput
                    ref={phoneInputRef}
                    value={phoneNumber}
                    placeholder={phoneNumberPlaceholder ? phoneNumberPlaceholder?.formatNational() : null}
                    type="tel"
                    color="dark"
                    onIonInput={handlePhoneChange}
                    className={"bg-transparent p-4 text-primary-alt w-5/12 " + sizeClass}
                />
            </div>
            {error && <div className="text-highlight-danger text-center text-lg">{error}</div>}
            <IonButton
                expand="block"
                className="neon-button-highlight w-full normal-case font-bold"
                onClick={handleSubmit}
                disabled={!phoneNumber.trim()}
            >
                Continue
            </IonButton>
            {
                signUp &&
                <div className="text-primary-alt text-center">
                    {location && `Enter your number to find friends at ${location.location_name}.`}
                </div>
            }
            <div className="text-primary-secondary text-center">
                By
                entering your phone number, you consent to receive informational messages at that
                number from
                Ecliptic. Message and data
                rates may apply.
            </div>
        </>
    );
};

export default PhoneInput;