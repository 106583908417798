import {
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonDatetime,
    IonDatetimeButton,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React, {useContext, useEffect, useRef, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {ClipType} from "../../../models/clipType";
import {LocationInput} from "../../../models/locationInput";
import {SelectOptions} from "../../../models/profileSelectOptions";
import {UserTrick} from "../../../models/user-trick";
import LocationService from "../../../services/location.service";
import {FOLLOWERS_ONLY, UNLISTED_CONST} from "../../../services/utils";
import {AppContext} from "../../../AppStateProvider";
import Dropdown from "../../ComponentsUI/Dropdown/Dropdown";
import EditTrickSelect from "../EditTrickSelect/EditTrickSelect";
import IonInputWithLabel from "../../ComponentsUI/IonInputWithLabel/IonInputWithLabel";
import IonLongInputWithLabel from "../../ComponentsUI/IonLongInputWithLabel/IonLongInputWithLabel";
import TagSelectOnUpload from "../../ComponentsUI/TagSelectOnUpload/TagSelectOnUpload";
import {LocationTrick} from "../../../models/locationTrick";

interface ContainerProps {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void,
    userTrick: UserTrick,
    onEditTrickSubmit: (userTrick: UserTrick, auth_id: string, location_details: LocationInput) => void
}


interface TrickAttribute {
    name: string,
    lowercase: string,
    transitions: string,
}

const sportOptions: SelectOptions[] = [
    {
        id: 1,
        name: 'Skiing',
    },
    {
        id: 2,
        name: 'Snowboarding',
    },
    {
        id: 3,
        name: 'Other',
    }
];

const features: SelectOptions[] = [
    {
        id: 1,
        name: 'Jump',
    },
    {
        id: 2,
        name: 'Rail/Jib',
    },
    {
        id: 3,
        name: 'Halfpipe',
    },
    {
        id: 3,
        name: 'Freeride',
    },
    {
        id: 4,
        name: 'Off Mountain',
    }
];

const otherFeatures: SelectOptions[] = [];

const clipOptions: SelectOptions[] = [
    {
        id: 1,
        name: ClipType.CLIP,
    },
    {
        id: 2,
        name: ClipType.LINE,
    },
    {
        id: 3,
        name: ClipType.EDIT,
    }
];

const EditTrick: React.FC<ContainerProps> = ({onDismiss, userTrick, onEditTrickSubmit}) => {
    const [editedTrick, setEditedTrick] = React.useState(userTrick);
    const {dispatch, state} = useContext(AppContext);

    const [trickName, setTrickName] = React.useState(userTrick.trick_name);
    const [location, setLocation] = React.useState(userTrick.location);
    const [sport, setSport] = React.useState(userTrick.sport);
    const [feature, setFeature] = React.useState(userTrick.terrain);
    const [dropdown, setShowDropdown] = React.useState(false);
    const [filmDate, setFilmDate] = React.useState(userTrick.date);
    const [tags, setTags] = React.useState(userTrick.tags);
    const [caption, setCaption] = React.useState(userTrick.description);

    const [unlisted, setUnlisted] = React.useState<boolean>(false);
    const [clipType, setClipType] = React.useState<string>(ClipType.CLIP);

    const [locationInput, setLocationInput] = useState<LocationInput>(null);
    const [locationDropdownOptions, setLocationDropdownOptions] = useState<LocationInput[]>([]);
    const [locationDropdown, setLocationDropdown] = useState(false);
    const [googlePlacesSessionToken, setGooglePlacesSessionToken] = useState(null);

    // Location Dropdown
    const closeDropdown = useRef(null);

    const closeOpenMenus = (e: any) => {
        if (locationDropdown && !closeDropdown.current.contains(e.target)) {
            setLocationDropdown(false);
        }

        if (locationDropdown && e.key === 'Enter') {
            setLocationDropdown(false);
        }
    }

    const setTrickLocation = async (locationVal: string, ignoreSameLocation: boolean = false) => {
        if (locationVal === location && !ignoreSameLocation) {
            setLocationDropdown(false);
            return;
        }

        let currGooglePlacesToken = googlePlacesSessionToken;

        if (locationVal === '' || locationVal === null) {
            currGooglePlacesToken = uuidv4();
            setGooglePlacesSessionToken(currGooglePlacesToken);
        }

        setLocation(locationVal);
        await queryTrickLocation(locationVal, currGooglePlacesToken);
    }

    const queryTrickLocation = async (location: string, currGooglePlacesToken: string) => {
        const res = await LocationService.getLocationFromPlacesAPI(location, currGooglePlacesToken);
        const locationDropdownOptions: LocationInput[] = res.map((location: any) => {
            const locationInput: LocationInput = {
                description: location.description,
                main_text: location.structured_formatting.main_text,
                place_id: location.place_id,
            }
            return locationInput;
        });
        setLocationDropdownOptions(locationDropdownOptions);
        if (locationDropdownOptions.length > 0) {
            setLocationDropdown(true);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", closeOpenMenus);
        document.addEventListener('keypress', closeOpenMenus);

        return () => {
            document.removeEventListener("mousedown", closeOpenMenus);
            document.removeEventListener('keypress', closeOpenMenus);
        }
    }, [locationDropdown]);

    useEffect(() => {
        const setInitialLocationDetails = async () => {
            if (userTrick?.location && userTrick?.location !== '' && userTrick?.trick_id) {
                const locationForTrick: LocationTrick = await LocationService.getTrickLocation(userTrick.trick_id)
                if (locationForTrick) {
                    setLocationInput({
                        description: locationForTrick.description,
                        main_text: locationForTrick.main_text,
                        place_id: locationForTrick.place_id,
                    });
                }
            }
        }

        console.log("rendering edit trick");
        setEditedTrick(editedTrick);
        setTrickName(userTrick?.trick_name);
        setLocation(userTrick?.location);
        setSport(userTrick?.sport);
        setFeature(userTrick?.terrain);
        setFilmDate(userTrick?.date);
        setTags(userTrick?.tags);
        setCaption(userTrick?.description);

        // Location
        setLocationDropdown(false);
        setLocationDropdownOptions([]);
        setGooglePlacesSessionToken(uuidv4());
        setInitialLocationDetails();

        // Analyze Tags
        setUnlisted(userTrick?.tags?.includes(UNLISTED_CONST));

        if (userTrick?.clip_type === null) {
            setClipType(ClipType.CLIP);
        } else {
            setClipType(userTrick?.clip_type);
        }
    }, [userTrick]);

    // pause video when open modal
    const currentDate = new Date(new Date().toDateString()).toISOString();

    // adds new item to multiselect

    const addTag = (item: any) => {
        setTags(tags.concat(item));
        // const filtered = options.filter((option: any) => option !== item);
        // setOptions(filtered);
        if (item === UNLISTED_CONST) {
            setUnlisted(true);
        }

        setShowDropdown(false);
    };

    // removes item from multiselect
    const removeTag = (item: any) => {
        const filtered = tags.filter((e: any) => e !== item);
        setTags(filtered);

        if (item === UNLISTED_CONST) {
            setUnlisted(false);
        }
        // // only add tag back to options if it's in the trick name and not user generated
        // if (trickName.split(" ").includes(item)) {
        //   setTagOptions(tagOptions.concat(item));
        // }
    }

    const editTrickHandler = (editedTrick: UserTrick, auth_id: string) => {
        editedTrick.tags = tags;
        editedTrick.terrain = feature;
        editedTrick.sport = sport;
        editedTrick.location = location;
        editedTrick.trick_name = trickName;
        editedTrick.date = filmDate;
        editedTrick.description = caption;
        editedTrick.clip_type = clipType;

        onEditTrickSubmit(editedTrick, auth_id, locationInput);
        onDismiss();
    }

    const addLocationItem = (locationInput: LocationInput) => {
        setLocationInput(locationInput);
        setLocation(locationInput.main_text);
        setLocationDropdown(false);
    }

    const onSetUnlisted = (checkedValue: boolean) => {
        if (checkedValue) {
            setTags(tags.concat(UNLISTED_CONST));
        } else {
            const filtered = tags.filter((e: any) => e !== UNLISTED_CONST);
            setTags(filtered);
        }
        setUnlisted(checkedValue);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="flex justify-between flex-row">
                    <IonButtons slot="start">
                        <IonButton strong={true} onClick={() => onDismiss(null, 'cancel')}>
                            Close
                        </IonButton>
                    </IonButtons>
                    <IonTitle className="items-center text-center">Edit</IonTitle>
                    <IonButtons slot="end">
                        <IonButton strong={true} onClick={() => editTrickHandler(editedTrick, state.user.auth_id)}>
                            Submit
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonContent className="ion-padding mx-2 space-y-4">
                    <IonInputWithLabel value={trickName} label={"Name"} placeholder={"Name"}
                                       parentCallback={setTrickName}></IonInputWithLabel>
                    <div ref={closeDropdown}>
                        <IonItem>
                            <IonLabel position="stacked">{"Location"}</IonLabel>
                            <IonInput type="text" placeholder={"Location"} value={location}
                                      onIonInput={(e) => setTrickLocation(e.detail.value as string, false)}/>
                        </IonItem>
                        {locationDropdown ?
                            <Dropdown list={locationDropdownOptions} addItem={addLocationItem} accessProperty={true}
                                      accessPropertyString={'description'}></Dropdown> : null}
                    </div>
                    <IonDatetimeButton className="justify-start" datetime="datetime"/>
                    <IonModal keepContentsMounted={true}>
                        <IonDatetime id="datetime" presentation="date" color="dark" value={filmDate} max={currentDate}
                                     onIonChange={(event) => setFilmDate(event.target.value.toString())}/>
                    </IonModal>
                    <EditTrickSelect parentCallback={setClipType} options={clipOptions} value={clipType}
                                     label="Clip Type"></EditTrickSelect>
                    <EditTrickSelect parentCallback={setSport} options={sportOptions} value={sport}
                                     label="Sport"></EditTrickSelect>
                    {((clipType !== ClipType.EDIT) && (clipType !== ClipType.LINE)) &&
                        <EditTrickSelect parentCallback={setFeature}
                                         options={sport === "Other" ? otherFeatures : features} value={feature}
                                         label="Feature"></EditTrickSelect>}
                    <TagSelectOnUpload sport={[sport]} feature={[feature]} parentAddTag={addTag}
                                       parentRemoveTag={removeTag} currentTags={tags} tagsCombinedToOne={true}/>
                    <IonLongInputWithLabel value={caption} label={"Caption"} placeholder={"Caption"}
                                           parentCallback={setCaption}></IonLongInputWithLabel>
                    <IonCheckbox className="mx-3" labelPlacement="start"
                                 onIonChange={(ev) => onSetUnlisted(ev.detail.checked)} checked={unlisted}>
                        {FOLLOWERS_ONLY}
                    </IonCheckbox>
                </IonContent>
            </IonContent>
        </IonPage>
    );
};

export default EditTrick;