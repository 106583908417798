import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {App} from '@capacitor/app';
import {Capacitor} from "@capacitor/core";
import {AppStateListenerProps} from "./models/appStateListenerProps";

// Create the context with a default inactive state
const AppStateContext = createContext<AppStateListenerProps>(undefined);

// Define the props for the provider component
interface AppProviderProps {
    children: ReactNode;
}

// Create the provider functional component
export const AppListenerProvider: React.FC<AppProviderProps> = ({children}) => {
    const [isActive, setIsActive] = useState(false);
    const isNative = Capacitor.isNativePlatform();
    const platform = Capacitor.getPlatform();

    useEffect(() => {
        // set onboarding

    }, []);


    useEffect(() => {
        const handler = App.addListener('appStateChange', (state) => {
            setIsActive(state.isActive);
        });

        return () => {
            handler.then(h => h.remove());
        };
    }, []);

    return (
        <AppStateContext.Provider value={{isActive, isNative, platform}}>
            {children}
        </AppStateContext.Provider>
    );
};

// Export a hook for easy context consumption
export const useAppState = () => useContext(AppStateContext);
