import {Browser} from "@capacitor/browser";
import {Clipboard} from "@capacitor/clipboard";
import {Capacitor} from "@capacitor/core";
import {Share} from "@capacitor/share";
import {OverlayEventDetail} from "@ionic/core/components";
import {
    IonButton,
    IonContent,
    IonIcon,
    IonImg,
    IonPage,
    IonPopover,
    IonRefresher,
    IonRefresherContent,
    IonSearchbar,
    IonSpinner,
    RefresherEventDetail,
    useIonAlert,
    useIonModal,
} from "@ionic/react";
import {
    globeOutline,
    linkOutline,
    pencilOutline,
    personRemoveOutline,
    shareOutline,
    trashOutline
} from "ionicons/icons";
import React, {useContext, useEffect, useState} from "react";
import {RouteComponentProps, useHistory} from "react-router";
import "../global.css";
import {ReactSortable} from "react-sortablejs";
import BrandProfileSocial from "../components/ComponentsCrewProfile/BrandProfileSocial/BrandProfileSocial";
import CrewRiders from "../components/ComponentsCrew/CrewRiders/CrewRiders";
import EditBrandModal from "../components/ComponentsCrewProfile/EditBrandModal/EditBrandModal";
import EditDeleteEvent from "../components/ComponentsEvent/EditDeleteEvent/EditDeleteEvent";
import LogoutButton from "../components/ComponentsLogin/LogoutButton/LogoutButton";
import PostUpsert from "../components/ComponentsCrewProfile/PostUpsert/PostUpsert";
import ToolbarSearchBar from "../components/ComponentsUI/ToolbarSearchBar/ToolbarSearchBar";
import ViewProfileFollowInfoButton
    from "../components/ComponentsUI/ViewProfileFollowInfoButton/ViewProfileFollowInfoButton";
import {Brand} from "../models/brand";
import {BrandEvent} from "../models/brandEvent";
import {PictureUploadType} from "../models/pictureUploadType";
import {Post} from "../models/post";
import {SportType} from "../models/sportType";
import {User} from "../models/user";
import {UserProfileSearch} from "../models/user-profile-search";
import {UserTrick} from "../models/user-trick";
import {UserTrickSearch} from "../models/user-trick-search";
import {UserType} from "../models/usertype";
import BrandsService from "../services/brand.service";
import TricksService from "../services/tricks.service";
import UserService from "../services/user.service";
import {analyzeUserTrickResForUnlisted, arrangeTricksByPreferences, handleTagClickUtil,} from "../services/utils";
import {AppContext} from "../AppStateProvider";
import ProfilePicture from "../components/ComponentsProfile/ProfilePicture/ProfilePicture";
import CustomHelmet from "../components/ComponentsFunctional/CustomHelmet/CustomHelmet";
import BrandEventInfoCard from "../components/ComponentsCrewProfile/BrandEventInfoCard/BrandEventInfoCard";
import IDXChip from "../components/ComponentsUI/IDXChip/IDXChip";
import useAuthService from "../hooks/useAuthService";
import {useAuth} from "../AuthProvider";

interface CrewPageProps
    extends RouteComponentProps<{
        username: string;
    }> {
}

const crewLookIntoTerms: string[] = [
    "Investigate",
    "Examine",
    "Study",
    "Probe",
    "View",
    "Explore",
    "Sift Through",
    "Survey",
    "Delve Into"
];


const Crew: React.FC<CrewPageProps> = ({match}) => {
    const history = useHistory();
    const {dispatch} = useContext(AppContext);
    const {login} = useAuthService();

    const [presentAlert] = useIonAlert();

    const {user, isLoading, isAuthenticated} = useAuth();
    const [username, setUsername] = useState<string>(undefined);
    const [brand, setBrand] = useState<Brand>(undefined);
    const [brandUrl, setBrandUrl] = useState<string>(undefined);
    const [editBrand, setEditBrand] = useState<boolean>(false);
    const [profileError, setProfileError] = useState<boolean>(false);

    const [followingUser, setFollowingUser] = useState(false);
    const [followingUserLoading, setFollowingUserLoading] = useState(false);

    const [userType, setUserType] = useState<string>(undefined);
    const [loggedInUser, setLoggedInUser] = useState<Brand | User>(undefined);

    const isNative = Capacitor.isNativePlatform();

    const [currPostEditing, setCurrentPostEditing] = useState<Post>(undefined);

    const [followersCount, setFollowersCount] = useState<number>(0);
    const [followingCount, setFollowingCount] = useState<number>(0);

    const tabs = ['Events', 'Crew Bag', 'Posts'];
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const [clipsLinesEditsLoading, setClipsLinesEditsLoading] = useState(false);

    // followers + following
    const onClickPersonUserInteractions = async (usernameCrew: string, userType: string) => {
        if (userType === UserType.USER) {
            history.push(`/profile/${usernameCrew}`);
        } else if (userType === UserType.BRAND) {
            history.push(`/crews/${usernameCrew}`);
            await getBrandProfile(usernameCrew);
        } else {
            history.push(`/profile/${usernameCrew}`);
        }
    }

    const [presentFollowing, dismissFollowing] = useIonModal(ViewProfileFollowInfoButton, {
        onDismiss: (data: string, role: string) => dismissFollowing(data, role),
        userProfileId: brand?.id,
        followers: false,
        onClickResult: onClickPersonUserInteractions,
    })

    const [presentFollowers, dismissFollowers] = useIonModal(ViewProfileFollowInfoButton, {
        onDismiss: (data: string, role: string) => dismissFollowers(data, role),
        userProfileId: brand?.id,
        followers: true,
        onClickResult: onClickPersonUserInteractions,
    })

    function openFollowersModal() {
        presentFollowers({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    const onFollowersProfileClick = async () => {
        // Open Modal;
        openFollowersModal();
    };

    function openFollowingModal() {
        presentFollowing({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    const onFollowingProfileClick = async () => {
        // Open Modal;
        openFollowingModal();
    };

    // members
    const onClickSubmitTeamRiders = async (user_ids: string[]) => {
        console.log(user_ids);
        const updatedBrand = await BrandsService.upsertRidersToBrand(user.uid, user_ids);
        if (updatedBrand) {
            await dispatch({type: 'setUser', payload: updatedBrand});
            await history.push(`/crews/${updatedBrand.username}`);
            await getBrandProfile(updatedBrand.username);
        }
    }

    const [presentTeamRiders, dismissTeamRiders] = useIonModal(CrewRiders, {
        onDismiss: (data: string, role: string) => dismissTeamRiders(data, role),
        userProfile: {...brand},
        onClickResult: onClickPersonUserInteractions,
        editBrand: editBrand,
        onClickSubmitTeamRiders: onClickSubmitTeamRiders
    })

    function openRidersModal() {
        presentTeamRiders({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    const onClickBrandMembers = async () => {
        openRidersModal();
    }

    // search
    const onClickPerson = async (user: UserProfileSearch) => {
        history.push(`/profile/${user.username}`);
    }

    // brand information
    // team bag of tricks
    const [userTricks, setUserTricks] = useState([]);
    const [bagOfTricksFilter, setBagOfTricksFilter] = useState<string>("")
    const [editSortingBrandTricks, setEditSortingBrandTricks] = useState(false);

    const handleSortingBrandTricks = async (sortableTricks: UserTrick[]) => {
        if (editBrand) {
            const trickIds: string[] = sortableTricks.map((trick) => trick.trick_id);
            const positions: number[] = Array.from(sortableTricks.entries()).map(([index]) => index);
            await UserService.upsertUserTrickPreferences(user.uid, trickIds, positions).then((response) => {
                if (response) {
                    console.log('Successfully updated user trick preferences');
                    console.log(response);
                }
            });
            setUserTricks(sortableTricks);
        }
    }

    // posts
    const [userPosts, setUserPosts] = useState([]);
    const [viewPhotoFullScreen, setViewPhotoFullScreen] = useState(false);
    const [currentPhoto, setCurrentPhoto] = useState<string>(undefined);

    const handleClickShopPhoto = (photo: string) => {
        setCurrentPhoto(photo);
        setViewPhotoFullScreen(true);
    }

    const handleCloseShopPhoto = () => {
        setViewPhotoFullScreen(false);
        setCurrentPhoto(undefined);
    }

    // events
    const [eventsFilter, setEventsFilter] = useState<string>("")
    const [userEvents, setUserEvents] = useState([]);

    const resetProfile = async () => {
        console.log("resetting userprofile");
        setBrand(undefined);
        setUsername(undefined);

        setEditSortingBrandTricks(false);

        setUserPosts([]);
        setUserEvents([]);
        setUserEvents([]);

        setProfileError(false);
        setFollowingUser(false);
        setFollowingUserLoading(false);

        setEditBrand(undefined);
        setBrandUrl(undefined);
        setBagOfTricksFilter("");
        setEventsFilter("");

        setFollowersCount(0);
        setFollowingCount(0);

        setClipsLinesEditsLoading(false);
    }

    const checkIfFollowingUser = async (loggedInUserId: string, brandId: string) => {
        const isFollowing = await UserService.checkIfUserFollowingOtherUser(loggedInUserId, brandId);
        setFollowingUser(isFollowing);
    }

    const getBrandProfile = async (currentUsername: string) => {
        await resetProfile();

        setClipsLinesEditsLoading(true);

        setUsername(currentUsername);
        console.log("Getting brand with this username: " + currentUsername);

        const response: Brand = await BrandsService.getBrandByUsername(currentUsername);
        console.log(response);

        setBrand(response);
        setBrandUrl(`${process.env.REACT_APP_IDX_URL}/crews/${response.username}`);
        let editBrandRes = false;
        if (response) {
            const followersCountRes = await UserService.getFollowCount(response.id, true);
            const followingCountRes = await UserService.getFollowCount(response.id, false);
            dispatch({type: 'setFollowers', payload: followersCountRes});
            dispatch({type: 'setFollowing', payload: followingCountRes});
            setFollowersCount(followersCountRes);
            setFollowingCount(followingCountRes);

            if (!isAuthenticated) {
                setEditBrand(false);
            } else {
                if (response.auth_id === user.uid) {
                    editBrandRes = true;
                    setEditBrand(true);
                }

                const userType = await UserService.getUserType(user.uid)
                setUserType(userType);

                if (userType === UserType.USER) {
                    const loggedInUserRes: User = await UserService.getUserByAuthId(user.uid);
                    setLoggedInUser(loggedInUserRes);
                    await checkIfFollowingUser(loggedInUserRes.id, response.id);
                }

                if (userType === UserType.BRAND) {
                    const loggedInUserRes: Brand = await BrandsService.getBrandByAuthId(user.uid);
                    setLoggedInUser(loggedInUserRes);
                    await checkIfFollowingUser(loggedInUserRes.id, response.id);
                }
            }

            const allUserTricks = []

            for (const rider of response.user_interactions.members) {
                const userTricksRes: UserTrick[] = await TricksService.getUserTricks(rider);
                allUserTricks.push(...userTricksRes);
            }

            const userTrickPreferencesRes: Map<string, number> = await UserService.getUserTrickPreferences(response.id)

            if (allUserTricks.length > 0) {
                const userTricksToDisplay: UserTrick[] = analyzeUserTrickResForUnlisted(allUserTricks, editBrandRes);
                if (userTrickPreferencesRes) {
                    const userTrickPreferences: Map<string, number> = new Map(Object.entries(userTrickPreferencesRes));
                    if (userTrickPreferences.size > 0) {
                        const sortedTricks = arrangeTricksByPreferences(userTricksToDisplay, userTrickPreferences);
                        setUserTricks(sortedTricks);
                    } else {
                        setUserTricks(userTricksToDisplay);
                    }
                } else {
                    setUserTricks(userTricksToDisplay);
                }
            } else {
                setUserTricks(allUserTricks);
            }

            const posts: Post[] = await BrandsService.getBrandPosts(response.id);
            setUserPosts(posts);

            const events: BrandEvent[] = await BrandsService.getBrandEvents(response.id);
            setUserEvents(events);
        } else {
            setProfileError(true);
        }
        window.prerenderReady = true;

        setClipsLinesEditsLoading(false);
    }

    // Edit Brand Logic
    const onEditProfileSubmit = async (name: string, username: string, homeMountain: string, sports: string[],
                                       trickInterests: string[], instagram: string, tiktok: string, youtube: string,
                                       website: string, website_label: string, discord: string, twitter: string,
                                       brand_description: string, contact: string, setup: string) => {
        const returnedUser: Brand = await BrandsService.upsertBrandProfileInformation(loggedInUser.id,
            name, username, homeMountain, sports, trickInterests, instagram, tiktok, youtube, website, website_label,
            discord, twitter, brand_description, contact, setup);
        await dispatch({type: 'setUser', payload: returnedUser});
        await history.push(`/crews/${returnedUser.username}`);
        await getBrandProfile(returnedUser.username);
    }

    const [presentEditProfileModal, dismissEditProfileModal] = useIonModal(EditBrandModal, {
        onDismiss: (data: string, role: string) => dismissEditProfileModal(data, role),
        onEditProfileSubmit: onEditProfileSubmit,
        userProfileEdit: {...brand},
        isNative: {isNative}
    });

    function openEditProfileModal() {
        presentEditProfileModal({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    // Social Modal
    const [presentSocial, dismissSocial] = useIonModal(BrandProfileSocial, {
        onDismiss: (data: string, role: string) => dismissSocial(data, role),
        userProfileSocial: {...brand}
    });

    function openSocialModal() {
        presentSocial({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    const onDismissEditEvent = async (data: string, role: string) => {
        if (role === 'success') {
            console.log("refreshing events");
            const events: BrandEvent[] = await BrandsService.getBrandEvents(brand.id);
            setUserEvents(events);
        }
    }

    // Post Modal
    const [presentEditPost, dismissPost] = useIonModal(PostUpsert, {
        onDismiss: (data: string, role: string) => onDismissEditPost(data, role),
        brand: {...brand},
        post: {...currPostEditing},
    });

    const onDismissEditPost = async (data: string, role: string) => {
        dismissPost(data, role);
        if (role === 'success') {
            console.log("refreshing events");
            const posts: Post[] = await BrandsService.getBrandPosts(brand.id);
            setUserPosts(posts);
        }
    }

    function openEditPostModal(post: Post) {
        setCurrentPostEditing(post);
        presentEditPost({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    const onSocialProfileClick = async () => {
        // Open Modal;
        openSocialModal();
    };

    useEffect(() => {
        if (!isLoading) {
            console.log("Calling get profile for some reason");
            getBrandProfile(match.params.username);
        }
    }, [isLoading, match.params.username, user?.uid, userType, isAuthenticated]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    // Share Brand Logic
    const writeProfileUrlToClipboard = async () => {
        if (isNative) {
            let shareOptions: any = {
                title: "Ecliptic // " + brand?.username,
                dialogTitle: "Ecliptic // " + brand?.username,
                url: brandUrl,
            }
            await Share.share(shareOptions)
        } else {
            await Clipboard.write({
                url: brandUrl
            })
        }
    }

    const onFollowUser = async (followingUserState: boolean) => {
        if (isAuthenticated) {
            setFollowingUserLoading(true);

            const response: User = await UserService.followUserV2(loggedInUser.id, brand.id, !followingUserState);
            if (response) {
                setFollowingUserLoading(false);
                setFollowingUser(!followingUserState);

                const followersCountRes = await UserService.getFollowCount(brand.id, true);
                const followingCountRes = await UserService.getFollowCount(brand.id, false);
                setFollowersCount(followersCountRes);
                setFollowingCount(followingCountRes);

                const followCountForLoggedInUser = await UserService.getFollowCount(loggedInUser.id, false);
                dispatch({type: 'setFollowing', payload: followCountForLoggedInUser});
            }
        } else {
            await presentAlert({
                header: 'Login Required',
                message: 'You must be logged in to follow a user',
                buttons: [
                    'Cancel',
                    {
                        text: 'Login',
                        handler: async () => {
                            await login();
                        }
                    }
                ]
            });
        }
    }

    const onResetBrand = async (event: CustomEvent<RefresherEventDetail>) => {
        await getBrandProfile(match.params.username);
        event.detail.complete();
    }

    const onTrickSelection = (trick: UserTrick): void => {
        history.push('/clip/' + trick.user_id + '/' + trick.trick_id);
    }

    const goToAddEventPost = () => {
        history.push(`/crew-upload`);
    }

    const goToLink = (url: string) => {
        if (isNative) {
            Browser.open({url: url, presentationStyle: 'popover', 'toolbarColor': '#000000'});
        } else {
            window.open(url, '_blank');
        }
    }

    const goToPostDetail = (post: Post) => {
        goToLink(post.link);
    }

    const goToBioLink = (link: string) => {
        goToLink(link);
    }

    const goToEventDetail = (event: BrandEvent) => {
        history.push(`/event/${event.id}`)
    }

    const deleteEvent = async (event: BrandEvent) => {
        const response: BrandEvent = await BrandsService.deleteBrandEvent(brand.id, event.id);
        if (response) {
            const events: BrandEvent[] = await BrandsService.getBrandEvents(brand.id);
            setUserEvents(events);
        }
    }

    const deletePost = async (post: Post) => {
        const response: Post = await BrandsService.deletePostForBrand(post.id, brand.id);
        if (response) {
            const posts: Post[] = await BrandsService.getBrandPosts(brand.id);
            setUserPosts(posts);
        }
    }

    const postDeleteWarningDetails = (post: Post) => {
        presentAlert({
            header: 'Do you want to delete this post?',
            message: 'If you delete the post, the wisdom you shared will be lost forever.',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'OK',
                    role: 'confirm',
                    handler: () => {
                        deletePost(post);
                    },
                },
            ],
        })
    }

    const handleTagClick = (tag: string) => {
        const newTag = handleTagClickUtil(tag)
        history.push('/search/' + newTag);
    }

    return (!profileError ?
            <IonPage>
                <CustomHelmet title={brand?.username ? "Ecliptic // " + brand?.username : "Ecliptic // View Crew}"}
                              description={brand?.name ? `View ${brand?.name}'s Crew` : "Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed"}
                              image={brand?.profile?.cover_pic ? brand.profile.cover_pic : brand?.profile?.profile_pic ? brand.profile.profile_pic : "https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                              url={`https://ecliptic.day/crews/${match.params.username}`}/>
                <ToolbarSearchBar></ToolbarSearchBar>
                <IonContent>
                    <IonRefresher slot="fixed" onIonRefresh={onResetBrand}>
                        <IonRefresherContent></IonRefresherContent>
                    </IonRefresher>
                    <div className="flex flex-col mx-2 mt-2 mb-2 gap-3 font-bold text-2xl">
                        <div className="flex flex-row gap-4 items-center">
                            {/* Box 1: Pic + Name + Sport + Location */}
                            <div>
                                <ProfilePicture src={brand?.profile?.profile_pic} editProfile={editBrand}
                                                pictureUploadType={PictureUploadType.PROFILE}
                                                isNative={isNative}></ProfilePicture>
                            </div>
                            <div className="flex flex-col flex-grow">
                                <div className="flex flex-row">
                                    <div className="flex-auto">{brand?.username}</div>
                                </div>
                                {/* TODO: max character length is 15 characters */}
                                <div className="flex flex-row gap-2 font-normal text-base">
                                    <div>{brand?.name}</div>
                                </div>
                                <div className="flex flex-row gap-2 font-normal text-base">
                                    <div>{brand?.profile?.home_mountain}</div>
                                </div>
                                {brand?.profile?.sports ? <div className="flex flex-row gap-2 font-normal text-base">
                                    {brand.profile.sports.includes(SportType.SNOWBOARDING) ? <div>🏂</div> : null}
                                    {brand.profile.sports.includes(SportType.SKIING) ? <div>⛷️</div> : null}
                                </div> : null}
                                {
                                    brand?.profile?.website && brand?.profile?.website !== "" &&
                                    <div className="flex flex-row items-center gap-x-1">
                                        <IonIcon icon={linkOutline} size="small"></IonIcon>
                                        <div className="flex flex-row gap-2 font-normal text-base"
                                             onClick={() => goToBioLink(brand.profile.website)}>
                                            {brand.profile?.website_name && brand.profile?.website_name !== "" ? brand.profile.website_name : brand.profile.website}
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="flex flex-col gap-0.5 items-end">
                                <div className="flex">
                                    {editBrand ?
                                        <IonButton fill="solid" color="favorite" size="small"
                                                   onClick={() => openEditProfileModal()}>
                                            <IonIcon slot="icon-only" icon={pencilOutline}/>
                                        </IonButton> :
                                        followingUserLoading ? <IonSpinner name="dots"></IonSpinner> :
                                            (!followingUser || !isAuthenticated ?
                                                <IonButton fill="solid" color="favorite" size="small"
                                                           onClick={() => onFollowUser(followingUser)}>
                                                    Follow
                                                </IonButton> : <IonButton fill="solid" color="favorite" size="small"
                                                                          onClick={() => onFollowUser(followingUser)}>
                                                    <IonIcon slot="icon-only" icon={personRemoveOutline}/>
                                                </IonButton>)
                                    }
                                </div>
                                <div className="flex">
                                    <IonButton fill="outline" color="favorite-secondary" size="small"
                                               onClick={() => onSocialProfileClick()}>
                                        <IonIcon slot="icon-only" icon={globeOutline} color="dark"/>
                                    </IonButton>
                                </div>
                                <div className="flex">
                                    <IonButton id="share-trigger-crew" fill="outline" color="favorite-secondary"
                                               size="small" onClick={() => writeProfileUrlToClipboard()}>
                                        <IonIcon slot="icon-only" color="dark" icon={shareOutline}/>
                                    </IonButton>
                                    {
                                        !isNative ?
                                            <IonPopover trigger="share-trigger-crew" side="left" alignment="start"
                                                        size="auto">
                                                <IonContent class="ion-padding">Copied Link to
                                                    Profile! {brandUrl}</IonContent>
                                            </IonPopover> : null
                                    }

                                </div>
                                {editBrand ?
                                    <IonButton fill="solid" color="favorite" size="small"
                                               onClick={() => goToAddEventPost()}>
                                        Add Event/Post
                                    </IonButton> : null}
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <IonButton fill="solid" size="small" color="favorite"
                                       onClick={() => onFollowersProfileClick()}>
                                Followers: {followersCount}
                            </IonButton>
                            <IonButton fill="solid" size="small" color="favorite"
                                       onClick={() => onFollowingProfileClick()}>
                                Following: {followingCount}
                            </IonButton>
                            <IonButton fill="solid" size="small" color="favorite" onClick={() => onClickBrandMembers()}>
                                Riders: {brand?.user_interactions?.members ? (brand?.user_interactions?.members.length === 0 ? 0 : brand?.user_interactions?.members?.length) : 0}
                            </IonButton>
                        </div>
                    </div>
                    <div className="flex justify-center space-x-4 border-b-2 border-gray-500 text-base font-bold">
                        {tabs.map((tab, index) => (
                            <div key={index} className={activeTab === tab ? 'border-b-4 border-[#5963A7]' : ''}>
                                <button
                                    key={tab}
                                    className={`py-2 px-2.5 text-xl ${activeTab === tab ? 'border-b-4 border-[#5963A7] text-white' : 'text-gray-500 hover:text-white'}`}
                                    onClick={() => setActiveTab(tab)}
                                >
                                    {tab} {!clipsLinesEditsLoading && <span
                                    className="text-sm">{tab === 'Events' ? ` ${userEvents.length}` : tab === 'Posts' ? ` ${userPosts.length}` : tab === 'Crew Bag' ? ` ${userTricks.length}` : ''}</span>}
                                </button>
                            </div>

                        ))}
                    </div>
                    <div className="p-1">
                        <div>
                            {activeTab === "Events" ?
                                <>
                                    <div className="flex flex-row justify-between items-center mb-0.5">
                                        <IonSearchbar onIonInput={(e) => setEventsFilter(e.detail.value)}
                                                      showClearButton="focus"
                                                      showCancelButton="focus"
                                                      style={{paddingTop: 0, paddingBottom: 0}}
                                                      id="searchText"
                                                      debounce={0}
                                                      animated={true}></IonSearchbar>
                                    </div>
                                    <div
                                        className="grid grid-flow-row auto-row-max xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-2 p-1">
                                        {
                                            userEvents.filter((event: BrandEvent) =>
                                                event.name.toLowerCase().includes(eventsFilter.toLowerCase())
                                            ).map((event: BrandEvent, i) => (
                                                <div key={event.id}>
                                                    <div
                                                        className="cursor-pointer p-0 m-0 border-2 border-gray-900 bg-black rounded-md font-bold"
                                                        onClick={() => goToEventDetail(event)}>
                                                        <IonImg
                                                            className="object-cover min-w-full min-h-full h-full aspect-video"
                                                            src={event?.cover_pic} alt={event?.name}/>
                                                        <div className="bg-black flex flex-col p-4">
                                                            <div className="text-xl text-white">{event.name}</div>
                                                            <div
                                                                className="flex flex-wrap text-xl my-2 gap-x-2 gap-y-2">
                                                                <IDXChip
                                                                    bgColor="bg-transparent hover:bg-slate-500/50 click:bg-slate-500/50"
                                                                    borderColor="border-2 border-primary-secondary"
                                                                    text={event.event_tag}/>
                                                                {event.sports?.map((tag, index) => (<IDXChip key={index}
                                                                                                             bgColor="hover:bg-highlight-alt/95 click:bg-highlight-alt/95"
                                                                                                             borderColor="border-2 border-highlight-alt"
                                                                                                             text={tag}
                                                                                                             onClickChip={(tag) => handleTagClick(tag)}/>))}
                                                                {event.features?.map((tag, index) => (
                                                                    <IDXChip key={index}
                                                                             bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                                                             borderColor="border-2 border-highlight"
                                                                             onClickChip={(tag) => handleTagClick(tag)}
                                                                             text={tag}/>))}
                                                                {event.event_trick_tags?.map((tag, index) => (
                                                                    <IDXChip text={tag} key={index}
                                                                             bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                                                             borderColor="border-2 border-highlight"
                                                                             onClickChip={(tag) => handleTagClick(tag)}/>))}
                                                                {event.event_tricks?.map((trick, index) => (
                                                                    <IDXChip key={index} text={trick.join(" ")}
                                                                             bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                                                             borderColor="border-2 border-highlight"
                                                                             onClickChip={(tag) => handleTagClick(tag)}/>))}
                                                                {new Date(new Date().toDateString()).toISOString() >= event.start_date && new Date(new Date().toDateString()).toISOString() <= event.end_date ?
                                                                    <IDXChip
                                                                        bgColor="bg-highlight-secondary/50 hover:bg-highlight-secondary/95 click:bg-highlight-secondary/95"
                                                                        borderColor="border-2 border-highlight-secondary"
                                                                        text={"Live"}/> : <IDXChip
                                                                        bgColor="bg-highlight-danger/50 hover:bg-highlight-danger/95 click:bg-highlight-danger/95"
                                                                        borderColor="border-2 border-highlight-danger"
                                                                        text={"Closed"}/>}
                                                            </div>
                                                            <div>
                                                                <div className="text-sm text-gray-400">
                                                                    {event.description ? event.description : ""}
                                                                </div>
                                                                <div className="flex flex-row-reverse gap-x-2">
                                                                    <IonButton fill="solid" color="favorite"
                                                                               size="small"
                                                                               onClick={() => goToEventDetail(event)}>
                                                                        {crewLookIntoTerms[Math.floor(Math.random() * crewLookIntoTerms.length)]}
                                                                    </IonButton>
                                                                    {editBrand ?
                                                                        <EditDeleteEvent
                                                                            onDismissEditEvent={onDismissEditEvent}
                                                                            deleteEvent={deleteEvent}
                                                                            brandEvent={event} brand={brand}/> : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </> : null
                            }
                        </div>
                        <div>
                            <div>
                                {activeTab === "Crew Bag" ?
                                    <>
                                        <div className="flex flex-row justify-between items-center mb-0.5">
                                            <IonSearchbar onIonInput={(e) => setBagOfTricksFilter(e.detail.value)}
                                                          showClearButton="focus"
                                                          showCancelButton="focus"
                                                          style={{paddingTop: 0, paddingBottom: 0}}
                                                          id="searchText"
                                                          debounce={0}
                                                          animated={true}></IonSearchbar>
                                        </div>
                                        <div>
                                            <ReactSortable
                                                disabled={isNative ? (!editSortingBrandTricks || bagOfTricksFilter.length > 0 || !editBrand) : (bagOfTricksFilter.length > 0 || !editBrand)}
                                                list={userTricks}
                                                setList={(newState, sortable, store) => {
                                                    if (store.dragging && store.dragging.props && JSON.stringify(store.dragging.props.list) !== JSON.stringify(newState)) {
                                                        handleSortingBrandTricks(newState)
                                                    }
                                                }}
                                                className="grid grid-flow-row auto-row-max xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-2"
                                                delay={100} delayOnTouchOnly={isNative} sort={true}>
                                                {userTricks.filter(
                                                    (trick) =>
                                                        trick.trick_name.toLowerCase().includes(bagOfTricksFilter.toLowerCase()) ||
                                                        (trick.location && trick.location.toLowerCase().includes(bagOfTricksFilter.toLowerCase()))).map((trick: UserTrickSearch, i) => (
                                                    <div key={trick.trick_id} onClick={() => onTrickSelection(trick)}
                                                         className={trick.tags.includes("Unlisted") ? "opacity-25" : ""}>
                                                        <BrandEventInfoCard trick={trick}/>
                                                    </div>
                                                ))}
                                            </ReactSortable>
                                        </div>
                                    </> : null}
                            </div>
                        </div>
                        <div>
                            {
                                activeTab === "Posts" ?
                                    <div
                                        className="grid grid-flow-row auto-row-max xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-2 p-1">
                                        {userPosts.map((post: Post, i) => (
                                            <div key={post.id}>
                                                {
                                                    viewPhotoFullScreen &&
                                                    <>
                                                        <div
                                                            className="fixed inset-0 z-40 flex items-center justify-center p-4"
                                                            onClick={() => handleCloseShopPhoto()}
                                                        >
                                                            <div className="absolute inset-0 bg-black opacity-75"></div>
                                                            {/* Separate div for background with opacity */}
                                                            <div className="flex flex-col items-center justify-center">
                                                                <img
                                                                    src={currentPhoto}
                                                                    alt="Full Screen"
                                                                    className="z-10 rounded-lg w-full md:w-5/6 lg:w-1/2 xl:w-1/2 object-contain"
                                                                    onClick={(e) => e.stopPropagation()} // Prevent the modal from closing when the image is clicked
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                <div
                                                    className="cursor-pointer p-0 m-0 border-2 border-gray-900 bg-black rounded-md font-bold">
                                                    <IonImg
                                                        className="object-cover min-w-full min-h-full h-full aspect-video"
                                                        src={post?.image} alt={post?.title}
                                                        onClick={() => handleClickShopPhoto(post?.image)}/>
                                                    <div className="bg-black p-4 flex flex-col font-bold">
                                                        <div className="text-xl text-white">{post.title}</div>
                                                        <div className="text-gray-400">
                                                            <div className="text-sm">
                                                                {post.description ? post.description : ""}
                                                            </div>
                                                            <div className="flex flex-row-reverse gap-x-2">
                                                                {post.link ?
                                                                    <IonButton fill="solid" color="favorite"
                                                                               size="small"
                                                                               onClick={() => goToPostDetail(post)}>
                                                                        {crewLookIntoTerms[Math.floor(Math.random() * crewLookIntoTerms.length)]}
                                                                    </IonButton> : null}
                                                                {editBrand ?
                                                                    <><IonButton
                                                                        onClick={() => postDeleteWarningDetails(post)}
                                                                        fill="outline" color="danger"
                                                                        size="small">
                                                                        <IonIcon slot="icon-only"
                                                                                 icon={trashOutline}></IonIcon>
                                                                    </IonButton>
                                                                        <IonButton
                                                                            onClick={() => openEditPostModal(post)}
                                                                            fill="outline"
                                                                            color="favorite-secondary" size="small">
                                                                            <IonIcon slot="icon-only"
                                                                                     icon={pencilOutline}></IonIcon>
                                                                        </IonButton></> : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div> : null
                            }
                        </div>
                    </div>
                    <div className="flex flex-row justify-center">
                        <LogoutButton
                            display={editBrand}></LogoutButton> {/* TODO: get rid of when figure out how to only login once*/}
                    </div>
                </IonContent>
            </IonPage> :
            <IonPage>
                <IonContent>
                    <div className="flex flex-col gap-2 m-4 space-y-4 font-bold text-2xl">
                        <div className="flex-col text-center">
                            <div>There was an error loading this crew.</div>
                        </div>
                        <div className="flex-col text-center">
                            <IonButton routerLink="/home" color="favorite">Go Back to Ecliptic</IonButton>
                        </div>
                    </div>
                </IonContent>
            </IonPage>
    );
};

export default Crew;
