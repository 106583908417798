import React, {useState} from 'react';
import {IonButton, IonIcon} from '@ionic/react';
import {checkmarkCircleOutline} from 'ionicons/icons';
import {useSignUp} from '../SignUpProvider/SignUpProvider'; // Adjust the import path as needed
import './SportSelection.css';
import {SportType} from "../../../models/sportType";
import OnboardingPageWrapper from "../OnboardingPageWrapper/OnboardingPageWrapper"; // We'll update this CSS file

interface Sport {
    name: string;
    emoji: string;
    disabled?: boolean;
}

interface SportSelectionProps {
    onNext: () => void;
    title: string;
    showBackButton: boolean;
    showSkipButton: boolean;
    onBack?: () => void;
}

const sports: Sport[] = [
    {name: SportType.SKIING, emoji: '⛷️'},
    {name: SportType.SNOWBOARDING, emoji: '🏂'},
    // { name: 'Mountain Biking', emoji: '🚵', disabled: true },
    // { name: 'BMX', emoji: '🚲', disabled: true },
    // { name: 'Skateboarding', emoji: '🛹', disabled: true },
];

const SportSelection: React.FC<SportSelectionProps> = ({onNext, title, showBackButton, onBack, showSkipButton}) => {
    const {signUpData, updateSignUpData} = useSignUp();
    const [selectedSports, setSelectedSports] = useState<string[]>(signUpData.sports);

    const toggleSport = async (sport: string) => {
        const updatedSports = selectedSports.includes(sport)
            ? selectedSports.filter(s => s !== sport)
            : [...selectedSports, sport];
        setSelectedSports(updatedSports);
        await updateSignUpData({sports: updatedSports});
    };

    const handleContinue = () => {
        if (selectedSports.length > 0) {
            onNext();
        }
    };

    return (
        <OnboardingPageWrapper
            title={title}
            onBack={onBack}
            showSkipButton={showSkipButton}
            showBackButton={showBackButton}>
            <div className="w-full max-w-md space-y-4 flex flex-col items-center font-bold">
                <div className="text-primary-secondary text-center">
                    What activities do you do or enjoy to watch?
                </div>
                <div className="grid grid-cols-2 gap-4 w-full">
                    {sports.map((sport) => (
                        <IonButton
                            key={sport.name}
                            className={`
              sport-button
              ${sport.disabled ? 'sport-button-disabled' : ''}
              ${selectedSports.includes(sport.name) ? 'sport-button-selected' : ''}
            `}
                            onClick={() => !sport.disabled && toggleSport(sport.name)}
                            disabled={sport.disabled}
                            style={{
                                '--border-color': selectedSports.includes(sport.name)
                                    ? 'var(--electric-blue)'
                                    : 'var(--primary-secondary)',
                                '--box-shadow': selectedSports.includes(sport.name)
                                    ? '0 0 10px var(--electric-blue), 0 0 10px var(--electric-blue), inset 0 0 8px var(--electric-blue)'
                                    : 'none'
                            }}
                        >
                            <div
                                className="flex flex-col items-center justify-center w-full h-full normal-case font-bold">
                                <span className="text-4xl mb-2">{sport.emoji}</span>
                                <span className="text-sm">{sport.name}</span>
                                {sport.disabled && (
                                    <span className="text-xs mt-1">Coming soon...</span>
                                )}
                                {!sport.disabled && (
                                    <IonIcon
                                        icon={checkmarkCircleOutline}
                                        className={`absolute top-0 right-0 m-1 text-xl checkmark-icon ${selectedSports.includes(sport.name) ? 'text-highlight-secondary' : 'text-primary-alt'}`}
                                    />
                                )}
                            </div>
                        </IonButton>
                    ))}
                </div>
                <IonButton
                    expand="block"
                    className={"neon-button-alt w-full normal-case font-bold"}
                    onClick={handleContinue}
                    disabled={selectedSports.length === 0}
                >
                    Continue
                </IonButton>
            </div>
        </OnboardingPageWrapper>

    );
};

export default SportSelection;