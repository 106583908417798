import React from "react";
import {Browser} from "@capacitor/browser";

const WelcomeTOS: React.FC = () => {
    const handlePrivacyPolicy = async () => {
        await Browser.open({
            url: 'https://idx.style/private-policy',
            presentationStyle: "popover",
            windowName: "_blank"
        })
    }

    const handleTermsOfService = async () => {
        await Browser.open({
            url: 'https://idx.style/terms-and-conditions',
            presentationStyle: "popover",
            windowName: "_blank"
        })
    }

    return (
        <div className="text-sm text-white text-center px-2">
            By continuing, you agree to Ecliptic's <a className="text-primary-secondary"
                                                      onClick={handleTermsOfService}>Terms of
            Service</a> and confirm that you have read Ecliptic's <a className="text-primary-secondary"
                                                                     onClick={handlePrivacyPolicy}>Privacy
            Policy</a>
        </div>
    );
}

export default WelcomeTOS;
