import React, {useEffect} from "react";
import {IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle,} from "@ionic/react";
import {UserTrickSearch} from "../../../models/user-trick-search";
import UsernameDisplay from "../../ComponentsUI/Username/UsernameDisplay";

interface ContainerProps {
    trick: UserTrickSearch,
    onClick?: any
    onClickFlyTo?: any
    loggedInId?: string
}

const MapPopup: React.FC<ContainerProps> = (props) => {

    useEffect(() => {
        console.log("MapPopup: " + JSON.stringify(props.trick));
        console.log("MapPopup: " + JSON.stringify(props.loggedInId));
    }, [props.loggedInId, props.trick])

    return (
        <IonCard style={{margin: '-15px', width: `${props.trick.description ? '250px' : '250px'}`, overflow: 'wrap'}}>
            <IonCardHeader>
                <IonCardTitle>{props.trick.trick_name}</IonCardTitle>
                <UsernameDisplay username={props.trick.username} className={"text-xl"} userId={props.trick?.user_id}
                                 loggedInId={props.loggedInId} bypassState={true}></UsernameDisplay>
            </IonCardHeader>
            {
                props.trick.description &&
                <IonCardContent>
                    {props.trick.description}
                </IonCardContent>
            }
            <div className="flex flex-row mx-0.5 mb-4 gap-x-0.5 justify-center">
                <IonButton
                    size="small"
                    color="favorite"
                    onClick={() => props.onClick(props.trick.trick_id, props.trick.user_id)}
                >
                    View Trick
                </IonButton>
                <IonButton
                    size="small"
                    color="favorite-secondary"
                    onClick={() => props.onClickFlyTo()}
                >
                    Explore Area
                </IonButton>
            </div>
        </IonCard>
    );
};

export default MapPopup;