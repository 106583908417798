import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {hammer, logoInstagram, logoYoutube, planetOutline, snow} from "ionicons/icons";
import React, {useEffect} from "react";
import {User} from "../../../models/user";
import {handleInstagramLink, handleTikTokLink, handleTwitterLink} from "../../../services/utils";
import SocialProfileLink from "../SocialProfileLink/SocialProfileLink";
import xLogo from "../../../assets/icons/logos/x-logo.svg";
import BlockUser from "../BlockUser/BlockUser";
import ReportUser from "../ReportUser/ReportUser";

interface ContainerProps {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void,
    userProfileSocial: User,
    displayBlock: boolean,
    userBlocked: boolean,
    onBlockUser: (blocked_id: string, block: boolean) => void,
    onReportUser: (report_id: string) => void,
    loggedIn: boolean,
}

const ProfileSocial: React.FC<ContainerProps> = ({
                                                     onDismiss,
                                                     userProfileSocial,
                                                     displayBlock,
                                                     userBlocked,
                                                     onBlockUser,
                                                     onReportUser,
                                                     loggedIn
                                                 }) => {
    const [noProfileInformation, setNoProfileInformation] = React.useState(false);

    useEffect(() => {
        if (!(userProfileSocial?.profile?.trick_interests && userProfileSocial?.profile?.trick_interests?.length > 0)
            && !(userProfileSocial?.profile?.discord && userProfileSocial?.profile?.discord !== "")
            && !(userProfileSocial?.profile?.instagram && userProfileSocial?.profile?.instagram !== "")
            && !(userProfileSocial?.profile?.twitter && userProfileSocial?.profile?.twitter !== "")
            && !(userProfileSocial?.profile?.youtube && userProfileSocial?.profile?.youtube !== "")
            && !(userProfileSocial?.profile?.website && userProfileSocial?.profile?.website !== "")
            && !(userProfileSocial?.profile?.tiktok && userProfileSocial?.profile?.tiktok !== "")
            && !(userProfileSocial?.profile?.setup && userProfileSocial?.profile?.setup !== "")) {
            setNoProfileInformation(true);
        }
    }, [userProfileSocial]);

    const onBlockUserHelper = (blocked_id: string, block: boolean) => {
        onBlockUser(blocked_id, block);
        onDismiss(null, 'cancel');
    }

    const onReportUserHelper = (reported_id: string) => {
        onReportUser(reported_id);
        onDismiss(null, 'cancel');
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="flex flex-row">
                    <IonButtons slot="start" className="mr-14">
                        <IonButton strong={true} onClick={() => onDismiss(null, 'cancel')}>
                        </IonButton>
                    </IonButtons>
                    <IonTitle className="items-center text-center">Information</IonTitle>
                    <IonButtons slot="end">
                        <IonButton strong={true} onClick={() => onDismiss(null, 'cancel')}>
                            Close
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding flex">
                {noProfileInformation ?
                    <div className="text-center text-base">Looks like there is no rider information filled out
                        yet!</div> : null}
                {
                    userProfileSocial?.profile?.trick_interests && userProfileSocial?.profile?.trick_interests?.length > 0 ?
                        <div className="flex-row mt-4">
                            <IonItem className="flex-row mt-4 items-center">
                                <IonIcon icon={snow} size="large" className="mr-4"></IonIcon>
                                {userProfileSocial.profile.trick_interests.map((trick: string) => (
                                    <div className="mr-2">{trick}</div>))}
                            </IonItem>
                        </div> : <div></div>
                }
                {
                    userProfileSocial?.profile?.setup && userProfileSocial?.profile?.setup !== "" ?
                        <IonItem className="flex-row mt-4 items-center">
                            <IonIcon icon={hammer} size="large" className="mr-4"></IonIcon>
                            <IonLabel>
                                <div className="whitespace-pre-wrap text-white">
                                    {userProfileSocial?.profile?.setup.split('\n').map((line, index) => (
                                        <React.Fragment key={index}>
                                            <span className="break-word text-white text-base font-bold">{line}</span>
                                            <br/>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </IonLabel>
                        </IonItem> : <div></div>
                }
                {userProfileSocial?.profile?.discord && userProfileSocial?.profile?.discord !== "" ?
                    <SocialProfileLink svg_path="/assets/icon/icons8-discord.svg"
                                       value={userProfileSocial.profile.discord}></SocialProfileLink> : <div></div>}
                {userProfileSocial?.profile?.instagram && userProfileSocial?.profile?.instagram !== "" ?
                    <SocialProfileLink icon={logoInstagram}
                                       link={handleInstagramLink(userProfileSocial.profile.instagram)}
                                       value={userProfileSocial.profile.instagram}></SocialProfileLink> : <div></div>}
                {userProfileSocial?.profile?.tiktok && userProfileSocial?.profile?.tiktok !== "" ?
                    <SocialProfileLink svg_path="/assets/icon/icons8-tiktok.svg"
                                       link={handleTikTokLink(userProfileSocial.profile.tiktok)}
                                       value={userProfileSocial.profile.tiktok}></SocialProfileLink> : <div></div>}
                {userProfileSocial?.profile?.twitter && userProfileSocial?.profile?.twitter !== "" ?
                    <SocialProfileLink icon={xLogo} value={userProfileSocial.profile.twitter}
                                       link={handleTwitterLink(userProfileSocial.profile.twitter)}></SocialProfileLink> :
                    <div></div>}
                {userProfileSocial?.profile?.youtube && userProfileSocial?.profile?.youtube !== "" ?
                    <SocialProfileLink icon={logoYoutube} value={userProfileSocial.profile.youtube}
                                       link={userProfileSocial.profile.youtube}></SocialProfileLink> : <div></div>}
                {userProfileSocial?.profile?.website && userProfileSocial?.profile?.website !== "" ?
                    <SocialProfileLink icon={planetOutline}
                                       value={userProfileSocial.profile?.website_name && userProfileSocial.profile?.website_name !== "" ? userProfileSocial.profile.website_name : userProfileSocial.profile.website}
                                       link={userProfileSocial.profile.website}></SocialProfileLink> : <div></div>}
                {

                    displayBlock &&
                    <div className="mt-4">
                        <ReportUser reportUserId={userProfileSocial?.id}
                                    onReportUser={onReportUserHelper} username={userProfileSocial?.username}
                                    loggedIn={loggedIn}/>
                    </div>
                }
                {

                    displayBlock &&
                    <div className="mt-4">
                        <BlockUser blockedUserId={userProfileSocial?.id} userBlocked={userBlocked}
                                   onBlockUser={onBlockUserHelper} username={userProfileSocial?.username}
                                   loggedIn={loggedIn}/>
                    </div>
                }
            </IonContent>
        </IonPage>
    );
};

export default ProfileSocial;