import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {Analytics, getAnalytics} from "firebase/analytics";
import {useAuth} from "./AuthProvider";

type AppAnalyticsContextType = {};

const AppAnalyticsContext = createContext<AppAnalyticsContextType | undefined>(undefined);

interface AppProviderProps {
    children: ReactNode;
}

export const AppAnalyticsProvider: React.FC<AppProviderProps> = ({children}) => {
    const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);
    const {firebaseApp} = useAuth();

    useEffect(() => {
        if (firebaseApp) {
            const analytics = getAnalytics(firebaseApp);
            setAnalytics(analytics);
        }
    }, [firebaseApp]);


    return (
        <AppAnalyticsContext.Provider
            value={{}}>
            {children}
        </AppAnalyticsContext.Provider>
    );
};

export const useAnalytics = () => {
    const context = useContext(AppAnalyticsContext);
    if (!context) {
        throw new Error('useAnalytics must be used within an AnalyticsProvider');
    }
    return context;
};
