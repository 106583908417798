import {Browser, OpenOptions} from "@capacitor/browser";
import {Capacitor} from "@capacitor/core";
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import {
    accessibilityOutline,
    chatboxEllipsesOutline,
    flashOutline,
    glassesOutline,
    medalOutline,
    pulseOutline,
    snowOutline,
    terminalOutline,
    trainOutline,
    videocamOutline
} from "ionicons/icons";
import React from "react";

interface ContainerProps {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void,
}

// TODO: make a mat card
const InfiniteDegrees: React.FC<ContainerProps> = ({onDismiss}) => {
    const web: boolean = !(Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android');

    const onSubscribe = async () => {
        const options: OpenOptions = {
            'url': 'https://discord.gg/u6VhZ6hMVE'
        }

        await Browser.open(options);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="flex justify-between flex-row">
                    <IonButtons slot="start" className="mr-14">
                        <IonButton strong={true} onClick={() => onDismiss(null, 'cancel')}>
                        </IonButton>
                    </IonButtons>
                    <IonTitle className="items-center text-center">Ecliptic Community</IonTitle>
                    <IonButtons slot="end">
                        <IonButton strong={true} onClick={() => onDismiss(null, 'cancel')}>
                            Close
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className="flex flex-col mx-4 mt-2 space-y-2">
                    <div className="text-2xl text-center">Join our Discord for FREE FOREVER to talk about all things!
                    </div>
                    <div>
                        <IonButton color="favorite" expand="full" className="font-bold flex flex-row"
                                   onClick={() => onSubscribe()}>
                            <div>Join The Discord</div>
                            <IonIcon slot="end" icon={snowOutline}></IonIcon>
                        </IonButton>
                    </div>
                    <div className="flex flex-row items-center gap-x-6">
                        <IonIcon icon={accessibilityOutline} color="success" size="default"
                                 className="flex-none"></IonIcon>
                        <div>Use our bot to cue up videos for replay on tips, progression advice, and Ecliptic certified
                            heaters
                        </div>
                    </div>
                    <div className="flex flex-row items-center gap-x-6">
                        <IonIcon icon={flashOutline} color="tertiary" size="default" className="flex-none"></IonIcon>
                        <div>We talk about the brrr and whats going on mountains around the world</div>
                    </div>
                    <div className="flex flex-row items-center gap-x-6">
                        <IonIcon icon={chatboxEllipsesOutline} color="secondary" size="default"
                                 className="flex-none"></IonIcon>
                        <div>Chat with others! From just someone just entering za park to doing dub cork 10s</div>
                    </div>
                    <div className="flex flex-row items-center gap-x-6">
                        <IonIcon icon={glassesOutline} color="danger" size="default" className="flex-none"></IonIcon>
                        <div>Kit is parallel. Get suggestions on the Discord and avoid the google gap.</div>
                    </div>
                    <div className="flex flex-row items-center gap-x-6">
                        <IonIcon icon={pulseOutline} color="warning" size="default" className="flex-none"></IonIcon>
                        <div>Get a pulse on the industry and what it's like on the inside.</div>
                    </div>
                    <div className="flex flex-row items-center gap-x-6">
                        <IonIcon icon={medalOutline} color="secondary" size="default" className="flex-none"></IonIcon>
                        <div>Future AMAs with Pros is hopefully going to happen!</div>
                    </div>
                    <div className="flex flex-row items-center gap-x-6">
                        <IonIcon icon={trainOutline} color="success" size="default" className="flex-none"></IonIcon>
                        <div>There will be potential discounts on gear, giveaways, or a brazen exchange of passes</div>
                    </div>
                    <div className="flex flex-row items-center gap-x-6">
                        <IonIcon icon={videocamOutline} color="warning" size="default" className="flex-none"></IonIcon>
                        <div>Link up with someone who might want to clip you up in the park or on the mountain</div>
                    </div>
                    <div className="flex flex-row items-center gap-x-6">
                        <IonIcon icon={terminalOutline} color="danger" size="default" className="flex-none"></IonIcon>
                        <div>Keep up with the people behind Ecliptic and suggest new dev features!</div>
                    </div>
                    <div>
                        <IonButton color="favorite" expand="full" className="font-bold flex flex-row"
                                   onClick={() => onSubscribe()}>
                            <div>Join The Discord</div>
                            <IonIcon slot="end" icon={snowOutline}></IonIcon>
                        </IonButton>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default InfiniteDegrees;