import React, {useEffect, useState} from 'react';
import {IonButton, IonContent, IonHeader, IonPage, IonSpinner, IonToolbar} from '@ionic/react';
import {useHistory, useLocation} from "react-router-dom";
import {AppContext} from "../AppStateProvider";
import UserService from "../services/user.service";
import BiskService from "../services/bisk.service";
import {UserBisk} from "../models/userBisk"; // assuming axios is used for API calls

const PurchaseSuccessPage = () => {
    const {dispatch} = React.useContext(AppContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdateComplete, setIsUpdateComplete] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const numberOfRobotGifs = 20;
    const [loadingGifSelected, setLoadingGifSelected] = React.useState<number>(Math.floor(Math.random() * numberOfRobotGifs));


    const history = useHistory();

    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const infdId = queryParams.get('infd_id');
        const oldBiskStr = queryParams.get('old_bisk');
        const oldBisk = parseInt(oldBiskStr);
        setLoadingGifSelected(Math.floor(Math.random() * numberOfRobotGifs));

        const sessionId = queryParams.get('session_id');
        if (sessionId) {
            console.log("Stripe Session ID: ", sessionId);
        }

        const maxRetries = 10;
        let retries = 0;

        console.log("Infd ID: ", infdId);
        console.log("Old Bisk: ", oldBisk);


        const checkUpdates = async () => {
            if (retries > maxRetries) {
                clearInterval(intervalId); // Stop further checks
                setErrorMessage('An error occurred while updating your information. Ecliptic Devs solving it asap and we will reach out to you!');
                setIsUpdateComplete(false);
                setIsLoading(false);
                return;
            }
            if (!infdId) {
                clearInterval(intervalId); // Stop further checks
                setErrorMessage('Invalid user identification.');
                setIsLoading(false);
                return;
            }

            try {
                console.log("trying to get bisk for user: ", infdId)
                const userBisk: UserBisk = await BiskService.getBiskForUser(infdId);
                console.log("userBisk: ", userBisk);
                if (userBisk.bisk !== oldBisk) {
                    setLoadingGifSelected(Math.floor(Math.random() * numberOfRobotGifs));
                    await UserService.setUser(true, dispatch);
                    clearInterval(intervalId); // Stop further checks
                    setIsUpdateComplete(true);
                    setIsLoading(false);
                    return;
                }
                retries++;
            } catch (error) {
                console.error('Error updating user info:', error);
                setIsLoading(false);
                setErrorMessage('An error occurred while updating your information. Ecliptic Devs solving it asap and we will reach out to you!');
                clearInterval(intervalId); // Stop further checks on error
            }
        };

        const intervalId = setInterval(checkUpdates, 3000); // Check every 5 seconds

        // Initial check
        checkUpdates();

        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, [location.search]);

    const goBackToProfile = () => {
        history.push('/profile/');
    }

    return (
        <IonPage>
            <IonHeader className="bg-grey" color="light">
                <IonToolbar>
                    <div className="flex flex-row justify-between items-center">
                        <div className="flex-1 flex justify-center">
                            <a href="/"><img alt="ID Logo" src="/assets/photos/logo_white.png"></img></a>
                        </div>
                    </div>
                </IonToolbar>
            </IonHeader>
            <IonContent className="flex justify-center items-center p-4" color="dark">
                {isLoading ? (
                    <div className="text-center flex flex-col mt-8 text-black gap-y-4 justify-center mx-4">
                        <div className="text-2xl">Updating your Ecliptic info...</div>
                        <div className="flex flex-row justify-center">
                            <IonSpinner name="crescent" color="favorite"/>
                        </div>
                        <div className="object-contain aspect-video">
                            <img src={`/assets/photos/robots/${loadingGifSelected}.gif`}
                                 className="w-full h-full block object-contain"
                                 alt="loading suggested tricks"></img>
                        </div>
                    </div>
                ) : isUpdateComplete ? (
                    <div className="text-center flex flex-col mt-8 text-black gap-y-4 mx-4">
                        <div className="text-3xl font-bold">Purchase Successful!</div>
                        <div className="text-2xl">Your Ecliptic account has been updated 🅿️</div>
                        <div className="object-contain aspect-video">
                            <img src={`/assets/photos/robots/${loadingGifSelected}.gif`}
                                 className="w-full h-full block object-contain"
                                 alt="loading suggested tricks"></img>
                        </div>
                        <IonButton className="px-4 py-2 sm:px-1 sm:py-1" shape="round" color="favorite"
                                   onClick={goBackToProfile}>Go To Your Profile</IonButton>
                    </div>
                ) : (
                    <div className="text-center flex flex-col mt-8 text-black gap-y-4 justify-center mx-4">
                        <div className="flex flex-row justify-center">
                            <IonSpinner name="crescent" color="favorite"/>
                        </div>
                    </div>
                )}
            </IonContent>
        </IonPage>
    );
};

export default PurchaseSuccessPage;