import React, {useContext, useEffect} from "react";
import {Redirect, Route, useHistory} from "react-router-dom";
import {AppContext} from "./AppStateProvider";
import {useAppState} from "./AppListenerProvider";
import {useAuth} from "./AuthProvider";
import {useAuth0} from "@auth0/auth0-react";
import {UserType} from "./models/usertype";
import AuthService from "./services/auth.service";
import {LOG_LEVEL, Purchases} from "@revenuecat/purchases-capacitor";
import PreferencesService from "./services/preferences.service";
import UserService from "./services/user.service";
import PushNotificationsService from "./services/pushNotifications.service";
import {User} from "./models/user";
import {v4 as uuidv4} from "uuid";
import VideoCacheService from "./services/videoCache.service";
import {fileDirectories, protectedRoutes} from "./services/utils";
import {Directory} from "@capacitor/filesystem";
import {MutedStatus} from "./models/mutedStatus";
import {IonTabsCustomEvent} from "@ionic/core/dist/types/components";
import {IonIcon, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs} from "@ionic/react";
import ExplorePage from "./pages/ExplorePage";
import MapPage from "./pages/Map";
import Upload from "./pages/Upload";
import CrewUpload from "./pages/CrewUpload";
import Profile from "./pages/Profile";
import Community from "./pages/Community";
import Crew from "./pages/Crew";
import BrandEventInfo from "./pages/BrandEventInfo";
import TrickVideo from "./pages/TrickVideo";
import Claim from "./pages/Claim";
import CrewClaim from "./pages/CrewClaim";
import Filter from "./pages/Filter";
import Shop from "./pages/Shop";
import PurchaseSuccessPage from "./pages/PurchaseSuccessPage";
import Settings from "./pages/Settings";
import LoginWebPage from "./pages/LoginWebPage";
import LoginEmailUsernameWebPage from "./pages/LoginEmailUsernameWebPage";
import {addCircle, bonfire, homeSharp, map, person} from "ionicons/icons";
import LoadingSplash from "./pages/LoadingSplash";
import {useDeepLink} from "./DeepLinkProvider";

const AppMain: React.FC = () => {
    const history = useHistory();
    const {state, dispatch} = useContext(AppContext);
    const {isActive, isNative, platform} = useAppState();
    const {user, isLoading, isAuthenticated, firebaseApp, auth} = useAuth();
    const {getAccessTokenSilently} = useAuth0();
    const [userType, setUserType] = React.useState<string>(UserType.UNKNOWN);
    const authService = AuthService({user, isLoading, isAuthenticated, firebaseApp, auth}, {
        isActive,
        isNative,
        platform
    });
    const {deepLinkData, clearDeepLinkData} = useDeepLink();

    // PAYMENTS LOGIC
    const initializeRevenueCat = async () => {
        await Purchases.setLogLevel({level: LOG_LEVEL.DEBUG});

        if (platform === 'ios') {
            console.log("Initializing for Apple - Revenue Cat");
            await Purchases.configure({apiKey: 'appl_dTgMFKeUzMoMYIzYwbMzDmRnXvQ'})
        } else if (platform === 'android') {
            console.log("Initializing for Android - Revenue Cat");
            await Purchases.configure({apiKey: 'goog_DcfrRmocYTiAaotDMpCpPBQicsW'})
        }
    }

    const logInToRevenueCat = async (retrievedUserId: string) => {
        console.log("Logging Into Revenue cat with User ID: ", retrievedUserId);
        await Purchases.logIn({appUserID: retrievedUserId});
    }

    // PUSH NOTIFICATION LOGIC
    const fcmTokenReceived = async (event: any) => {
        console.log("FCM TOKEN RECEIVED");
        await PreferencesService.setPushNotificationToken(event.token);
        if (isAuthenticated) {
            console.log("FCM TOKEN RECEIVED AND USER IS AUTHENTICATED");
            const res = await UserService.updateUserPushNotifications(user?.uid, event.token);
            if (res) {
                await UserService.setUser(true, dispatch);
                console.log("SET THE USER WITH THE NEW FCM TOKEN");
            }
        }
    };

    // handles the notification received events
    const notificationReceived = (event: any) => {
        console.log("NOTIFICATION RECEIVED");
        console.log(event);
    };

    // notification action (like clicking on a notification) will be in actionId
    // of the event and remove notification from notification screen?
    const notificationActionPerformed = async (event: any) => {
        console.log("NOTIFICATION ACTION PERFORMED");
        console.log(event);
        if (event.actionId === "tap") {
            // delete all notifications instead of just the one that was tapped
            await PushNotificationsService.removeAllDeliveredNotifications();
            // do something here about the actual notification
            if (event.notification?.data?.redirect_url) {
                if (event.notification?.data?.redirect_url !== "") {
                    dispatch({type: "setPushNotificationRedirectUrl", payload: event.notification?.data?.redirect_url})
                }
            }
        }
    };

    const initNotificationsHelper = async (token: string) => {
        await PushNotificationsService.addTokenReceivedListener(fcmTokenReceived);
        await PushNotificationsService.addNotificationReceivedListener(notificationReceived);
        await PushNotificationsService.addNotificationActionPerformedListener(notificationActionPerformed);
        if (isAuthenticated) {
            console.log("FCM TOKEN RECEIVED AND USER IS AUTHENTICATED");
            const res = await UserService.updateUserPushNotifications(user?.uid, token);
            if (res) {
                await UserService.setUser(true, dispatch);
                console.log("SET THE USER WITH THE NEW FCN TOKEN");
            }
        }
    }

    const upsertNotificationsInDb = async () => {
        const token = await PushNotificationsService.getToken();
        const tokenInStorage = await PreferencesService.getPushNotificationToken();
        return tokenInStorage !== token;
    }

    const initNotifications = async () => {
        const token = await PushNotificationsService.getToken();
        await PreferencesService.setPushNotificationToken(token);
        await PushNotificationsService.removeAllListeners();
        await initNotificationsHelper(token);
    }

    const setupPushNotifications = async () => {
        if (isNative) {
            console.log("CHECKING NOTIFICATIONS");
            const permissions = await PushNotificationsService.checkPermissions();
            if (permissions === "denied") {
                console.log("PERMISSIONS DENIED");
                // don't do anything
                return;
            } else if (permissions === "granted") {
                console.log("PERMISSIONS GRANTED");
                await initNotifications();
            } else {
                console.log("PERMISSIONS REQUEST");
                const requestedPerms = await PushNotificationsService.requestPermissions();
                if (requestedPerms === "granted") {
                    await initNotifications();
                }
            }
        }
    }

    const setAuthVariables = async () => {
        await PreferencesService.setUserUid(user?.uid);
        await UserService.checkCreateUser(user.uid, user.email, user.displayName);
        await UserService.setUser(true, dispatch);
        const retrievedUserType = await UserService.getUserType(user?.uid)
        setUserType(retrievedUserType);
        console.log("Entering isAuthenticated for Payments");
        if (isNative && retrievedUserType === UserType.USER) {
            console.log("Entering Authenticated for Payments Natively to set up Revenue Cat");
            const retrievedUserFromAuthId: User = await UserService.getUserByAuthId(user?.uid);
            await logInToRevenueCat(retrievedUserFromAuthId.id);
        } else {
            console.log("Not configuring RevenueCat because this is not native or not authenticated");
        }
        await setupPushNotifications();
    }

    useEffect(() => {
        if (!isLoading) {
            console.log("IS LOADING IS FALSE - Getting/Setting User Data");
            if (isAuthenticated) {
                setAuthVariables();
            }
        }

    }, [isAuthenticated, isNative, user?.uid, user, isLoading]);

    const getAccessTokenSilentlyForAuth0 = async () => {
        const value = await PreferencesService.getAccessToken();
        if (value) {
            try {
                console.log(`Getting token through this url + ${process.env.REACT_APP_AUTH_API}`);

                const actionTokenOptions = !isNative ? {
                        audience: `${process.env.REACT_APP_AUTH_API}`,
                        scope: "read:current_user"
                    }
                    : {
                        audience: `${process.env.REACT_APP_AUTH_API}`,
                        scope: "read:current_user",
                        ignoreCache: true,
                        timeoutInSeconds: 10
                    }

                const accessToken = await getAccessTokenSilently(actionTokenOptions);

                console.log("Setting user auth0 access token");
                await PreferencesService.setAccessToken(accessToken);
                return accessToken;

            } catch (e: any) {
                console.log("ERROR ON APP getting the access token");
                console.log(e);
            }
        } else {
            return null;
        }

    }

    useEffect(() => {
        const getUserMetadataCheckNotificationsSetUpPurchases = async () => {
            console.log("Setting user state");

            const sessionId = uuidv4();
            await PreferencesService.setUserSessionId(sessionId);

            if (isNative) {
                await initializeRevenueCat();
            }

            const isAuth0Token = await getAccessTokenSilentlyForAuth0();

            if (isAuth0Token) {
                console.log("Found auth0 token, now logging in with firebase");
                try {
                    const res = await authService.fetchCustomTokenAndSignIn();
                } catch (e: any) {
                    console.log("ERROR ON APP getting the access token");
                    console.log(e);
                }
            }

            await setupPushNotifications();
        }

        if (!isLoading) {
            console.log("IS LOADING IS FALSE - Getting User Metadata and Setting Up Notifications and Purchases");
            getUserMetadataCheckNotificationsSetUpPurchases();
        }
    }, [isNative, isLoading]);

    useEffect(() => {
        if (isNative) {
            // code for clearing cache
            console.log("CLEARING CACHE")
            // code for clearing cache
            VideoCacheService.clearCache(24, fileDirectories, Directory.Cache);
        }
    }, [isNative]);

    useEffect(() => {
        const initAppFunctionality = async () => {
            if (!isActive) {
                console.log("App is in the background");
                await PreferencesService.setMutedPreferences(MutedStatus.TRUE)
                dispatch({type: 'setMutedSettings', payload: true});
            }

            if (isActive) {
                console.log("App is in the foreground");
                if (isNative) {
                    const permissions = await PushNotificationsService.checkPermissions();
                    if (permissions === "granted") {
                        const upsertNotificationsInDB = await upsertNotificationsInDb();
                        console.log("UPSERT NOTIFICATIONS IN DB: ", upsertNotificationsInDB);

                        if (upsertNotificationsInDB) {
                            console.log("NOTIFICATIONS PERMISSION REQUEST");
                            const requestedPerms = await PushNotificationsService.requestPermissions();
                            if (requestedPerms === "granted") {
                                await initNotifications();
                            }
                        }
                    }
                    // code for clearing cache
                    VideoCacheService.clearCache(24, fileDirectories, Directory.Cache);
                }
            }
        }

        initAppFunctionality();

    }, [isNative, isActive]);

    useEffect(() => {
        const handleDeepLink = async () => {
            if (isLoading) return; // Don't handle deep links while loading
            console.log("HANDLING DEEP LINKS IN MAIN APP");

            if (deepLinkData.slug) {
                // Check if the slug starts with any of the protected routes
                const matchedRoute = protectedRoutes.find(route => deepLinkData.slug.startsWith(route));

                if (matchedRoute) {
                    // If it's a protected route, navigate to it
                    history.push(deepLinkData.slug);
                } else if (deepLinkData.slug.startsWith('/refer')) {
                    // Handle referral links
                    history.push('/profile');
                } else {
                    // Handle any other deep links
                    console.log('Unhandled deep link in main app:', deepLinkData.slug);
                }

                // Clear the deep link data after handling
                await clearDeepLinkData();
            }
        };

        handleDeepLink();
    }, [deepLinkData, isLoading, clearDeepLinkData, history]);

    const handleTabWillChange = (e: IonTabsCustomEvent<{ tab: string }>) => {
        console.log('Tab will change', e.detail.tab);
        if (e.detail.tab === 'home') {
            console.log('Clicked on home tab');
            history.push('/home');
        } else if (e.detail.tab === 'upload') {
            if (userType === UserType.BRAND) {
                console.log('Clicked on crew upload tab');
                history.push('/crew-upload');
            } else {
                console.log('Clicked on upload tab');
                history.push('/upload');
            }
        } else if (e.detail.tab === 'profile') {
            console.log('Clicked on profile tab');
            history.push(`/profile/${state.user.username}`);
        } else if (e.detail.tab === 'community') {
            console.log('Clicked on notifications tab');
            history.push('/community');
        } else if (e.detail.tab === 'map') {
            console.log('Clicked on map tab');
            history.push('/map');
        }
    };

    // TODO: Look back on for logic for the profile page
    const getProfileHref = () => {
        let href;
        if (userType === UserType.USER) {
            console.log(`Navigating to: /profile/${state.user.username}`)
            href = `/profile/${state.user.username}`;
        } else if (userType === UserType.BRAND) {
            console.log(`Navigating to: /crews/${state.user.username}`)
            href = `/crews/${state.user.username}`;
        } else {
            console.log(`Navigating to: /profile`)
            href = `/profile`;
        }
        return href;
    };

    // AUTH ROUTING STRATEGY
    // Do not do any redirects based on conditional state - all conditional state logic should be in the component
    // If need to navigate to a different page based on conditional state, then do it in the component
    // If this navigation is to another tab, then call that tab in that component and history.go(0) if things don't work as expected

    return (
        <IonTabs onIonTabsWillChange={handleTabWillChange}>
            <IonRouterOutlet animated={false}>
                <Route
                    path="/"
                    render={() => <Redirect to="/home"/>}
                    exact={true}
                />
                <Route path="/home" component={ExplorePage} exact={true}/>
                <Route
                    path="/home/explore"
                    render={() => <Redirect to="/home"/>}
                    exact={true} /*use redirect here because this isn't a valid page */
                />
                <Route
                    path="/home/following"
                    render={() => <Redirect to="/home"/>}
                    exact={true}
                />
                <Route
                    path="/home/new"
                    render={() => <Redirect to="/home"/>}
                    exact={true}
                />
                <Route
                    path="/home/hot"
                    render={() => <Redirect to="/home"/>}
                    exact={true}
                />
                <Route path="/map" component={MapPage} exact={true}/>
                <Route path="/upload" component={Upload} exact={true}/>
                <Route path="/crew-upload" component={CrewUpload}
                       exact={true} /* Do not use conditional statements here because can include it in the component *//>
                <Route
                    path="/flow/login"
                    render={() => <Redirect to="/profile"/>}
                    exact={true}
                />
                <Route
                    path="/flow/signup"
                    render={() => <Redirect to="/profile"/>}
                    exact={true}
                />
                <Route path="/profile/:username" component={Profile} exact={true}/>
                <Route path="/profile" component={Profile} exact={true}/>
                <Route path="/community" component={Community} exact={true}/>
                <Route
                    path="/crews"
                    render={() => <Redirect to={`/community`}/>}
                    exact={true}
                />
                <Route path="/crews/:username" component={Crew} exact={true}/>
                <Route path="/event/:id" component={BrandEventInfo} exact={true}/>
                <Route path="/clip/:user_id/:id" component={TrickVideo} exact={true}/>
                <Route path="/trick/:user_id/:id" component={TrickVideo} exact={true}/>
                <Route path="/claim" component={Claim} exact={true}/>
                <Route path="/claim/crews" component={CrewClaim} exact={true}/>
                <Route path="/search" component={Filter} exact={true}/>
                <Route path="/search/:query" component={Filter} exact={true}/>
                <Route path="/shop" component={Shop} exact={true}/>
                <Route path="/purchase" component={PurchaseSuccessPage} exact={true}/>
                <Route path="/settings" component={Settings} exact={true}/>
                <Route path="/loading" component={LoadingSplash} exact={true}/>
                <Route
                    path="/login"
                    render={() => (<LoginWebPage login={true} signUp={false}/>)}
                    exact={true} /* use redirect here because they aren't supposed to be on this page if logged in*/
                />
                <Route
                    path="/login/username"
                    render={() => (<LoginEmailUsernameWebPage login={true} signUp={false}/>)}
                    exact={true}
                />
                <Route
                    path="/signup"
                    render={() => (<LoginWebPage login={false} signUp={true}/>)}
                    exact={true} /* use redirect here because they aren't supposed to be on this page if logged in*/
                />
                <Route
                    path="/signup/email"
                    render={() => (<LoginEmailUsernameWebPage login={false} signUp={true}/>)}
                    exact={true} /* use redirect here because they aren't supposed to be on this page if logged in*/
                />
                <Route render={() => <Redirect to="/home"/>}/>
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
                <IonTabButton tab="home" href={'/home'}>
                    <IonIcon icon={homeSharp}/>
                    <span>Feed</span>
                </IonTabButton>
                <IonTabButton tab="map" href={'/map'}>
                    <IonIcon icon={map}/>
                    <span>Maps</span>
                </IonTabButton>
                <IonTabButton tab="upload" href={userType !== UserType.BRAND ? '/upload' : '/crew-upload'}>
                    <IonIcon icon={addCircle}/>
                    <span>Upload</span>
                </IonTabButton>
                <IonTabButton tab="community" href={`/community`}>
                    <IonIcon icon={bonfire}/>
                    <span>Community</span>
                </IonTabButton>
                <IonTabButton tab="profile"
                              href={`/profile/${state.user.username}`}>
                    <IonIcon icon={person}/>
                    <span>You</span>
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
    );
};

{/*
Notes:

Profile Route:
like instagram, if logged in, then go to your profile with username,
if not then assume it's another profile, and use that to get profile information.
Should get profile information from the username that is supplied
*/
}

export default AppMain;