import React from "react";

interface ContainerProps {
}

const LoadingProfileComponent: React.FC<ContainerProps> = (props: ContainerProps) => {
    return (
        <div className="p-4 space-y-4">
            <div className="animate-pulse flex flex-col space-y-4">
                {/* Title Placeholder */}
                <div className="h-6 bg-gray-900 rounded"></div>
                {/* Image Placeholder */}
                <div className="h-48 bg-gray-900 rounded"></div>
                {/* Text Placeholders */}
                <div className="h-4 bg-gray-900 rounded"></div>
                <div className="h-4 bg-gray-900 rounded w-5/6"></div>
                <div className="h-4 bg-gray-900 rounded w-4/6"></div>
            </div>
        </div>
    );
};

export default LoadingProfileComponent;
