import {OverlayEventDetail} from "@ionic/core/components";
import {IonActionSheet, IonButton, IonIcon, useIonModal} from "@ionic/react";
import React, {useContext, useEffect, useState} from "react";
import ProfilePictureModal from "../ProfilePictureModal/ProfilePictureModal";
import {PictureUploadType} from "../../../models/pictureUploadType";
import {User} from "../../../models/user";
import {UserType} from "../../../models/usertype";
import BrandsService from "../../../services/brand.service";
import UserService from "../../../services/user.service";
import {AppContext} from "../../../AppStateProvider";
import {closeOutline} from "ionicons/icons";
import {Camera, CameraResultType} from "@capacitor/camera";
import {Capacitor} from "@capacitor/core";

interface ContainerProps {
    editProfile: boolean;
    src: string;
    pictureUploadType?: string;
    isNative: boolean;
}

const defaultButtonsActionSheet = [
    {
        text: 'View',
        data: {
            action: 'view',
        },
    },
    {
        text: 'Upload',
        data: {
            action: 'edit',
        },
    },
    {
        text: 'Cancel',
        role: 'cancel',
        data: {
            action: 'cancel',
        },
    },
]

const ProfilePicture: React.FC<ContainerProps> = (props: ContainerProps) => {
    const {state, dispatch} = useContext(AppContext);
    const [userType, setUserType] = useState<UserType>(UserType.USER);
    const [profilePicSrc, setProfilePicSrc] = useState(props.src);
    const [profilePictureFullScreen, setProfilePictureFullScreen] = useState(false);

    const [viewEditProfilePictureOpen, setViewEditProfilePictureOpen] = useState(false);

    const [preview, setPreview] = useState<any>(undefined);

    const [buttonsActionSheet, setButtonsActionSheet] = useState(defaultButtonsActionSheet);

    const confirmProfilePictureSubmit = async (selectedFile: any) => {
        console.log('uploading');
        const data = new FormData();
        data.append('file', selectedFile);
        console.log("selected file", selectedFile);
        // @ts-ignore
        data.append('filename', selectedFile.value);
        console.log("filename", selectedFile.value);
        if (userType == UserType.USER) {
            const updatedUser: User = await UserService.uploadUserProfilePicture(state.user.id, data);
            await dispatch({type: 'setUser', payload: updatedUser});
            console.log("updated user pro pic", updatedUser.profile.profile_pic);
            setProfilePicSrc(updatedUser.profile.profile_pic);
            setPreview(undefined);
        }

        if (userType == UserType.BRAND && props.pictureUploadType == PictureUploadType.PROFILE) {
            const updatedBrand = await BrandsService.uploadBrandProfilePicture(state.user.id, data);
            await dispatch({type: 'setUser', payload: updatedBrand});
            setProfilePicSrc(updatedBrand.profile.profile_pic);
            setPreview(undefined);
        }

        if (userType == UserType.BRAND && props.pictureUploadType == PictureUploadType.COVER) {
            const updatedBrand = await BrandsService.uploadBrandCoverPicture(state.user.id, data);
            await dispatch({type: 'setUser', payload: updatedBrand});
            setProfilePicSrc(updatedBrand.profile.cover_pic);
            setPreview(undefined);
        }

    }

    function openProfilePictureModal() {
        presentProfilePicModal({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    const [presentProfilePicModal, dismissProfilePicModal] = useIonModal(ProfilePictureModal, {
        onDismiss: (data: string, role: string) => dismissProfilePicModal(data, role),
        onProfilePictureSubmit: confirmProfilePictureSubmit,
        src: props.src,
        pictureUploadType: props.pictureUploadType,
        preview: preview,
    });

    const getUserType = async () => {
        const retrievedUserType = await UserService.getUserType(state.user.auth_id);
        setUserType(retrievedUserType);
    }

    useEffect(() => {
        console.log("Waiting for props to be mounted");
        setProfilePicSrc(props.src);
        getUserType();
    }, [props.src, userType])

    useEffect(() => {
        if (props.editProfile && !profilePicSrc && props.isNative) {
            setButtonsActionSheet([
                {
                    text: 'Upload',
                    data: {
                        action: 'edit',
                    },
                },
                {
                    text: 'Cancel',
                    role: 'cancel',
                    data: {
                        action: 'cancel',
                    },
                },
            ]);
        } else {
            setButtonsActionSheet(defaultButtonsActionSheet);
        }
    }, [props.editProfile, profilePicSrc]);

    /*
    Logic:
    User is viewing their profile
    1. If the user is viewing their profile, and they don't have a profile picture, then display icon to upload pro pic
    2. If they have a profile picture, then they can click that and change their pro pic using the modal

    User is viewing another profile
    1. If the user cannot edit a profile picture, display the default profile picture
    2. If the user that they are viewing does not have a profile picture, display the default profile picture


     */

    /*
    For some reason rendering this was causing an error
    <IonButton id="open-modal-upload" fill="solid" color="light" size="large" onClick={() => setIsOpen(true)}>
          <IonIcon slot="icon-only" icon={cloudUploadOutline} />
     */

    const handleActionSheet = async (data: any) => {
        if (data) {
            const action = data.action;
            if (action === 'view') {
                // view profile picture
                setViewEditProfilePictureOpen(false);
                setProfilePictureFullScreen(true);
            } else if (action === 'edit') {
                setViewEditProfilePictureOpen(false);
                // edit profile picture
                console.log('editing profile picture');
                await selectFile();
            } else if (action === 'photo') {
                setViewEditProfilePictureOpen(false);
                console.log('taking photo');
                await takePhoto();
            } else {
                setViewEditProfilePictureOpen(false);
                console.log('cancelling');
            }
        } else {
            setViewEditProfilePictureOpen(false);
            console.log('cancelling');
        }

    }

    const selectFile = async () => {
        try {
            if (Capacitor.isNativePlatform()) {
                const permissions = await Camera.checkPermissions();
                if (permissions.photos === 'granted') {
                    console.log('Permissions granted');
                } else {
                    console.log('Permissions denied');
                    const reqPermRes = await Camera.requestPermissions();
                    if (reqPermRes.photos === 'granted') {
                        console.log('Permissions granted');
                    } else {
                        console.log('Permissions denied');
                        return;
                    }
                }
            }

            const image = await Camera.pickImages({
                quality: 80, // Set the desired quality here (0 to 100)
                limit: 1,
            });

            // Since pickImages() returns an array of images, we'll pick the first one
            const pickedImage = image.photos ? image.photos.length === 1 ? image.photos[0] : null : null;

            if (!pickedImage) {
                return;
            }

            console.log("Photo", pickedImage);
            setPreview(pickedImage.webPath);

            openProfilePictureModal();
        } catch (error) {
            console.log("Error picking image", error);
        }
    }

    const takePhoto = async () => {
        try {
            if (Capacitor.isNativePlatform()) {
                const permissions = await Camera.checkPermissions();
                if (permissions.camera === 'granted') {
                    console.log('Permissions granted');
                } else {
                    console.log('Permissions denied');
                    const reqPermRes = await Camera.requestPermissions();
                    if (reqPermRes.camera === 'granted') {
                        console.log('Permissions granted');
                    } else {
                        console.log('Permissions denied');
                        return;
                    }
                }
            }

            const image = await Camera.getPhoto({
                quality: 80,
                allowEditing: false,
                resultType: CameraResultType.Uri,
            });

            console.log("Image", image);

            if (image) {
                const photo = image.webPath;
                console.log("Photo", photo);
                setPreview(photo);
                openProfilePictureModal();
            }
        } catch (error) {
            console.log("Error taking photo", error);
        }

    }

    return (
        profilePictureFullScreen ?
            <>
                <img alt="profile" className="shrink w-24 h-24 rounded-full"
                     src={profilePicSrc ? profilePicSrc : '/assets/photos/defaultProPic.png'}></img>
                <div
                    className="fixed inset-0 z-40 flex items-center justify-center p-4"
                    onClick={() => setProfilePictureFullScreen(false)}
                >
                    <div className="absolute inset-0 bg-black opacity-75"></div>
                    {/* Separate div for background with opacity */}
                    <div className="flex flex-col items-center justify-center">
                        <IonButton shape="round" fill="clear" color="dark" className="absolute top-12 right-0 z-50"
                                   onClick={() => setProfilePictureFullScreen(false)}>
                            <IonIcon icon={closeOutline}></IonIcon>
                        </IonButton>
                        <img
                            src={profilePicSrc ? profilePicSrc : '/assets/photos/defaultProPic.png'}
                            alt="Full Screen"
                            className="z-10 rounded-lg w-full md:w-5/6 lg:w-1/2 xl:w-1/2 object-contain"
                            onClick={(e) => e.stopPropagation()} // Prevent the modal from closing when the image is clicked
                        />
                    </div>
                </div>
            </>
            :
            props.editProfile ?
                <>
                    <IonActionSheet
                        isOpen={viewEditProfilePictureOpen}
                        buttons={buttonsActionSheet}
                        onDidDismiss={(e) => handleActionSheet(e.detail.data)}
                    ></IonActionSheet>
                    {
                        !profilePicSrc ? props.pictureUploadType ? props.pictureUploadType == PictureUploadType.PROFILE ?
                                    <img alt="profile" className="shrink w-24 h-24 rounded-full"
                                         src='/assets/photos/uploadProPicImage.png'
                                         onClick={() => setViewEditProfilePictureOpen(true)}></img> :
                                    <img alt="profile" className="shrink w-24 h-24 rounded-full"
                                         src='https://ionicframework.com/docs/img/demos/card-media.png'
                                         onClick={() => setViewEditProfilePictureOpen(true)}></img> :
                                <img alt="profile" className="shrink w-24 h-24 rounded-full"
                                     src='/assets/photos/uploadProPicImage.png'
                                     onClick={() => setViewEditProfilePictureOpen(true)}></img> :
                            <>
                                <img alt="profile" className="shrink w-24 h-24 rounded-full" src={profilePicSrc}
                                     onClick={() => setViewEditProfilePictureOpen(true)}></img>
                            </>
                    }
                </> :
                <img alt="profile" className="shrink w-24 h-24 rounded-full"
                     src={profilePicSrc ? profilePicSrc : '/assets/photos/defaultProPic.png'}
                     onClick={() => setProfilePictureFullScreen(true)}></img>
    );
};

export default ProfilePicture;
