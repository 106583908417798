import {Capacitor} from "@capacitor/core";
import React, {useContext, useEffect, useState} from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonPage,
    IonSpinner,
} from "@ionic/react";
import ToolbarSearchBar from "../components/ComponentsUI/ToolbarSearchBar/ToolbarSearchBar";
import WorldWeb from '../components/ComponentsMap/WorldWeb/WorldWeb';
import {LocationTrick} from "../models/locationTrick";
import {UserTrickSearch} from "../models/user-trick-search";
import LocationService from "../services/location.service";
import '../global.css'
import {useHistory, useLocation} from "react-router-dom";
import UserService from "../services/user.service";
import {UserTrick} from "../models/user-trick";
import TricksService from "../services/tricks.service";
import {UserType} from "../models/usertype";
import {User} from "../models/user";
import LoginButton from "../components/ComponentsLogin/LoginButton/LoginButton";
import {AppContext} from "../AppStateProvider";
import BiskService from "../services/bisk.service";
import CustomHelmet from "../components/ComponentsFunctional/CustomHelmet/CustomHelmet";
import {useAuth} from "../AuthProvider";
import PreferencesService from "../services/preferences.service";

const MapPage: React.FC = () => {
    const {state} = useContext(AppContext);
    const history = useHistory();
    const location = useLocation();
    const {isAuthenticated, isLoading} = useAuth();
    const [isAuthed, setIsAuthed] = useState(undefined);

    const [geoJson, setGeoJson] = useState<any>(undefined);
    const [baseLat, setBaseLat] = useState<number>(40.6461);
    const [baseLong, setBaseLong] = useState<number>(-111.498);
    const [baseZoom, setBaseZoom] = useState<number>(4);
    const [blurredMap, setBlurredMap] = useState<boolean>(undefined);
    const [flyTo, setFlyTo] = useState<boolean>(false);
    const [flyToCoords, setFlyToCoords] = useState<number[]>(undefined);

    const [isSubscribed, setIsSubscribed] = React.useState(undefined);
    const [subscriptionLoading, setSubscriptionLoading] = React.useState(true);

    const isNative = Capacitor.isNativePlatform();

    const query = new URLSearchParams(location.search);

    const getIsSubscribed = async () => {
        setSubscriptionLoading(true);
        const auth_id = await PreferencesService.getUserUid();
        if (auth_id) {
            try {
                const user: User = await UserService.getUserByAuthId(auth_id);
                const isSubscribedRes: boolean = await BiskService.getIsSubscribedById(user.id);
                setIsSubscribed(isSubscribedRes);
                setSubscriptionLoading(false);
                return isSubscribedRes;
            } catch (e) {
                setSubscriptionLoading(false);
                setIsSubscribed(false);
                return false;
            }
        } else {
            setSubscriptionLoading(false);
            setIsSubscribed(false);
            return false;
        }
    }

    const getAuthInformation = async (isSubscribedRes: boolean) => {
        const auth_id = await PreferencesService.getUserUid();
        if (auth_id) {
            setIsAuthed(true);
            const userType = await UserService.getUserType(auth_id);

            if (userType === UserType.USER) {
                const response: User = await UserService.getUserByAuthId(auth_id);

                if (response) {
                    const userTricks: UserTrick[] = await TricksService.getUserTricks(response.id);
                    const isUserGodRes = await UserService.getIsUserGod(auth_id);
                    const numberOfInteractions: number = await UserService.getUserInteractionsCount(response.id);
                    const isUserGod = isUserGodRes['result'];

                    // god users can see map at all times and if user is not a god, check how many tricks they have
                    if (isUserGod || userTricks.length > 0 || isSubscribedRes || numberOfInteractions) {
                        setBlurredMap(false);
                    } else {
                        setBlurredMap(true);
                    }
                } else {
                    setBlurredMap(true);
                }
            } else if (userType === UserType.BRAND) {
                // brands are allowed to see the map
                setBlurredMap(false);
            }
        } else {
            setIsAuthed(false);
            setBlurredMap(true);
        }
    }

    useEffect(() => {
        if (!isLoading) {
            getAuthInformation(isSubscribed);
        }
    }, [isAuthenticated, isLoading, history, isSubscribed]);

    const onUploadTrickClick = () => {
        history.push('/upload/u')
    }

    const getAllLocationTricks = async (isSubscribedState: boolean) => {
        const res: LocationTrick[] = await LocationService.getAllLocationTricks();

        const resGeoJsonHolder: any = {
            'type': 'FeatureCollection',
            'features': []
        }

        const resGeoJson: any[] = [];

        if (res) {
            const trick_ids = res.map((trick) => trick.trick_id);
            const user_tricks: UserTrickSearch[] = await UserService.getTricksFromIds(trick_ids);
            if (res) {
                for (const trickLocation of res) {
                    const trick = user_tricks.find((trick) => trick.trick_id === trickLocation.trick_id);
                    if (trick) {
                        let long, lat;

                        if (isSubscribedState) {
                            long = trickLocation.long;
                            lat = trickLocation.lat;

                            if (long === null && lat === null) {
                                long = trickLocation.google_long;
                                lat = trickLocation.google_lat;
                            }
                        } else {
                            long = trickLocation.google_long;
                            lat = trickLocation.google_lat;
                        }


                        const mapThumbUrl = trick.thumbnail.replace("d3btq01zzysv3d.cloudfront.net", "d2xrbrtg3kyq6g.cloudfront.net");

                        const newGeoJson = {
                            'type': 'Feature',
                            'properties': {
                                'iconSize': [10, 10],
                                'creation_date': trick.date,
                                'image': mapThumbUrl,
                                'trick': trick,
                                'id': trick.id,
                            },
                            'geometry': {
                                'type': 'Point',
                                'coordinates': [long, lat]
                            }
                        };
                        resGeoJson.push(newGeoJson);
                    }
                }
                resGeoJsonHolder['features'] = resGeoJson;
                setGeoJson(resGeoJsonHolder);
            } else {
                setGeoJson(resGeoJsonHolder);
            }
        } else {
            setGeoJson(resGeoJsonHolder);
        }
        setGeoJson(resGeoJsonHolder);
        window.prerenderReady = true;
    }

    const onTrickButtonClick = (trick_id: string, user_id: string) => {
        history.push('/clip/' + user_id + "/" + trick_id);
    }


    useEffect(() => {
        const processGeneratingMapLocations = async () => {
            console.log("getting all locations");
            const isSubscribedState = await getIsSubscribed();

            if (isSubscribedState != state.isSubscribed) {
                return;
            }

            await getAllLocationTricks(isSubscribedState);
        }

        if (!isLoading) {
            processGeneratingMapLocations();
        }
    }, [isAuthenticated, isLoading, state.isSubscribed]);

    useEffect(() => {
        if (query.has("lat") && query.has("long")) {
            setFlyTo(true);
            const lat = query.get("lat")
            const long = query.get("long")
            if (lat && long) {
                setFlyToCoords([parseFloat(long), parseFloat(lat)]);
            }
            query.delete('lat')
            query.delete('long')
            history.replace({
                search: query.toString()
            });
        } else {
            setFlyTo(false);
            setFlyToCoords([]);
        }
    }, [location.search]);

    return (
        <IonPage>
            <CustomHelmet title={"Ecliptic // Map"}
                          description={"Explore Ecliptic, Spots, and Skiers and Snowboarders Sending It Around The WorldWeb"}
                          image={"https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                          url={`https://ecliptic.day/map`}/>
            <ToolbarSearchBar></ToolbarSearchBar>
            <IonContent>
                {(isAuthed !== undefined) ?
                    <>
                        <WorldWeb lat={baseLat} long={baseLong} zoom={baseZoom} geojson={geoJson}
                                  onClick={onTrickButtonClick} blurred={blurredMap} flyTo={flyTo}
                                  flyToCoords={flyToCoords}
                                  isNative={isNative} isSubscribed={isSubscribed}/>
                        {isAuthed && blurredMap &&
                            <div className="z-100 fixed top-1/4 left-1/2 transform -translate-x-1/2"
                                 style={{zIndex: 100}}>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle className="text-center">
                                            "To map, you must interact with the Ecliptic Community" - Christopher
                                            Railbus.
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <div className="flex flex-col">
                                            <IonButton
                                                expand="block"
                                                size="large"
                                                color="favorite"
                                                onClick={onUploadTrickClick}
                                            >
                                                Upload Trick
                                            </IonButton>
                                            <IonButton
                                                expand="block"
                                                size="large"
                                                color="secondary"
                                                onClick={() => getAuthInformation(isSubscribed)}
                                            >
                                                Reload
                                            </IonButton>
                                        </div>
                                    </IonCardContent>
                                </IonCard>
                            </div>
                        }
                        {!isAuthed && blurredMap &&
                            <div className="z-100 fixed top-1/2 left-1/2 transform -translate-x-1/2"
                                 style={{zIndex: 100}}>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle className="text-center">
                                            "You underestimate my power!" - Anikin Skyrailer
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent className="flex flex-col">
                                        <LoginButton upload={false} profile={false}/>
                                        <IonButton
                                            expand="block"
                                            size="large"
                                            color="secondary"
                                            onClick={() => getAuthInformation(isSubscribed)}
                                        >
                                            Reload
                                        </IonButton>
                                    </IonCardContent>
                                </IonCard>
                            </div>
                        }
                    </>
                    :
                    <div className="flex h-screen justify-center">
                        <IonSpinner className="m-auto custom_spin" name="crescent" color="tertiary"/>
                    </div>
                }
            </IonContent>
        </IonPage>
    );
};

export default MapPage;