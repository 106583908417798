import {IonImg} from "@ionic/react";
import React from "react";
import {UserTrick} from "../../../models/user-trick";
import {format, parseISO} from "date-fns";

interface ProfileTrickCardProps {
    trick: UserTrick;
}

const ProfileTrickCard: React.FC<ProfileTrickCardProps> = ({trick}) => {

    const parseFilmDate = (date: string) => {
        return format(parseISO(date), 'MMM d, yyyy');
    }

    return (
        <div className="cursor-pointer p-0 m-0 border border-gray-900 bg-black rounded-md font-bold">
            <IonImg className="object-cover min-w-full min-h-full h-full aspect-video" src={trick?.thumbnail}/>
            <div className="bg-black p-4">
                <div className="text-base text-white">{trick.trick_name}</div>
                {trick.location && trick.location != "" ?
                    <div className="text-sm capitalize text-gray-400">{trick.location}</div> :
                    <div className="text-sm capitalize">{parseFilmDate(trick.date)}</div>}
            </div>
        </div>
    );
};

export default ProfileTrickCard;