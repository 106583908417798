import {IonCard, IonImg} from "@ionic/react";
import React from "react";
import {format, parseISO} from "date-fns";
import {UserTrickSearch} from "../../../models/user-trick-search";
import UsernameDisplay from "../../ComponentsUI/Username/UsernameDisplay";
import {AppContext} from "../../../AppStateProvider";

interface BrandEventInfoCardProps {
    trick: UserTrickSearch;
}

const BrandEventInfoCard: React.FC<BrandEventInfoCardProps> = ({trick}) => {
    const {state} = React.useContext(AppContext);

    const parseFilmDate = (date: string) => {
        return format(parseISO(date), 'MMM d, yyyy');
    }

    return (
        <IonCard className="cursor-pointer p-0 m-0 border border-gray-900 bg-black font-bold">
            <IonImg className="object-cover min-w-full min-h-full h-full aspect-video" src={trick?.thumbnail}/>
            <div className="bg-black p-4">
                <div className="text-base text-white">{trick.trick_name}</div>
                <div className="text-white">
                    <UsernameDisplay username={trick.username} className={"text-sm"} userId={trick.user_id}
                                     loggedInId={state.user?.id}></UsernameDisplay>
                </div>
                <div
                    className="text-sm">{trick.location && trick.location != "" ? trick.location : parseFilmDate(trick.date)}</div>
            </div>
        </IonCard>
    );
};

export default BrandEventInfoCard;