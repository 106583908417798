import {IonButton} from "@ionic/react";
import React, {useEffect} from "react";

interface ContainerProps {
    username: string,
    usernameCallback: (username: string) => void,
}

const ReturnToProfile: React.FC<ContainerProps> = ({username, usernameCallback}) => {
    const [usernameToReturnTo, setUsernameToReturnTo] = React.useState(username);

    useEffect(() => {
        setUsernameToReturnTo(username);
        console.log("usernameToReturnTo: ", usernameToReturnTo);
    }, [username]);

    return (
        <IonButton expand="block" color="dark" shape="round"
                   onClick={() => usernameCallback(usernameToReturnTo)}>{'Click to Return to Your Profile'}</IonButton>
    );
};

export default ReturnToProfile;