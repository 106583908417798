import React, {useEffect} from 'react';
import {IonIcon} from "@ionic/react";

interface ChipProps {
    text: any;
    bgColor?: string; // Tailwind class for background color, optional
    borderColor?: string; // Tailwind class for border color, optional
    imageUrl?: string; // Optional image URL
    onClickChip?: (tag: any) => void; // Optional click handler
    textColor?: string; // Tailwind class for text color, optional
    icon?: string; // Optional icon
}

const IDXChip: React.FC<ChipProps> = ({ text, bgColor= 'bg-black', borderColor= 'border-white', imageUrl, onClickChip, textColor = 'text-white', icon }) => {

    useEffect(() => {

    }, [bgColor, borderColor]);

    return (
        <div className={`flex items-center gap-2 py-1 px-3 ${bgColor} border ${borderColor} rounded-full`} onClick={() => onClickChip && onClickChip(text)}>
            {imageUrl && <img src={imageUrl} alt="Icon" className="h-4 w-4" />}
            {icon && <IonIcon icon={icon} color="theme-secondary"/>}
            <span className={`text-sm ${textColor} whitespace-nowrap`}>{text}</span>
        </div>
    );
};

export default IDXChip;