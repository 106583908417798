import {IonButton, IonSpinner, isPlatform} from "@ionic/react";
import React, {useEffect, useState} from "react";

interface ContainerProps {
    onProfilePictureSubmit: (selectedFile: any) => void,
    currEventPhotoUrl?: string,
}

const PhotoUpload: React.FC<ContainerProps> = ({onProfilePictureSubmit, currEventPhotoUrl}) => {
    const [fileLoaded, setFileLoaded] = useState(false);
    const [preview, setPreview] = useState(undefined);
    const [selectedFile, setSelectedFile] = useState();

    const [profilePhotoUploading, setProfilePhotoUploading] = useState(false);

    useEffect(() => {
        if (currEventPhotoUrl) {
            setFileLoaded(true);
        }

        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile, currEventPhotoUrl]);

    const onSelectFile = async (event: any) => {
        console.log("Selected file: ", event);
        if (!event.target.files || event.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }
        setSelectedFile(event.target.files[0]);
        setFileLoaded(true);
        console.log("Selected file: ", event.target.files[0]);
        await onProfilePictureSubmit(event.target.files[0]);
    }

    // @ts-ignore
    return (!fileLoaded ?
            <>
                <div className="flex justify-center items-center w-full">
                    <label
                        className="flex flex-col justify-center items-center w-10/12 h-64 rounded-lg border-2 border-dashed cursor-pointer">
                        <div className="flex flex-col justify-center items-center pt-5 pb-6">
                            <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none"
                                 stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                            </svg>
                            <p className="mb-2 text-sm text-gray-500"><span
                                className="font-semibold">Click to upload</span>{isPlatform("mobile") || isPlatform("cordova") ? "" : " or drag and drop"}
                            </p>
                            {isPlatform("mobile") || isPlatform("cordova") ? "" :
                                <p className="text-xs text-gray-500">.JPG, .JPEG, .PNG, .GIF, .BMP</p>}
                        </div>
                        <input id="dropzone-file" type="file"
                               accept='image/jpg,image/jpeg,image/png,image/gif,image/bmp' className="hidden"
                               onChange={(e) => {
                                   console.log(typeof (e))
                                   onSelectFile(e)
                               }}/>
                    </label>
                </div>
            </> :
            <>
                {!profilePhotoUploading || currEventPhotoUrl ?
                    <>
                        <div className="flex justify-center items-center w-full mt-10">
                            <img className="flex flex-col justify-center items-center w-10/12"
                                 src={preview ? preview : (currEventPhotoUrl ? currEventPhotoUrl : "")}></img>
                        </div>
                        <div className="flex justify-center items-center mt-4">
                            <IonButton className="mr-4" onClick={() => {
                                setSelectedFile(undefined);
                                setPreview("");
                                setFileLoaded(false);
                            }}>
                                Pick A New Photo</IonButton>
                        </div>
                    </> :
                    <div className="items-center space-x-4">
                        <IonSpinner name="crescent" color="tertiary"/>
                    </div>
                }
            </>
    );
};

export default PhotoUpload;