import {Preferences} from "@capacitor/preferences";
import {SignUpData} from "../models/signUpData";
import {OnboardingStatus} from "../models/onboardingStatus";

const PreferencesService = {
    ONBOARDING_STATUS_KEY: 'ONBOARDING_STATUS',
    PUSH_NOTIFICATION_TOKEN_KEY: 'push_notification_token',
    ACCESS_TOKEN_KEY: 'access_token',
    ACCESS_TOKEN_FIREBASE_KEY: 'access_token_firebase',
    AUTH_ID_KEY: 'auth_id',
    MUTED_KEY: 'muted',
    SESSION_ID_KEY: 'session_id',
    USER_TYPE_KEY: 'user_type',
    LOAD_SUGGESTED_TRICKS_KEY: 'load_suggested_tricks',
    SUGGESTED_TRICKS_KEY: 'suggested_tricks',
    SUGGESTED_TRICKS_TIME_LOADED_KEY: 'suggested_tricks_time_loaded',
    FEED_SETTINGS_KEY: 'feed_settings',
    COMMUNITY_TAB_KEY: 'communityTab',
    EXPLORE_TAB_KEY: 'exploreTab',
    HOT_SETTINGS_KEY: 'hot_settings',
    SHOP_TAB_KEY: 'shopTab',
    RATING_PROMPT_TIME_KEY: 'rating_prompt_time',
    PUSH_NOTIFICATION_REDIRECT_URL_KEY: 'push_notification_redirect_url',
    DEEP_LINK_SLUG_KEY: 'deepLinkReferrer',
    REFERRER_KEY: 'referrer',
    INTENDED_DESTINATION_KEY: 'intended_destination',
    SIGNUP_DATA_KEY: 'signUpData',


    setFirebaseToken: async (token: string) => {
        await Preferences.set({key: PreferencesService.ACCESS_TOKEN_FIREBASE_KEY, value: token});
    },

    removeFirebaseToken: async () => {
        await Preferences.remove({key: PreferencesService.ACCESS_TOKEN_FIREBASE_KEY});
    },

    getFirebaseToken: async () => {
        const {value} = await Preferences.get({key: PreferencesService.ACCESS_TOKEN_FIREBASE_KEY});
        return value;
    },

    setUserUid: async (uid: string) => {
        await Preferences.set({
            key: PreferencesService.AUTH_ID_KEY,
            value: uid,
        });
    },

    removeUserUid: async () => {
        await Preferences.remove({key: PreferencesService.AUTH_ID_KEY});
    },

    getUserUid: async () => {
        const {value} = await Preferences.get({key: PreferencesService.AUTH_ID_KEY});
        return value;
    },

    setAccessToken: async (token: string) => {
        await Preferences.set({key: PreferencesService.ACCESS_TOKEN_KEY, value: token});
    },

    getAccessToken: async () => {
        const {value} = await Preferences.get({key: PreferencesService.ACCESS_TOKEN_KEY});
        return value;
    },

    removeAccessToken: async () => {
        await Preferences.remove({key: PreferencesService.ACCESS_TOKEN_KEY});
    },

    getPushNotificationToken: async () => {
        const {value} = await Preferences.get({key: PreferencesService.PUSH_NOTIFICATION_TOKEN_KEY});
        return value;
    },

    setPushNotificationToken: async (token: string) => {
        await Preferences.set({
            key: PreferencesService.PUSH_NOTIFICATION_TOKEN_KEY,
            value: token
        });
    },

    setOnboardingStatus: async (status: string) => {
        await Preferences.set({
            key: PreferencesService.ONBOARDING_STATUS_KEY,
            value: status
        })
    },

    getOnboardingStatus: async () => {
        const {value} = await Preferences.get({
            key: PreferencesService.ONBOARDING_STATUS_KEY
        });
        return value;
    },

    setMutedPreferences: async (value: string) => {
        await Preferences.set({key: PreferencesService.MUTED_KEY, value: value});
    },

    getMutedPreferences: async () => {
        const {value} = await Preferences.get({key: PreferencesService.MUTED_KEY})
        return value
    },

    setUserSessionId: async (sessionId: string) => {
        await Preferences.set({
            key: PreferencesService.SESSION_ID_KEY,
            value: sessionId,
        });
    },

    getUserSessionId: async () => {
        const {value} = await Preferences.get({key: PreferencesService.SESSION_ID_KEY});
        return value;
    },

    setUserType: async (userType: string) => {
        await Preferences.set({
            key: PreferencesService.USER_TYPE_KEY,
            value: userType,
        });
    },

    getUserType: async () => {
        const {value} = await Preferences.get({key: PreferencesService.USER_TYPE_KEY});
        return value;
    },

    clearPreferences: async () => {
        await Preferences.clear();
    },

    setLoadSuggestedTricks: async (value: string) => {
        await Preferences.set({
            key: PreferencesService.LOAD_SUGGESTED_TRICKS_KEY,
            value
        });
    },

    setSuggestedTricksTimeLoaded: async (value: string) => {
        await Preferences.set({
            key: PreferencesService.SUGGESTED_TRICKS_TIME_LOADED_KEY,
            value
        });
    },

    setSuggestedTricks: async (value: string) => {
        await Preferences.set({
            key: PreferencesService.SUGGESTED_TRICKS_KEY,
            value
        });
    },

    getLoadSuggestedTricks: async () => {
        const {value} = await Preferences.get({key: PreferencesService.LOAD_SUGGESTED_TRICKS_KEY});
        return value
    },

    getSuggestedTricksKey: async () => {
        const {value} = await Preferences.get({key: PreferencesService.SUGGESTED_TRICKS_KEY});
        return value
    },

    getSuggestedTricksTimeLoaded: async () => {
        const {value} = await Preferences.get({key: PreferencesService.SUGGESTED_TRICKS_TIME_LOADED_KEY});
        return value
    },

    setFeedSettings: async (value: string) => {
        await Preferences.set({
            key: PreferencesService.FEED_SETTINGS_KEY,
            value
        });
    },

    getFeedSettings: async () => {
        const {value} = await Preferences.get({key: PreferencesService.FEED_SETTINGS_KEY});
        return value
    },

    setCommunityTab: async (value: string) => {
        await Preferences.set({
            key: PreferencesService.COMMUNITY_TAB_KEY,
            value
        });
    },

    getCommunityTab: async () => {
        const {value} = await Preferences.get({key: PreferencesService.COMMUNITY_TAB_KEY});
        return value
    },

    setExploreTab: async (value: string) => {
        await Preferences.set({
            key: PreferencesService.EXPLORE_TAB_KEY,
            value
        });
    },

    getExploreTab: async () => {
        const {value} = await Preferences.get({key: PreferencesService.EXPLORE_TAB_KEY});
        return value
    },

    setHotSettings: async (value: string) => {
        await Preferences.set({
            key: PreferencesService.HOT_SETTINGS_KEY,
            value
        });
    },

    getHotSettings: async () => {
        const {value} = await Preferences.get({key: PreferencesService.HOT_SETTINGS_KEY});
        return value
    },

    setShopTab: async (value: string) => {
        await Preferences.set({
            key: PreferencesService.SHOP_TAB_KEY,
            value
        });
    },

    getShopTab: async () => {
        const {value} = await Preferences.get({key: PreferencesService.SHOP_TAB_KEY});
        return value
    },

    setRatingPromptTime: async (value: string) => {
        await Preferences.set({
            key: PreferencesService.RATING_PROMPT_TIME_KEY,
            value
        });
    },

    getRatingPromptTime: async () => {
        const {value} = await Preferences.get({key: PreferencesService.RATING_PROMPT_TIME_KEY});
        return value
    },

    getPushNotificationRedirectUrl: async () => {
        const {value} = await Preferences.get({key: PreferencesService.PUSH_NOTIFICATION_REDIRECT_URL_KEY});
        return value;
    },

    removePushNotificationRedirectUrl: async () => {
        await Preferences.remove({key: PreferencesService.PUSH_NOTIFICATION_REDIRECT_URL_KEY});
    },

    setPushNotificationRedirectUrl: async (url: string) => {
        await Preferences.set({
            key: PreferencesService.PUSH_NOTIFICATION_REDIRECT_URL_KEY,
            value: url
        });
    },

    setDeepLinkSlug: async (slug: string) => {
        await Preferences.set({
            key: PreferencesService.DEEP_LINK_SLUG_KEY,
            value: slug
        });
    },

    getDeepLinkSlug: async () => {
        const {value} = await Preferences.get({key: PreferencesService.DEEP_LINK_SLUG_KEY});
        return value;
    },

    removeDeepLinkSlug: async () => {
        await Preferences.remove({key: PreferencesService.DEEP_LINK_SLUG_KEY});
    },

    setReferrer: async (referrer: string) => {
        await Preferences.set({
            key: PreferencesService.REFERRER_KEY,
            value: referrer
        });
    },

    getReferrer: async () => {
        const {value} = await Preferences.get({key: PreferencesService.REFERRER_KEY});
        return value;
    },

    removeReferrer: async () => {
        await Preferences.remove({key: PreferencesService.REFERRER_KEY});
    },

    setIntendedDestination: async (destination: string) => {
        await Preferences.set({
            key: PreferencesService.INTENDED_DESTINATION_KEY,
            value: destination
        });
    },

    getIntendedDestination: async () => {
        const {value} = await Preferences.get({key: PreferencesService.INTENDED_DESTINATION_KEY});
        return value;
    },

    saveSignUpData: async (data: SignUpData) => {
        await Preferences.set({
            key: PreferencesService.SIGNUP_DATA_KEY,
            value: JSON.stringify(data)
        });
    },

    loadSignUpData: async (): Promise<SignUpData | null> => {
        const {value} = await Preferences.get({key: PreferencesService.SIGNUP_DATA_KEY});
        return value ? JSON.parse(value) : null;
    },

    clearSignUpData: async () => {
        await Preferences.remove({key: PreferencesService.SIGNUP_DATA_KEY});
        await PreferencesService.setOnboardingStatus(OnboardingStatus.COMPLETED);
    },
};

export default PreferencesService;