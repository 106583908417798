export enum OnboardingStatus {
    INIT = "INIT", // GOOD
    IN_PROGRESS_LOGIN_INIT = "IN_PROGRESS_LOGIN_INIT", // GOOD
    IN_PROGRESS_LOGIN = "IN_PROGRESS_LOGIN", // GOOD
    IN_PROGRESS_LOGIN_USERNAME = "IN_PROGRESS_LOGIN_USERNAME", // GOOD
    IN_PROGRESS_SIGNUP_INIT = "IN_PROGRESS_SIGNUP_INIT", // flow/signup INITIAL POINT
    IN_PROGRESS_SIGNUP_BIRTHDAY = "IN_PROGRESS_SIGNUP_BIRTHDAY", // BIRTHDAY
    IN_PROGRESS_SIGNUP_HUMAN_NAME = "IN_PROGRESS_SIGNUP_HUMAN_NAME", // HUMAN NAME
    IN_PROGRESS_SIGNUP_NAME = "IN_PROGRESS_SIGNUP_NAME", // USERNAME
    IN_PROGRESS_SIGNUP_SPORTS = "IN_PROGRESS_SIGNUP_SPORTS", // SPORTS
    IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN = "IN_PROGRESS_SIGNUP_SPORTS_SKILL_TERRAIN", // SPORTS
    IN_PROGRESS_SIGNUP_LOCATION_PROMPT = "IN_PROGRESS_SIGNUP_LOCATION_PROMPT", // LOCATION PERMS
    IN_PROGRESS_SIGNUP_LOCATION_SEARCH = "IN_PROGRESS_SIGNUP_LOCATION_SEARCH", // LOCATION SELECT
    IN_PROGRESS_SIGNUP_PHONE_NUMBER = "IN_PROGRESS_SIGNUP_PHONE_NUMBER", // PHONE NUMBER
    IN_PROGRESS_SIGNUP_AUTH = "IN_PROGRESS_SIGNUP_AUTH", //  SIGN UP - CREATE AN ACCOUNT
    IN_PROGRESS_SIGNUP_USERNAME = "IN_PROGRESS_SIGNUP_AUTH_USERNAME", // SIGN UP - CREATE AN ACCOUNT WITH EMAIL
    COMPLETED = "COMPLETED",
    SIGNUP = "SIGNUP",
    LOGIN = "LOGIN"
}