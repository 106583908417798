import {IonSearchbar,} from "@ionic/react";
import React from "react";
import {AppContext} from "../../../AppStateProvider";
import UsernameDisplay from "../Username/UsernameDisplay";
import {LikeView} from "../../../models/likeView";

interface ContainerProps {
    trickLikes: LikeView[]
    onClickResult: (username: string) => void,
    showBack?: boolean,
    handleBackClick?: () => void
}

const ViewLikesContent: React.FC<ContainerProps> = ({trickLikes, onClickResult, showBack = false, handleBackClick}) => {
    const {state} = React.useContext(AppContext);
    const [trickLikesSearchValue, setTrickLikesSearchValue] = React.useState<string>("");

    //return the new array of user profiles
    return (
        <div>
            {
                trickLikes && trickLikes.length > 0 ?
                    <>

                        <IonSearchbar placeholder="Search"
                                      onIonInput={(e) => setTrickLikesSearchValue(e.detail.value)}
                                      showClearButton="focus"
                                      showCancelButton="focus"
                                      debounce={0}
                                      animated={true}/>
                        {
                            trickLikes.filter(
                                (trickLikeView) => (trickLikeView.username.toLowerCase().includes(trickLikesSearchValue.toLowerCase()) || (trickLikeView.name && trickLikeView.name.toLowerCase().includes(trickLikesSearchValue.toLowerCase())))).map(
                                (trickLikeView: LikeView, i) => (
                                    <div
                                        className="cursor-pointer flex flex-row items-center gap-x-4 p-2"
                                        onClick={() => onClickResult(trickLikeView.username)}
                                        key={i}>
                                        <div className="ml-1">
                                            <img className="shrink w-12 h-12 rounded-full"
                                                 src={trickLikeView.profile_pic ? trickLikeView.profile_pic : '/assets/photos/defaultProPic.png'}></img>
                                        </div>
                                        <div className="flex flex-col">
                                            <UsernameDisplay username={trickLikeView.username}
                                                             className={"text-xl font-bold"}
                                                             userId={trickLikeView.action_user_id}
                                                             loggedInId={state.user?.id}></UsernameDisplay>
                                            <div className="text-sm font-bold">{trickLikeView.name}</div>
                                        </div>
                                    </div>
                                ))}
                    </>
                    : <div></div>
            }
        </div>
    );
};

export default ViewLikesContent;