import React, {useState} from "react";
import "./SportSelect.css";

interface ContainerProps {
    selectSport: (sport: string, resetFilters: boolean) => void,
    sport: string,
}

interface Sport {
    name: string,
    lowercase: string,
    transitions: string,
}

const sports : Sport[] = [
    {
        name: "Back",
        lowercase: "",
        transitions: "",
    },
    { 
        name: "Ski", // skiing
        lowercase: "ski",
        transitions: "btn-1",
    },
    {
        name: "Board", // snowboarding
        lowercase: "board",
        transitions: "btn-1",
    }
];

const SportSelect: React.FC<ContainerProps> = ({ selectSport, sport }) => {
    const [showSportOptions, setShowSportOptions] = useState(false);

    if (showSportOptions) {
        return (
            // maybe need to change the z-index of this div if it doesn't go over the vids
            <div className="z-100 m-2">
                {sports.map((sport: Sport, key: any) => 
                    <div 
                        key={key}
                        className={`w-[55px] h-[55px] rounded-full flex justify-center items-center circle-button text-xs text-black mb-2 ${sport.transitions}`}
                        onClick={() => {
                            if (sport.name === "Back") {
                                setShowSportOptions(false);
                            } else {
                                selectSport(sport.lowercase, true);
                            }
                        }}
                    >
                        {sport.name}
                    </div>
                )}
            </div>
        );
    }

    return (
        // maybe need to change the z-index of this div if it doesn't go over the vids
        <div className="z-100 m-2">
            <div className="w-[55px] h-[55px] rounded-full flex justify-center items-center circle-button text-xs text-black">
                <div onClick={() => setShowSportOptions(true)}>{sport ? sport : "Sport"}</div>
            </div>
        </div>
    );
};

export default SportSelect;
