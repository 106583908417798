import React, {useEffect, useRef, useState} from 'react';
import {PhoneNumber} from 'libphonenumber-js';
import OnboardingPageWrapper from "../OnboardingPageWrapper/OnboardingPageWrapper";
import {useSignUp} from "../SignUpProvider/SignUpProvider";
import {useAppState} from "../../../AppListenerProvider";
import {useAuth} from "../../../AuthProvider";
import AuthService from "../../../services/auth.service";
import {RecaptchaVerifier} from "firebase/auth";
import OTPInput from "../../ComponentsUI/OTPInput/OTPInput";
import PhoneInput from "../../ComponentsUI/PhoneInput/PhoneInput";

interface PhoneInputProps {
    onNext: () => void;
    onBack: () => void;
    onSkip: () => void;
    onSkipDelay: number;
    title: string;
    showBackButton: boolean;
    showSkipButton: boolean;
}

const PhoneNumberInput: React.FC<PhoneInputProps> = ({
                                                         onNext,
                                                         showSkipButton,
                                                         showBackButton,
                                                         title,
                                                         onBack,
                                                         onSkip,
                                                         onSkipDelay
                                                     }) => {
    const {signUpData, updateSignUpData} = useSignUp();
    const {isNative, platform, isActive} = useAppState();
    const {auth, user, isLoading, isAuthenticated, firebaseApp} = useAuth();
    const authService = AuthService({auth, user, isLoading, isAuthenticated, firebaseApp}, {
        isActive,
        isNative,
        platform
    })

    const [phoneNumberObj, setPhoneNumberObj] = useState<PhoneNumber | null>(null)

    const [error, setError] = useState<string | null>(null)
    const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null);
    const recaptchaContainerRef = useRef<HTMLDivElement | null>(null);

    const confirmationResultRef = useRef<any>(null);
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationId, setVerificationId] = useState('')
    const [isVerificationView, setIsVerificationView] = useState(false);

    const onBackPhoneVerifyHandler = () => {
        setIsVerificationView(false);
        if (!isNative) {
            clearRecaptcha();
            // Optionally, re-initialize reCAPTCHA for the phone input view
            initializeRecaptcha();
        }
    }

    useEffect(() => {
        if (!isNative) {
            initializeRecaptcha();
        }
        return () => {
            if (!isNative) {
                clearRecaptcha();
            }
        };
    }, [isNative]);

    const clearRecaptcha = () => {
        if (recaptchaVerifierRef.current) {
            recaptchaVerifierRef.current.clear();
            recaptchaVerifierRef.current = null;
        }
    };

    const initializeRecaptcha = () => {
        recaptchaVerifierRef.current = new RecaptchaVerifier(auth, recaptchaContainerRef.current, {
            size: 'invisible'
        });
    };

    const handlePhoneSubmit = async (phoneNumberObj: PhoneNumber) => {
        setPhoneNumberObj(phoneNumberObj);

        try {
            const result = await authService.signInWithPhoneNumberHandler(
                phoneNumberObj.number,
                !isNative ? recaptchaVerifierRef.current : undefined
            );

            if (!isNative) {
                confirmationResultRef.current = result.confirmationResult;
                setIsVerificationView(true);
            } else if (result.verificationCompleted) {
                // Verification was completed automatically
                await updateSignUpData({phoneNumber: phoneNumberObj, userId: result.user.uid});
                onNext();
            } else {
                // SMS sent, wait for user to enter code
                setVerificationId(result.verificationId);
                setIsVerificationView(true);
            }
        } catch (error) {
            console.error('Error starting phone verification:', error);
            setError('Failed to start phone verification. Please skip as we might be having technical difficulties :/');
        }
    };

    const handleVerificationSubmit = async (otp: string) => {
        setVerificationCode(otp);

        if (!otp) {
            setError('Please enter the verification code');
            return;
        }

        try {
            const user = await authService.confirmPhoneVerificationCode(
                otp,
                verificationId,
                !isNative ? confirmationResultRef.current : undefined
            );
            await updateSignUpData({phoneNumber: phoneNumberObj, userId: user.uid});
            onNext();
        } catch (error) {
            console.error('Error confirming verification code:', error);
            setError('Invalid verification code. Please try again. If you did not receive a code and entered everything correctly, please skip as we might be having technical difficulties :/');
        }
    };

    return (
        <OnboardingPageWrapper title={isVerificationView ? 'Verify your phone number' : title}
                               showBackButton={showBackButton}
                               showSkipButton={showSkipButton}
                               onBack={isVerificationView ? onBackPhoneVerifyHandler : onBack}
                               onSkip={onSkip}
                               onSkipDelay={onSkipDelay}>
            <div className="w-full max-w-md space-y-4 flex flex-col items-center font-bold">
                {
                    isVerificationView ?
                        <>
                            <OTPInput length={6}
                                      onComplete={handleVerificationSubmit}
                                      buttonClass={'neon-button-alt'}
                                      inputClass={"neon-text"}
                                      signUp={true}/>
                        </>
                        :
                        <PhoneInput signUp={true} onSubmit={handlePhoneSubmit} isActive={isActive} isNative={isNative}
                                    countryData={signUpData.countryData} location={signUpData.location}
                                    sizeClass={"text-2xl"}/>
                }
                {error && <div className="error text-highlight-danger text-center">{error}</div>}
                <div id="recaptcha-container"></div>
            </div>
        </OnboardingPageWrapper>
    );
};

export default PhoneNumberInput;