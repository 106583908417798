import React, {useEffect, useState} from 'react';
import {IonButton, IonIcon} from '@ionic/react';
import {locationOutline} from 'ionicons/icons';
import {useAppState} from "../../../AppListenerProvider";
import {Geolocation} from "@capacitor/geolocation";
import {Capacitor} from "@capacitor/core";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";
import OnboardingPageWrapper from "../OnboardingPageWrapper/OnboardingPageWrapper";
import {useSignUp} from "../SignUpProvider/SignUpProvider";

interface LocationPermissionsProps {
    onNext: () => void;
    title: string;
    showBackButton: boolean;
    showSkipButton: boolean;
    onBack?: () => void;
    onSkip?: () => void;
    onSkipDelay?: number;
}

const LocationPermissions: React.FC<LocationPermissionsProps> = ({
                                                                     onNext,
                                                                     title,
                                                                     showBackButton,
                                                                     showSkipButton,
                                                                     onBack,
                                                                     onSkip,
                                                                     onSkipDelay
                                                                 }) => {
    const {updateSignUpData} = useSignUp();
    const [permissionStatus, setPermissionStatus] = useState<'prompt' | 'denied' | 'granted' | 'prompt-with-rationale'>('prompt');
    const {isNative, isActive} = useAppState();
    const [text, setText] = useState<string>('');

    useEffect(() => {
        checkPermissionStatus();
    }, [isActive]);

    const handleSetPermissionText = (perm: string) => {
        if (perm === 'prompt-with-rationale') {
            setText('Please enable location services to continue.');
        } else if (perm === 'denied') {
            setText('Ecliptic works best when you enable location services to discover spots and connect with local riders, crews, brands, and events. You can enable location services in your phone\'s settings.');
        } else if (perm === 'granted') {
            setText('Location services enabled. Continue to discover spots and connect with local riders, crews, brands, and events.');
        } else {
            setText('Allow Ecliptic to access your location to discover spots and connect with local riders, crews, brands, and events.');
        }
    };

    const checkPermissionStatus = async () => {
        if (isNative) {
            const status = await Geolocation.checkPermissions();
            handleSetPermissionText(status.location);
            setPermissionStatus(status.location);
        } else {
            if (navigator.permissions) {
                navigator.permissions.query({name: 'geolocation'}).then((result) => {
                    handleSetPermissionText(result.state);
                    setPermissionStatus(result.state as 'prompt' | 'denied' | 'granted');
                });
            }
        }
    };

    const requestLocationPermission = async () => {
        try {
            if (isNative) {
                const result = await Geolocation.requestPermissions();
                handleSetPermissionText(result.location);
                setPermissionStatus(result.location);
                if (result.location === 'granted') {
                    const position = await Geolocation.getCurrentPosition();
                    console.log('Current position:', position);
                    await updateSignUpData({latitude: position.coords.latitude, longitude: position.coords.longitude});
                }
            } else {
                console.log("Requesting location permission");
                try {
                    navigator.geolocation.getCurrentPosition(
                        async function (position) {
                            handleSetPermissionText('granted');
                            setPermissionStatus('granted');
                            await updateSignUpData({
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            });
                        },
                        function (error) {
                            handleSetPermissionText('denied');
                            setPermissionStatus('denied');
                        }
                    );
                } catch (e) {
                    console.error(e);
                }
            }
        } catch (error) {
            console.error('Error requesting location permission:', error);
        }
    };

    const openSettings = async () => {
        if (isNative) {
            try {
                if (Capacitor.getPlatform() === 'ios') {
                    await NativeSettings.openIOS({
                        option: IOSSettings.App,
                    });
                } else if (Capacitor.getPlatform() === 'android') {
                    await NativeSettings.openAndroid({
                        option: AndroidSettings.ApplicationDetails,
                    });
                }
            } catch (error) {
                console.error('Error opening settings:', error);
            }
        }
    };

    return (
        <OnboardingPageWrapper
            title={title}
            showBackButton={showBackButton}
            showSkipButton={showSkipButton}
            onBack={onBack}
            onSkip={onSkip}
            onSkipDelay={onSkipDelay}>
            <div className="space-y-4 flex flex-col items-center font-bold">
                <IonIcon icon={locationOutline} className="text-electric-blue text-6xl"/>
                <div className="text-center text-primary-secondary w-full max-w-md">
                    {text}
                </div>
                {(permissionStatus === 'prompt' || permissionStatus === 'prompt-with-rationale') && (
                    <IonButton
                        expand="block"
                        className="neon-button-highlight w-full max-w-md"
                        onClick={requestLocationPermission}
                    >
                        Enable Location
                    </IonButton>
                )}
                {permissionStatus === 'denied' && isNative && (
                    <>
                        <IonButton
                            expand="block"
                            className="neon-button-highlight w-full max-w-md"
                            onClick={openSettings}
                        >
                            Open Settings
                        </IonButton>
                    </>
                )}
                {
                    permissionStatus === 'granted' && (
                        <IonButton
                            expand="block"
                            className={`${'neon-button-alt'} w-full max-w-md`}
                            onClick={onNext}
                        >
                            Continue
                        </IonButton>
                    )
                }
            </div>
        </OnboardingPageWrapper>
    );
};

export default LocationPermissions;
