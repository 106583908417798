import {ReactComponent as EclipticLogo} from '../../../assets/icons/system/ECLIPTIC_NEW_BLACK_PNG_NO_PADDING.svg';
import {motion} from 'framer-motion';

const Logo: React.FC = () => (
    <div className="flex items-center justify-center">
        <motion.div
            className="w-10/12"
            whileHover={{scale: 1.05}}
            transition={{type: "spring", stiffness: 300}}
        >
            <EclipticLogo className="w-full h-full object-contain fill-current text-white"/>
        </motion.div>
    </div>
);

export default Logo;