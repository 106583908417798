import {IonInput, IonItem, IonLabel, IonNote} from "@ionic/react";

interface ContainerProps {
    label: string;
    value: string;
    placeholder: string;
    disabled?: boolean;
    parentCallback: any;
    invalid?: boolean;
}

const IonInputWithLabelEditUsername: React.FC<ContainerProps> = (props: ContainerProps) => {
    return (
        <IonItem>
            <IonLabel position="stacked" color={props.invalid ? "danger" : ""}>{props.label}</IonLabel>
            <IonInput type="text" placeholder={props.placeholder} value={props.value} disabled={props.disabled}
                      onIonInput={e => props.parentCallback(e.detail.value)} color={props.invalid ? "danger" : ""}/>
            {props.invalid &&
                <IonNote color="danger" className="text-sm">Sorry, that username already taken, contains a space, or
                    contains characters other than these characters (A-Z, a-z, 0-9, -, _, ., @, $, %, or &)</IonNote>}
        </IonItem>
    );
};

export default IonInputWithLabelEditUsername;
