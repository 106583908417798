import React from "react";
import {motion} from 'framer-motion';
import Logo from "../Logo/Logo";
import Title from "../Title/Title";


interface WelcomeHeaderProps {
    text: string;
    animate?: boolean;
}

const WelcomeHeader: React.FC<WelcomeHeaderProps> = ({text, animate}) => {
    return (
        <motion.div
            className="w-full h-2/5 flex flex-col items-center justify-end pb-2 space-y-4"
            initial={animate ? {opacity: 0, y: -50} : {opacity: 1, y: 0}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.8}}
        >
            <Logo/>
            <Title text={text} animate={animate}/>
        </motion.div>
    );
};

export default WelcomeHeader;