import React, {useContext, useEffect} from "react";
import basicBitch from "../../../assets/icons/system/idXBasicBadge.svg";
import premiumHo from "../../../assets/icons/system/idXPremiumBadge.svg";
import {IonIcon} from "@ionic/react";
import {SubscriptionType} from "../../../models/subscriptionType";
import {AppContext} from "../../../AppStateProvider";
import BiskService from "../../../services/bisk.service";

interface ContainerProps {
    username: string,
    className: string,
    userId?: string,
    onUsernameClickHandler?: (username: string) => void,
    loggedInId?: string
    bypassState?: boolean
}

const UsernameDisplay: React.FC<ContainerProps> = ({
                                                       username,
                                                       className,
                                                       userId,
                                                       onUsernameClickHandler,
                                                       loggedInId,
                                                       bypassState = false
                                                   }) => {
    const {state} = useContext(AppContext);
    const [usernameDisplay, setUsernameDisplay] = React.useState<string>('');
    const [subscriptionLevel, setSubscriptionLevel] = React.useState<string>('');
    const [isSubscribed, setIsSubscribed] = React.useState<boolean>(false);
    const subscriptionArray = [SubscriptionType.Premium, SubscriptionType.Basic];

    const getUserSubscriptionLevelById = async (userId: string) => {
        const subscriptionLevel = await BiskService.getSubscriptionLevelById(userId);
        return subscriptionLevel;
    }

    const getUserIsSubscribedById = async (userId: string): Promise<boolean> => {
        const isSubscribed: boolean = await BiskService.getIsSubscribedById(userId);
        return isSubscribed;
    }

    const getUserSubscriptionLevelByUsername = async (username: string) => {
        const subscriptionLevel = await BiskService.getSubscriptionLevelByUsername(username);
        return subscriptionLevel;
    }

    const getUserIsSubscribedByUsername = async (userId: string): Promise<boolean> => {
        const isSubscribed: boolean = await BiskService.getIsSubscribedByUsername(username);
        return isSubscribed;
    }

    const onUsernameClick = () => {
        if (!onUsernameClickHandler) return;
        onUsernameClickHandler(username)
    }

    const checkSubscriptionLoggedInProfile = async () => {
        setIsSubscribed(state.isSubscribed);
        setSubscriptionLevel(state.subscriptionLevel);
    }

    const checkSubscriptionByUserId = async (userIdProps: string) => {
        // get from the state - check subscription on get/set User and edit subscription if they subscribe or unsubscribe
        const isUserSubscribed = await getUserIsSubscribedById(userIdProps);
        setIsSubscribed(isUserSubscribed)
        if (isUserSubscribed) {
            const subscriptionLevel = await getUserSubscriptionLevelById(userIdProps);
            setSubscriptionLevel(subscriptionLevel);
        }
    }

    const checkSubscriptionByUsername = async (username: string) => {
        // get from the state - check subscription on get/set User and edit subscription if they subscribe or unsubscribe
        const isUserSubscribed = await getUserIsSubscribedByUsername(username);
        setIsSubscribed(isUserSubscribed)
        if (isUserSubscribed) {
            const subscriptionLevel = await getUserSubscriptionLevelByUsername(username);
            setSubscriptionLevel(subscriptionLevel);
        }
    }

    useEffect(() => {

        if (username) {
            setUsernameDisplay(username);

            if (loggedInId && userId && loggedInId === userId && !bypassState) {
                checkSubscriptionLoggedInProfile();
            } else if (userId) {
                checkSubscriptionByUserId(userId);
            } else if (username) {
                checkSubscriptionByUsername(username);
            } else {
                setIsSubscribed(false);
                setSubscriptionLevel(SubscriptionType.None);
            }
        }
    }, [username, userId, loggedInId, state.isSubscribed, state.subscriptionLevel]);

    return (
        <div className={className + " flex flex-row items-center"} onClick={onUsernameClick}>
            {usernameDisplay}
            {isSubscribed && (
                <IonIcon
                    src={subscriptionLevel === SubscriptionType.Basic ? basicBitch : premiumHo}
                    className={"w-3.5 h-3.5 ml-2"}
                />
            )}
        </div>
    );
};

export default UsernameDisplay;