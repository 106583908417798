import {Filesystem, Directory, GetUriResult} from '@capacitor/filesystem';
import {base64FromBlob, base64FromPath, fileDirectories} from "./utils";
import axios from "axios";
import {Device} from "@capacitor/device";

const VideoCacheService = {
  clearAllCache: async () => {
    await Filesystem.rmdir({
      path: 'trick-videos',
      directory: Directory.Cache,
      recursive: true,
    })
  },

  getVideoCached: async (trickId: string) => {
    const deviceId = await Device.getId();
    const params = {
      trick_id: trickId,
      device_id: deviceId.identifier.toString()
    };

    return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/video_cached`, {params: params})
        .then((response) => {
          return response.data;
        }).catch((error) => {
          console.error(error);
          return null;
        });
  },

  setVideoCached: async (trickId: string, cached: boolean) => {
    const deviceId = await Device.getId();
    const params = {
      trick_id: trickId,
      device_id: deviceId.identifier.toString(),
      cached: cached
    };

    return await axios.post(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/video_cached`, null, {params: params})
        .then((response) => {
          return response.data;
        }).catch((error) => {
          console.error(error);
          return null;
        });
  },

  clearCache: async (hours: number, directories: string[], directory: Directory) => {
    for (const fileDirectory of directories) {
      await Filesystem.readdir({
        path: fileDirectory,
        directory: directory,
      }).then(async (filesToDelete) => {
        for (const file of filesToDelete.files) {
          if (file.type === 'file') {
            // for some reason ctime is a string, so need to convert
            let creationTime = file.ctime;
            if (typeof(creationTime) === 'string') {
              creationTime = parseInt(creationTime);
            }

            const fileDate = new Date(creationTime);
            const currentDate = new Date();

            // hours since creation
            const hoursSinceCreation = (currentDate.getTime() - fileDate.getTime()) / 36e5;
            console.log(hoursSinceCreation)

            // get file id
            const trickId = file.name.split(".")[0]

            // actual deleting of the file
            if (hoursSinceCreation > hours) {
              try {
                await Filesystem.deleteFile({
                  path: fileDirectory + file.name,
                  directory: Directory.Cache,
                });
              } catch (error) {
                console.error('Error deleting file', error);
              }
            }
          }
        }
      })
      .catch((e) => {
        console.log("Not deleting any files from: " + fileDirectory)
      });
    }
  },

  updateAndWriteToCache: async(objectId: string, fileExtension: string, data: string) => {
    await VideoCacheService.setVideoCached(objectId, false);

    try {
      const base64Data = await base64FromPath(data);
      await Filesystem.writeFile({
        data: base64Data,
        directory: Directory.Cache,
        path: 'trick-videos/' + objectId + '.' + fileExtension,
        recursive: true
      });
      await VideoCacheService.setVideoCached(objectId, true);
    } catch (error) {
      console.error('Error writing video to cache', error);
      await VideoCacheService.setVideoCached(objectId, false);
    }
  },

  getCachedFile: async (directory: string, fileTitle: string, fileLink: string, video: boolean) => {
    const fileExtension = fileLink.split('.').pop() === fileLink ?
      video ? 'mp4': '.jpg' : fileLink.split('.').pop();
    return await Filesystem.readFile({
      path: directory + fileTitle + '.' + fileExtension,
      directory: Directory.Cache
    })
    .then(async () => {
      return await Filesystem.getUri({
        path: directory + fileTitle + '.' + fileExtension,
        directory: Directory.Cache
      })
    })
    .catch(async () => {
      console.log("writing file: " + fileLink)
      const base64Data = await base64FromPath(fileLink);
      return await Filesystem.writeFile({
        data: base64Data,
        directory: Directory.Cache,
        path: directory + fileTitle + '.' + fileExtension,
        recursive: true
      });
    });
  }
};

export default VideoCacheService;