import {IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import React, {useEffect, useState} from "react";
import Bisk from "../Bisk/Bisk";
import BiskInput from "../BiskInput/BiskInput";

interface ContainerProps {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void,
    bisk: number,
    onBuy?: () => void,
    onSend?: (bisk: number, note: string) => void,
    trickName?: string,
    recipientUsername?: string,
}

const BiskSendModal: React.FC<ContainerProps> = ({onDismiss, onBuy, bisk, onSend, trickName, recipientUsername}) => {
    const [modalBisk, setModalBisk] = useState<number>(bisk);

    useEffect(() => {
        setModalBisk(bisk);
    }, [bisk]);

    const handleNavigateToShop = () => {
        onDismiss(null, 'cancel');
        onBuy();
    }

    const handleSendBisk = (bisk: number, note: string) => {
        onDismiss(null, 'cancel');
        onSend(bisk, note);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="flex flex-row">
                    <IonButtons slot="start" className="mr-8">
                        <IonButton strong={true} onClick={() => onDismiss(null, 'cancel')}>
                        </IonButton>
                    </IonButtons>
                    <IonTitle className="items-center text-center">Send Bisk to {recipientUsername}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton strong={true} onClick={() => onDismiss(null, 'cancel')}>
                            Exit
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent className="ion-padding">
                <Bisk onBuy={handleNavigateToShop}/>
                <BiskInput bisk={modalBisk} onBuy={handleNavigateToShop} onSend={handleSendBisk} trickName={trickName}
                           recipientUsername={recipientUsername}/>
            </IonContent>
        </IonPage>
    );
};

export default BiskSendModal;