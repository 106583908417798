import React, {useRef, useState} from "react";

interface ContainerProps {
    selectedOptions: any[],
    addTag: any,
    removeTag: any,
    placeholder?: string;
}

const MultiSelectHashtag: React.FC<ContainerProps> = ({selectedOptions, addTag, removeTag, placeholder}) => {
    const [userInput, setUserInput] = useState("");

    const closeDropdown = useRef(null);

    const addItem = (item: any) => {
        addTag(item);
        setUserInput("");
    }

    return (<div className="autcomplete-wrapper" ref={closeDropdown}>
        <div className="autcomplete">
            <div className="w-full flex flex-col items-center mx-auto">
                <div className="w-full">
                    <div className="flex flex-col items-center relative">
                        <div className="w-full">
                            <div
                                className="my-2 p-1 flex shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5">
                                <div className="flex flex-auto flex-wrap">
                                    {
                                        selectedOptions.map((tag: any, index: any) => {
                                            return (
                                                <div key={index}
                                                     className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-teal-700 bg-teal-100 border border-teal-300 ">
                                                    <div
                                                        className="text-xs font-normal leading-none max-w-full flex-initial">{tag}</div>
                                                    <div className="flex flex-auto flex-row-reverse">
                                                        <div onClick={() => removeTag(tag)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="100%"
                                                                 height="100%" fill="none" viewBox="0 0 24 24"
                                                                 stroke="currentColor" strokeWidth="2"
                                                                 strokeLinecap="round" strokeLinejoin="round"
                                                                 className="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-4 h-4 ml-2">
                                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>)
                                        })
                                    }
                                    <div className="flex-1">
                                        <input
                                            value={userInput}
                                            placeholder={placeholder ? placeholder : "See our trick filtering options in the search for proper tags"}
                                            className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full ion-text-area-upload-clip"
                                            onChange={(e) => {
                                                const input = e.target.value;
                                                if (!input.replace(/\s/g, '').length) {
                                                    setUserInput('');
                                                    return;
                                                }
                                                const hashTagPosition = input.indexOf('#');
                                                if (hashTagPosition === 0) {
                                                    setUserInput(input);
                                                } else if (hashTagPosition === -1 && input.length > 0) {
                                                    setUserInput(`#${input}`);
                                                } else if (input.length > 0) {
                                                    setUserInput(`#${input.replace('#', '')}`);
                                                }
                                            }}
                                            onKeyDown={(e) => {
                                                if ((e.key === ' ' || e.key === 'Spacebar' || e.key === 'Enter') && userInput) {
                                                    addItem(userInput);
                                                } else if ((e.key === 'Delete' || e.key === 'Backspace') && userInput === '#') {
                                                    setUserInput('');
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>)
};

export default MultiSelectHashtag;
