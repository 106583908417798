import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {FirebaseAuthentication, User as NativeUser} from '@capacitor-firebase/authentication';
import {FirebaseApp, getApp, getApps, initializeApp} from "@firebase/app";
import {Capacitor} from "@capacitor/core";
import {
    Auth,
    browserLocalPersistence,
    getAuth,
    indexedDBLocalPersistence,
    initializeAuth,
    setPersistence,
    User as WebUser,
} from 'firebase/auth';
import PreferencesService from "./services/preferences.service";
import {AuthContextProps} from "./models/authContextProps";

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
    firebaseConfig: {
        apiKey: string;
        authDomain: string;
        projectId: string;
        storageBucket: string;
        messagingSenderId: string;
        appId: string;
        measurementId: string;
    };
}

const isNative = Capacitor.isNativePlatform();

const getFirebaseAuth = async (firebaseConfigParams: AuthProviderProps['firebaseConfig']): Promise<Auth> => {
    let app: FirebaseApp;

    // Check if the Firebase app is already initialized
    if (!getApps().length) {
        app = initializeApp(firebaseConfigParams);
    } else {
        app = getApp();
    }


    if (isNative) {
        return initializeAuth(app, {
            persistence: indexedDBLocalPersistence,
        });
    } else {
        const auth = getAuth(app);
        await setPersistence(auth, browserLocalPersistence);
        return auth;
    }
};

export const AuthProvider: React.FC<AuthProviderProps> = ({children, firebaseConfig}) => {
    const [firebaseApp, setFirebaseApp] = useState<FirebaseApp | null>(null);
    const [auth, setAuth] = useState<Auth | null>(null);
    const [user, setUser] = useState<NativeUser | WebUser | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        const initializeFirebaseAuth = async () => {
            console.log("Firebase config: ", firebaseConfig);
            const authInstance = await getFirebaseAuth(firebaseConfig);
            setAuth(authInstance);
            setFirebaseApp(authInstance.app);

            const removeListeners = await addListeners(authInstance);

            return () => {
                removeListeners();
            };
        };

        if (firebaseConfig.apiKey) {
            initializeFirebaseAuth();
        }
    }, [firebaseConfig]);


    useEffect(() => {
        const initializeFirebaseAuth = async () => {
            console.log("Firebase config: ", firebaseConfig);
            const authInstance = await getFirebaseAuth(firebaseConfig);
            setAuth(authInstance);
            setFirebaseApp(authInstance.app);

            const removeListeners = await addListeners(authInstance);

            return () => {
                removeListeners();
            };
        };

        if (firebaseConfig.apiKey) {
            initializeFirebaseAuth();
        }
    }, [firebaseConfig]);

    const addListeners = async (authInstance: Auth) => {
        const removeWebListener = authInstance.onAuthStateChanged(async (user) => {
            console.log("ANALYZING USER/AUTH/LOADING FROM WEB LISTENER");
            if (user) {
                console.log("SETTING USER AUTH FROM WEB LISTENER TO AUTHENTICATED");
                setUser(user);
                await PreferencesService.setUserUid(user.uid);
                setIsAuthenticated(true);
                setIsLoading(false);
            } else {
                console.log("SETTING UNAUTHENTICATED FROM WEB LISTENER TO NOT AUTHENTICATED");
                setUser(undefined);
                setIsAuthenticated(false);
                setIsLoading(false);
                await PreferencesService.removeUserUid();
            }
        });

        let removeNativeListener: (() => void) | null = null;

        if (isNative) {
            const authStateChangeNative = await FirebaseAuthentication.addListener("authStateChange", async ({user}) => {
                console.log("ANALYZING USER/AUTH/LOADING FROM NATIVE LISTENER");
                if (user) {
                    console.log("SETTING USER AUTH FROM NATIVE LISTENER TO AUTHENTICATED");
                    console.log("USER: ", user);
                    setUser(user);
                    await PreferencesService.setUserUid(user.uid);
                    setIsAuthenticated(true);
                    setIsLoading(false);
                } else {
                    console.log("SETTING USER AUTH FROM NATIVE LISTENER TO NOT AUTHENTICATED");
                    setUser(undefined);
                    setIsAuthenticated(false);
                    setIsLoading(false);
                    await PreferencesService.removeUserUid();
                }
            });

            if (authStateChangeNative && typeof authStateChangeNative.remove === 'function') {
                removeNativeListener = () => authStateChangeNative.remove();
            }
        }

        return () => {
            removeWebListener();
            if (removeNativeListener) {
                removeNativeListener();
            }
        };
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                isLoading,
                isAuthenticated,
                firebaseApp,
                auth
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};