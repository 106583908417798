import {IonButton, useIonToast} from "@ionic/react";
import React, {useEffect} from "react";
import {AppContext} from "../../../AppStateProvider";
import UserService from "../../../services/user.service";

interface ContainerProps {
    name: string;
    display: boolean;
    id: string;
    isNative: boolean;
}

const RestorePurchasesButton: React.FC<ContainerProps> = (props: ContainerProps) => {
    const {dispatch} = React.useContext(AppContext);
    const [presentToast] = useIonToast();

    useEffect(() => {
    }, [props.isNative, props.id]);

    const restorePurchases = async () => {
        await UserService.setUser(true, dispatch);
        await presentToast({
            message: "Restored Purchases!",
            duration: 4000,
            color: "success",
            buttons: [
                {
                    text: 'Close',
                    role: 'cancel',
                    handler: () => {
                        console.log('Cancel clicked');
                    }
                }
            ]
        });
    }

    return (props.display && props.isNative ?
            <div className="w-6/12">
                <IonButton
                    color="favorite"
                    shape="round"
                    expand="full"
                    onClick={restorePurchases}>
                    Restore Purchases
                </IonButton>
            </div> : <div></div>
    );
};

export default RestorePurchasesButton;
