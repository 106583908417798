import React, {useCallback, useEffect, useState} from "react";
import {useInView} from "react-intersection-observer";
import ReactPlayer from "react-player/lazy";


interface ContainerProps {
    options: any;
    isCapacitor: boolean;
    threshold: number;
    isHorizontal: boolean;
    isExternal: boolean;
    isYoutube: boolean;
    isVideoRestricted: boolean;
    isScrolling: boolean;
    scrollSpeed: number;
    aspectRatio: number;
}

//video player takes in video options and onReady function
const AutoVideoPlayer: React.FC<ContainerProps> = (props) => {
    const [threshold, setThreshold] = React.useState(undefined);
    const [playerWidth, setPlayerWidth] = useState("100%");
    const [isVideoReady, setIsVideoReady] = useState(false);
    const [viewControls, setViewControls] = useState(true);
    const [muted, setMuted] = useState(true);
    const [myStyle, setMyStyle] = useState<React.CSSProperties>({pointerEvents: 'none'});
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [ref, inView] = useInView({
        threshold: threshold,
    });
    const [playerKey, setPlayerKey] = useState(0);

    const playingScrollSpeedMax = 500;

    const setVideoReady = useCallback((player: ReactPlayer) => {
        setIsVideoReady(true);
    }, []);

    const handleError = (e: any) => {
        console.log("Error: " + e);
        if (playerKey > 2) {
            console.log("Not doing shit :/")
        } else {
            setPlayerKey((playerKey) => playerKey + 1); // Increment key to force re-render
        }
    }

    useEffect(() => {
        if (props.options && props.threshold !== undefined && props.isCapacitor !== undefined && props.isHorizontal !== undefined) {
            setThreshold(props.threshold);
            if (props.isCapacitor) {
                setViewControls(false);
            }
        }
    }, [props.options, props.threshold, props.isHorizontal, isVideoReady, props.isCapacitor])

    useEffect(() => {
        if (props.isCapacitor) {
            setMyStyle({pointerEvents: 'all'});
        }
    }, [props.isCapacitor]);

    useEffect(() => {
        if (inView && isVideoReady && (!props.isScrolling || props.scrollSpeed < playingScrollSpeedMax)) {
            setIsPlaying(true);
            setMuted(true);
        } else {
            setIsPlaying(false);
            setMuted(true);
        }
    }, [inView, isVideoReady, props.isScrolling]);


    const updatePlayerWidth = () => {
        // This function will calculate the width of the player based on the aspect ratio
        // For the sake of this example, we're using a simple condition
        // In a real scenario, you might want to calculate based on the actual aspect ratio
        const width = props.isHorizontal || props.isExternal ? "100%" : getWidthForVerticalVideo(); // Change "70%" to whatever width you need for vertical videos
        setPlayerWidth(width);
    };

    useEffect(() => {
        updatePlayerWidth();
        // Optional: Adjust width on window resize
        window.addEventListener("resize", updatePlayerWidth);
        // Cleanup
        return () => {
            window.removeEventListener("resize", updatePlayerWidth);
        };
    }, [props.isHorizontal, props.isExternal]);


    const getWidthForVerticalVideo = () => {
        if (document.documentElement.clientWidth < 400) {
            return "65%";
        }

        if (document.documentElement.clientWidth < 640) {
            return "50%"; // good
        }

        if (document.documentElement.clientWidth < 768) {
            return "55%"; // good
        }

        if (document.documentElement.clientWidth < 1024) {
            return "50%"; // good
        }

        if (document.documentElement.clientWidth < 1280) {
            return "45%";
        }

        if (document.documentElement.clientWidth < 1536) {
            return "40%";
        }

        if (document.documentElement.clientWidth >= 1536) {
            return "40%";
        }

        return "70%"
    }

    const setViewControlsHandler = async () => {
        setViewControls(true);
        setMuted(false);
        setMyStyle({pointerEvents: 'all'});
    }

    if (!props.options || props.threshold === undefined || props.isCapacitor === undefined || props.isHorizontal === undefined) {
        return null; // return null if any required props are not defined
    }

    return (
        <div className="flex flex-col items-center" ref={ref} onClick={setViewControlsHandler}>
            <ReactPlayer
                key={playerKey}
                url={props.options?.sources}
                loop={props.options?.loop}
                volume={1}
                controls={viewControls}
                width={playerWidth}
                config={{
                    file: {
                        attributes: {
                            onContextMenu: (e: MouseEvent) => e.preventDefault(),
                            controlsList: 'nodownload',
                            poster: props.options?.poster,
                        },
                    }
                }}
                style={myStyle}
                playsinline={true}
                playing={isPlaying}
                muted={muted}
                height={!props.isYoutube ? "100%" : "360px"}
                onReady={setVideoReady}
                pip={false}
                stopOnUnmount={true}
                onError={handleError}
            />
        </div>
    )
};

export default AutoVideoPlayer;
