import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonHeader,
    IonIcon,
    IonicSafeString,
    IonLabel,
    IonPage,
    IonSearchbar,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
    useIonAlert
} from "@ionic/react";
import {checkmarkCircleOutline, createOutline} from "ionicons/icons";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {ReactSortable} from "react-sortablejs";
import {Badge} from "../../../models/badges";
import UserService from "../../../services/user.service";
import {getDateNowUTCISO} from "../../../services/utils";
import SuggestedTrick from "../SuggestedTrick/SuggestedTrick";
import PreferencesService from "../../../services/preferences.service";
import {LoadSuggestedTricksStatus} from "../../../models/loadSuggestedTricksStatus";

interface ContainerProps {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void,
    badges: Badge[],
    caseTitle: string,
    sports: string[],
    editProfile: boolean,
    userProfileId: string,
    isNative: boolean,
    username: string
    setBadgeOrder: (badges: Badge[]) => void,
    onClickTrickHandler: (trick: string) => void,
    shopRedirect?: () => void
}

const CaseModal: React.FC<ContainerProps> = ({
                                                 onDismiss,
                                                 badges,
                                                 caseTitle,
                                                 sports,
                                                 editProfile,
                                                 userProfileId,
                                                 isNative,
                                                 setBadgeOrder,
                                                 username,
                                                 onClickTrickHandler,
                                                 shopRedirect
                                             }) => {
    const [segmentValue, setSegmentValue] = React.useState("pins");
    const [badgeFilter, setBadgeFilter] = React.useState<string>("");

    const numberOfSuggestedTricks = 4;

    const [displaySuggestedTricks, setDisplaySuggestedTricks] = useState([]);
    const [displaySuggestedTricksNumber, setDisplaySuggestedTricksNumber] = useState(numberOfSuggestedTricks / 2);
    const [maxSuggestedTricksSelected, setMaxSuggestedTricksSelected] = useState(false);
    const [suggestedTricks, setSuggestedTricks] = useState([]);

    const [editSortingUserPins, setEditSortingUserPins] = useState(false);

    const history = useHistory();

    const getSuggestedTricksHelper = async (userId: string, isYourProfile: boolean) => {
        revertSuggestedTricks(suggestedTricks);
        if (isYourProfile) {
            const suggestedTricksLoad = await PreferencesService.getLoadSuggestedTricks();
            const suggestedTricksPreferences = await PreferencesService.getSuggestedTricksKey();
            const suggestedTricksTimeLoaded = await PreferencesService.getSuggestedTricksTimeLoaded();

            let loadSuggestedTricks = false;
            if (!suggestedTricksLoad) {
                console.log("The values isn't in here, loading tricks")
                loadSuggestedTricks = true;
            }

            if (Number(suggestedTricksLoad)) {
                loadSuggestedTricks = true;
            }

            const oldTime = new Date(suggestedTricksTimeLoaded);
            const newTime = new Date();

            if (newTime.getTime() - oldTime.getTime() >= 86400000) {
                loadSuggestedTricks = true;
            }

            if (loadSuggestedTricks) {
                const suggestedTricks: string[] = await UserService.getUserSuggestedTricks(userId, numberOfSuggestedTricks);
                if (suggestedTricks) {
                    await PreferencesService.setLoadSuggestedTricks(LoadSuggestedTricksStatus.ZERO);
                    await PreferencesService.setSuggestedTricksTimeLoaded(getDateNowUTCISO());
                    await PreferencesService.setSuggestedTricks(suggestedTricks.join(","));

                    revertSuggestedTricks(suggestedTricks);
                }
            } else {
                const suggestedTricks = suggestedTricksPreferences ? suggestedTricksPreferences.split(",") : []
                revertSuggestedTricks(suggestedTricks);
            }
        } else {
            const suggestedTricks: string[] = await UserService.getUserSuggestedTricks(userId, numberOfSuggestedTricks);
            if (suggestedTricks) {
                revertSuggestedTricks(suggestedTricks);
            }
        }
    }

    const [presentAlert] = useIonAlert();

    useEffect(() => {
        getSuggestedTricksHelper(userProfileId, editProfile);
    }, [editProfile, userProfileId]);

    const handleSegmentChange = (segment: CustomEvent) => {
        console.log("Segment Changed: " + segment.detail.value);
        setSegmentValue(segment.detail.value);
        setBadgeFilter("");
    }

    const revertSuggestedTricks = (suggestedTricks: string[]) => {
        setDisplaySuggestedTricksNumber(numberOfSuggestedTricks / 2);
        setMaxSuggestedTricksSelected(false);
        setSuggestedTricks(suggestedTricks);
        setDisplaySuggestedTricks(suggestedTricks.slice(0, numberOfSuggestedTricks / 2));
    }

    const onLoadMoreSuggestedTricks = async (numberOfPreviousTricks: number) => {
        setDisplaySuggestedTricks(suggestedTricks.slice(0, numberOfPreviousTricks + displaySuggestedTricksNumber));
        setDisplaySuggestedTricksNumber(numberOfPreviousTricks + displaySuggestedTricksNumber);
        if (numberOfPreviousTricks + displaySuggestedTricksNumber >= numberOfSuggestedTricks) {
            setMaxSuggestedTricksSelected(true);
        }
    }

    const handleSortingPins = (badgesSorted: Badge[]) => {
        console.log("handling sorting of pins");
        if (editProfile) {
            setBadgeOrder(badgesSorted);
        }
    }

    const onClickTrick = (trick: string) => {
        onClickTrickHandler(trick);
        onDismiss();
    }

    const handleShopRedirect = () => {
        shopRedirect();
        onDismiss();
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="flex flex-row">
                    <IonButtons slot="start" className="mr-14">
                        <IonButton strong={true} onClick={() => onDismiss(null, 'cancel')}>
                        </IonButton>
                    </IonButtons>
                    <IonTitle className="items-center text-center">{caseTitle}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton strong={true} onClick={() => onDismiss(null, 'cancel')}>
                            Exit
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <div>
                <IonSegment>
                    <IonSegment color="dark" mode="md" value={segmentValue}
                                onIonChange={(ev) => handleSegmentChange(ev)}>
                        <IonSegmentButton value="pins">
                            <IonLabel>Pins</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="sugTricks">
                            <IonLabel>Suggestions</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonSegment>
            </div>
            <IonContent className="ion-padding">
                {segmentValue === "pins" ?
                    <div>
                        {badges.length > 0 ?
                            <>
                                <div className="flex flex-row justify-between items-center">
                                    <div className="flex-1">
                                        <IonSearchbar onIonInput={(e) => setBadgeFilter(e.detail.value)}
                                                      showClearButton="focus"
                                                      showCancelButton="focus"
                                                      style={{paddingTop: 0, paddingBottom: 0}}
                                                      id="searchText"
                                                      debounce={0}
                                                      animated={true}></IonSearchbar>
                                    </div>
                                    <div>
                                        {isNative && editProfile && badges.length > 0 ?
                                            <IonButton fill="outline"
                                                       color={!editSortingUserPins ? "favorite-secondary" : "success"}
                                                       size="small"
                                                       onClick={() => setEditSortingUserPins(!editSortingUserPins)}>
                                                <IonIcon slot="icon-only" color="dark"
                                                         icon={!editSortingUserPins ? createOutline : checkmarkCircleOutline}/>
                                            </IonButton>
                                            : null
                                        }
                                    </div>
                                </div>
                                <div>
                                    <ReactSortable
                                        disabled={isNative ? (!editSortingUserPins || badgeFilter.length > 0 || !editProfile) : (badgeFilter.length > 0 || !editProfile)}
                                        list={badges} setList={(newState, sortable, store) => {
                                        if (store.dragging && store.dragging.props && JSON.stringify(store.dragging.props.list) !== JSON.stringify(newState)) {
                                            handleSortingPins(newState)
                                        }
                                    }}
                                        className="grid grid-flow-row auto-row-max xs:grid-cols-2 sm:grid-cols-3 mt-4 gap-4 justify-center"
                                        delay={100} delayOnTouchOnly={isNative} sort={true}>
                                        {badges.filter(
                                            (badge) =>
                                                (
                                                    badge.name.toLowerCase().includes(badgeFilter.toLowerCase()) ||
                                                    badge.description.toLowerCase().includes(badgeFilter.toLowerCase())))
                                            .map((entry, key) => {
                                                return (
                                                    <div key={key} onClick={() => presentAlert({
                                                        message: new IonicSafeString(`<div style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%"><div style="text-align: center; color: #FFFFFF; margin-bottom: 2px; font-size: 110%;">${entry.name}</div><div style="text-align: center; color: #FFFFFF; margin-top: 2px; margin-bottom: 4px; font-size: 105%;">${entry.description}</div><img src="${entry.link}" alt="${entry.name}" /><div></div></div>`),
                                                    })}>
                                                        <IonCard className="bg-transparent border-0 shadow-none">
                                                            <div className="object-contain aspect-video">
                                                                <img className="w-full h-full block object-contain"
                                                                     src={entry.link} alt={entry.name}/>
                                                            </div>
                                                            <IonCardHeader>
                                                                <IonCardTitle
                                                                    className="text-base text-center">{entry.name}</IonCardTitle>
                                                            </IonCardHeader>
                                                        </IonCard>
                                                    </div>);
                                            })}
                                    </ReactSortable>
                                </div>
                            </> : <div className="text-center text-base">No Pins Earned Yet!</div>
                        }
                    </div> :
                    segmentValue === "sugTricks" ?
                        <div>
                            <SuggestedTrick tricks={displaySuggestedTricks} maxSuggestions={maxSuggestedTricksSelected}
                                            loadMoreSuggestions={onLoadMoreSuggestedTricks} sports={sports}
                                            isTheProfile={editProfile} maxTricks={numberOfSuggestedTricks}
                                            username={username} isNative={isNative}
                                            getSuggestedTricksHelper={getSuggestedTricksHelper}
                                            userProfileId={userProfileId} onClickTrick={onClickTrick}
                                            goToShop={handleShopRedirect}></SuggestedTrick>
                        </div> :

                        <div></div>
                }
            </IonContent>
        </IonPage>
    );
};

export default CaseModal;