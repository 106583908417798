import React, {useEffect, useState} from "react";
import {Auth0Provider} from "@auth0/auth0-react";
import {defineCustomElements} from "@ionic/pwa-elements/loader";
import {hydrateRoot} from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import {HelmetProvider} from 'react-helmet-async';
import {AuthProvider} from "./AuthProvider";

defineCustomElements(window);

// replace console.* for disable log on production
if (process.env.REACT_APP_PRODUCTION === "true" && process.env.REACT_APP_HOST_TYPE === "web") {
    console.log = () => {
    };
    console.error = () => {
    };
    console.debug = () => {
    };
}

declare global {
    interface Window {
        prerenderReady: boolean;
    }
}

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const Main = () => {
    const [hydrated, setHydrated] = useState(false);

    useEffect(() => {
        setHydrated(true);
    }, []);

    if (!hydrated) {
        return null;
    }

    return (
        <HelmetProvider>
            <AuthProvider firebaseConfig={firebaseConfig}>
                <Auth0Provider
                    domain={process.env.REACT_APP_AUTH_EXTERNAL_DOMAIN}
                    clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
                    redirectUri={process.env.REACT_APP_AUTH_REDIRECT_URI}
                    audience={process.env.REACT_APP_AUTH_API}
                    scope="read:current_user"
                    useRefreshTokens={true}
                    cacheLocation="localstorage"
                >
                    <App/>
                </Auth0Provider>
            </AuthProvider>
        </HelmetProvider>
    );
};

const container = document.getElementById("root");
if (container) {
    hydrateRoot(container, <Main/>);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
