import {IonApp, IonRouterOutlet, setupIonicReact,} from "@ionic/react";
import {IonReactRouter} from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import React, {useEffect, useState} from "react";
import {Route} from "react-router-dom";

/* Global CSS */
import "./global.css";
import {AppStateProvider} from "./AppStateProvider";

/* Tailwind styles */
import "./theme/tailwind.css";

/* Theme variables */
import "./theme/variables.css";
import {AppListenerProvider, useAppState} from "./AppListenerProvider";
import {AppAnalyticsProvider} from "./AppAnalyticsProvider";
import {useAuth} from "./AuthProvider";
import AppMain from "./AppMain";
import AppRedirect from "./components/ComponentsFunctional/AppRedirect/AppRedirect";
import {SplashScreen} from "@capacitor/splash-screen";
import PreferencesService from "./services/preferences.service";
import {OnboardingStatus} from "./models/onboardingStatus";
import LoadingSplash from "./pages/LoadingSplash";
import AppOnboarding from "./AppOnboarding";
import {DeepLinkProvider} from "./DeepLinkProvider";
import {SignUpProvider} from "./components/ComponentsOnboarding/SignUpProvider/SignUpProvider";

setupIonicReact({
    swipeBackEnabled: true,
});

const App: React.FC = () => {
    return (
        <IonReactRouter>
            <AppStateProvider>
                <AppListenerProvider>
                    <AppAnalyticsProvider>
                        <DeepLinkProvider>
                            <SignUpProvider>
                                <AppRedirect/>
                                <IonicApp/>
                            </SignUpProvider>
                        </DeepLinkProvider>
                    </AppAnalyticsProvider>
                </AppListenerProvider>
            </AppStateProvider>
        </IonReactRouter>
    );
};

const IonicApp: React.FC = () => {
    const {isNative, platform} = useAppState();
    const {isLoading, isAuthenticated} = useAuth();
    const [onboarded, setOnboarded] = useState<boolean | undefined>(undefined);
    const [onboardedStatus, setOnboardedStatus] = useState<string>(OnboardingStatus.INIT);
    const isNativeActual = isNative;

    const handleInitialAppOnboarding = async () => {
        console.log("HANDLING THAT ONBOARDING SHIT");

        if (platform !== "android") {
            await SplashScreen.show({autoHide: false});
        }

        const status = await PreferencesService.getOnboardingStatus();
        setOnboardedStatus(status);
        console.log("ONBOARDING STATUS: " + status);

        if (!isNativeActual) {
            console.log("SETTING ONBOARDED TO TRUE - IS NOT NATIVE");
            setOnboarded(true);
            await SplashScreen.hide();
            return;
        }

        if (isAuthenticated) {
            console.log("SETTING ONBOARDED TO TRUE - IS AUTHED");
            await PreferencesService.setOnboardingStatus(OnboardingStatus.COMPLETED);
            setOnboardedStatus(OnboardingStatus.COMPLETED);
            setOnboarded(true);
        } else {
            if (status === OnboardingStatus.COMPLETED) {
                console.log("SETTING ONBOARDED TO TRUE - Onboarding COMPLETED");
                setOnboarded(true);
            } else {
                console.log("SETTING ONBOARDED TO FALSE");
                setOnboarded(false);
            }
        }

        if (platform !== "android") {
            await SplashScreen.hide();
        }
    }

    useEffect(() => {
        if (platform !== "android") {
            SplashScreen.show({autoHide: false});
        }

        const checkLoadingState = async () => {
            if (!isLoading) {
                await handleInitialAppOnboarding();
            }
        };

        checkLoadingState();
    }, [isLoading]);

    useEffect(() => {
        const handleStateChange = async () => {
            console.log("HANDLING AUTH STATE CHANGE FOR ONBOARDING");
            const status = await PreferencesService.getOnboardingStatus();
            setOnboardedStatus(status);
            console.log("ONBOARDING STATUS: " + status);
            if (isAuthenticated) {
                setOnboarded(true);
                await PreferencesService.setOnboardingStatus(OnboardingStatus.COMPLETED);
                setOnboardedStatus(OnboardingStatus.COMPLETED);
            } else if (!isNativeActual) {
                setOnboarded(true);
            } else {
                if (status === OnboardingStatus.COMPLETED) {
                    console.log("SETTING ONBOARDED TO TRUE - Onboarding COMPLETED");
                    setOnboarded(true);
                } else {
                    console.log("SETTING ONBOARDED TO FALSE");
                    setOnboarded(false);
                }
            }
        };

        if (!isLoading) {
            handleStateChange();
        }
    }, [isAuthenticated, isLoading]);


    return (
        <IonApp>
            <IonReactRouter>
                <IonRouterOutlet animated={isNative}>
                    {/* Main App Routes */}
                    <Route path="/" render={() => (
                        onboarded === undefined ? <LoadingSplash/> : (onboarded ? <AppMain/> :
                            <AppOnboarding appOnboardingStatus={onboardedStatus}/>)
                    )}/>
                    <Route render={() => (
                        onboarded === undefined ? <LoadingSplash/> : (onboarded ? <AppMain/> :
                            <AppOnboarding appOnboardingStatus={onboardedStatus}/>)
                    )}/>
                </IonRouterOutlet>
            </IonReactRouter>
        </IonApp>
    );
};

export default App;
