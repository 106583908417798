import React, {useEffect, useState} from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonImg,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonSearchbar,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import './LocationSearch.css';
import {LocationOnboarding} from "../../../models/locationOnboarding";
import {useSignUp} from "../SignUpProvider/SignUpProvider";
import SparkGrid from "../SparkGrid/SparkGrid";
import {
    chevronBackOutline,
    compassOutline,
    locationOutline,
    navigateCircleOutline,
    navigateOutline,
    pinOutline
} from "ionicons/icons";
import {Geolocation} from "@capacitor/geolocation";
import surfIcon from '../../../assets/icons/sports/twemoji--man-surfing.svg';
import skateIcon from '../../../assets/icons/sports/noto--skateboard.svg';
import bikeIcon from '../../../assets/icons/sports/cil--bike.svg';
import mountainIcon from '../../../assets/icons/sports/noto--mountain.svg';
import {useAppState} from "../../../AppListenerProvider";
import UserService from "../../../services/user.service";
import IonSpinnerMainContent from "../../ComponentsUI/IonSpinnerMainContent/IonSpinnerMainContent";
import {IonInfiniteScrollCustomEvent} from "@ionic/core/dist/types/components";
import {Keyboard} from "@capacitor/keyboard";


interface LocationSearchProps {
    onNext: () => void;
    onBack: () => void;
    defaultLocations: LocationOnboarding[];
    defaultIsLoading: boolean;
    onSkip: () => void;
    sessionToken: string;
}

const LocationSearch: React.FC<LocationSearchProps> = ({
                                                           onNext,
                                                           onBack,
                                                           defaultLocations,
                                                           defaultIsLoading,
                                                           onSkip,
                                                           sessionToken
                                                       }) => {
    const {signUpData, updateSignUpData} = useSignUp();
    const {isNative, isActive} = useAppState();
    const [searchTerm, setSearchTerm] = useState('');
    const [locations, setLocations] = useState<LocationOnboarding[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<LocationOnboarding | null>(null);
    const [page, setPage] = useState(2);
    const [searchLocationsIsLoading, setSearchLocationsIsLoading] = useState(false);
    const [isInfiniteDisabled, setIsInfiniteDisabled] = useState(false);

    const searchbarRef = React.createRef<HTMLIonSearchbarElement>();

    const locationIcons = [compassOutline, locationOutline, navigateOutline, navigateCircleOutline, pinOutline]
    const skateIcons = [skateIcon]
    const bikeIcons = [bikeIcon]
    const snowIcons = [mountainIcon]
    const surfIcons = [surfIcon]

    const setSearchBarFocus = async () => {
        await searchbarRef.current?.setFocus();
    }

    useEffect(() => {
        if (defaultLocations) {
            setLocations(defaultLocations);
        }
    }, [defaultLocations]);

    useEffect(() => {
        getCurrentLocation();
    }, []);

    useEffect(() => {
        if (signUpData.location) {
            setSelectedLocation(signUpData.location);
        }
    }, [signUpData.location]);

    useEffect(() => {
        if (searchTerm) {
            setSearchLocationsIsLoading(true);
            getLocationsFromSearch(searchTerm);
            return;
        } else {
            setLocations(defaultLocations);
            setPage(2);
            setIsInfiniteDisabled(false);
        }
    }, [searchTerm]);


    useEffect(() => {
        const focusInput = async () => {
            setTimeout(async () => {
                await setSearchBarFocus();
                if (isNative) {
                    await Keyboard.show();
                }
            }, 300);
        };

        focusInput();

        return () => {
            if (isNative) {
                Keyboard.hide();
            }
        };
    }, [isNative, searchbarRef, isActive]);

    const getLocationsFromSearch = async (searchTermInput: string) => {
        try {
            const searchLocations: LocationOnboarding[] = await UserService.getUserLocationsOnboardingBase(sessionToken, searchTermInput);
            setLocations(searchLocations);
            setSearchLocationsIsLoading(false);
        } catch (error) {
            console.error('Error getting locations:', error);
            setSearchLocationsIsLoading(false);
        }

    }

    const getMoreLocations = async (ev: IonInfiniteScrollCustomEvent<void>) => {
        if (signUpData.latitude && signUpData.longitude) {
            try {
                const moreLocations: LocationOnboarding[] = await UserService.getUserLocationsOnboardingLocation(page, signUpData.latitude, signUpData.longitude);
                setLocations([...locations, ...moreLocations]);
                setPage(page + 1);
                if (moreLocations.length === 0) {
                    setIsInfiniteDisabled(true);
                }
            } catch (error) {
                console.error('Error getting more locations:', error);
            }
        } else {
            try {
                const moreLocations: LocationOnboarding[] = await UserService.getUserLocationsOnboarding(page);
                setLocations([...locations, ...moreLocations]);
                setPage(page + 1);
                if (moreLocations.length === 0) {
                    setIsInfiniteDisabled(true);
                }
            } catch (error) {
                console.error('Error getting more locations:', error);
            }
        }

        await ev.target.complete();
    }

    const getCurrentLocation = async () => {
        try {
            if (isNative) {
                const result = await Geolocation.getCurrentPosition();
                await updateSignUpData({latitude: result.coords.latitude, longitude: result.coords.longitude});
            } else {
                console.log("Requesting location permission");
                try {
                    navigator.geolocation.getCurrentPosition(
                        async function (position) {
                            await updateSignUpData({
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            });
                        },
                        function (error) {
                        }
                    );
                } catch (e) {
                    console.error(e);
                }
            }
        } catch (error) {
            console.error('Error requesting location permission:', error);
        }
    };

    const handleLocationSelectAndContinue = async (base: LocationOnboarding) => {
        setSelectedLocation(base);

        console.log("Selected Location: ", base)

        const locationDetails: LocationOnboarding = {
            location_id: base.location_id,
            location_name: base.location_name,
            secondary_location: base.secondary_location,
            google_place_ids: base.google_place_ids,
            location_types: base.location_types,
            logo: base.logo,
            address: base.address,
            lng: base.lng,
            lat: base.lat,
            audited: base.audited,
            people: base.people,
        }

        await updateSignUpData({location: locationDetails});

        onNext();
    }

    const handleLocationSearch = (e: CustomEvent) => {
        setSearchTerm(e.detail.value);
    }

    const generateIcon = (base: LocationOnboarding) => {
        if (base.location_name.toLowerCase().includes('skate')) {
            return skateIcons[Math.floor(Math.random() * skateIcons.length)];
        } else if (base.location_name.toLowerCase().includes('bike')) {
            return bikeIcons[Math.floor(Math.random() * bikeIcons.length)];
        } else if (base.location_name.toLowerCase().includes('snow') || base.location_name.toLowerCase().includes('ski') || base.location_name.toLowerCase().includes('mountain') || base.location_name.toLowerCase().includes('resort') || base.location_name.toLowerCase().includes('snowboard')) {
            return snowIcons[Math.floor(Math.random() * snowIcons.length)];
        } else if (base.location_name.toLowerCase().includes('surf')) {
            return surfIcons[Math.floor(Math.random() * surfIcons.length)];
        } else {
            return locationIcons[Math.floor(Math.random() * locationIcons.length)];
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={onBack}>
                            <IonIcon slot="icon-only" icon={chevronBackOutline} className="text-primary-alt"></IonIcon>
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Pick your base</IonTitle>
                    <IonButtons slot="end" onClick={onSkip}>
                        <IonButton><span className="text-primary-alt normal-case">Skip</span></IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonToolbar>
                    <IonSearchbar placeholder="Search..."
                                  value={searchTerm}
                                  className="custom-searchbar"
                                  debounce={50}
                                  onIonInput={(e) => handleLocationSearch(e)}
                                  ref={searchbarRef}
                    />
                </IonToolbar>
            </IonHeader>
            <IonContent className="bg-grid-pattern">
                <div className="relative bg-grid-pattern">
                    <SparkGrid height='120%'/>
                    {
                        (locations.length === 0 && !defaultIsLoading) && (
                            <div className="flex flex-col items-center justify-center h-full pt-4">
                                <IonIcon icon={locationOutline} size="large" className="text-electric-blue"/>
                                <div className="text-primary-secondary text-center text-lg">No locations found. Search for
                                    something again as our servers might be overloaded!
                                </div>
                            </div>
                        )
                    }
                    {
                        (defaultIsLoading || searchLocationsIsLoading) ? (
                                <div className="flex flex-col items-center justify-center h-full">
                                    <div className="pt-4">
                                        <IonSpinnerMainContent className={"text-neon-pink"}/>
                                    </div>
                                </div>
                            ) :
                            <>
                                <IonList className="custom-list z-50">
                                    {locations.map((base, index) => (
                                        <IonItem key={index} button={true}
                                                 onClick={async () => {
                                                     searchbarRef.current?.blur();
                                                     await handleLocationSelectAndContinue(base)
                                                 }}
                                                 className={selectedLocation === null ? "custom-item" :
                                                     base.google_place_ids.includes(selectedLocation.google_place_ids[0])
                                                         ? "custom-item-selected" : "custom-item"}>
                                            {
                                                base.logo && (
                                                    <IonImg src={base.logo} slot="start"
                                                            className="h-8 w-8 rounded-md bg-white"/>
                                                )
                                            }
                                            {
                                                !base.logo && (
                                                    <IonIcon icon={generateIcon(base)} size="large" slot="start"
                                                             className="text-electric-blue"/>
                                                )
                                            }
                                            <IonLabel>
                                                <div className="text-primary-alt text-xl">{base.location_name}</div>
                                                <div
                                                    className="text-primary-secondary text-sm">{base.secondary_location}</div>
                                            </IonLabel>
                                            <IonLabel slot="end" className="ion-text-right text-center">
                                                <div
                                                    className="text-electric-blue text-xl font-bold text-center">{base.people}</div>
                                                <div className="text-primary-secondary text-sm text-center">Riders</div>
                                            </IonLabel>
                                        </IonItem>
                                    ))}
                                </IonList>
                                <IonInfiniteScroll
                                    onIonInfinite={(ev) => getMoreLocations(ev)}
                                    threshold="500px"
                                    disabled={isInfiniteDisabled || searchTerm !== ''}
                                >
                                    <IonInfiniteScrollContent
                                        loadingSpinner="bubbles"
                                        loadingText="Loading more bases..."
                                    ></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </>
                    }
                </div>
            </IonContent>
        </IonPage>
    );
};

export default LocationSearch;