import React, {useEffect, useRef, useState} from 'react';
import {IonButton, IonContent, IonIcon, IonInput} from '@ionic/react';
import {eyeOffOutline, eyeOutline} from 'ionicons/icons';
import {isEmail} from 'validator';
import {useAppState} from "../../../AppListenerProvider";
import {useAuth} from "../../../AuthProvider";
import AuthService from "../../../services/auth.service";
import {useHistory} from "react-router-dom";
import '../LoginFlow.css'

const EmailUsernameLoginFlow: React.FC = () => {
    const history = useHistory();

    const [emailOrUsername, setEmailOrUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const {isActive, isNative, platform} = useAppState();
    const {user, isLoading, isAuthenticated, firebaseApp, auth} = useAuth();
    const authService = AuthService({user, isLoading, isAuthenticated, firebaseApp, auth}, {
        isActive,
        isNative,
        platform
    });

    const userInput = useRef<HTMLIonInputElement>(null);
    const passwordInput = useRef<HTMLIonInputElement>(null);


    const signInWithEmailPasswordHandler = async (email: string, password: string) => {
        try {
            const res = await authService.loginWithEmailAndPassword(email, password);
        } catch (error) {
            console.error("Error signing in with Google: ", error);
            setError("Invalid email or password. Please try again or reach out to Liz or Brendan on IG @ecliptic.snow, email ecliptic.founders@gmail.com, or text us at 1-855-MAX-BISK (1-855-629-2475) and we will get this sorted ASAP!");
        }
    }

    const signInWithUsernamePassword = async (username: string, password: string) => {
        try {
            const res = await authService.loginWithUsernameAndPassword(username, password);
        } catch (error) {
            console.error("Error signing in with Google: ", error);
            setError("Invalid username or password. Please try again or reach out to Liz or Brendan on IG @ecliptic.snow, email ecliptic.founders@gmail.com, or text us at 1-855-MAX-BISK (1-855-629-2475) and we will get this sorted ASAP!");
        }
    }

    const handleLogin = async () => {
        // remove focus from input fields
        userInput.current?.blur();
        passwordInput.current?.blur();

        if (!emailOrUsername) {
            setError('Email or username is required');
            return;
        }
        if (!password) {
            setError('Password is required');
            return;
        }
        if (isEmail(emailOrUsername)) {
            console.log('Logging in with email:', emailOrUsername);
            const res = await signInWithEmailPasswordHandler(emailOrUsername, password);
        } else {
            console.log('Logging in with username:', emailOrUsername);
            const res = await signInWithUsernamePassword(emailOrUsername, password);
        }
    };

    const handleGoBack = () => {
        setEmailOrUsername('');
        setPassword('');
        setError('');
        setShowPassword(false);
        history.push("/login");
    }

    useEffect(() => {
        console.log("Loading Login In Flow Page");
        setError(null);
        setEmailOrUsername('');
        setPassword('');
        setShowPassword(false);
    }, [history]);

    const onKeyPress = async (e: any) => {
        if (e.key === 'Enter') {
            await handleLogin();
        }
    }

    const handleSetEmailOrUsername = (emailOrUsername: string) => {
        if (error) setError(null);
        setEmailOrUsername(emailOrUsername);
    }

    const handleSetPassword = (password: string) => {
        if (error) setError(null);
        setPassword(password);
    }

    return (
        <IonContent className="ion-padding h-full flex justify-center items-center login-form">
            <div className="w-full">
                <div className="w-full">
                    <h1 className="text-2xl font-bold text-center mt-8 text-black">Log in</h1>
                    <div className="mt-4 space-y-2 font-bold">
                        <div>
                            <IonInput
                                ref={userInput}
                                type="text"
                                color="light"
                                placeholder="Email or username"
                                value={emailOrUsername}
                                onIonInput={e => handleSetEmailOrUsername(e.detail.value!)}
                                className="border-b border-gray-300 p-2 rounded w-full"
                            />
                        </div>
                        <div>
                            <div className="relative">
                                <IonInput
                                    ref={passwordInput}
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Password"
                                    color="light"
                                    value={password}
                                    onIonInput={e => handleSetPassword(e.detail.value!)}
                                    className="border-b border-gray-300 p-2 rounded w-full"
                                    onKeyPress={onKeyPress}
                                />
                                <IonIcon
                                    icon={showPassword ? eyeOutline : eyeOffOutline}
                                    className="absolute right-3 top-3.5 cursor-pointer z-50"
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            </div>
                        </div>
                        {error && <div className="text-red-500">{error}</div>}
                        <div className="text-sm text-gray-500"
                             onClick={() => setError('Reach out to Liz or Brendan on IG @ecliptic.snow, email ecliptic.founders@gmail.com, or text us at 1-855-MAX-BISK (1-855-629-2475) and we will get this sorted ASAP!')}>Forgot
                            password?
                        </div>
                        <div className="mt-2">
                            <IonButton expand="block" disabled={emailOrUsername === "" || password === ""}
                                       color={(emailOrUsername === "" || password === "") ? "medium" : "favorite"}
                                       onClick={handleLogin} className="mt-4">
                                <span className="text-lg normal-case font-bold">Log in</span>
                            </IonButton>
                        </div>

                    </div>
                </div>
                <div className="text-center mt-2">
                    <IonButton expand="block" fill="clear" color="light"
                               onClick={handleGoBack}>
                        <span className="text-sm normal-case font-bold">&lt; Go back</span>
                    </IonButton>
                </div>
            </div>
        </IonContent>
    );
};

export default EmailUsernameLoginFlow;