import React from "react";
import "./ExitFilterButton.css";

interface ContainerProps {
    closeFilter: () => void,
    name: string,
    buttonType: string
}

const ExitFilterButton: React.FC<ContainerProps> = (props: ContainerProps) => {
    return (
        // maybe need to change the z-index of this div if it doesn't go over the vids
        <div className="z-100 m-2 cursor-pointer">
            <div
                className={"w-[55px] h-[55px] rounded-full flex justify-center items-center text-xs " + props.buttonType}>
                <div onClick={props.closeFilter}>
                    {props.name}
                </div>
            </div>
        </div>
    );
};

export default ExitFilterButton;
