import React, {useEffect, useRef, useState} from 'react';
import {IonButton, IonCol, IonGrid, IonInput, IonRow} from '@ionic/react';
import {Haptics, ImpactStyle} from '@capacitor/haptics';

interface OTPInputProps {
    length: number;
    onComplete: (otp: string) => void;
    inputClass?: string;
    buttonClass?: string;
    signUp: boolean;
}

const OTPInput: React.FC<OTPInputProps> = ({length, onComplete, inputClass, buttonClass, signUp}) => {
    const [otp, setOtp] = useState<string[]>(Array(length).fill(''));
    const inputRefs = useRef<(HTMLIonInputElement | null)[]>([]);

    useEffect(() => {
        inputRefs.current[0]?.setFocus();
    }, []);

    const handleChange = async (value: string | number | null | undefined, index: number) => {
        if (value === null || value === undefined) return;

        const digit = typeof value === 'number' ? value.toString() : value;

        if (isNaN(Number(digit)) || digit.length > 1) return;

        const newOtp = [...otp];
        newOtp[index] = digit;
        setOtp(newOtp);

        await Haptics.impact({style: ImpactStyle.Light});

        if (digit !== '') {
            if (index < length - 1) {
                inputRefs.current[index + 1]?.setFocus();
            } else {
                inputRefs.current[index]?.blur();
                onComplete(newOtp.join(''));
            }
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
        if (e.key === 'Backspace') {
            if (index > 0 && otp[index] === '') {
                const newOtp = [...otp];
                newOtp[index - 1] = '';
                setOtp(newOtp);
                inputRefs.current[index - 1]?.setFocus();
            }
        }
    };

    const handlePaste = (e: React.ClipboardEvent) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text/plain').slice(0, length);
        const newOtp = [...otp];
        for (let i = 0; i < pasteData.length; i++) {
            if (i < length) {
                newOtp[i] = pasteData[i];
            }
        }
        setOtp(newOtp);
        inputRefs.current[Math.min(pasteData.length, length) - 1]?.setFocus();
    };

    const defaultInputClass = "text-center text-2xl bg-transparent rounded-lg h-8 w-8 border-2 border-electric-blue";
    const defaultButtonClass = "mt-6 neon-button";

    return (
        <div className="w-full max-w-md mx-auto">
            <IonGrid>
                <IonRow className="justify-center">
                    {otp.map((data, index) => (
                        <IonCol size="auto" key={index} className="px-2">
                            <IonInput
                                className={`${defaultInputClass} ${inputClass || ''}`}
                                type="text"
                                inputMode="numeric"
                                maxlength={1}
                                value={data}
                                onIonInput={(e) => handleChange(e.detail.value, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                onPaste={handlePaste}
                                placeholder="0"
                                color="dark"
                                ref={(el) => (inputRefs.current[index] = el)}
                            />
                        </IonCol>
                    ))}
                </IonRow>
            </IonGrid>
            {
                signUp &&
                <IonButton
                    expand="block"
                    className={`${defaultButtonClass} ${buttonClass || ''}`}
                    onClick={() => onComplete(otp.join(''))}
                    disabled={otp.some((digit) => digit === '')}
                >
                    Continue
                </IonButton>
            }
        </div>
    );
};

export default OTPInput;