import React, {useEffect, useState} from 'react';
import {IonButton, IonIcon} from '@ionic/react';
import {
    book,
    bookOutline,
    heart,
    heartOutline,
    infinite,
    infiniteOutline,
    planet,
    planetOutline,
    star,
    starOutline,
    telescope,
    telescopeOutline
} from 'ionicons/icons';
import {useSignUp} from '../SignUpProvider/SignUpProvider';
import './SportSkillTerrainSelection.css';
import {SkillLevel} from "../../../models/skillLevel";
import {terrainIconDictionary} from "../../../models/terrainOptions";
import OnboardingPageWrapper from "../OnboardingPageWrapper/OnboardingPageWrapper";

interface SportSkillTerrainSelectionProps {
    onNext: () => void;
    title: string;
    showBackButton: boolean;
    showSkipButton: boolean;
    onBack?: () => void;
    onSkip?: () => void;
}

const skillLevels = [
    {name: SkillLevel.FAN, icon: heartOutline, iconSelected: heart},
    {name: SkillLevel.BEGINNER, icon: bookOutline, iconSelected: book},
    {name: SkillLevel.INTERMEDIATE, icon: telescopeOutline, iconSelected: telescope},
    {name: SkillLevel.ADVANCED, icon: planetOutline, iconSelected: planet},
    {name: SkillLevel.EXPERT, icon: starOutline, iconSelected: star},
    {name: SkillLevel.ECLIPTIC, icon: infiniteOutline, iconSelected: infinite},
];

const SportSkillTerrainSelection: React.FC<SportSkillTerrainSelectionProps> = ({
                                                                                   onNext,
                                                                                   showBackButton,
                                                                                   onBack,
                                                                                   showSkipButton,
                                                                                   onSkip,
                                                                                   title
                                                                               }) => {
    const {signUpData, updateSignUpData} = useSignUp();
    const [currentSportIndex, setCurrentSportIndex] = useState<number | null>(signUpData.sportIndex ?? 0);
    const [selectedSkill, setSelectedSkill] = useState<string | null>(null);
    const [selectedTerrain, setSelectedTerrain] = useState<string[]>([]);
    const [displaySkill, setDisplaySkill] = useState<boolean>(true);
    const [terrainOptions, setTerrainOptions] = useState<any>([]);

    const currentSport = currentSportIndex !== null ? signUpData.sports[currentSportIndex] : null;
    const isLastSport = currentSportIndex !== null && currentSportIndex === signUpData.sports.length - 1;

    useEffect(() => {
        if (currentSport) {
            const currentSkill = signUpData.skills?.[currentSport] ?? null;
            setSelectedSkill(currentSkill);

            const currentTerrain = signUpData.terrain?.[currentSport] ?? [];
            setSelectedTerrain(currentTerrain);

            // @ts-ignore
            setTerrainOptions(terrainIconDictionary[currentSport]);
        } else {
            setSelectedSkill(null);
            setSelectedTerrain([]);
        }

        setDisplaySkill(signUpData.displaySkill);
    }, [currentSportIndex, signUpData.skills, signUpData.terrain, currentSport, signUpData.displaySkill]);

    const handleSkillSelect = async (skill: string) => {
        setSelectedSkill(skill);
        await updateSignUpData({
            skills: {...(signUpData.skills ?? {}), [currentSport]: skill},
        });
    };

    const handleTerrainSelect = async (terrain: string) => {
        const newSelectedTerrain = selectedTerrain.includes(terrain)
            ? selectedTerrain.filter(t => t !== terrain)
            : [...selectedTerrain, terrain];

        setSelectedTerrain(newSelectedTerrain);

        const newTerrain = {
            ...(signUpData.terrain ?? {}),
            [currentSport]: newSelectedTerrain
        };

        await updateSignUpData({
            terrain: newTerrain,
        });
    };

    const handleContinue = async () => {
        if (!currentSport) return;

        if (displaySkill) {
            if (selectedSkill === SkillLevel.FAN) {
                const nextSportIndex = isLastSport ? currentSportIndex : currentSportIndex + 1;
                await updateSignUpData({
                    sportIndex: nextSportIndex,
                    terrain: {
                        ...(signUpData.terrain ?? {}),
                        [currentSport]: []
                    },
                    displaySkill: true
                });
                setDisplaySkill(true);

                if (isLastSport) {
                    onNext();
                } else {
                    setCurrentSportIndex(nextSportIndex);
                }
            } else {
                await updateSignUpData({
                    displaySkill: false
                });
                setDisplaySkill(false);
            }

            setSelectedSkill(null);
        } else {
            const nextSportIndex = isLastSport ? currentSportIndex : currentSportIndex + 1;
            await updateSignUpData({
                sportIndex: nextSportIndex,
                displaySkill: true
            });

            if (isLastSport) {
                onNext();
            } else {
                setCurrentSportIndex(nextSportIndex);
                setDisplaySkill(true);
                // Reset selectedTerrain for the next sport
            }

            setSelectedTerrain([]);
        }
    };

    const handlePrevious = async () => {
        if (displaySkill && currentSportIndex > 0) {
            const prevSportIndex = currentSportIndex - 1;
            setCurrentSportIndex(prevSportIndex);
            await updateSignUpData({
                sportIndex: prevSportIndex,
                displaySkill: signUpData.skills?.[signUpData.sports[prevSportIndex]] === SkillLevel.FAN
            });
            setDisplaySkill(signUpData.skills?.[signUpData.sports[prevSportIndex]] === SkillLevel.FAN);
        } else {
            setDisplaySkill(true);
            await updateSignUpData({displaySkill: true});
        }
    };

    return (
        <OnboardingPageWrapper
            title={title}
            showBackButton={showBackButton}
            showSkipButton={showSkipButton}
            onBack={onBack}
            onSkip={onSkip}>
            <div className="w-full max-w-md space-y-4 flex flex-col items-center font-bold">
                {currentSportIndex === 0 && displaySkill && (
                    <div className="text-primary-secondary text-sm text-center font-bold hidden h-md:block">
                        We use this to customize your progression experience.
                    </div>
                )}
                {displaySkill ? (
                    <div className="space-y-1 w-full h-48 h-md:h-64 overflow-auto">
                        {
                            skillLevels.map((skill) => (
                                <IonButton
                                    key={skill.name}
                                    expand="block"
                                    className={`skill-button ${selectedSkill === skill.name ? 'skill-button-selected' : ''}`}
                                    onClick={() => handleSkillSelect(skill.name)}
                                >
                                    <div className="flex items-center justify-between w-full normal-case font-bold">
                                        <div className="flex items-center">
                                            <IonIcon
                                                icon={selectedSkill === skill.name ? skill.iconSelected : skill.icon}
                                                className="text-2xl mr-4"/>
                                            <span>{skill.name}</span>
                                        </div>
                                    </div>
                                </IonButton>
                            ))
                        }
                    </div>
                ) : (
                    <div className="h-48 h-md:h-64 overflow-auto grid grid-cols-2">
                        {
                            terrainOptions.map((terrain: any) => (
                                <IonButton
                                    key={terrain.name}
                                    className={`skill-button ${selectedTerrain.includes(terrain.name) ? 'skill-button-selected' : ''}`}
                                    onClick={() => handleTerrainSelect(terrain.name)}
                                >
                                    <div className="flex items-center justify-between w-full normal-case font-bold">
                                        <div className="flex items-center">
                                            <IonIcon icon={terrain.icon} className="text-2xl mr-4"/>
                                            <span>{terrain.name}</span>
                                        </div>
                                    </div>
                                </IonButton>
                            ))
                        }
                    </div>
                )}
                <IonButton
                    expand="block"
                    className={`${isLastSport && !displaySkill ? 'neon-button-alt' : 'neon-button'} w-full font-bold normal-case`}
                    onClick={handleContinue}
                    disabled={displaySkill ? !selectedSkill : selectedTerrain.length === 0}
                >
                    {(isLastSport && !displaySkill) ? 'Continue' : 'Next'}
                </IonButton>
                {(currentSportIndex > 0 || (currentSportIndex === 0 && !displaySkill)) && (
                    <IonButton
                        expand="block"
                        className="neon-button-highlight w-full font-bold normal-case"
                        onClick={handlePrevious}
                    >
                        Previous
                    </IonButton>
                )}
            </div>
        </OnboardingPageWrapper>
    );
};

export default SportSkillTerrainSelection;