import {IonCard, IonImg} from "@ionic/react";
import React, {useEffect, useState} from "react";
import {UserTrickSearch} from "../../../models/user-trick-search";
import UsernameDisplay from "../../ComponentsUI/Username/UsernameDisplay";

interface ContainerProps {
    trickArray: UserTrickSearch[];
    onTrickSelection: (trick: UserTrickSearch) => void;
    loggedInId?: string;
}

const TrickSearchCardHolder: React.FC<ContainerProps> = (props: ContainerProps) => {
    const [trickArray, setTrickArray] = useState<UserTrickSearch[]>([]);

    useEffect(() => {
        setTrickArray(props.trickArray);
        console.log("Trick array updated");
    }, [props.trickArray]);

    return (
        <>
            {
                trickArray.map((trick: UserTrickSearch, i) =>
                    <div key={i} onClick={() => props.onTrickSelection(trick)}>
                        <IonCard className="cursor-pointer p-0 m-0 border border-gray-900 bg-black font-bold">
                            <IonImg className="object-cover min-w-full min-h-full h-full aspect-video"
                                    src={trick?.thumbnail}/>
                            <div className="bg-black p-4">
                                <div className="text-base text-white">{trick.trick_name}</div>
                                <div className="text-sm"><UsernameDisplay username={trick.username} className={""}
                                                                          loggedInId={props.loggedInId}
                                                                          userId={trick.user_id}/></div>
                            </div>
                        </IonCard>
                    </div>
                )
            }
        </>
    );
};

export default TrickSearchCardHolder;
