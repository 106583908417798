import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {IonButton, IonContent, IonIcon} from "@ionic/react";
import {logoApple, logoGoogle, personCircleOutline} from "ionicons/icons";
import {useAuth} from "../../../AuthProvider";
import {useAppState} from "../../../AppListenerProvider";
import AuthService from "../../../services/auth.service";
import {Browser} from "@capacitor/browser";
import '../LoginFlow.css'
import TagLine from "../TagLine/TagLine";

interface ContainerProps {
}

const LoginFlow: React.FC<ContainerProps> = (props: ContainerProps) => {
    const history = useHistory();
    const {isActive, isNative, platform} = useAppState();
    const {user, isLoading, isAuthenticated, firebaseApp, auth} = useAuth();
    const authService = AuthService({user, isLoading, isAuthenticated, firebaseApp, auth}, {
        isActive,
        isNative,
        platform
    });
    const [error, setError] = useState<string | null>(null);

    const signInWithAppleHandler = async () => {
        try {
            const res = await authService.signInWithApple();
        } catch (error) {
            console.error("Error signing in with Apple: ", error);
        }
    }

    const signInWithGoogleHandler = async () => {
        try {
            const res = await authService.signInWithGoogle();
        } catch (error) {
            console.error("Error signing in with Google: ", error);
        }
    }

    useEffect(() => {
        console.log("Loading Login In Flow Page");
        setError(null);
    }, [history]);

    const handlePrivacyPolicy = async () => {
        await Browser.open({
            url: 'https://idx.style/private-policy',
            presentationStyle: "popover",
            windowName: "_blank"
        })
    }

    const handleTermsOfService = async () => {
        await Browser.open({
            url: 'https://idx.style/terms-and-conditions',
            presentationStyle: "popover",
            windowName: "_blank"
        })
    }

    const navigateToUsernameEmailLogin = () => {
        setError(null);
        history.push("/login/username");
    }

    const navigateToSignUp = () => {
        setError(null);
        history.push("/signup");
    }

    return (
        <IonContent className="ion-padding flex flex-col justify-between h-full login-form">
            <div>
                <h1 className="text-2xl font-bold text-center mt-8 text-black">Log in to Ecliptic</h1>
                <div className="text-center text-gray-500 mt-2 font-bold">
                    <TagLine/>
                </div>
                <div className="mt-6 space-y-4 text-white font-bold">
                    <IonButton expand="block" fill="outline" shape="round" color="light"
                               className="flex items-center justify-center" onClick={navigateToUsernameEmailLogin}>
                        <IonIcon icon={personCircleOutline} className="mr-2 text-highlight-alt"/>
                        <span className="font-bold">Use email / username</span>
                    </IonButton>
                    <IonButton expand="block" fill="outline" shape="round" color="light"
                               className="flex items-center justify-center"
                               onClick={signInWithGoogleHandler}>
                        <IonIcon icon={logoGoogle} className="mr-2 text-red-500"/>
                        <span className="font-bold">Continue with Google</span>
                    </IonButton>
                    <IonButton expand="block" fill="outline" shape="round" color="light"
                               className="flex items-center justify-center"
                               onClick={signInWithAppleHandler}>
                        <IonIcon icon={logoApple} className="mr-2 text-black" color="light"/>
                        <span className="font-bold">Continue with Apple</span>
                    </IonButton>
                </div>
            </div>
            {error && (
                <div className="text-center mt-6">
                    <p className="text-red-500">{error}</p>
                </div>
            )}
            <div className="text-center mt-6">
                <p className="text-sm text-gray-500">
                    By continuing, you agree to Ecliptic's <a onClick={() => handleTermsOfService()}
                                                              className="text-black">Terms of
                    Service</a> and confirm that you have read Ecliptic's <a onClick={() => handlePrivacyPolicy()}
                                                                             className="text-black">Privacy
                    Policy</a>.
                </p>
                <p className="mt-4 text-black font-bold">
                    Don't have an account? <a onClick={navigateToSignUp} className="text-highlight font-bold">Sign
                    up</a>
                </p>
            </div>
        </IonContent>
    );
};

export default LoginFlow;