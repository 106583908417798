import React from "react";


interface ContainerProps {
}

const TagLine: React.FC<ContainerProps> = ({}) => {
    return (
        <div>
            Become a part of the world's greatest freeski and snowboard community. Elevate your skills and push your
            progression. Make the most of your time on the hill, in the park, backcountry, or the streets.
        </div>
    )
};

export default TagLine;
