import {
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    OAuthProvider,
    RecaptchaVerifier,
    signInWithCredential,
    signInWithCustomToken,
    signInWithEmailAndPassword,
    signInWithPhoneNumber,
    signOut
} from "firebase/auth";
import {FirebaseAuthentication, SignInWithOAuthOptions} from "@capacitor-firebase/authentication";
import UserService from "./user.service";
import {AUTH0} from "./utils";
import {AuthContextProps} from "../models/authContextProps";
import {AppStateListenerProps} from "../models/appStateListenerProps";
import PreferencesService from "./preferences.service";

const AuthService = (authContext: AuthContextProps, appStateContext: AppStateListenerProps) => {
    const {auth, user} = authContext;
    const {isNative} = appStateContext;

    const fetchSignInMethodsForEmail = async (email: string) => {
        const result = await FirebaseAuthentication.fetchSignInMethodsForEmail({
            email: email,
        });
        return result.signInMethods;
    };

    const loginWithEmailAndPassword = async (email: string, password: string): Promise<boolean> => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            return true;
        } catch (error) {
            console.error('Error logging in with email and password:', error);
            throw error;
        }
    };

    const signInWithPhoneNumberHandler = async (phoneNumber: string, recaptchaVerifier?: RecaptchaVerifier): Promise<any> => {
        if (!isNative) {
            // Web implementation remains the same
            try {
                const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
                return {confirmationResult};
            } catch (error) {
                console.error("Error sending SMS:", error);
                throw error;
            }
        } else {
            // Native (iOS/Android) implementation
            return new Promise(async (resolve, reject) => {
                try {
                    // Attach `phoneCodeSent` listener
                    const phoneCodeSentListener = await FirebaseAuthentication.addListener('phoneCodeSent', async event => {
                        resolve({verificationId: event.verificationId, verificationStarted: true});
                    });

                    // Attach `phoneVerificationCompleted` listener
                    const phoneVerificationCompletedListener = await FirebaseAuthentication.addListener(
                        'phoneVerificationCompleted',
                        async event => {
                            resolve({user: event.user, verificationCompleted: true});
                        }
                    );

                    // Attach `phoneVerificationFailed` listener
                    const phoneVerificationFailedListener = await FirebaseAuthentication.addListener(
                        'phoneVerificationFailed',
                        async event => {
                            reject(new Error(event.message));
                        }
                    );

                    // Start sign in with phone number and send the SMS
                    await FirebaseAuthentication.signInWithPhoneNumber({
                        phoneNumber,
                    });

                    // Return a cleanup function
                    return () => {
                        phoneCodeSentListener.remove();
                        phoneVerificationCompletedListener.remove();
                        phoneVerificationFailedListener.remove();
                    };
                } catch (error) {
                    console.error("Error in signInWithPhoneNumber:", error);
                    reject(error);
                }
            });
        }
    };

    const confirmPhoneVerificationCode = async (verificationCode: string, verificationId?: string, confirmationResult?: any): Promise<any> => {
        if (!isNative) {
            // Web implementation
            try {
                const result = await confirmationResult.confirm(verificationCode);
                return result.user;
            } catch (error) {
                console.error('Error confirming verification code:', error);
                throw error;
            }
        } else {
            // Native implementation
            try {
                const result = await FirebaseAuthentication.confirmVerificationCode({
                    verificationId,
                    verificationCode,
                });
                return result.user;
            } catch (error) {
                console.error('Error confirming verification code:', error);
                throw error;
            }
        }
    };

    const signInWithGoogle = async (): Promise<boolean> => {
        try {
            const options: SignInWithOAuthOptions = !isNative ? {mode: 'popup'} : {skipNativeAuth: false};
            const result = await FirebaseAuthentication.signInWithGoogle(options);
            const credential = GoogleAuthProvider.credential(result.credential?.idToken);
            await signInWithCredential(auth, credential);
            return true;
        } catch (error) {
            console.error('Error signing in with Google:', error);
            // @ts-ignore
            if (error.code === 'auth/cancelled-popup-request' || error.code === 'auth/popup-closed-by-user') {
                console.log("THROWING ERROR");
                return false;
            } else { // @ts-ignore
                if (error.code === 'auth/popup-blocked') {
                    console.error('Popup blocked by browser');
                    console.log("THROWING ERROR");
                    throw new Error('Please enable popups in your browser to login with Google.');
                } else {
                    console.log("THROWING ERROR");
                    throw error;
                }
            }
        }
    };

    const signInWithApple = async (): Promise<boolean> => {
        try {
            const options: SignInWithOAuthOptions = !isNative ? {
                mode: 'popup',
                scopes: ['email', 'name']
            } : {scopes: ['email', 'name']};
            const result = await FirebaseAuthentication.signInWithApple(
                {
                    skipNativeAuth: false,
                    ...options,
                }
            );
            const provider = new OAuthProvider('apple.com');
            const credential = provider.credential({
                idToken: result.credential?.idToken,
                rawNonce: result.credential?.nonce,
            });
            await signInWithCredential(auth, credential);
            return true;
        } catch (error) {
            console.error('Error signing in with Apple:', error);
            console.log("THROWING ERROR");
            // @ts-ignore
            if (error.code === 'auth/cancelled-popup-request' || error.code === 'auth/popup-closed-by-user') {
                console.log("THROWING ERROR");
                return false;
            } else { // @ts-ignore
                if (error.code === 'auth/popup-blocked') {
                    console.error('Popup blocked by browser');
                    console.log("THROWING ERROR");
                    throw new Error('Please enable popups in your browser to login with Apple.');
                } else {
                    console.log("Error signing in with Apple: ", error);
                    console.log("THROWING ERROR");
                    throw error;
                }
            }
        }
    };

    return {
        fetchSignInMethodsForEmail,
        loginWithEmailAndPassword,
        signInWithApple,
        signInWithGoogle,
        signInWithPhoneNumberHandler,
        confirmPhoneVerificationCode,
        fetchCustomTokenAndSignIn: async () => {
            // Check for Auth0 token in local storage
            const auth0Token = await PreferencesService.getAccessToken();

            if (auth0Token && auth) {
                try {
                    const response = await UserService.postAuthToken(auth0Token, AUTH0);
                    const {firebaseToken} = response;

                    if (firebaseToken) {
                        const user = await signInWithCustomToken(auth, firebaseToken);
                        // Remove Auth0 token and store Firebase token
                        await PreferencesService.removeAccessToken();
                        return true;
                    } else {
                        throw new Error('Firebase token not received');
                    }
                } catch (error) {
                    console.error('Error fetching custom token from server:', error);
                    throw error;
                }
            } else {
                return false;
            }
        },

        signUpWithEmailAndPassword: async (email: string, password: string) => {
            try {
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                return true;
            } catch (error) {
                console.error(error);
                // @ts-ignore
                if (error.code === 'auth/email-already-in-use') {
                    const signInMethodsForEmail = await fetchSignInMethodsForEmail(email);
                    if (signInMethodsForEmail.includes('google.com')) {
                        return await signInWithGoogle();
                    } else if (signInMethodsForEmail.includes('apple.com')) {
                        return await signInWithApple();
                    } else {
                        return await loginWithEmailAndPassword(email, password);
                    }
                } else { // @ts-ignore
                    if (error.code === 'auth/invalid-email') {
                        console.error('That email address is invalid!');
                    } else {
                        console.error('Error signing up with email and password');
                    }
                    throw error;
                }
            }
        },

        loginWithUsernameAndPassword: async (username: string, password: string) => {
            // retrieve the users email from the db
            // sign in with the users email and password
            try {
                const user = await UserService.getUserByUsername(username);
                if (!user) {
                    console.error('User not found');
                    throw new Error('User not found');
                }
                const signInMethodsForUsername = await fetchSignInMethodsForEmail(user.email);

                if (signInMethodsForUsername.length === 0) {
                    console.error('User not found');
                    throw new Error('User not found');
                }

                if (signInMethodsForUsername.includes('google.com')) {
                    return await signInWithGoogle();
                } else if (signInMethodsForUsername.includes('apple.com')) {
                    return await signInWithApple();
                } else if (signInMethodsForUsername.includes('password')) {
                    return await loginWithEmailAndPassword(user.email, password);
                }

                throw new Error('No supported sign-in methods found');
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

        signOut: async () => {
            try {
                await FirebaseAuthentication.signOut();
                await signOut(auth);
                return true
            } catch (error) {
                console.error('Error signing out:', error);
                throw error;
            }
        },

        deleteUser: async () => {
            try {
                await FirebaseAuthentication.deleteUser();
                return true;
            } catch (error) {
                console.error('Error deleting user:', error);
                return false;
            }
        },

        updateEmail: async (email: string) => {
            if (!user) {
                return false;
            }
            const res = await FirebaseAuthentication.updateEmail({newEmail: email});
            return true;
        },

        updatePassword: async (newPassword: string) => {
            if (!user) {
                return false;
            }

            const res = await FirebaseAuthentication.updatePassword({newPassword});
            return true;
        },

        confirmPasswordReset: async (verificationCode: string, newPassword: string) => {
            const res = await FirebaseAuthentication.confirmPasswordReset({
                oobCode: verificationCode,
                newPassword: newPassword,
            });

            return true;
        },

        sendPasswordResetEmail: async (email: string) => {
            await FirebaseAuthentication.sendPasswordResetEmail({
                email: email,
            });
            return true;
        },
    };
};

export default AuthService;
