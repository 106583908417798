import React from "react";
import {IonButton, IonIcon} from "@ionic/react";
import {logoApple, logoGoogle, personCircleOutline} from "ionicons/icons";
import '../Welcome.css'
import {motion} from "framer-motion";
import AuthService from "../../../services/auth.service";
import {useHistory} from "react-router-dom";

interface WelcomeTheseNutsProps {
    login: boolean;
    authService: ReturnType<typeof AuthService>;
    platform: string;
    handleAuthedStep: () => void;
    handleUserPasswordAuthedStep?: () => void;
    handleEmailAuthStep?: () => void;
}

type ButtonConfig = {
    icon: string;
    text: string;
    onClick: (platform: string) => void;
    iconClass: string;
    iconColor?: string;
    className: string;
    providerName: string;
};

const AuthenticationButtons: React.FC<WelcomeTheseNutsProps> = ({
                                                                    login,
                                                                    authService,
                                                                    platform,
                                                                    handleAuthedStep,
                                                                    handleUserPasswordAuthedStep,
                                                                    handleEmailAuthStep
                                                                }) => {

    const history = useHistory();

    const signInWithAppleHandler = async () => {
        try {
            console.log("SIGNING IN WITH APPLE");
            const res = await authService.signInWithApple();
            if (res) {
                handleAuthedStep();
            }

            return;
        } catch (error) {
            console.error("Error signing in with Apple: ", error);
            return;
        }
    }

    const signInWithGoogleHandler = async () => {
        try {
            console.log("SIGNING IN WITH GOOGLE");
            const res = await authService.signInWithGoogle();
            if (res) {
                handleAuthedStep();
            }

            return;
        } catch (error) {
            console.error("Error signing in with Google: ", error);
            return;
        }
    }

    const handleSignIn = async (provider: string) => {
        try {
            if (provider === 'apple') {
                await signInWithAppleHandler();
            } else if (provider === 'google') {
                await signInWithGoogleHandler();
            } else {
                if (login) {
                    handleUserPasswordAuthedStep();
                } else {
                    handleEmailAuthStep();
                }
            }
        } catch (error) {
            // Handle error
            console.error('Sign-in error:', error);
        } finally {
        }

        return;
    };

    const buttonConfigs: Record<string, ButtonConfig> = {
        apple: {
            icon: logoApple,
            text: login ? "Continue with Apple" : "Sign Up with Apple",
            onClick: (platform: string) => handleSignIn(platform),
            iconClass: "mr-2 text-black",
            iconColor: "dark",
            className: "",
            providerName: 'apple'
        },
        google: {
            icon: logoGoogle,
            text: login ? "Continue with Google" : "Sign Up with Google",
            onClick: (platform: string) => handleSignIn(platform),
            iconClass: "mr-2 text-red-500",
            className: "",
            providerName: 'google'
        },
        email: {
            icon: personCircleOutline,
            text: login ? 'Use email / username' : 'Use email',
            onClick: (platform: string) => handleSignIn(platform),
            iconClass: "mr-2 text-highlight",
            className: "",
            providerName: 'password'
        }
    };

    const platformOrder: Record<string, string[]> = {
        ios: ['apple', 'google', 'email'],
        android: ['google', 'apple', 'email'],
        web: ['email', 'google', 'apple']
    };

    const currentPlatformOrder = platformOrder[platform as keyof typeof platformOrder] || platformOrder.web;

    const renderButtonContent = (config: any) => (
        <motion.div whileHover={{scale: 1.05}} whileTap={{scale: 0.95}}>
            <IonButton
                className={(login ? 'neon-button' : 'neon-button-alt') + ` ${config.className} w-64 h-12`}
                onClick={() => {
                    config.onClick(config.providerName)
                }}
            >
                <IonIcon icon={config.icon} className={config.iconClass} color={config.iconColor}/>
                <span className="text-lg font-bold text-white normal-case">{config.text}</span>
            </IonButton>
        </motion.div>
    );

    const renderButton = (key: string) => {
        const config = buttonConfigs[key as keyof typeof buttonConfigs];
        return renderButtonContent(config);
    };

    return (
        <div className="flex flex-col justify-start items-center space-y-6 w-full">
            {currentPlatformOrder.map((key: string) => (
                <React.Fragment key={key}>
                    {renderButton(key)}
                </React.Fragment>
            ))}
        </div>
    );
};

export default AuthenticationButtons;