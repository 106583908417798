import {
    IonButton,
    IonContent,
    IonIcon,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    isPlatform,
} from "@ionic/react";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {Virtuoso, VirtuosoHandle} from 'react-virtuoso'
import LoginButton from "../components/ComponentsLogin/LoginButton/LoginButton";
import RotatingBanner from "../components/ComponentsUI/RotatingBanner/RotatingBanner";
import "../global.css";
import {Announcement} from "../models/announcements";
import {HotType} from "../models/hotType";
import {UserType} from "../models/usertype";
import AnnouncementsService from "../services/announcements.service";
import SearchService from "../services/search.service";
import UserService from "../services/user.service";
import {filterTricksForBlockedIds, getDateNowUTCISO, getSlangTerm} from "../services/utils";
import {AppContext} from "../AppStateProvider";
import CustomHelmet from "../components/ComponentsFunctional/CustomHelmet/CustomHelmet";
import {arrowUpOutline} from "ionicons/icons";
import {UserTrickSearchMetadata} from "../models/userTrickSearchMetadata";
import IonSpinnerMainContent from "../components/ComponentsUI/IonSpinnerMainContent/IonSpinnerMainContent";
import HomeTrickCardContainer from "../components/ComponentsUI/HomeTrickCardContainer/HomeTrickCardContainer";
import {useAuth} from "../AuthProvider";
import PreferencesService from "../services/preferences.service";
import ToolbarSearchBarExplore from "../components/ComponentsUI/ToolbarSearchBarExplore/ToolbarSearchBarExplore";

interface ExploreCardContext {
    isAuthed: boolean;
    isCapacitor: boolean;
    userType: string;
    userId: string;
    publicId: string;
    scrollSpeed: number;
    isScrolling: boolean;
    isDisabled: boolean;
    endOfList: (segment: string) => Promise<void>;
    idXAnnouncements: Announcement[];
    currentTextIndex: number;
    loadedTricks: boolean;
    isAuthLoading: boolean;
    tricksLength: number;
}

interface FollowingExploreCardContext extends ExploreCardContext {
    tricksLength: number;
    numUsersFollowing: number;
}

const ExplorePage: React.FC = () => {
    const {state} = useContext(AppContext);
    const {isAuthenticated, isLoading, user} = useAuth();

    // GENERIC
    const [trickSearch, setHomeSearch] = React.useState(undefined);
    const [segmentValue, setSegmentValue] = React.useState(undefined);
    const history = useHistory();
    const isCapacitor = isPlatform("capacitor");

    const overscanTop = document.documentElement.clientHeight * 2.5;
    const overscanBottom = document.documentElement.clientHeight * 2.5;
    const increaseViewportTop = 630;
    const increaseViewportBottom = 630;

    const [idXAnnouncements, setIdxAnnouncements] = React.useState<Announcement[]>([]);
    const [currentTextIndex, setCurrentTextIndex] = useState(0);

    const [totalNumberOfActiveElements, setTotalNumberOfActiveElements] = React.useState(0);

    // EXPLORE
    const numberOfExploreTricksToRetrieve = 6;
    const [exploreTricksPage, setExploreTricksPage] = React.useState(0);
    const [exploreTricks, setExploreTricks] = React.useState<UserTrickSearchMetadata[]>([]);
    const [exploreTricksInfiniteScrollDisabled, setExploreTricksInfiniteScrollDisabled] = React.useState(false);
    const [loadedInitExploreTricks, setLoadedInitExploreTricks] = React.useState(false);
    const [numberOfActiveElements, setNumberOfActiveElements] = React.useState(0);
    const exploreScrollerRef = useRef(null);
    const [lastExploreScrollTop, setLastExploreScrollTop] = useState(0);
    const [lastExploreTimestamp, setLastExploreTimestamp] = useState(0);
    const [scrollExploreSpeed, setScrollExploreSpeed] = useState(0);
    const [isScrollingExplore, setIsScrollingExplore] = useState(false);
    const exploreVirtuosoRef = useRef<VirtuosoHandle>(null);
    const [currentExploreTrickIndex, setCurrentExploreTrickIndex] = useState(0);

    // NEW
    const numberOfNewTricksToRetrieve = 6;
    const [newTricks, setNewTricks] = React.useState<UserTrickSearchMetadata[]>([]);
    const [dateNewCompTricks, setDateNewCompTricks] = React.useState<string>(getDateNowUTCISO())
    const [newTricksInfiniteScrollDisabled, setNewTricksInfiniteScrollDisabled] = React.useState(false);
    const [userTricksAllOut, setUserTricksAllOut] = React.useState<boolean>(false);
    const [loadedInitNewTricks, setLoadedInitNewTricks] = React.useState(false);
    const [numberOfActiveElementsNew, setNumberOfActiveElementsNew] = React.useState(0);
    const newVirtuosoRef = useRef<VirtuosoHandle>(null);
    const newScrollerRef = useRef(null);
    const [lastNewScrollTop, setLastNewScrollTop] = useState(0);
    const [lastNewTimestamp, setLastNewTimestamp] = useState(0);
    const [scrollNewSpeed, setScrollNewSpeed] = useState(0);
    const [isScrollingNew, setIsScrollingNew] = useState(false);

    // FOLLOWING
    const numberOfFollowingTricksToRetrieve = 6;
    const [followingTricks, setFollowingTricks] = React.useState<UserTrickSearchMetadata[]>([]);
    const [followingTricksInfiniteScrollDisabled, setFollowingTricksInfiniteScrollDisabled] = React.useState(false);
    const [loadedInitFollowing, setLoadedInitFollowing] = React.useState(false);
    const [numberOfActiveElementsFollow, setNumberOfActiveElementsFollow] = React.useState(0);
    const followingVirtuosoRef = useRef<VirtuosoHandle>(null);
    const followingScrollerRef = useRef(null);
    const [lastFollowingScrollTop, setLastFollowingScrollTop] = useState(0);
    const [lastFollowingTimestamp, setLastFollowingTimestamp] = useState(0);
    const [scrollFollowingSpeed, setScrollFollowingSpeed] = useState(0);
    const [isScrollingFollowing, setIsScrollingFollowing] = useState(false);

    // HOT
    const numberOfHotTricksToRetrieve = 6;
    const [hotTricks, setHotTricks] = React.useState<UserTrickSearchMetadata[]>([]);
    const [hotTricksInfiniteScrollDisabled, setHotTricksInfiniteScrollDisabled] = React.useState(false);
    const [loadedInitHotTricks, setLoadedInitHotTricks] = React.useState(false);
    const hotVirtuosoRef = useRef<VirtuosoHandle>(null);
    const hotScrollerRef = useRef(null);
    const [lastHotScrollTop, setLastHotScrollTop] = useState(0);
    const [lastHotTimestamp, setLastHotTimestamp] = useState(0);
    const [scrollHotSpeed, setScrollHotSpeed] = useState(0);
    const [isScrollingHot, setIsScrollingHot] = useState(false);

    const [selectedHotValue, setSelectedHotValue] = useState<string>(HotType.Year);


    // GENERIC
    const [userType, setUserType] = React.useState(UserType.UNKNOWN);
    const slangTerm = getSlangTerm();

    // Debug Elements
    const [scrollPositions, setScrollPositions] = useState({});
    const [numberOfActiveElementsHot, setNumberOfActiveElementsHot] = React.useState(0);

    // DEBUG
    // const calculateNumberOfActiveElements = (increment: number, page: string) => {
    //     if (page === 'explore') setNumberOfActiveElements((prev) => prev + increment);
    //     if (page === 'following') setNumberOfActiveElementsFollow((prev) => prev + increment);
    //     if (page === 'new') setNumberOfActiveElementsNew((prev) => prev + increment);
    //     if (page === 'hot') setNumberOfActiveElementsHot((prev) => prev + increment);
    //     const totalNumberOfActiveElements = numberOfActiveElements + numberOfActiveElementsFollow + numberOfActiveElementsNew + numberOfActiveElementsHot;
    //     setTotalNumberOfActiveElements(totalNumberOfActiveElements);
    // }

    function customCorrectItemSize(el: HTMLElement, field: 'offsetHeight' | 'offsetWidth') {
        try {
            return Math.round(el.getBoundingClientRect().height);
        } catch (e) {
            // you might want to throw a more meaningful error, like, getting the element id, or something.
            throw new Error(`${el} does not have getBoundingClientRect`)
        }
    }

    useEffect(() => {
        if (idXAnnouncements.length === 0) {
            return; // Don't set up the interval if there's no text in the array.
        }

        const interval = setInterval(() => {
            setCurrentTextIndex((prevIndex) =>
                prevIndex === idXAnnouncements.length - 1 ? 0 : prevIndex + 1
            );
        }, 4000);

        return () => {
            clearInterval(interval);
        };
    }, [idXAnnouncements]);


    useEffect(() => {
        const handleScroll = () => {
            const scrollerElement = exploreScrollerRef.current;
            if (scrollerElement) {
                const currentScrollTop = scrollerElement.scrollTop;
                const currentTimestamp = performance.now();
                const deltaTime = currentTimestamp - lastExploreTimestamp;
                const deltaScroll = currentScrollTop - lastExploreScrollTop;

                if (deltaTime > 0) {
                    const speed = (deltaScroll / deltaTime) * 1000; // pixels per second
                    setScrollExploreSpeed(speed);
                }

                setLastExploreScrollTop(currentScrollTop);
                setLastExploreTimestamp(currentTimestamp);
            }
        };

        const scrollerElement = exploreScrollerRef.current;

        if (scrollerElement) {
            scrollerElement.addEventListener('scroll', handleScroll);
        }

        // Cleanup the event listener on component unmount
        return () => {
            if (scrollerElement) {
                scrollerElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [lastExploreScrollTop, lastExploreTimestamp, exploreScrollerRef.current]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollerElement = newScrollerRef.current;
            if (scrollerElement) {
                const currentScrollTop = scrollerElement.scrollTop;
                const currentTimestamp = performance.now();
                const deltaTime = currentTimestamp - lastNewTimestamp;
                const deltaScroll = currentScrollTop - lastNewScrollTop;

                if (deltaTime > 0) {
                    const speed = (deltaScroll / deltaTime) * 1000; // pixels per second
                    setScrollNewSpeed(speed);
                }

                setLastNewScrollTop(currentScrollTop);
                setLastNewTimestamp(currentTimestamp);
            }
        };

        const scrollerElement = newScrollerRef.current;

        if (scrollerElement) {
            scrollerElement.addEventListener('scroll', handleScroll);
        }

        // Cleanup the event listener on component unmount
        return () => {
            if (scrollerElement) {
                scrollerElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [lastNewScrollTop, lastNewTimestamp, newScrollerRef.current]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollerElement = followingScrollerRef.current;
            if (scrollerElement) {
                const currentScrollTop = scrollerElement.scrollTop;
                const currentTimestamp = performance.now();
                const deltaTime = currentTimestamp - lastFollowingTimestamp;
                const deltaScroll = currentScrollTop - lastFollowingScrollTop;

                if (deltaTime > 0) {
                    const speed = (deltaScroll / deltaTime) * 1000; // pixels per second
                    setScrollFollowingSpeed(speed);
                }

                setLastFollowingScrollTop(currentScrollTop);
                setLastFollowingTimestamp(currentTimestamp);
            }
        };

        const scrollerElement = followingScrollerRef.current;

        if (scrollerElement) {
            scrollerElement.addEventListener('scroll', handleScroll);
        }

        // Cleanup the event listener on component unmount
        return () => {
            if (scrollerElement) {
                scrollerElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [lastFollowingScrollTop, lastFollowingTimestamp, followingScrollerRef.current]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollerElement = hotScrollerRef.current;
            if (scrollerElement) {
                const currentScrollTop = scrollerElement.scrollTop;
                const currentTimestamp = performance.now();
                const deltaTime = currentTimestamp - lastHotTimestamp;
                const deltaScroll = currentScrollTop - lastHotScrollTop;

                if (deltaTime > 0) {
                    const speed = (deltaScroll / deltaTime) * 1000; // pixels per second
                    setScrollHotSpeed(speed);
                }

                setLastHotScrollTop(currentScrollTop);
                setLastHotTimestamp(currentTimestamp);
            }
        };

        const scrollerElement = hotScrollerRef.current;

        if (scrollerElement) {
            scrollerElement.addEventListener('scroll', handleScroll);
        }

        // Cleanup the event listener on component unmount
        return () => {
            if (scrollerElement) {
                scrollerElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [lastHotScrollTop, lastHotTimestamp, hotScrollerRef.current]);

    useEffect(() => {
        const getAnnouncements = async () => {
            const announcements = await AnnouncementsService.getActiveAnnouncements();
            setIdxAnnouncements(announcements);
            window.prerenderReady = true;
        }

        getAnnouncements();
    }, []);

    useEffect(() => {
        console.log("Loading HomePage - Explore - GETTING EXPLORE TRICKS USE EFFECT");
        getInitExploreTricks();
    }, [state.blockedUsers.length, state.blockingUsers.length]);

    useEffect(() => {
        console.log("Loading HomePage - New - GETTING NEW TRICKS USE EFFECT");
        const getAuthInformation = async () => {
            if (isAuthenticated) {
                const user_type_retrieval = await UserService.getUserType(user.uid);
                setUserType(user_type_retrieval);
                console.log("Loading HomePage - New - AUTHED TO GET NEW TRICKS");
                await getInitNewTricks();
            } else {
                setNewTricks([]);
            }
        }

        if (!isLoading) {
            getAuthInformation();
        } else {
            setNewTricks([]);
        }
    }, [isAuthenticated, isLoading, user, state.blockedUsers.length, state.blockingUsers.length]);

    useEffect(() => {
        if (!isLoading) {
            if (isAuthenticated) {
                console.log("GETTING FOLLOW TRICKS - INIT - EXPLORE PAGE USE EFFECT");
                getInitFollowTricks();
            } else {
                setFollowingTricks([]);
            }
        } else {
            setFollowingTricks([]);
        }
    }, [state.user.id, state.following, state.blockedUsers.length, state.blockingUsers.length, isLoading, isAuthenticated, user]);

    useEffect(() => {
        if (!isLoading) {
            if (isAuthenticated) {
                console.log("GETTING HOT TRICKS - INIT - EXPLORE PAGE USE EFFECT");
                getInitTrendingTricksRunner();
            } else {
                setHotTricks([]);
            }
        } else {
            setHotTricks([]);
        }
    }, [state.blockedUsers.length, state.blockingUsers.length, isLoading, isAuthenticated]);

    useEffect(() => {
        explicitErrorHandler();
    }, []);


    // GENERIC
    const saveScrollPosition = (listRefs: any, setScrollPositions: any, segment: string) => {
        const currentList = listRefs.current[segment];
        if (currentList) {
            const scrollTop = currentList.scrollTop;
            setScrollPositions((prev: any) => ({...prev, [segment]: scrollTop}));
        }
    };

    // Utility function to restore scroll positions
    const restoreScrollPosition = (listRefs: any, scrollPositions: any, segment: any) => {
        const currentList = listRefs.current[segment];
        if (currentList && scrollPositions[segment] !== undefined) {
            currentList.scrollTop = scrollPositions[segment];
        }
    };

    const handleSegmentChangeNew = async (segment: string) => {
        setSegmentValue(segment);
        if (segment === "explore" && segmentValue !== "explore") {
            if (exploreVirtuosoRef.current) {
                exploreVirtuosoRef.current.scrollToIndex({
                    index: 0,
                    behavior: 'auto', // could go smooth but would rather not have performance issues
                    align: 'start'
                });
            }
        } else if (segment === "explore" && segmentValue === "explore") {
            if (exploreVirtuosoRef.current) {
                exploreVirtuosoRef.current.scrollToIndex({
                    index: 0,
                    behavior: 'auto', // could go smooth but would rather not have performance issues
                    align: 'start'
                });
                await resetExploreTricks();
                exploreVirtuosoRef.current.scrollToIndex({
                    index: 0,
                    behavior: 'auto', // could go smooth but would rather not have performance issues
                    align: 'start'
                });
            }
        }

        if (segment === "following" && segmentValue !== "following") {
            if (followingVirtuosoRef.current) {
                followingVirtuosoRef.current.scrollToIndex({
                    index: 0,
                    behavior: 'auto',
                    align: 'start'
                });
            }

        } else if (segment === "following" && segmentValue === "following") {
            if (followingVirtuosoRef.current) {
                followingVirtuosoRef.current.scrollToIndex({
                    index: 0,
                    behavior: 'auto',
                    align: 'start'
                });
                await resetFollowingTricks();
                followingVirtuosoRef.current.scrollToIndex({
                    index: 0,
                    behavior: 'auto',
                    align: 'start'
                });
            }
        }

        if (segment === "new" && segmentValue !== "new") {
            if (newVirtuosoRef.current) {
                newVirtuosoRef.current.scrollToIndex({
                    index: 0,
                    behavior: 'auto',
                    align: 'start'
                });
            }
        } else if (segment === "new" && segmentValue === "new") {
            if (newVirtuosoRef.current) {
                await resetNewTricks();
                newVirtuosoRef.current.scrollToIndex({
                    index: 0,
                    behavior: 'auto',
                    align: 'start'
                });
            }

        }

        if (segment === "hot" && segmentValue !== "hot") {
            if (hotVirtuosoRef.current) {
                hotVirtuosoRef.current.scrollToIndex({
                    index: 0,
                    behavior: 'auto',
                    align: 'start'
                });
            } else if (segment === "hot" && segmentValue === "hot") {
                hotVirtuosoRef.current.scrollToIndex({
                    index: 0,
                    behavior: 'auto',
                    align: 'start'
                });
                await resetHotTricks();
                hotVirtuosoRef.current.scrollToIndex({
                    index: 0,
                    behavior: 'auto',
                    align: 'start'
                });
            }
        }
    }

    // EXPLORE
    const processExploreTricksForBlockedUsers = async (initialTricks: UserTrickSearchMetadata[]) => {
        let tricks = initialTricks;
        let processedTricks: UserTrickSearchMetadata[] = [];

        // Continue the loop until you find non-blocked tricks or run out of new tricks to process.
        while (tricks.length > 0) {
            // Filter the tricks based on blocked users.
            processedTricks = filterTricksForBlockedIds(tricks, state.blockedUsers, state.blockingUsers);

            // If some non-blocked tricks are found, break the loop.
            if (processedTricks.length > 0) {
                break;
            }

            // Fetch more tricks if all current ones are blocked.
            tricks = await SearchService.getExploreTricks(
                numberOfExploreTricksToRetrieve,
                exploreTricksPage + 1
            );

            setExploreTricksPage(exploreTricksPage + 1);

            if (tricks.length === 0) {
                break;
            }

            tricks = filterTricksForBlockedIds(tricks, state.blockedUsers, state.blockingUsers);
        }

        return processedTricks;
    }

    const getInitExploreTricks = async () => {
        setExploreTricksInfiniteScrollDisabled(false);
        setLoadedInitExploreTricks(false);
        setExploreTricksPage(0);
        setExploreTricks([]);
        let newExploreTricks = await SearchService.getExploreTricks(numberOfExploreTricksToRetrieve, 0);
        newExploreTricks = await processExploreTricksForBlockedUsers(newExploreTricks);
        setExploreTricksPage(exploreTricksPage + 1);
        setExploreTricks(newExploreTricks);
        setLoadedInitExploreTricks(true);
    }

    const getMoreExploreTricks = async () => {
        let newExploreTricks = await SearchService.getExploreTricks(numberOfExploreTricksToRetrieve, exploreTricksPage);
        newExploreTricks = await processExploreTricksForBlockedUsers(newExploreTricks);
        setExploreTricksPage(exploreTricksPage + 1);

        setExploreTricks((exploreTricks) => [
            ...exploreTricks,
            ...newExploreTricks
        ])
    }

    const resetExploreTricks = async () => {
        await getInitExploreTricks();
    }

    const EmptyListExplore: React.FC<{ context?: ExploreCardContext }> = ({context}) => {
        return (
            <div className="h-screen flex flex-row justify-center">
                {
                    !context.loadedTricks ?
                        <div className="py-20 px-10">
                            <IonSpinnerMainContent className={""} size="medium"/>
                        </div> :
                        <div className="font-bold m-4 text-center">Looks like we don't have what your looking for based
                            on
                            your filters set at the toolbar, change them or change the game to see more!</div>
                }
            </div>

        )
    }

    const ExploreFooterContainer: React.FC<{ context?: ExploreCardContext }> = ({context}) => {
        if (context.tricksLength > 0 && !context.isDisabled) {
            return (
                <div className="my-2 flex flex-row justify-center items-center">
                    <IonSpinner color="theme-secondary"></IonSpinner>
                </div>
            )
        } else if (context.isDisabled) {
            return (
                <div className="my-2 flex flex-row justify-center items-center">
                    <IonButton fill="clear" color="theme-secondary" size="small" className="pb-4"
                               onClick={(e) => context.endOfList('explore')}>
                            <span className="text-zinc-300 text-lg">
                                No more clips to retrieve :/
                            </span>
                        <IonIcon slot="end" icon={arrowUpOutline}/>
                    </IonButton>
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }
    }

    const ExploreHeaderContainer: React.FC<{ context?: ExploreCardContext }> = ({context}) => {
        return (
            <div className="mb-2">
                <RotatingBanner initialTextArray={context.idXAnnouncements}
                                currentTextIndex={context.currentTextIndex}></RotatingBanner>
            </div>
        )
    }

    // FOLLOWING TRICKS
    const processFollowTricksForBlockedUsers = async (initialTricks: UserTrickSearchMetadata[]) => {
        let tricks = initialTricks;
        let processedTricks: UserTrickSearchMetadata[] = [];

        // Continue the loop until you find non-blocked tricks or run out of new tricks to process.
        while (tricks.length > 0) {
            // Filter the tricks based on blocked users.
            processedTricks = filterTricksForBlockedIds(tricks, state.blockedUsers, state.blockingUsers);

            // If some non-blocked tricks are found, break the loop.
            if (processedTricks.length > 0) {
                break;
            }

            // Fetch more tricks if all current ones are blocked.
            tricks = await UserService.getFollowingTricks(
                state.user.id,
                tricks[tricks.length - 1].date_uploaded,
                numberOfFollowingTricksToRetrieve
            );

            if (tricks.length === 0) {
                break;
            }

            tricks = filterTricksForBlockedIds(tricks, state.blockedUsers, state.blockingUsers);
        }

        return processedTricks;
    }

    const getInitFollowingTricks = async () => {
        setLoadedInitFollowing(false);
        setFollowingTricksInfiniteScrollDisabled(false);
        const actualDate = getDateNowUTCISO();
        setFollowingTricks([]);
        let initFollowingTricks: UserTrickSearchMetadata[] = await UserService.getFollowingTricks(state.user.id, actualDate, numberOfFollowingTricksToRetrieve);
        initFollowingTricks = await processFollowTricksForBlockedUsers(initFollowingTricks);
        setFollowingTricks(initFollowingTricks);
        setLoadedInitFollowing(true);
    }

    const getInitFollowTricks = async () => {
        if (state?.user?.id !== undefined && state?.user.id !== "") {
            await getInitFollowingTricks();
        }
    }

    const getMoreFollowingTricks = async () => {
        if (followingTricksInfiniteScrollDisabled) {
            return;
        }

        console.log("Getting more following tricks with date: " + followingTricks[followingTricks.length - 1].date_uploaded);
        let newFollowingTricks = await UserService.getFollowingTricks(state.user.id, followingTricks[followingTricks.length - 1].date_uploaded, numberOfFollowingTricksToRetrieve);

        if (newFollowingTricks.length === 0) {
            setFollowingTricksInfiniteScrollDisabled(true);
        }

        newFollowingTricks = await processFollowTricksForBlockedUsers(newFollowingTricks);

        setFollowingTricks([
            ...followingTricks,
            ...newFollowingTricks
        ]);
    }

    const resetFollowingTricks = async () => {
        await getInitFollowingTricks();
    }

    const EmptyListFollowing: React.FC<{ context?: FollowingExploreCardContext }> = ({context}) => {
        if (context.isAuthLoading) {
            return (
                <div className="flex flex-col gap-2 m-4 space-y-4 font-bold text-2xl">
                </div>
            )
        } else if (!context.isAuthed) {
            return (
                <div className="my-8 mx-4"><LoginButton title="See Clips of Riders You Follow"
                                                        upload={false} profile={true}/></div>
            )
        } else if (context.numUsersFollowing <= 0) {
            return (
                <div className="flex flex-row justify-center">
                    <div className="text-xl m-4 text-center">Explore Ecliptic and Follow Skiers and
                        Snowboarders To Create Your Own Feed!
                    </div>
                </div>
            )
        } else if (context.numUsersFollowing > 0 && context.tricksLength <= 0) {
            return (
                <div className="flex flex-row justify-center">
                    {
                        !context.loadedTricks ?
                            <div className="py-20 px-10">
                                <IonSpinnerMainContent className={""} size="medium"/>
                            </div> :
                            <div className="text-xl m-4 text-center">Start Following More People in the Park on
                                Ecliptic!</div>
                    }
                </div>
            )
        } else {
            return (
                <div className="flex flex-row justify-center">
                    {
                        !context.loadedTricks ?
                            <div className="py-20 px-10">
                                <IonSpinnerMainContent className={""} size="medium"/>
                            </div> :
                            <div className="text-xl m-4 text-center">Looks like we don't have what your looking for
                                based on your filters set at the toolbar, change them or change the game to see
                                more!</div>
                    }
                </div>
            )
        }
    }

    const FollowingFooterContainer: React.FC<{ context?: FollowingExploreCardContext }> = ({context}) => {
        if (
            context.isAuthLoading ||
            !context.isAuthed ||
            context.numUsersFollowing <= 0 ||
            (context.numUsersFollowing > 0 && context.tricksLength <= 0)
        ) {
            return (
                <div className="my-2 flex flex-row justify-center items-center">
                </div>
            )
        } else if (context.tricksLength > 0 && !context.isDisabled) {
            return (
                <div className="my-2 flex flex-row justify-center items-center">
                    <IonSpinner color="theme-secondary"></IonSpinner>
                </div>
            )
        } else {
            return (
                <div className="my-2 flex flex-row justify-center items-center">
                    <IonButton fill="clear" color="theme-secondary" size="small" className="pb-4"
                               onClick={(e) => context.endOfList('following')}>
                        <span className="text-zinc-300 text-lg">
                            No more clips to retrieve :/
                        </span>
                        <IonIcon slot="end" icon={arrowUpOutline}/>
                    </IonButton>
                </div>
            )
        }
    }

    const FollowingHeaderContainer: React.FC<{ context?: FollowingExploreCardContext }> = ({context}) => {
        return (
            <div className="mb-2">
                <RotatingBanner initialTextArray={context.idXAnnouncements}
                                currentTextIndex={context.currentTextIndex}></RotatingBanner>
            </div>
        )
    }

    // NEW TRICKS
    const processNewTricksForBlockedUsers = async (initialTricks: UserTrickSearchMetadata[]) => {
        let tricks = initialTricks;
        let processedTricks: UserTrickSearchMetadata[] = [];

        // Continue the loop until you find non-blocked tricks or run out of new tricks to process.
        while (tricks.length > 0) {
            // Filter the tricks based on blocked users.
            processedTricks = filterTricksForBlockedIds(tricks, state.blockedUsers, state.blockingUsers);

            // If some non-blocked tricks are found, break the loop.
            if (processedTricks.length > 0) {
                break;
            }

            // Fetch more tricks if all current ones are blocked.
            tricks = await UserService.getNewUserTricks(
                numberOfNewTricksToRetrieve,
                tricks[tricks.length - 1].date_uploaded
            );

            if (tricks.length === 0) {
                break;
            }

            tricks = filterTricksForBlockedIds(tricks, state.blockedUsers, state.blockingUsers);
        }

        return processedTricks;
    };

    const getInitNewTricks = async () => {
        setLoadedInitNewTricks(false);
        setNewTricksInfiniteScrollDisabled(false);
        setNewTricks([]);
        const actualDate = getDateNowUTCISO();
        let initNewTricks: UserTrickSearchMetadata[] = await UserService.getNewUserTricks(numberOfNewTricksToRetrieve, actualDate);
        initNewTricks = await processNewTricksForBlockedUsers(initNewTricks);
        setNewTricks(initNewTricks);
        setDateNewCompTricks(actualDate);
        setLoadedInitNewTricks(true);
    }

    const resetNewTricks = async () => {
        await getInitNewTricks();
        setUserTricksAllOut(false);
    }

    const getCompetitionTricks = async () => {
        let newNewTricks = await UserService.getNewCompetitionTricks(numberOfNewTricksToRetrieve, dateNewCompTricks);
        newNewTricks = await processNewTricksForBlockedUsers(newNewTricks);
        setDateNewCompTricks(newNewTricks[newNewTricks.length - 1].date_uploaded)
        return newNewTricks
    }

    const getMoreNewTricks = async () => {
        let newNewTricks = [];

        if (newTricks.length === 0) {
            return;
        }

        if (!userTricksAllOut) {
            newNewTricks = await UserService.getNewUserTricks(numberOfNewTricksToRetrieve, newTricks[newTricks.length - 1].date_uploaded);
        } else {
            newNewTricks = await getCompetitionTricks();
        }

        if (newNewTricks.length === 0 && !userTricksAllOut) {
            setUserTricksAllOut(true);
            newNewTricks = await getCompetitionTricks();
        }

        if (newNewTricks.length === 0) {
            setNewTricksInfiniteScrollDisabled(true);
        }

        newNewTricks = await processNewTricksForBlockedUsers(newNewTricks)

        setNewTricks([
            ...newTricks,
            ...newNewTricks
        ]);
    }

    const EmptyListNew: React.FC<{ context?: ExploreCardContext }> = ({context}) => {
        if (context.isAuthLoading) {
            return (
                <div className="flex flex-col gap-2 m-4 space-y-4 font-bold text-2xl">
                </div>
            )
        } else if (!context.isAuthed) {
            return (
                <div className="my-8 mx-4"><LoginButton title="See New Clips on Ecliptic"
                                                        upload={false} profile={true}/></div>
            )
        } else {
            return (
                <div className="h-screen flex flex-row justify-center">
                    {
                        !context.loadedTricks ?
                            <div className="py-20 px-10">
                                <IonSpinnerMainContent className={""} size="medium"/>
                            </div> :
                            <div className="text-xl m-4 text-center">Looks like we don't have what your looking for
                                based on
                                your
                                filters set at the toolbar, change them or change the game to see more! Refresh if
                                you've
                                uploaded
                                too or changed your filters</div>
                    }
                </div>
            )
        }
    }

    const NewFooterContainer: React.FC<{ context?: ExploreCardContext }> = ({context}) => {
        if (context.tricksLength > 0 && !context.isDisabled) {
            return (
                <div className="my-2 flex flex-row justify-center items-center">
                    <IonSpinner color="theme-secondary"></IonSpinner>
                </div>
            )
        } else if (context.isDisabled) {
            return (
                <div className="my-2 flex flex-row justify-center items-center">
                    <IonButton fill="clear" color="theme-secondary" size="small" className="pb-4"
                               onClick={(e) => context.endOfList('new')}>
                        <span className="text-zinc-300 text-lg">
                            No more clips to retrieve :/
                        </span>
                        <IonIcon slot="end" icon={arrowUpOutline}/>
                    </IonButton>
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }
    }

    const NewHeaderContainer: React.FC<{ context?: ExploreCardContext }> = ({context}) => {
        return (
            <div className="mb-2">
                <RotatingBanner initialTextArray={context.idXAnnouncements}
                                currentTextIndex={context.currentTextIndex}></RotatingBanner>
            </div>
        )
    }

    // HOT TRICKS
    const processHotTricksForBlockedUsers = async (initialTricks: UserTrickSearchMetadata[]) => {
        let tricks = initialTricks;
        let processedTricks: UserTrickSearchMetadata[] = [];

        // Continue the loop until you find non-blocked tricks or run out of new tricks to process.
        while (tricks.length > 0) {
            // Filter the tricks based on blocked users.
            processedTricks = filterTricksForBlockedIds(tricks, state.blockedUsers, state.blockingUsers);

            // If some non-blocked tricks are found, break the loop.
            if (processedTricks.length > 0) {
                break;
            }

            // Fetch more tricks if all current ones are blocked.
            tricks = await UserService.getTrendingTricksMore(
                numberOfHotTricksToRetrieve,
                tricks[tricks.length - 1].trick_id,
                selectedHotValue
            );

            if (tricks.length === 0) {
                break;
            }

            tricks = filterTricksForBlockedIds(tricks, state.blockedUsers, state.blockingUsers);
        }

        return processedTricks;
    }

    const getHotSettings = async () => {
        const hotSettings = await PreferencesService.getHotSettings();
        if (hotSettings) {
            return hotSettings;
        } else {
            await setHotSettings(HotType.Year);
            return HotType.Year;
        }
    }

    const getInitTrendingTricksRunner = async () => {
        setLoadedInitHotTricks(false);
        setHotTricksInfiniteScrollDisabled(false);
        const selectedHotValue = await getHotSettings();
        setSelectedHotValue(selectedHotValue);
        await getInitTrendingTricks(selectedHotValue);
        setLoadedInitHotTricks(true);
    }

    const getInitTrendingTricks = async (hotClipsSetting: string) => {
        setHotTricks([]);
        const topScore = "1.7976931348623157e+308";

        let initHotTricks: UserTrickSearchMetadata[] = await UserService.getTrendingTricks(numberOfHotTricksToRetrieve, topScore, hotClipsSetting);
        initHotTricks = await processHotTricksForBlockedUsers(initHotTricks);
        setHotTricks(initHotTricks);
    }

    const getMoreHotTricks = async () => {
        if (hotTricksInfiniteScrollDisabled) {
            return;
        }

        if (hotTricks.length === 0) {
            return;
        }

        let newHotTricks = await UserService.getTrendingTricksMore(numberOfHotTricksToRetrieve, hotTricks[hotTricks.length - 1].trick_id, selectedHotValue);

        if (newHotTricks.length === 0) {
            setHotTricksInfiniteScrollDisabled(true);
        }

        newHotTricks = await processHotTricksForBlockedUsers(newHotTricks);

        setHotTricks([
            ...hotTricks,
            ...newHotTricks
        ]);
    }

    const resetHotTricks = async () => {
        await getInitTrendingTricksRunner();
    }

    const HotListEmpty: React.FC<{ context?: ExploreCardContext }> = ({context}) => {
        if (context.isAuthLoading) {
            return (
                <div className="flex flex-col gap-2 m-4 space-y-4 font-bold text-2xl">
                </div>
            )
        } else if (!context.isAuthed) {
            return (
                <div className="my-8 mx-4"><LoginButton title="Watch the Hottest Clips on Ecliptic"
                                                        upload={false} profile={true}/></div>
            )
        } else {
            return (
                <div className="h-screen flex flex-row justify-center">
                    {
                        !context.loadedTricks ?
                            <div className="py-20 px-10">
                                <IonSpinnerMainContent className={""} size="medium"/>
                            </div> :
                            <div className="text-xl m-4 text-center">Looks like we don't have what your looking for
                                based on
                                your
                                filters set at the toolbar, change them or change the game to see more! Refresh if
                                you've
                                uploaded
                                too or changed your filters</div>
                    }
                </div>
            )
        }
    }

    const HotFooterContainer: React.FC<{ context?: ExploreCardContext }> = ({context}) => {
        if (context.tricksLength > 0 && !context.isDisabled) {
            return (
                <div className="my-2 flex flex-row justify-center items-center">
                    <IonSpinner color="theme-secondary"></IonSpinner>
                </div>
            )
        } else if (context.isDisabled) {
            return (
                <div className="my-2 flex flex-row justify-center items-center">
                    <IonButton fill="clear" color="theme-secondary" size="small" className="pb-4"
                               onClick={(e) => context.endOfList('hot')}>
                        <span className="text-zinc-300 text-lg">
                            No more clips to retrieve :/
                        </span>
                        <IonIcon slot="end" icon={arrowUpOutline}/>
                    </IonButton>
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }
    }

    const HotHeaderContainer: React.FC<{ context?: ExploreCardContext }> = ({context}) => {
        return (
            <div>
                <RotatingBanner initialTextArray={context.idXAnnouncements}
                                currentTextIndex={context.currentTextIndex}></RotatingBanner>
                <div className="items-center mt-4 mx-8 justify-center">
                    <IonSelect
                        label="Hotness Level"
                        interface="popover"
                        placeholder="Select hotness level"
                        value={selectedHotValue}
                        style={{ionBackgroundColor: "none"}}
                        onIonChange={(e) => handleHotChange(e.detail.value!)}
                    >
                        <IonSelectOption value={HotType.Day}>Daily Heat</IonSelectOption>
                        <IonSelectOption value={HotType.Week}>Weekly Zest</IonSelectOption>
                        <IonSelectOption value={HotType.Month}>Monthly Movers</IonSelectOption>
                        <IonSelectOption value={HotType.Season}>Seasonal Bangers</IonSelectOption>
                        <IonSelectOption
                            value={HotType.Year}>Yearly Fire</IonSelectOption>
                        <IonSelectOption
                            value={HotType.All}>All Time Bombs</IonSelectOption>
                    </IonSelect>
                </div>
            </div>
        )
    }

    const setHotSettings = async (selectedHotValue: string) => {
        await PreferencesService.setHotSettings(selectedHotValue);
    }

    const explicitErrorHandler = () => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop completed with undelivered notifications.' ||
                e.message === 'ResizeObserver loop limit exceeded') {
                e.stopImmediatePropagation();
            }
        })
    }

    const handleHotChange = async (selectedValue: string) => {
        if (selectedValue === '') {
            await setHotSettings(HotType.Year);
            await getInitTrendingTricksRunner();
        } else {
            await setHotSettings(selectedValue);
            await getInitTrendingTricksRunner();
        }
    };

    return (
        <IonPage>
            <CustomHelmet title={"Ecliptic // A Ski and Snowboard Spot"}
                          description={"Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed. Search for any trick by name, rider, location, build your bag of tricks, and get noticed by the crew, sponsors or the world."}
                          image={"https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                          url={`https://ecliptic.day/home`}/>
            <ToolbarSearchBarExplore handleSegmentChangeNew={handleSegmentChangeNew}
                                     isAuthenticated={isAuthenticated}></ToolbarSearchBarExplore>
            <IonContent scrollY={false}>
                {
                    segmentValue === 'explore' ?
                        <Virtuoso<UserTrickSearchMetadata, ExploreCardContext>
                            style={{height: '100%'}}
                            data={exploreTricks}
                            context={{
                                isAuthed: isAuthenticated,
                                isCapacitor: isCapacitor,
                                userId: state.user?.auth_id,
                                publicId: state.user?.id,
                                userType: userType,
                                isScrolling: isScrollingExplore,
                                scrollSpeed: scrollExploreSpeed,
                                isDisabled: exploreTricksInfiniteScrollDisabled,
                                endOfList: handleSegmentChangeNew,
                                idXAnnouncements: idXAnnouncements,
                                currentTextIndex: currentTextIndex,
                                loadedTricks: loadedInitExploreTricks,
                                isAuthLoading: isLoading,
                                tricksLength: exploreTricks.length
                            }}
                            endReached={getMoreExploreTricks}
                            increaseViewportBy={{top: increaseViewportTop, bottom: increaseViewportBottom}}
                            overscan={{main: overscanTop, reverse: overscanBottom}}
                            itemContent={(index, item, context) => {
                                return (
                                    <HomeTrickCardContainer
                                        trick={item}
                                        isAuthed={context.isAuthed}
                                        isCapacitor={context.isCapacitor}
                                        userId={context.userId}
                                        publicId={context.publicId}
                                        trickId={item.id}
                                        userType={context.userType}
                                        isScrolling={context.isScrolling}
                                        scrollSpeed={context.scrollSpeed}
                                    />
                                );
                            }}
                            itemSize={customCorrectItemSize}
                            components={{
                                Footer: ExploreFooterContainer,
                                EmptyPlaceholder: EmptyListExplore,
                                Header: ExploreHeaderContainer,
                            }}
                            computeItemKey={(index, item, context) => {
                                return item.id + "-" + index;
                            }}
                            scrollerRef={(ref) => {
                                exploreScrollerRef.current = ref;
                            }}
                            isScrolling={setIsScrollingExplore}
                            ref={exploreVirtuosoRef}
                        />
                        : segmentValue === 'following' ?
                            (
                                <Virtuoso<UserTrickSearchMetadata, FollowingExploreCardContext>
                                    style={{height: '100%'}}
                                    data={followingTricks}
                                    endReached={getMoreFollowingTricks}
                                    context={{
                                        isAuthed: isAuthenticated,
                                        isCapacitor: isCapacitor,
                                        userId: state.user?.auth_id,
                                        publicId: state.user?.id,
                                        userType: userType,
                                        isScrolling: isScrollingFollowing,
                                        scrollSpeed: scrollFollowingSpeed,
                                        isDisabled: followingTricksInfiniteScrollDisabled,
                                        endOfList: handleSegmentChangeNew,
                                        idXAnnouncements: idXAnnouncements,
                                        currentTextIndex: currentTextIndex,
                                        loadedTricks: loadedInitFollowing,
                                        isAuthLoading: isLoading,
                                        tricksLength: followingTricks.length,
                                        numUsersFollowing: state.following
                                    }}
                                    overscan={{
                                        main: overscanTop,
                                        reverse: followingTricksInfiniteScrollDisabled ? 0 : overscanBottom
                                    }}
                                    increaseViewportBy={{
                                        top: increaseViewportTop,
                                        bottom: followingTricksInfiniteScrollDisabled ? 0 : increaseViewportBottom
                                    }}
                                    itemContent={(index, item, context) => {
                                        return (
                                            <HomeTrickCardContainer
                                                trick={item}
                                                isAuthed={context.isAuthed}
                                                isCapacitor={context.isCapacitor}
                                                userId={context.userId}
                                                publicId={context.publicId}
                                                trickId={item.id}
                                                userType={context.userType}
                                                isScrolling={context.isScrolling}
                                                scrollSpeed={context.scrollSpeed}
                                            />
                                        );
                                    }}
                                    itemSize={customCorrectItemSize}
                                    components={{
                                        Footer: FollowingFooterContainer,
                                        EmptyPlaceholder: EmptyListFollowing,
                                        Header: FollowingHeaderContainer
                                    }}
                                    computeItemKey={(index, item, context) => {
                                        return item.id + "-" + index;
                                    }}
                                    ref={followingVirtuosoRef}
                                    scrollerRef={(ref) => {
                                        followingScrollerRef.current = ref;
                                    }}
                                    isScrolling={setIsScrollingFollowing}
                                />
                            ) :
                            segmentValue === 'new' ?
                                (
                                    <Virtuoso<UserTrickSearchMetadata, ExploreCardContext>
                                        data={newTricks}
                                        style={{height: '100%'}}
                                        endReached={getMoreNewTricks}
                                        context={{
                                            isAuthed: isAuthenticated,
                                            isCapacitor: isCapacitor,
                                            userId: state.user?.auth_id,
                                            publicId: state.user?.id,
                                            userType: userType,
                                            isScrolling: isScrollingNew,
                                            scrollSpeed: scrollNewSpeed,
                                            isDisabled: newTricksInfiniteScrollDisabled,
                                            endOfList: handleSegmentChangeNew,
                                            idXAnnouncements: idXAnnouncements,
                                            currentTextIndex: currentTextIndex,
                                            loadedTricks: loadedInitNewTricks,
                                            isAuthLoading: isLoading,
                                            tricksLength: newTricks.length
                                        }}
                                        overscan={{
                                            main: overscanTop,
                                            reverse: newTricksInfiniteScrollDisabled ? 0 : overscanBottom
                                        }}
                                        increaseViewportBy={{
                                            top: increaseViewportTop,
                                            bottom: newTricksInfiniteScrollDisabled ? 0 : increaseViewportBottom
                                        }}
                                        itemContent={(index, item, context) => {
                                            return (
                                                <HomeTrickCardContainer
                                                    trick={item}
                                                    isAuthed={context.isAuthed}
                                                    isCapacitor={context.isCapacitor}
                                                    userId={context.userId}
                                                    publicId={context.publicId}
                                                    trickId={item.id}
                                                    userType={context.userType}
                                                    isScrolling={context.isScrolling}
                                                    scrollSpeed={context.scrollSpeed}
                                                />);
                                        }}
                                        itemSize={customCorrectItemSize}
                                        components={{
                                            Footer: NewFooterContainer,
                                            EmptyPlaceholder: EmptyListNew,
                                            Header: NewHeaderContainer
                                        }}
                                        computeItemKey={(index, item, context) => {
                                            return item.id + "-" + index;
                                        }}
                                        ref={newVirtuosoRef}
                                        scrollerRef={(ref) => {
                                            newScrollerRef.current = ref;
                                        }}
                                        isScrolling={setIsScrollingNew}
                                    />
                                ) :
                                segmentValue === 'hot' ?
                                    (
                                        <Virtuoso<UserTrickSearchMetadata, ExploreCardContext>
                                            data={hotTricks}
                                            style={{height: '100%'}}
                                            endReached={() => getMoreHotTricks()}
                                            context={{
                                                isAuthed: isAuthenticated,
                                                isCapacitor: isCapacitor,
                                                userId: state.user?.auth_id,
                                                publicId: state.user?.id,
                                                userType: userType,
                                                isScrolling: isScrollingHot,
                                                scrollSpeed: scrollHotSpeed,
                                                isDisabled: hotTricksInfiniteScrollDisabled,
                                                endOfList: handleSegmentChangeNew,
                                                idXAnnouncements: idXAnnouncements,
                                                currentTextIndex: currentTextIndex,
                                                loadedTricks: loadedInitHotTricks,
                                                isAuthLoading: isLoading,
                                                tricksLength: hotTricks.length
                                            }}
                                            overscan={{
                                                main: overscanTop,
                                                reverse: hotTricksInfiniteScrollDisabled ? 0 : overscanBottom
                                            }}
                                            increaseViewportBy={{
                                                top: increaseViewportTop,
                                                bottom: hotTricksInfiniteScrollDisabled ? 0 : increaseViewportBottom
                                            }}
                                            itemContent={(index, item, context) => {
                                                return (
                                                    <HomeTrickCardContainer
                                                        trick={item}
                                                        isAuthed={context.isAuthed}
                                                        isCapacitor={context.isCapacitor}
                                                        userId={context.userId}
                                                        publicId={context.publicId}
                                                        trickId={item.id}
                                                        userType={context.userType}
                                                        isScrolling={context.isScrolling}
                                                        scrollSpeed={context.scrollSpeed}
                                                    />
                                                );
                                            }}
                                            computeItemKey={(index, item, context) => {
                                                return item.id + "-" + index;
                                            }}
                                            itemSize={customCorrectItemSize}
                                            components={{
                                                Footer: HotFooterContainer,
                                                EmptyPlaceholder: HotListEmpty,
                                                Header: HotHeaderContainer
                                            }}
                                            ref={hotVirtuosoRef}
                                            scrollerRef={(ref) => {
                                                hotScrollerRef.current = ref;
                                            }}
                                            isScrolling={setIsScrollingHot}
                                        />
                                    ) :
                                    null
                }
            </IonContent>
        </IonPage>
    );
};

export default ExplorePage;
