import {IonButton} from "@ionic/react";
import React, {useEffect} from "react";
import {Capacitor, PluginListenerHandle} from '@capacitor/core';
import {Motion} from '@capacitor/motion';
import {AppContext} from "../../../AppStateProvider";
import ShopRedirect from "../../ComponentsPurchases/ShopRedirect/ShopRedirect";
import {SubscriptionType} from "../../../models/subscriptionType";

interface ContainerProps {
    tricks: string[],
    maxSuggestions: boolean
    loadMoreSuggestions: (numTricks: number) => void,
    sports: string[],
    isTheProfile: boolean,
    maxTricks: number,
    username: string,
    isNative: boolean,
    userProfileId: string,
    getSuggestedTricksHelper: (userId: string, isYourProfile: boolean) => void,
    onClickTrick: (trick: string) => void,
    goToShop?: () => void
}

const SuggestedTrick: React.FC<ContainerProps> = (props: ContainerProps) => {
    const {state} = React.useContext(AppContext);

    const [blur, setBlur] = React.useState<boolean>(false);

    const numberOfRobotGifs = 20;

    const [sportSearch, setSportSearch] = React.useState<string>("");
    const [shakingLikeAPoloraidPicture, setShakingLikeAPoloraidPicture] = React.useState<boolean>(false);
    const [loadingSuggestedTricks, setLoadingSuggestedTricks] = React.useState<boolean>(false);
    const [loadingGifSelected, setLoadingGifSelected] = React.useState<number>(Math.floor(Math.random() * numberOfRobotGifs));

    let accelHandler: PluginListenerHandle;

    const onTriggerShake = async () => {
        setShakingLikeAPoloraidPicture(true);

        if (Capacitor.getPlatform() !== "android") {
            try {
                const res = await (DeviceMotionEvent as any).requestPermission();
            } catch (e) {
                setShakingLikeAPoloraidPicture(false);
                console.log("Couldn't shake it like a polaroid picture", e);
                return;
            }
        }

        // Once the user approves, can start listening:
        accelHandler = await Motion.addListener('accel', async (event) => {
            console.log('Device motion event:', event);
            if (event.acceleration.x > 5 || event.acceleration.y > 5 || event.acceleration.z > 5) {
                setLoadingSuggestedTricks(true);
                setTimeout(async () => {
                    await props.getSuggestedTricksHelper(props.userProfileId, false);
                    setLoadingSuggestedTricks(false);
                    setShakingLikeAPoloraidPicture(false);
                    setLoadingGifSelected(Math.floor(Math.random() * numberOfRobotGifs));

                    if (accelHandler) {
                        await accelHandler.remove();
                    }
                }, 3000);
            }
        });
    }

    const onCancelShake = async () => {
        if (accelHandler) {
            await accelHandler.remove();
        }

        setShakingLikeAPoloraidPicture(false);
        setLoadingSuggestedTricks(false);
    }

    useEffect(() => {
        if (props.sports.length == 1) {
            setSportSearch(props.sports[0]);
        }
    }, [props.tricks, props.maxSuggestions, props.isTheProfile]);

    useEffect(() => {
        setLoadingGifSelected(Math.floor(Math.random() * numberOfRobotGifs));
    }, []);

    useEffect(() => {
        if (state.isSubscribed) {
            if (state.subscriptionLevel == SubscriptionType.Premium) {
                setBlur(false);
            } else {
                setBlur(true);
            }
        } else {
            setBlur(true);
        }
    }, [state.isSubscribed]);

    return (
        <div>
            {
                blur ?
                    <div className="flex flex-col align-middle items-center mt-4">
                        <ShopRedirect
                            title={"Head to the Smoke Shack and Sign Up for the Tow Rope Pass to View Suggested Tricks!"}
                            shopRedirect={props.goToShop}/>
                    </div> : <div></div>
            }
            <div className={blur ? "blur-lg" : ""}>
                {
                    shakingLikeAPoloraidPicture && loadingSuggestedTricks ?
                        <div className="flex flex-col align-middle items-center mt-4 gap-y-10">
                            <div className="object-contain aspect-video">
                                <img src={`/assets/photos/robots/${loadingGifSelected}.gif`}
                                     className="w-full h-full block object-contain"
                                     alt="loading suggested tricks"></img>
                            </div>
                            {props.isTheProfile ?
                                <div className="text-lg text-center break-normal">Loading suggested tricks based on your
                                    bag, profile, and clips!</div> :
                                <div className="text-lg text-center break-normal">Loading suggested tricks based
                                    on {props.username}'s bag, profile, and clips!</div>}
                        </div> :
                        shakingLikeAPoloraidPicture ?
                            <div className="flex flex-col align-middle items-center mt-4">
                                <img src="/assets/photos/icons8-palm-scan.gif" alt="shake hand"></img>
                            </div> :
                            <div
                                className="grid grid-flow-row auto-row-max xs:grid-cols-2 sm:grid-cols-2 mt-4 place-items-stretch">
                                {props.tricks.map((trick, index) => {
                                    return (
                                        ((index + 1) === 1 || (index + 1) === 4 || (index + 1) === 5 || (index + 1) === 8) ?
                                            <IonButton className="m-1" color="theme-secondary" key={index}
                                                       fill="outline" shape="round"
                                                       onClick={() => props.onClickTrick(trick)}>
                                                <span color="text-white">{trick}</span>
                                            </IonButton> :
                                            <IonButton className="m-1" key={index} color="theme-secondary" fill="solid"
                                                       shape="round" onClick={() => props.onClickTrick(trick)}>
                                                <span color="text-black">{trick}</span>
                                            </IonButton>
                                    );
                                })}
                            </div>
                }
                <div className="flex flex-col align-middle items-center mt-4">
                    {
                        props.maxSuggestions && props.isNative && !shakingLikeAPoloraidPicture ?
                            <IonButton color="favorite-secondary" fill="solid"
                                       onClick={() => onTriggerShake()}>
                                {props.isTheProfile ? "Shake For More!" : "Shake For More Of " + props.username + "'s Suggested Tricks!"}
                            </IonButton> :
                            props.maxSuggestions && props.isNative && shakingLikeAPoloraidPicture ?
                                <IonButton color="favorite-secondary" fill="solid"
                                           onClick={() => onCancelShake()}>
                                    Cancel
                                </IonButton> :
                                props.maxSuggestions && props.isTheProfile && !props.isNative ?
                                    <div>
                                        <div className="text-center text-base">Upload A Clip To Get New Suggestions
                                        </div>
                                        <div className="text-center text-base">Check Back Every Day For New
                                            Inspiration!
                                        </div>
                                    </div> :
                                    props.tricks.length < props.maxTricks ?
                                        <IonButton color="favorite" fill="solid"
                                                   onClick={() => props.loadMoreSuggestions(props.tricks.length)}>
                                            Load More
                                        </IonButton> :
                                        null
                    }
                </div>
            </div>
        </div>
    )
};

export default SuggestedTrick;
