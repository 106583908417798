import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";

interface ContainerProps {
    title: string;
    description: string;
    image: string;
    url: string;
}

const CustomHelmet: React.FC<ContainerProps> = (props: ContainerProps) => {
    const [title, setTitle] = useState<string>(props.title || "Ecliptic // View");
    const [description, setDescription] = useState<string>(props.description || "Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed");
    const [image, setImage] = useState<string>(props.image || "https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png");
    const [url, setUrl] = useState<string>(props.url || "");

    useEffect(() => {
        setTitle(props.title);
    }, [props.title]);

    useEffect(() => {
        setDescription(props.description);
    }, [props.description]);

    useEffect(() => {
        setImage(props.image);
    }, [props.image]);

    useEffect(() => {
        setUrl(props.url);
    }, [props.url]);

    return (
        <Helmet>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content={title}/>
            <meta
                property="og:description"
                content={description}
            />
            <meta
                property="og:image"
                content={image}
            />
            <meta property="og:url" content={url}/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@idxstyle"/>
            <meta
                property="twitter:image"
                content={image}
            />
        </Helmet>
    );
};

export default CustomHelmet;