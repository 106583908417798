import axios from "axios";
import UserService from "./user.service";
import PreferencesService from "./preferences.service";

const SettingsService = {
    sendFeedback: async (name: string, comments: string) => {
        const auth_id = await PreferencesService.getUserUid();
        let userName = "";
        let userEmail = "";

        if (!auth_id) {
            console.log("NO AUTH ID");
        } else {
            const user = await UserService.getUserByAuthId(auth_id);
            userEmail = user.email;
            userName = user.username;
        }

        const params = {
            name: name,
            email: userEmail,
            comments: comments,
            username: userName
        };

        return await axios.post(`${process.env.REACT_APP_DISCORD_BOT_SERVER}/feedback`, null, {params: params})
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    getTimeSinceLastPrompt: async () => {
        return await PreferencesService.getRatingPromptTime();
    },

    setLastPromptTime: async () => {
        const now = new Date().toISOString();
        await PreferencesService.setRatingPromptTime(now);
    }

};

export default SettingsService;