import {Capacitor} from "@capacitor/core";
import {
    IonAvatar,
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonPage,
    IonSpinner,
    isPlatform,
} from "@ionic/react";
import {IonInfiniteScrollCustomEvent} from "@ionic/core/dist/types/components";
import React, {useContext, useEffect, useState} from "react";
import {generatePath, RouteComponentProps, useHistory} from "react-router";
import ExitFilterButton from "../components/ComponentsSearch/ExitFilterButton/ExitFilterButton";
import FeatureSelect from "../components/ComponentsSearch/FeatureSelect/FeatureSelect";
import FilterToolbar from "../components/ComponentsSearch/FilterToolbar/FilterToolbar";
import FilterTrickButton from "../components/ComponentsSearch/FilterTricksButton/FilterTricksButton";
import LoginButton from "../components/ComponentsLogin/LoginButton/LoginButton";
import SearchBarFilter from "../components/ComponentsSearch/SearchBarFilter/SearchBarFilter";
import SportSelect from "../components/ComponentsSearch/SportSelect/SportSelect";
import ToolbarSearchBar from "../components/ComponentsUI/ToolbarSearchBar/ToolbarSearchBar";
import TrickAttributeSelect from "../components/ComponentsUI/TrickAttributeSelect/TrickAttributeSelect";
import TrickDropdown from "../components/ComponentsUI/TrickDropdown/TrickDropdown";
import "../global.css";
import {SportType} from "../models/sportType";
import {UserProfileSearch} from "../models/user-profile-search";
import {UserTrickSearch} from "../models/user-trick-search";
import SearchService from "../services/search.service";
import UserService from "../services/user.service";
import {AppContext} from "../AppStateProvider";
import TrickSearchCardHolder from "../components/ComponentsSearch/TrickSearchCard/TrickSearchCardHolder";
import CustomHelmet from "../components/ComponentsFunctional/CustomHelmet/CustomHelmet";
import {UserTrickSearchMetadata} from "../models/userTrickSearchMetadata";
import {filterTricksForBlockedIds} from "../services/utils";
import IonSpinnerMainContent from "../components/ComponentsUI/IonSpinnerMainContent/IonSpinnerMainContent";
import {useAuth} from "../AuthProvider";

interface FilterPageProps
    extends RouteComponentProps<{
        query: string;
    }> {
}

const Filter: React.FC<FilterPageProps> = ({match}) => {
    const history = useHistory();
    const {state} = useContext(AppContext);
    const {isAuthenticated, isLoading, user} = useAuth();

    const isCapacitor = Capacitor.isNativePlatform();
    const platform = Capacitor.getPlatform();

    const [showFilterOverlay, setShowFilterOverlay] = useState(false); // shows the filter buttons

    // search for tricks by trick name or riders doing them (ie: I search for A Hall and get A Hall tricks)
    const [filterSearch, setFilterSearch] = useState("");
    const [trickSearch, setTrickSearch] = useState<string>("");
    const [trickSearchResults, setTrickSearchResults] = useState<UserTrickSearchMetadata[]>([]);
    const [exploreTrickSearchResults, setExploreTrickSearchResults] = useState<UserTrickSearchMetadata[]>([]);

    // for filtering
    const [sport, setSport] = useState("");
    const [sportDisplay, setSportDisplay] = useState("");
    const [feature, setFeature] = useState("");
    const [featureSearchValue, setFeatureSearchValue] = useState("");
    const [trickAttribute, setTrickAttribute] = useState("");
    const [filteredTricks, setFilteredTricks] = useState([]);

    const [showFeatureOptions, setShowFeatureOptions] = useState(false);
    const [showTrickAttributeOptions, setShowTrickAttributeOptions] = useState(false);
    const [showTricks, setShowTricks] = useState(false);
    const [initLoading, setInitLoading] = useState(false);

    const [pageExplore, setPageExplore] = useState(0);

    // for pagination
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(18);
    const [searchTricksInfiniteScrollDisabled, setSearchTricksInfiniteScrollDisabled] = useState(false);

    const [displayClips, setDisplayClips] = useState<boolean>(true);
    const [displayProfiles, setDisplayProfiles] = useState<boolean>(false);

    // PROFILE SEARCH
    const [profileSearchResults, setProfileSearchResults] = React.useState<UserProfileSearch[]>([]);
    const [profileToProfilePic, setProfileToProfilePic] = React.useState(new Map<string, string>());
    const [loadingProfile, setLoadingProfile] = React.useState(false);

    const onSelectClips = async () => {
        setDisplayClips(true);
        setDisplayProfiles(false);
        await onTrickSearch(trickSearch);
    }

    const onSelectRiders = async () => {
        setDisplayClips(false);
        setDisplayProfiles(true);
        await getProfileResults(trickSearch);
    }

    const getProfileResults = async (search: string) => {
        setTrickSearch(search)
        setLoadingProfile(true);
        setProfileSearchResults([]);
        const res: UserProfileSearch[] = await SearchService.searchUserProfiles(search);
        const userIdToProfilePic = new Map<string, string>();
        for (let i = 0; i < res.length; i++) {
            const userProfilePic = await UserService.getUserProfilePic(res[i].id);
            userIdToProfilePic.set(res[i].id, userProfilePic['url']);
        }

        setProfileToProfilePic(userIdToProfilePic);
        await setProfileSearchResults(res);
        setLoadingProfile(false);
    }

    const onClickPersonProfile = (user: UserProfileSearch) => {
        history.push(`/profile/${user.username}`);
    }

    const processExploreTricksForBlockedUsers = async (initialTricks: UserTrickSearchMetadata[]) => {
        let tricks = initialTricks;
        let processedTricks: UserTrickSearchMetadata[] = [];

        // Continue the loop until you find non-blocked tricks or run out of new tricks to process.
        while (tricks.length > 0) {
            // Filter the tricks based on blocked users.
            processedTricks = filterTricksForBlockedIds(tricks, state.blockedUsers, state.blockingUsers);

            // If some non-blocked tricks are found, break the loop.
            if (processedTricks.length > 0) {
                break;
            }

            // Fetch more tricks if all current ones are blocked.
            tricks = await SearchService.getExploreTricks(
                pageSize,
                pageExplore + 1
            );

            setPageExplore(pageExplore + 1);

            if (tricks.length === 0) {
                break;
            }

            tricks = filterTricksForBlockedIds(tricks, state.blockedUsers, state.blockingUsers);
        }

        return processedTricks;
    }

    const getInitExploreTricks = async () => {
        let initExploreTricks: UserTrickSearchMetadata[] = await SearchService.getExploreTricks(pageSize, pageExplore);
        initExploreTricks = await processExploreTricksForBlockedUsers(initExploreTricks);
        setPageExplore(pageExplore + 1);
        return initExploreTricks;
    }

    const setInitExploreTricks = async () => {
        setInitLoading(true);
        let newSearchTricks = await getInitExploreTricks();
        newSearchTricks = await processExploreTricksForBlockedUsers(newSearchTricks);
        setExploreTrickSearchResults(newSearchTricks);
        setInitLoading(false);
        window.prerenderReady = true;
    }

    const processTrickSearchForBlockedUsers = async (initialTricks: UserTrickSearchMetadata[]) => {
        let tricks = initialTricks;
        let processedTricks: UserTrickSearchMetadata[] = [];

        // Continue the loop until you find non-blocked tricks or run out of new tricks to process.
        while (tricks.length > 0) {
            // Filter the tricks based on blocked users.
            processedTricks = filterTricksForBlockedIds(tricks, state.blockedUsers, state.blockingUsers);

            // If some non-blocked tricks are found, break the loop.
            if (processedTricks.length > 0) {
                break;
            }

            // Fetch more tricks if all current ones are blocked.
            tricks = await SearchService.searchUserTrickPaginated(
                trickSearch,
                pageSize,
                page
            );

            setPage(page + 1);

            if (tricks.length === 0) {
                break;
            }

            tricks = filterTricksForBlockedIds(tricks, state.blockedUsers, state.blockingUsers);
        }

        return processedTricks;
    }

    const onTrickSearch = async (search: string) => {
        setInitLoading(true);
        setTrickSearch(search);
        setSearchTricksInfiniteScrollDisabled(false);
        setTrickSearchResults([]);
        setPage(0);
        console.log("Searching for: " + search);
        let res: UserTrickSearchMetadata[] = await SearchService.searchUserTrickPaginated(search, pageSize, 0);
        res = await processTrickSearchForBlockedUsers(res);
        setTrickSearchResults(res);
        setShowFilterOverlay(false);
        setShowFeatureOptions(false);
        setShowTrickAttributeOptions(false);
        setPage(1); // finished getting page 0, now on page 1
        setInitLoading(false);
        window.prerenderReady = true;
    };

    const getMoreSearchTricks = async (event: IonInfiniteScrollCustomEvent<void>) => {
        console.log("Getting more search tricks");
        let newSearchTricks = [];
        let usingExploreTricks = false;
        if (filterSearch && filterSearch != "") {
            newSearchTricks = await SearchService.searchUserTrickPaginated(filterSearch, pageSize, page);
        } else if (trickSearch) {
            newSearchTricks = await SearchService.searchUserTrickPaginated(trickSearch, pageSize, page);
        } else {
            usingExploreTricks = true;
            newSearchTricks = await getInitExploreTricks();
        }

        if (newSearchTricks.length === 0) {
            setSearchTricksInfiniteScrollDisabled(true);
        } else {
            if (usingExploreTricks) {
                newSearchTricks = await processExploreTricksForBlockedUsers(newSearchTricks);
                setExploreTrickSearchResults([
                    ...exploreTrickSearchResults,
                    ...newSearchTricks
                ]);
                setPageExplore(pageExplore + 1);
            } else {
                newSearchTricks = await processTrickSearchForBlockedUsers(newSearchTricks);
                setTrickSearchResults([
                    ...trickSearchResults,
                    ...newSearchTricks
                ]);
                setPage(page + 1);
            }
        }

        await event.target.complete();
        console.log('Loaded more search tricks');
    }

    const onTrickSelection = (trick: UserTrickSearch): void => {
        history.push('/clip/' + trick.user_id + '/' + trick.id);
    }

    useEffect(() => {
        if (isPlatform("capacitor") || isPlatform("mobile")) {
            setPageSize(10);
        }
    }, []);

    useEffect(() => {
        setDisplayClips(true);
        setDisplayProfiles(false);
    }, []);

    useEffect(() => {
        setTrickSearch(match.params.query);
        if (match.params.query) {
            console.log("replacing page with populated query")
            onTrickSearch(match.params.query);
        } else {
            console.log("replacing page with blank query")
            history.replace({
                pathname: generatePath(match.path, {query: ''}),
            });
            setInitExploreTricks();
        }
        console.log("Filter page loaded");
    }, [history, match.params.query]);

    useEffect(() => {

    }, [state.user.profile]);

    // i don't think we need a deselect function for sport for now as we can just reset the sport state
    const onFeatureSelect = async (feature: string, featureSearchValue: string) => {
        const filteredFeature = feature;
        const filteredFeatureSearchValue = featureSearchValue
        const filteredTricksFilter: string[] = []
        setFeature(filteredFeature);
        setFeatureSearchValue(filteredFeatureSearchValue);
        console.log("Feature: " + filteredFeature);
        setFilteredTricks(filteredTricksFilter);
        // TODO: not sure if we need any more logic here
        await filterTricks(sport, sportDisplay, filteredFeatureSearchValue, trickSearch, filteredTricksFilter, showFilterOverlay, showFeatureOptions, showTrickAttributeOptions);
    }

    const onTrickAttributeSelect = (trickAttribute: string) => {
        setTrickAttribute(trickAttribute);
        // TODO: not sure if we need any more logic here
    }

    // TODO: you might need to change addTrickToFilter to removeTrickFromFilter based on user interactions
    // could be some bugs (happened to me w/the multiselect with dropdown component)
    const addTrickToFilter = async (trick: string) => {
        const filteredTricksFilter = [...filteredTricks, trick];
        console.log("Adding trick to filter: " + trick);
        setFilteredTricks(filteredTricksFilter);
        console.log("Filtered tricks: " + filteredTricksFilter)
        await filterTricks(sport, sportDisplay, featureSearchValue, trickSearch, filteredTricksFilter, showFilterOverlay, showFeatureOptions, showTrickAttributeOptions);
    }

    const removeTrickFromFilter = async (trick: string) => {
        const filteredTricksFilter = filteredTricks.filter((t) => t !== trick);
        console.log("Removing trick from filter: " + trick);
        setFilteredTricks(filteredTricksFilter);
        await filterTricks(sport, sportDisplay, featureSearchValue, trickSearch, filteredTricksFilter, showFilterOverlay, showFeatureOptions, showTrickAttributeOptions);
    }

    const clearFilters = () => {
        setSport("");
        setFeature("");
        setTrickSearch("");
        setTrickAttribute("");
        setFilteredTricks([]);
        setShowFilterOverlay(false);
        setShowFeatureOptions(false);
        setShowTrickAttributeOptions(false);
        setShowTricks(false);
        setFilterSearch("");
    }

    const closeFilter = () => {
        setShowFilterOverlay(false);
        setShowFeatureOptions(false);
        setShowTrickAttributeOptions(false);
    }

    // TODO: maybe actually call the filter api here?
    const filterTricks = async (sportFilter: string, sportDisplayFilter: string, featureFilter: string, trickSearchFilter: string, filteredTricksFilter: string[], setShowFilterOverlayFilter: boolean, setShowFeatureOptionsFilter: boolean, setShowTrickAttributeOptionsFilter: boolean) => {
        setShowFilterOverlay(setShowFilterOverlayFilter);  // hide the filter buttons
        setShowFeatureOptions(setShowFeatureOptionsFilter);
        setShowTrickAttributeOptions(setShowTrickAttributeOptionsFilter);
        setPage(0);
        setTrickSearchResults([]);

        const searchSport = sportFilter != "" ? sportDisplayFilter : "";
        const searchFeature = featureFilter != "" ? featureFilter : "";
        const searchValue = trickSearchFilter != "" && trickSearchFilter != null ? trickSearchFilter : "";
        const searchTags = filteredTricksFilter.length > 0 ? filteredTricksFilter.map((x, i) => x.replace("_", " ")) : [];

        const searchString = searchValue + " " + searchSport + " " + searchFeature + " " + searchTags.join(" ");

        setFilterSearch(searchString);

        console.log("searching for: " + searchString);
        let res: UserTrickSearchMetadata[] = await SearchService.searchUserTrickPaginated(searchString, pageSize, 0);
        res = await processTrickSearchForBlockedUsers(res);
        setTrickSearchResults(res);
        setPage(1); // finished getting page 0, now on page 1
    }

    const handleTagDelete = async (tag: string) => {
        console.log("Deleting tag: " + tag);
        const filteredTricksValue = filteredTricks.filter(obj => {
            return obj !== tag
        });
        console.log("Filtered tricks: " + filteredTricksValue);
        setFilteredTricks(filteredTricksValue);
        await filterTricks(sport, sportDisplay, featureSearchValue, trickSearch, filteredTricksValue, showFilterOverlay, showFeatureOptions, showTrickAttributeOptions);
    }

    const handleSearchDelete = async (search: string) => {
        const trickSearchValue = "";
        setTrickSearch(trickSearchValue);
        history.replace('/search');
        await filterTricks(sport, sportDisplay, featureSearchValue, trickSearchValue, filteredTricks, showFilterOverlay, showFeatureOptions, showTrickAttributeOptions);
    }

    const handleSportChange = async (sport: string, resetFilters: boolean) => {
        const filterSport = sport
        let sportDisplayValue = "";
        if (sport === 'ski') {
            sportDisplayValue = SportType.SKIING
            setSportDisplay(sportDisplayValue);
        }

        if (sport === 'board') {
            sportDisplayValue = SportType.SNOWBOARDING
            setSportDisplay(sportDisplayValue);
        }

        if (sport === 'other') {
            sportDisplayValue = 'Other'
            setSportDisplay(sportDisplayValue);
        }

        console.log("Sport: " + filterSport);

        let featureValue = feature;
        let featureSearchFilterValue = featureSearchValue;
        let filteredTricksValue: string[] = filteredTricks;
        let trickAttributeValue = trickAttribute;

        let showFilterOverlayValue = true;
        let showFeatureOptionsValue = showFeatureOptions;
        let showTrickAttributeOptionsValue = showTrickAttributeOptions;

        if (resetFilters) {
            featureValue = "";
            featureSearchFilterValue = "";
            filteredTricksValue = [];
            trickAttributeValue = "";
        }

        console.log("sport changed");

        setSport(filterSport);
        setSportDisplay(sportDisplayValue);
        setFeature(featureValue);
        setFeatureSearchValue(featureSearchFilterValue);
        setTrickAttribute(trickAttributeValue);
        console.log("Filtered tricks: " + filteredTricksValue);
        setFilteredTricks(filteredTricksValue);
        setShowFilterOverlay(showFilterOverlayValue);
        setShowFeatureOptions(showFeatureOptionsValue);
        setShowTrickAttributeOptions(showTrickAttributeOptionsValue);
        setShowTricks(false);
        await filterTricks(filterSport, sportDisplayValue, featureSearchValue, trickSearch, filteredTricksValue, showFilterOverlayValue, showFeatureOptionsValue, showTrickAttributeOptionsValue);
    }

    // had to put these 2 functions in this class so that Feature options are shown when Trick Attribute button is clicked
    const setShowFeatures = (show: boolean) => {
        setShowFeatureOptions(show);
        if (show) {
            setShowTrickAttributeOptions(false);
            setShowTricks(false); // hide the tricks sidebar
        }
    }

    const setShowTrickAttribute = (show: boolean, trickAttribute: string) => {
        setShowTrickAttributeOptions(show);
        if (show) {
            console.log("setting show features to false");
            setShowFeatureOptions(false);
            if (!trickAttribute) {
                setShowTricks(false);
            }
        } else if (trickAttribute) {
            setShowTricks(true);
        }
    }

    const handleFilterFindTrickDisplay = async () => {
        if (sport != "") {
            await handleSportChange(sport, false);
        } else {
            setShowFilterOverlay(true);
        }
    }

    const handleBack = () => {
        console.log("Back button clicked");
        history.go(-1);
    }

    // TODO: add a toolbar with the filter button and the existing filters in a horizontal scroll view (like the trick tags)

    if (!isAuthenticated && Capacitor.isNativePlatform()) {
        return (
            <IonPage>
                <CustomHelmet title={"Ecliptic // Search"}
                              description={"View the tricks and clips on Ecliptic. Search for any trick ever - index by location, rider, trick name, trick attributes, and more"}
                              image={"https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                              url={`https://ecliptic.day/search`}/>
                <IonContent>
                    <ToolbarSearchBar></ToolbarSearchBar>
                    <div className="m-4"><LoginButton title="Sign up to search on Ecliptic :)" upload={false}
                                                      profile={true}/></div>
                </IonContent>
            </IonPage>
        );
    } else if (!sport && displayClips) {
        return (
            <IonPage>
                <CustomHelmet title={"Ecliptic // Search"}
                              description={"View the tricks and clips on Ecliptic. Search for any trick ever - index by location, rider, trick name, trick attributes, and more"}
                              image={"https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                              url={`https://ecliptic.day/search`}/>
                <FilterToolbar handleBack={handleBack} onTrickSearch={onTrickSearch} onProfileSearch={getProfileResults}
                               onDisplayClips={onSelectClips} onDisplayRiders={onSelectRiders}
                               displayClips={displayClips} displayRiders={displayProfiles} searchValue={trickSearch}/>
                <IonContent>
                    <FilterTrickButton showFilterOverlay={showFilterOverlay}
                                       handleFilterFindTrickDisplay={handleFilterFindTrickDisplay}/>
                    {
                        initLoading ?
                            <div className="flex h-screen justify-center">
                                <IonSpinnerMainContent className={"py-20"}
                                                       size="medium"/>
                            </div>
                            : null
                    }
                    <div
                        className="grid grid-flow-row auto-row-max xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-2 p-1">
                        <TrickSearchCardHolder
                            trickArray={trickSearchResults.length > 0 ? trickSearchResults : exploreTrickSearchResults}
                            onTrickSelection={onTrickSelection} loggedInId={state.user.id}/>
                        <IonInfiniteScroll
                            onIonInfinite={(ev) => getMoreSearchTricks(ev)}
                            threshold={"10%"}
                            disabled={searchTricksInfiniteScrollDisabled}>
                            <IonInfiniteScrollContent
                                loadingSpinner="bubbles"
                            ></IonInfiniteScrollContent>
                        </IonInfiniteScroll>
                    </div>
                    {showFilterOverlay &&
                        <div>
                            <div className="fixed left-0 top-0">
                                <div
                                    className={`relative ${isCapacitor ? (platform == 'android' ? "top-28" : "top-44") : "top-28"}`}>
                                    <SportSelect selectSport={handleSportChange} sport={sport}/>
                                </div>
                            </div>
                            <div className="fixed right-0 bottom-16">
                                <ExitFilterButton closeFilter={clearFilters} name="Clear" buttonType="clear-button"/>
                            </div>
                            <div className="fixed right-0 bottom-0">
                                <ExitFilterButton closeFilter={closeFilter} name="Close"
                                                  buttonType="search-filter-button"/>
                            </div>
                        </div>
                    }
                </IonContent>
            </IonPage>
        );
    } else if (sport && !feature && displayClips) {
        return (
            <IonPage>
                <CustomHelmet title={"Ecliptic // Search"}
                              description={"View the tricks and clips on Ecliptic. Search for any trick ever - index by location, rider, trick name, trick attributes, and more"}
                              image={"https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                              url={`https://ecliptic.day/search`}/>
                <FilterToolbar handleBack={handleBack} onTrickSearch={onTrickSearch} onProfileSearch={getProfileResults}
                               onDisplayClips={onSelectClips} onDisplayRiders={onSelectRiders}
                               displayClips={displayClips} displayRiders={displayProfiles} searchValue={trickSearch}/>
                <SearchBarFilter searchValue={trickSearch} filters={filteredTricks} tagCallback={handleTagDelete}
                                 sport={sportDisplay} searchCallback={handleSearchDelete}
                                 sportCallback={handleSportChange}></SearchBarFilter>
                <IonContent>
                    <FilterTrickButton showFilterOverlay={showFilterOverlay}
                                       handleFilterFindTrickDisplay={handleFilterFindTrickDisplay}/>
                    {
                        initLoading ?
                            <div className="flex h-screen justify-center">
                                <IonSpinnerMainContent className={"py-20"}
                                                       size="medium"/>
                            </div>
                            : null
                    }
                    <div
                        className="grid grid-flow-row auto-row-max xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-2 p-1">
                        <TrickSearchCardHolder
                            trickArray={trickSearchResults.length > 0 ? trickSearchResults : exploreTrickSearchResults}
                            onTrickSelection={onTrickSelection} loggedInId={state.user.id}/>
                        <IonInfiniteScroll
                            onIonInfinite={(ev) => getMoreSearchTricks(ev)}
                            threshold={"10%"}
                            disabled={searchTricksInfiniteScrollDisabled}>
                            <IonInfiniteScrollContent
                                loadingSpinner="bubbles"
                            ></IonInfiniteScrollContent>
                        </IonInfiniteScroll>
                    </div>
                    {showFilterOverlay &&
                        <>
                            <div className="fixed left-0 top-0">
                                <div
                                    className={`relative ${isCapacitor ? (platform == 'android' ? "top-40" : "top-52") : "top-40"}`}>
                                    <FeatureSelect sport={sport} feature={feature} selectFeature={onFeatureSelect}
                                                   showOptions={showFeatureOptions}
                                                   setShowFeatureOptions={setShowFeatures}/>
                                </div>
                            </div>
                            <div className="fixed right-0 bottom-16">
                                <ExitFilterButton closeFilter={clearFilters} name="Clear" buttonType="clear-button"/>
                            </div>
                            <div className="fixed right-0 bottom-0">
                                <ExitFilterButton closeFilter={closeFilter} name="Close"
                                                  buttonType="search-filter-button"/>
                            </div>
                        </>
                    }
                </IonContent>
            </IonPage>
        )
    }

    // Select Trick Attribute and then show the dropdown
    return (
        <IonPage>
            <CustomHelmet title={"Ecliptic // Search"}
                          description={"View the tricks and clips on Ecliptic. Search for any trick ever - index by location, rider, trick name, trick attributes, and more"}
                          image={"https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                          url={`https://ecliptic.day/search`}/>
            <FilterToolbar handleBack={handleBack} onTrickSearch={onTrickSearch} onProfileSearch={getProfileResults}
                           onDisplayClips={onSelectClips} onDisplayRiders={onSelectRiders} displayClips={displayClips}
                           displayRiders={displayProfiles} searchValue={trickSearch}/>
            {
                displayClips ?
                    <>
                        <SearchBarFilter searchValue={trickSearch} filters={filteredTricks}
                                         tagCallback={handleTagDelete}
                                         sport={sportDisplay} searchCallback={handleSearchDelete}
                                         sportCallback={handleSportChange}></SearchBarFilter>
                        <IonContent>
                            <FilterTrickButton showFilterOverlay={showFilterOverlay}
                                               handleFilterFindTrickDisplay={handleFilterFindTrickDisplay}/>
                            {initLoading ?
                                <div className="flex h-screen justify-center">
                                    <IonSpinnerMainContent className={"py-20"}
                                                           size="medium"/>
                                </div>
                                : null}
                            <div
                                className="grid grid-flow-row auto-row-max xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 z-0 gap-2 p-1">
                                <TrickSearchCardHolder
                                    trickArray={trickSearchResults.length > 0 ? trickSearchResults : exploreTrickSearchResults}
                                    onTrickSelection={onTrickSelection} loggedInId={state.user.id}/>
                                <IonInfiniteScroll
                                    onIonInfinite={(ev) => getMoreSearchTricks(ev)}
                                    threshold={"10%"}
                                    disabled={searchTricksInfiniteScrollDisabled}>
                                    <IonInfiniteScrollContent
                                        loadingSpinner="bubbles"
                                    ></IonInfiniteScrollContent>
                                </IonInfiniteScroll>
                            </div>
                            {showFilterOverlay &&
                                <>
                                    <div className="fixed left-0 top-0">
                                        <div
                                            className={`relative ${isCapacitor ? (platform == 'android' ? "top-40" : "top-52") : "top-40"}`}>
                                            <FeatureSelect sport={sport} feature={feature}
                                                           selectFeature={onFeatureSelect}
                                                           showOptions={showFeatureOptions}
                                                           setShowFeatureOptions={setShowFeatures}/>
                                        </div>
                                    </div>
                                    <div className="fixed left-0 bottom-0">
                                        <TrickAttributeSelect sport={sport} feature={feature}
                                                              trickAttribute={trickAttribute}
                                                              selectTrickAttribute={onTrickAttributeSelect}
                                                              showOptions={showTrickAttributeOptions}
                                                              setShowTrickAttributeOptions={setShowTrickAttribute}/>
                                    </div>
                                    {showTricks &&
                                        <TrickDropdown sport={sport} feature={feature} trickAttribute={trickAttribute}
                                                       addTrickToFilter={addTrickToFilter}
                                                       removeTrickFromFilter={removeTrickFromFilter}
                                                       selectedTricks={filteredTricks}/>}
                                    {showTricks ?
                                        <div>
                                            <div className="fixed left-0 bottom-32">
                                                <ExitFilterButton closeFilter={clearFilters} name="Clear"
                                                                  buttonType="clear-button"/>
                                            </div>
                                            <div className="fixed left-0 bottom-16">
                                                <ExitFilterButton closeFilter={closeFilter} name="Close"
                                                                  buttonType="search-filter-button"/>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div className="fixed right-0 bottom-16">
                                                <ExitFilterButton closeFilter={clearFilters} name="Clear"
                                                                  buttonType="clear-button"/>
                                            </div>
                                            <div className="fixed right-0 bottom-0">
                                                <ExitFilterButton closeFilter={closeFilter} name="Close"
                                                                  buttonType="search-filter-button"/>
                                            </div>
                                        </div>}
                                </>}
                        </IonContent>
                    </>
                    :
                    (
                        displayProfiles ?
                            <IonContent>
                                {loadingProfile ?
                                    <IonItem className="align-bottom space-x-4">
                                        <div>Searching</div>
                                        <IonSpinner name="dots"></IonSpinner>
                                    </IonItem> :
                                    profileSearchResults && profileSearchResults.length > 0 ?
                                        profileSearchResults.map((user, index) => (
                                            <IonItem key={index} className="items-center"
                                                     onClick={() => onClickPersonProfile(user)}>
                                                <IonAvatar slot="start" className="ml-2">
                                                    <img className="shrink w-8 h-8 rounded-full"
                                                         src={profileToProfilePic.has(user.id) ? ((profileToProfilePic.get(user.id) === undefined || profileToProfilePic.get(user.id) === null) ? '/assets/photos/defaultProPic.png' : profileToProfilePic.get(user.id)) : '/assets/photos/defaultProPic.png'}></img>
                                                </IonAvatar>
                                                <div className="flex flex-col">
                                                    <div className="flex flex-row">
                                                        <div className="text-xl">{user?.username}</div>
                                                    </div>
                                                    <div className="flex flex-row">
                                                        <div className="text-base">{user?.name}</div>
                                                    </div>
                                                </div>
                                            </IonItem>)) : trickSearch.length > 0 ?
                                            <IonItem className="text-xl justify-center text-center">No users found searching
                                                for {trickSearch}</IonItem> : null
                                }
                            </IonContent>
                            : null
                    )
            }
        </IonPage>
    );
};

export default Filter;
