import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {IonAvatar, IonIcon, IonImg, IonSpinner, useIonToast} from "@ionic/react";
import UserService from "../../../services/user.service";
import {User} from "../../../models/user";
import {motion} from 'framer-motion';
import biskLogo from "../../../assets/icons/bisk/BISK_ICON_NO_DEGREE.svg";
import biskSvg from "../../../assets/icons/bisk/BISK_ICON_NO_DEGREE.svg";
import UsernameDisplay from "../../ComponentsUI/Username/UsernameDisplay";
import {AppContext} from "../../../AppStateProvider";
import {BoardIdentifierNumber} from "../../../models/boardUserBisk";
import {isUUID} from "../../../services/utils";
import useAuthService from "../../../hooks/useAuthService";


interface ContainerProps {
    result: BoardIdentifierNumber;
    blurred?: boolean;
    isAuthed?: boolean;
}

const BoardUserBiskCard: React.FC<ContainerProps> = ({result, blurred, isAuthed}) => {
    const history = useHistory();
    const {state} = useContext(AppContext);
    const {login} = useAuthService();

    const [originUsername, setOriginUsername] = useState<string>('');
    const [originProfilePic, setOriginProfilePic] = useState<string>('/assets/photos/defaultProPic.png');
    const [amount, setTransactionAmount] = useState<number>(0);
    const [isFlipped, setIsFlipped] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const [blurredClass, setBlurredClass] = useState<string>('blur-lg');
    const [present] = useIonToast();

    const getUserInfoOrigin = async (userId: string) => {
        let user: User = undefined;

        if (isUUID(userId)) {
            user = await UserService.getUserPublicById(userId);
        } else {
            user = await UserService.getUserByUsername(userId);
        }

        if (user.username === undefined) {
            setError(true);
            return;
        }
        setOriginUsername(user.username);
        if (user.profile.profile_pic !== null && user.profile.profile_pic !== undefined && user.profile.profile_pic !== '') {
            setOriginProfilePic(user.profile.profile_pic);
        }
    }

    useEffect(() => {
        // Function to reset state
        const resetState = () => {
            setOriginUsername('');
            setOriginProfilePic('/assets/photos/defaultProPic.png');
            setTransactionAmount(0);
        };

        // Reset the state
        resetState();

        if (result) {
            setTransactionAmount(result.number);
            getUserInfoOrigin(result.id);
        }
    }, [result, result.number, result.id]);

    useEffect(() => {
        if (blurred) {
            setBlurredClass('blur-lg');
        } else {
            setBlurredClass('');
        }
    }, [blurred]);

    const presentToastLoginHandler = async (message: string) => {
        await present({
            message: message,
            duration: 3000, // Duration in milliseconds
            position: 'top',
            icon: biskSvg,
            buttons: [
                {
                    text: 'Login',
                    handler: async () => {
                        await login();
                    }
                },
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ]
        });
    };

    const onClickUsername = async (username: string) => {
        if (!isAuthed) {
            await presentToastLoginHandler("You must be logged in to view profiles!");
            return;
        }

        history.push('/profile/' + username);
    }

    const onFlip = () => {
        setIsFlipped(!isFlipped);
    };

    return (
        originUsername !== '' ?
            <div className={"flex flex-col border-b border-gray-900 " + blurredClass}>
                <div className="flex items-center justify-between px-4 py-3">
                    <div className="flex items-center space-x-3 cursor-pointer">
                        <IonAvatar slot="start" className="mx-0.5"
                                   onClick={() => onClickUsername(originUsername)}>
                            <IonImg className="shrink w-12 h-12 flex-none rounded-full" src={originProfilePic}></IonImg>
                        </IonAvatar>
                        <div>
                            <UsernameDisplay className="font-semibold" username={originUsername} userId={result.id}
                                             onUsernameClickHandler={onClickUsername} loggedInId={state.user.id}/>
                        </div>
                    </div>
                    <motion.div
                        className="flex flex-none items-center justify-center w-10 h-10 bg-highlight rounded-full cursor-pointer"
                        animate={{rotateY: isFlipped ? 180 : 0}}
                        transition={{duration: 0.25}}
                        onClick={onFlip}
                    >
                        {isFlipped ? (
                            <motion.div
                                style={{rotateY: "180deg"}}
                            >
                                <IonIcon src={biskLogo} size="large" style={{rotateY: '180deg'}}
                                         className="w-8 h-8 pt-2"/>
                            </motion.div>
                        ) : (
                            <motion.span
                                className="text-dark text-xl font-semibold"
                            >
                                {amount}
                            </motion.span>
                        )}
                    </motion.div>

                </div>
            </div> :
            <div className={"flex flex-row justify-center " + blurredClass}>
                <IonSpinner name="dots" className="mx-auto" color="light"/>
            </div>
    )
};

export default BoardUserBiskCard;