import {isPlatform} from "@ionic/react";
import React, {useEffect, useState} from "react";
import TrickFilterOptionsService from "../../../services/trickFilterOptions.service";
import "./TrickDropdown.css";

interface ContainerProps {
    sport: string,
    feature: string,
    trickAttribute: string,
    addTrickToFilter: (trick: string) => void,
    removeTrickFromFilter: (trick: string) => void,
    selectedTricks: string[],
}

interface TrickOptions {
    name: string,
    tricks: string[],
    nameForApi: string,
}

const TrickDropdown: React.FC<ContainerProps> = ({
                                                     sport,
                                                     feature,
                                                     trickAttribute,
                                                     addTrickToFilter,
                                                     removeTrickFromFilter,
                                                     selectedTricks
                                                 }) => {
    const [trickOptionsList, setTrickOptionsList] = useState<TrickOptions[]>([]);
    const isCapacitor = isPlatform('capacitor');

    useEffect(() => {
        console.log("tricky tricky");
        const fetchData = async () => {
            const res: TrickOptions[] = await TrickFilterOptionsService.getTrickFilterOptions(sport, feature, trickAttribute);
            console.log(res);
            setTrickOptionsList(res);
        }

        // call the function
        fetchData()
            // make sure to catch any error
            .catch(console.error);
    }, [sport, feature, trickAttribute]);

    if (trickOptionsList === null) {
        console.log("trickOptionsList is null");
        return <div>Loading...</div>;
    }

    // if the number of items in the map is 0, then we dont have any tricks to display
    if (trickOptionsList.length === 0) {
        return <div>Loading...</div>;
    }

    return (
        // maybe need to change the z-index of this div if it doesn't go over the vids
        <div className="z-100 fixed top-20 right-0 border w-7/12 md:w-4/12 h-[91%] trickDropdown overflow-scroll">
            <div className={`relative ${isCapacitor ? "top-28" : "top-14"}`}>
                <div className="m-4 flex flex-col flex-nowrap font-bold text-xl overflow-y-auto mx-4">
                    {trickOptionsList.map((trickOptions: TrickOptions, key: any) =>
                        <div key={key} className="flex flex-col w-full">
                            <h3 className="font-bold text-black text-2xl m-1">{trickOptions.name}</h3>
                            {trickOptions.tricks.map((trick: string, trickKey: any) =>
                                <ul key={trickKey} className="w-full font-thin">
                                    <li className="w-full mb-2">
                                        <div className="flex items-center pl-3">
                                            <input
                                                id="black-checkbox"
                                                type="checkbox"
                                                value={trick} // might need the trickOptions.name (i.e. Air) in here for api call
                                                className="w-4 h-4 text-black bg-gray-100 focus:ring-black trickCheckbox"
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        addTrickToFilter(event.target.value);
                                                    } else {
                                                        removeTrickFromFilter(event.target.value);
                                                    }
                                                }}
                                                checked={selectedTricks.includes(trick)}
                                            />
                                            <label
                                                className="ml-2 w-full font-thin text-black leading-tight">{trick}</label>
                                        </div>
                                    </li>
                                </ul>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TrickDropdown;
