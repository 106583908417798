import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {IonAvatar, IonIcon, IonImg, IonSpinner, useIonToast} from "@ionic/react";
import {BiskTransactionType} from "../../../models/biskTransactionType";
import UserService from "../../../services/user.service";
import {User} from "../../../models/user";
import BiskService from "../../../services/bisk.service";
import {UserTrick} from "../../../models/user-trick";
import TricksService from "../../../services/tricks.service";
import {motion} from 'framer-motion';
import biskLogo from "../../../assets/icons/bisk/BISK_ICON_NO_DEGREE.svg";
import biskSvg from "../../../assets/icons/bisk/BISK_ICON_NO_DEGREE.svg";
import UsernameDisplay from "../../ComponentsUI/Username/UsernameDisplay";
import {AppContext} from "../../../AppStateProvider";
import useAuthService from "../../../hooks/useAuthService";
import {BiskTransactionView} from "../../../models/biskTransactionView";


interface ContainerProps {
    transaction: BiskTransactionView;
    isModal?: boolean;
    onClickObjectHandler?: (targetId: string, objectId: string, transactionType: string) => void;
    onClickUsernameHandler?: (username: string) => void;
    blurred: boolean;
    isAuthed?: boolean;
}

const BiskActivityCard: React.FC<ContainerProps> = ({
                                                        transaction,
                                                        isModal,
                                                        onClickObjectHandler,
                                                        onClickUsernameHandler,
                                                        blurred,
                                                        isAuthed
                                                    }) => {
    const history = useHistory();
    const {state} = useContext(AppContext);
    const {login} = useAuthService();

    const [originUsername, setOriginUsername] = useState<string>('');
    const [originProfilePic, setOriginProfilePic] = useState<string>('/assets/photos/defaultProPic.png');

    const [recipientUsername, setRecipientUsername] = useState<string>('');
    const [recipientObject, setRecipientObject] = useState<string>('');
    const [transactionType, setTransactionType] = useState<string>('');
    const [transactionAmount, setTransactionAmount] = useState<number>(0);

    const [extraWords, setExtraWords] = useState<string>('');

    const [isFlipped, setIsFlipped] = useState<boolean>(false);
    const [hasNote, setHasNote] = useState<boolean>(false);
    const [isNoteVisible, setIsNoteVisible] = useState(false);

    const [blurredClass, setBlurredClass] = useState<string>('blur-lg');
    const [present] = useIonToast();


    const getUserInfoOrigin = async (userId: string) => {
        const user: User = await UserService.getUserPublicById(userId);
        setOriginUsername(user.username);
        if (user.profile.profile_pic !== null && user.profile.profile_pic !== undefined && user.profile.profile_pic !== '') {
            setOriginProfilePic(user.profile.profile_pic);
        }
    }

    const getUserInfoRecipient = async (userId: string) => {
        const user: User = await UserService.getUserPublicById(userId);
        setRecipientUsername(user.username);
    }

    const getRecipientObject = async (objectId: string, recipientId: string, biskTransactionType: string) => {
        if (biskTransactionType === BiskTransactionType.SEND_TO_OBJECT) {
            const trick: UserTrick = await TricksService.getUserTrick(objectId, recipientId);
            setRecipientObject(trick.trick_name);
        }

        if (biskTransactionType === BiskTransactionType.REDEEM_FOR_OBJECT) {
            const object: any = await BiskService.getShopItemById(objectId);
            setRecipientObject(object.name);
        }
    }

    useEffect(() => {
        // Function to reset state
        const resetState = () => {
            setOriginUsername('');
            setOriginProfilePic('/assets/photos/defaultProPic.png');
            setRecipientUsername('');
            setRecipientObject('');
            setTransactionType('');
            setTransactionAmount(0);
            setExtraWords('');
            setIsFlipped(false);
            setIsNoteVisible(false);
        };

        // Reset the state
        resetState();

        if (transaction) {
            const hasNote = transaction.notes && transaction.notes.trim() !== '';
            setHasNote(hasNote);

            setTransactionAmount(transaction.amount);

            if (!transaction.origin_username || !transaction.origin_name || !transaction.origin_profile_pic) {
                getUserInfoOrigin(transaction.origin_id);
            } else {
                setOriginUsername(transaction.origin_username);
                setOriginProfilePic(transaction.origin_profile_pic);
            }

            if (transaction.transaction_type === BiskTransactionType.SEND || transaction.transaction_type === BiskTransactionType.SEND_TO_OBJECT) {
                if (!transaction.target_username || !transaction.target_name || !transaction.target_profile_pic) {
                    getUserInfoRecipient(transaction.target_id);
                } else {
                    setRecipientUsername(transaction.target_username);
                }
            }

            if (transaction.transaction_type === BiskTransactionType.SEND_TO_OBJECT || transaction.transaction_type === BiskTransactionType.REDEEM_FOR_OBJECT) {
                getRecipientObject(transaction.object_id, transaction.target_id, transaction.transaction_type);
            }

            if (transaction.transaction_type === BiskTransactionType.REDEEM) {
                setTransactionType('redeemed');
            }

            if (transaction.transaction_type === BiskTransactionType.SEND) {
                setTransactionType('sent');
            }

            if (transaction.transaction_type === BiskTransactionType.WITHDRAW) {
                setTransactionType('withdrew');
            }

            if (transaction.transaction_type === BiskTransactionType.BUY) {
                setTransactionType('bought');
            }

            if (transaction.transaction_type === BiskTransactionType.AWARD) {
                setTransactionType('received')
            }

            if (transaction.transaction_type === BiskTransactionType.SEND_TO_OBJECT) {
                setTransactionType('sent');
                setExtraWords('for the');
            }

            if (transaction.transaction_type === BiskTransactionType.REDEEM_FOR_OBJECT) {
                setTransactionType('redeemed');
                setExtraWords('for a');
            }
        }
    }, [transaction, transaction.amount, transaction.origin_id, transaction.target_id, transaction.transaction_type]);

    useEffect(() => {
        if (blurred) {
            setBlurredClass('blur-lg');
        } else {
            setBlurredClass('');
        }
    }, [blurred]);

    const presentToastLoginHandler = async (message: string) => {
        await present({
            message: message,
            duration: 3000, // Duration in milliseconds
            position: 'top',
            icon: biskSvg,
            buttons: [
                {
                    text: 'Login',
                    handler: async () => {
                        await login();
                    }
                },
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ]
        });
    };

    const onClickUsername = async (username: string) => {
        if (!isAuthed) {
            await presentToastLoginHandler('Please login to view profiles');
            return;
        }

        if (isModal) {
            onClickUsernameHandler(username);
            return;
        }

        history.push('/profile/' + username);
    }

    const onClickObject = async (targetId: string, objectId: string, transactionType: string) => {
        if (!isAuthed) {
            await presentToastLoginHandler('Please login to view the bisk action');
            return;
        }

        if (isModal) {
            onClickObjectHandler(targetId, objectId, transactionType);
            return;
        }

        if (transactionType === BiskTransactionType.REDEEM || transactionType === BiskTransactionType.REDEEM_FOR_OBJECT) {
            history.push('/shop');
        }

        if (transactionType === BiskTransactionType.SEND_TO_OBJECT) {
            history.push('/clip/' + targetId + "/" + objectId);
        }
    }

    const onFlip = () => {
        setIsFlipped(!isFlipped);
    };

    const toggleNote = () => {
        setIsNoteVisible(!isNoteVisible);
    };

    return (
        originUsername !== '' ?
            <div className={"flex flex-col border-b border-gray-900 " + blurredClass}>
                <div className="flex items-center justify-between px-4 py-3">
                    <div className="flex items-center space-x-3 cursor-pointer">
                        <IonAvatar slot="start" className="mx-0.5"
                                   onClick={() => onClickUsername(originUsername)}>
                            <IonImg className="shrink w-12 h-12 flex-none rounded-full" src={originProfilePic}></IonImg>
                        </IonAvatar>
                        <div>
                            <UsernameDisplay className="font-semibold" username={originUsername}
                                             userId={transaction.origin_id} onUsernameClickHandler={onClickUsername}
                                             loggedInId={state.user.id}/>
                            <div className="text-gray-300 text-sm">
                                {transactionType}
                                <span
                                    className={`font-semibold text-indigo-300 ${recipientUsername ? 'cursor-pointer hover:underline' : ''}`}
                                    onClick={() => recipientUsername && onClickUsername(recipientUsername)}>
                    {recipientUsername && ` ${recipientUsername}`}
                </span>
                                {" bisk "}
                                <span
                                    className={`font-semibold text-indigo-300 ${recipientObject ? 'cursor-pointer hover:underline' : ''}`}
                                    onClick={() => recipientObject && onClickObject(transaction.target_id, transaction.object_id, transaction.transaction_type)}>
                    {extraWords} {recipientObject}
                </span>
                            </div>
                        </div>
                    </div>
                    <motion.div
                        className="flex flex-none items-center justify-center w-10 h-10 bg-highlight rounded-full cursor-pointer"
                        animate={{rotateY: isFlipped ? 180 : 0}}
                        transition={{duration: 0.25}}
                        onClick={onFlip}
                    >
                        {isFlipped ? (
                            <motion.div
                                style={{rotateY: "180deg"}}
                            >
                                <IonIcon src={biskLogo} size="large" style={{rotateY: '180deg'}}
                                         className="w-8 h-8 pt-2"/>
                            </motion.div>
                        ) : (
                            <motion.span
                                className="text-dark text-xl font-semibold"
                            >
                                {transactionAmount}
                            </motion.span>
                        )}
                    </motion.div>

                </div>
                {
                    hasNote &&
                    transaction.transaction_type !== BiskTransactionType.WITHDRAW &&
                    transaction.transaction_type !== BiskTransactionType.REDEEM_FOR_OBJECT &&
                    transaction.transaction_type !== BiskTransactionType.REDEEM && (
                        <motion.div
                            initial={false}
                            animate={{height: true ? 'auto' : 0}}
                            transition={{duration: 0.25}}
                            className="overflow-hidden px-4"
                            onClick={toggleNote}
                        >
                            <p className="text-gray-200 text-sm py-1">
                                {transaction.notes}
                            </p>
                        </motion.div>
                    )
                }
            </div> :
            <div className={"flex flex-row justify-center " + blurredClass}>
                <IonSpinner name="dots" className="mx-auto" color="light"/>
            </div>
    )
};

export default BiskActivityCard;