import React, {useEffect} from "react";
import "./TrickAttributeSelect.css";

interface ContainerProps {
    sport: string,
    feature: string,
    trickAttribute: string,
    selectTrickAttribute: (trickAttribute: string) => void,
    setShowTrickAttributeOptions: (show: boolean, trickAttribute: string) => void,
    showOptions: boolean,
}

interface TrickAttribute {
    name: string,
    lowercase: string,
    transitions: string,
}

// direction, axis, degree, grab, and then flavor
const attributes : TrickAttribute[] = [
    { 
        name: "Direction",
        lowercase: "direction",
        transitions: "btn-1",
    },
    {
        name: "Axis",
        lowercase: "axis",
        transitions: "btn-1",
    },
    {
        name: "Degree",
        lowercase: "degree",
        transitions: "btn-1",
    },
    {
        name: "Grab",
        lowercase: "grab",
        transitions: "btn-1",
    },
    {
        name: "Flavor",
        lowercase: "flavor",
        transitions: "btn-1",
    },
    {
        name: "Back",
        lowercase: "",
        transitions: "",
    },
];

const railAttributes : TrickAttribute[] = [
    { 
        name: "Style",
        lowercase: "style",
        transitions: "btn-1",
    },
    {
        name: "Feature",
        lowercase: "feature",
        transitions: "btn-1",
    },
];

const trickshotAttributes : TrickAttribute[] = [
    {
        name: "Activity",
        lowercase: "activity",
        transitions: "btn-1",
    },
    {
        name: "Flavor",
        lowercase: "flavor",
        transitions: "btn-1",
    },
];

const TrickAttributeSelect: React.FC<ContainerProps> = (props: ContainerProps) => {
    useEffect(() => {
        // TODO: call api to get trick options based on the sport and trick attribute (i.e. based on grab)
        console.log("Waiting for props to be mounted");
    }, [props.sport, props.feature]);

    if (props.showOptions) {
        let allAttributes = [...attributes];
        if (props.feature === "rail") {
            allAttributes = allAttributes.slice(0, -1);
            allAttributes = [...allAttributes, ...railAttributes, attributes[attributes.length - 1]];
        }

        if (props.feature === "trickshot") {
            allAttributes = [...trickshotAttributes];
        }

        return (
            // maybe need to change the z-index of this div if it doesn't go over the vids
            <div className="z-100 m-2">
                {allAttributes.map((attribute: TrickAttribute, key: any) => 
                    <div 
                        key={key}
                        className={`w-[55px] h-[55px] rounded-full flex justify-center items-center circle-button text-xs text-black mb-1 ${attribute.transitions}`}
                        onClick={() => {
                            if (attribute.name !== "Back") {
                                props.selectTrickAttribute(attribute.lowercase);
                            }
                            props.setShowTrickAttributeOptions(false, attribute.lowercase);
                        }}
                    >
                        {attribute.name}
                    </div>
                )}
            </div>
        );
    }

    return (
        // maybe need to change the z-index of this div if it doesn't go over the vids
        <div className="z-100 m-2">
            <div className="w-[55px] h-[55px] rounded-full flex justify-center items-center circle-button text-xs text-black">
                <div onClick={() => props.setShowTrickAttributeOptions(true, "")}>
                    {props.trickAttribute ? 
                        props.trickAttribute.charAt(0).toUpperCase() + props.trickAttribute.slice(1) : 
                        "Attribute"
                    }
                </div>
            </div>
        </div>
    );
};

export default TrickAttributeSelect;
