import React, {useCallback, useEffect, useRef, useState} from 'react';
import {IonButton, IonIcon, IonInput} from '@ionic/react';
import {refreshOutline} from 'ionicons/icons';
import {useSignUp} from "../SignUpProvider/SignUpProvider";

// Assume this is your server function to generate a username
import UserService from "../../../services/user.service";
import {handleIfUsernameIsTaken, validateBasics} from "../../../services/utils";
import {debounce} from "lodash";
import {useAppState} from "../../../AppListenerProvider";
import {Keyboard} from "@capacitor/keyboard";
import OnboardingPageWrapper from "../OnboardingPageWrapper/OnboardingPageWrapper";

interface UsernameStepProps {
    onNext: () => void;
    title: string;
    showBackButton: boolean;
    showSkipButton: boolean;
    onBack?: () => void;
}


const UsernameInput: React.FC<UsernameStepProps> = ({onNext, showSkipButton, showBackButton, onBack, title}) => {
    const {signUpData, updateSignUpData} = useSignUp();
    const [isGenerating, setIsGenerating] = useState(false);
    const {isNative, isActive} = useAppState();
    const [newUsername, setNewUsername] = useState('');
    const [placeholder, setPlaceholder] = useState('');
    const [isValidating, setIsValidating] = useState(false);
    const [isAvailable, setIsAvailable] = useState<boolean | null>(null);
    const inputRef = useRef<HTMLIonInputElement>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const focusInput = async () => {
            setTimeout(async () => {
                await inputRef.current?.setFocus();
                if (isNative) {
                    await Keyboard.show();
                }
            }, 300);
        };

        focusInput();

        return () => {
            if (isNative) {
                Keyboard.hide();
            }
        };
    }, [isNative, isActive, inputRef]);

    const checkAvailability = useCallback(
        debounce(async (value: string) => {
            if (value && !error) {
                setIsValidating(true);
                const result = await handleIfUsernameIsTaken(value);
                setIsAvailable(result.isValid);
                setError(result.error);
                setIsValidating(false);
            }
        }, 500),
        []
    );

    useEffect(() => {
        if (newUsername.trim() === '') {
            setError(null);
            setIsAvailable(null);
            return;
        }

        const {isValid, error} = validateBasics(newUsername);
        if (!isValid) {
            setError(error);
            setIsAvailable(false);
        } else {
            checkAvailability(newUsername);
        }
    }, [newUsername, checkAvailability]);

    useEffect(() => {
        generateUsername(false);
    }, []);

    useEffect(() => {
        if (signUpData.username !== newUsername) {
            setNewUsername(signUpData.username || '');
        }
    }, [signUpData.username]);

    const generateUsername = async (setAsUsername: boolean) => {
        setIsGenerating(true);
        try {
            const newName = await UserService.getRandomAssUsername();
            if (newName) {
                const usernameRetrieved = newName['username'];
                console.log(usernameRetrieved);
                setPlaceholder(usernameRetrieved);
                if (setAsUsername) {
                    setNewUsername(usernameRetrieved);
                    await updateSignUpData({username: usernameRetrieved});
                }
            } else {
                const defaultUsername = "moonjibs";
                setPlaceholder(defaultUsername);
                if (setAsUsername) {
                    setNewUsername(defaultUsername);
                    await updateSignUpData({username: defaultUsername});
                }
            }
            await inputRef.current?.setFocus();
        } catch (error) {
            console.error('Failed to generate username:', error);
        } finally {
            setIsGenerating(false);
        }
    };

    const handleNameChange = async (value: string) => {
        setNewUsername(value);
        await updateSignUpData({username: value.trim()});
    };

    const handleSubmit = async () => {
        const trimmedUsername = newUsername.trim();
        const {isValid, error} = validateBasics(trimmedUsername);
        if (isValid) {
            setIsValidating(true);
            const result = await handleIfUsernameIsTaken(trimmedUsername);
            setIsValidating(false);
            if (result.isValid) {
                if (isNative) {
                    await Keyboard.hide();
                }
                onNext();
            } else {
                setError(result.error);
                setIsAvailable(false);
            }
        } else {
            setError(error);
            setIsAvailable(false);
        }
    };

    return (
        <OnboardingPageWrapper title={title} showBackButton={showBackButton} showSkipButton={showSkipButton}
                               onBack={onBack}>
            <div className="space-y-4 flex flex-col items-center font-bold">
                <div className="text-primary-secondary text-center">
                    How do you want to be known on Ecliptic?
                </div>
                <div className="relative w-full">
                    <IonInput
                        ref={inputRef}
                        value={newUsername}
                        placeholder={placeholder}
                        color="dark"
                        onIonInput={(e) => handleNameChange(e.detail.value)}
                        className="bg-transparent p-4 w-full text-primary-alt text-2xl font-bold">
                        <IonIcon
                            icon={refreshOutline}
                            slot="end"
                            className={!isGenerating ? "text-primary-alt" : "text-primary"}
                            onClick={() => generateUsername(true)}
                        />
                    </IonInput>
                </div>
                {error && <div className="text-highlight-danger text-center text-lg">{error}</div>}
                <IonButton
                    expand="block"
                    className="neon-button-alt w-full normal-case font-bold"
                    onClick={handleSubmit}
                    disabled={!signUpData.username || signUpData.username.trim().length === 0 || (error !== null)}
                >
                    Continue
                </IonButton>
            </div>
        </OnboardingPageWrapper>
    );
};

export default UsernameInput;