import {IonItem, IonLabel, IonSelect, IonSelectOption} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import {SelectOptions} from "../../../models/profileSelectOptions";

interface ContainerProps {
    options: any[]
    values: string[];
    label: string;
    parentCallback: (values: any) => void;
    multiple: boolean;
    value: string;
}


const compareWith = (o1: SelectOptions, o2: SelectOptions) => {
    if (!o1 || !o2) {
        return o1 === o2;
    }

    if (Array.isArray(o2)) {
        return o2.some((o) => o.name === o1.name);
    }

    return o1.name === o2.name;
};

const EditProfileSelect: React.FC<ContainerProps> = (props: ContainerProps) => {
    const [selected, setSelected] = useState<SelectOptions[]>([]);
    const [singleSelected, setSingleSelected] = useState<SelectOptions>();

    useEffect(() => {
        const selectedOptions = [];

        if (props.values) {
            for (let i = 0; i < props.options.length; i++) {
                for (let j = 0; j < props.values.length; j++) {
                    if (props.options[i].name === props.values[j]) {
                        selectedOptions.push(props.options[i]);
                    }
                }
            }
        }

        setSelected(selectedOptions);
    }, [props.values])

    useEffect(() => {
        if (props.value) {
            const value = props.value.toLowerCase();
            for (let i = 0; i < props.options.length; i++) {
                if (props.options[i].name === value.charAt(0).toUpperCase() + value.slice(1)) {
                    setSingleSelected(props.options[i]);
                }
            }
        }
    }, [props.value]);

    const setCurrentSelectHandler = (values: any) => {
        if (Array.isArray(values)) {
            setSelected(values);
            console.log(values.map((value: any) => value.name));
            props.parentCallback(values.map((value: any) => value.name));
        } else if (values) {
            console.log(values)
            setSingleSelected(values);
            props.parentCallback(values.name);
        }
    }

    return (
        <IonItem>
            <IonLabel position="stacked">{props.label}</IonLabel>
            <IonSelect
                placeholder={`Select your ${props.label}`}
                interface="popover"
                compareWith={compareWith}
                onIonChange={(ev) => setCurrentSelectHandler(ev.detail.value)}
                multiple={props.multiple}
                value={props.multiple ? selected : singleSelected}
            >
                {props.options.map((option) => (
                    <IonSelectOption key={option.id} value={option}>
                        {option.name}
                    </IonSelectOption>
                ))}
            </IonSelect>
        </IonItem>
    );
};

export default EditProfileSelect;