import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import PreferencesService from "./services/preferences.service";
import {App} from "@capacitor/app";
import {useAppState} from "./AppListenerProvider";
import {Browser} from "@capacitor/browser";

interface DeepLinkProviderProps {
    children: ReactNode;
}

interface DeepLinkData {
    slug: string | null;
    referrer: string | null;
}

interface DeepLinkContextType {
    deepLinkData: DeepLinkData;
    clearDeepLinkData: () => Promise<void>;
}

const DeepLinkContext = createContext<DeepLinkContextType | undefined>(undefined);

export const DeepLinkProvider: React.FC<DeepLinkProviderProps> = ({children}) => {
    const {isNative} = useAppState();
    const [deepLinkData, setDeepLinkData] = useState<DeepLinkData>({slug: null, referrer: null});

    const clearDeepLinkData = useCallback(async () => {
        await PreferencesService.removeDeepLinkSlug();
        await PreferencesService.removeReferrer();
        setDeepLinkData({slug: null, referrer: null});
    }, []);

    useEffect(() => {
        const loadInitialData = async () => {
            const slug = await PreferencesService.getDeepLinkSlug();
            const referrer = await PreferencesService.getReferrer();
            setDeepLinkData({slug, referrer});
        };
        loadInitialData();

        const handleDeepLink = async (url: string) => {
            console.log("deep link url: ", url);

            let slug = '';
            if (url.includes('style')) {
                slug = url.split("style").pop();
            } else if (url.includes('live')) {
                slug = url.split("live").pop();
            } else {
                console.log("Unrecognized URL format");
                return;
            }

            console.log("Extracted slug:", slug);

            let referrer = null;
            if (slug.startsWith('/share')) {
                const params = new URLSearchParams(slug.split('?')[1]);
                referrer = params.get('username');
            }

            await PreferencesService.setDeepLinkSlug(slug);
            if (referrer) {
                await PreferencesService.setReferrer(referrer);
            } else {
                await PreferencesService.removeReferrer();
            }

            setDeepLinkData({slug, referrer});

            // Assuming isNative is true for mobile platforms
            if (isNative) {
                await Browser.close();
            }
        };

        const listener = App.addListener("appUrlOpen", async ({url}) => {
            await handleDeepLink(url);
        });

        return () => {
            listener.then(f => f.remove());
        };
    }, []);

    return (
        <DeepLinkContext.Provider value={{deepLinkData, clearDeepLinkData}}>
            {children}
        </DeepLinkContext.Provider>
    );
};

export const useDeepLink = () => {
    const context = useContext(DeepLinkContext);
    if (context === undefined) {
        throw new Error('useDeepLink must be used within a DeepLinkProvider');
    }
    return context;
};