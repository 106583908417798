import {IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import React, {useEffect} from "react";
import UserService from "../../../services/user.service";
import {LikeView} from "../../../models/likeView";
import ViewLikesContent from "../ViewLikesContent/ViewLikesContent";

interface ContainerProps {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void,
    trickId: string
    onClickResult: (username: string) => void,
}

const ViewTrickLikes: React.FC<ContainerProps> = ({onDismiss, trickId, onClickResult}) => {
    const [trickLikes, setTrickLikes] = React.useState<LikeView[]>([]);

    const [loading, setLoading] = React.useState(false);

    const getTrickLikes = async (trick_id: string) => {
        setLoading(true);
        const response: LikeView[] = await UserService.getUserTrickLikes(trick_id);
        if (response) {
            // filter out only unique user ids likes
            const uniqueLikes = response.filter((like, index, self) =>
                    index === self.findIndex((t) => (
                        t.action_user_id === like.action_user_id
                    ))
            )
            setTrickLikes(uniqueLikes);
        }
        setLoading(false);
    }

    useEffect(() => {
        if (trickId != "" && trickId != undefined) {
            getTrickLikes(trickId);
        }
    }, [trickId]);

    const onClickPersonLikes = (username: string) => {
        onClickResult(username);
        onDismiss();
    }

    //return the new array of user profiles
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="flex justify-between flex-row">
                    <IonButtons slot="start" className="mr-14">
                        <IonButton strong={true} onClick={() => onDismiss(null, 'cancel')}>
                        </IonButton>
                    </IonButtons>
                    <IonTitle className="items-center text-center">Likes</IonTitle>
                    <IonButtons slot="end">
                        <IonButton strong={true} onClick={() => onDismiss(null, 'cancel')}>
                            Close
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <ViewLikesContent trickLikes={trickLikes} onClickResult={onClickPersonLikes}/>
            </IonContent>
        </IonPage>
    );
};

export default ViewTrickLikes;