import {Capacitor} from "@capacitor/core";
import {IonButton, useIonAlert} from "@ionic/react";
import React, {useContext} from "react";
import {AppContext} from "../../../AppStateProvider";
import {useAuth} from "../../../AuthProvider";
import {useHistory} from "react-router-dom";
import {useAppState} from "../../../AppListenerProvider";
import AuthService from "../../../services/auth.service";
import PreferencesService from "../../../services/preferences.service";
import {OnboardingStatus} from "../../../models/onboardingStatus";

interface ContainerProps {
    display: boolean;
}

const LogoutButton: React.FC<ContainerProps> = (props: ContainerProps) => {
    const history = useHistory();
    const {dispatch} = useContext(AppContext);
    const [presentAlert] = useIonAlert();
    const {isActive, isNative, platform} = useAppState();
    const {user, isLoading, isAuthenticated, firebaseApp, auth} = useAuth();
    const authService = AuthService({user, isLoading, isAuthenticated, firebaseApp, auth}, {
        isActive,
        isNative,
        platform
    });

    const clearStorage = async () => {
        await PreferencesService.clearPreferences();
        await PreferencesService.setOnboardingStatus(OnboardingStatus.COMPLETED)
    }

    const clearState = async () => {
        dispatch({type: "clearUser", payload: null});
        dispatch({type: "clearBisk", payload: null});
        dispatch({type: "clearIsSubscribed", payload: null});
        dispatch({type: "clearSubscriptionLevel", payload: null});
        dispatch({type: "clearFeedSettings", payload: null});
        dispatch({type: "clearMutedSettings", payload: null});
        dispatch({type: "clearUserType", payload: null});
        dispatch({type: "clearFollowers", payload: null});
        dispatch({type: "clearFollowing", payload: null});
        dispatch({type: "clearPushNotificationRedirectUrl", payload: null});
        dispatch({type: "clearBlockedUsers", payload: null});
        dispatch({type: "clearBlockingUsers", payload: null});
    }

    const doLogout = async () => {
        await clearStorage();
        await clearState();
        try {
            const res = await authService.signOut();
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    }

    return (props.display ?
        <IonButton expand="full" color="favorite" shape="round" onClick={() => {
            if (Capacitor.getPlatform() === 'web') {
                doLogout();
            } else {
                presentAlert({
                    header: 'Logout Warning!',
                    message: 'Logging out has been been buggier than expected. You will have to force close the app after logging out and restart it :/',
                    buttons: [
                        {
                            text: 'Cancel',
                            role: 'cancel',
                        },
                        {
                            text: 'OK',
                            role: 'confirm',
                            handler: async () => {
                                await doLogout();
                            },
                        },
                    ],
                })
            }
        }}>Log Out</IonButton> : <div></div>);
};

export default LogoutButton;