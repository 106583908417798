import {Geolocation} from "@capacitor/geolocation";
import {IonIcon} from "@ionic/react";
import {layersOutline, searchOutline} from "ionicons/icons";
import ReactDOM from "react-dom";
import React, {useContext, useEffect, useRef, useState} from "react";
import mapboxgl from 'mapbox-gl'; // or "const mapboxgl = require('mapbox-gl');"
import './WorldWeb.css';
import MapPopup from "../MapPopup/MapPopup";
import MapSearchBar from "../MapSearchBar/MapSearchBar";
import LocationService from "../../../services/location.service";
import {v4 as uuidv4} from 'uuid';
import {AppContext} from "../../../AppStateProvider";
import {useHistory} from "react-router-dom";
import 'mapbox-gl/dist/mapbox-gl.css';


// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

interface ContainerProps {
    lat: number;
    long: number;
    zoom: number;
    geojson: any;
    onClick?: any;
    blurred: boolean;
    flyTo: boolean;
    flyToCoords?: number[];
    isNative?: boolean;
    isSubscribed?: boolean;
}

const WorldWeb: React.FC<ContainerProps> = (props) => {
    const {state} = useContext(AppContext);
    const history = useHistory();
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [long, setLong] = useState(props.long); // between -180 and 180
    const [lat, setLat] = useState(props.lat); // between -90 and 90
    const [zoom, setZoom] = useState(props.zoom); // Zoom levels: https://docs.mapbox.com/help/glossary/zoom-level/
    const [is3D, setIs3D] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [locationSearch, setLocationSearch] = useState(undefined);
    const [showShop, setShowShop] = useState(false);

    const [googlePlacesSessionToken, setGooglePlacesSessionToken] = useState(null);

    const checkGeoLocationPermission = async () => {
        const permissions = await Geolocation.checkPermissions();
        if (permissions.location === 'denied' || permissions.location === 'granted') {
            return true;
        } else {
            return false;
        }
    }

    const getGeoLocationMobile = async () => {
        const checkLocationRes = await checkGeoLocationPermission();
        if (!checkLocationRes) {
            const res = await Geolocation.requestPermissions();
            if (res.location === 'granted') {
                return true
            } else {
                return false;
            }
        } else {
            const permissions = await Geolocation.checkPermissions();
            if (permissions.location === 'denied') {
                return false;
            }
            if (permissions.location === 'granted') {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        if (map.current) {
            return // initialize map only once
        }
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/brendanhart/clo8icc5500bd01rfcl0b6acx',
            center: [long, lat],
            zoom: zoom
        })
            .addControl(new mapboxgl.NavigationControl(), "top-right");// zooming stuff

        if (!props.isNative) {
            map.current
                .addControl(
                    new mapboxgl.GeolocateControl({
                        positionOptions: {
                            enableHighAccuracy: true
                        },
                        // When active the map will receive updates to the device's location as it changes.
                        trackUserLocation: true,
                        // Draw an arrow next to the location dot to indicate which direction the device is heading.
                        showUserHeading: true
                    })
                );
        }
    }, []);

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize

        map.current.on('move', () => {
            setLong(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    }, []);

    useEffect(() => {
        if (props.isSubscribed !== undefined && props.isSubscribed !== null) {
            if (!props.isSubscribed) {
                setShowShop(true);
            } else {
                setShowShop(false);
            }
        }

    }, [props.isSubscribed]);

    // this useEffect makes sure the map is resized to fix the screen
    useEffect(() => {
        if (!map.current) return; // wait for map to initialize

        map.current.on('load', async function () {
            map.current.resize();

            // for 3d stuff
            // map.current.addSource('mapbox-dem', {
            //   'type': 'raster-dem',
            //   'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
            //   'tileSize': 512,
            //   'maxzoom': 14
            // });

            // clown emoji on FIS

            // add the DEM source as a terrain layer with exaggerated height
            map.current.setTerrain({'source': 'mapbox-dem', 'exaggeration': 1.5});

            map.current.loadImage(
                'https://corked.blob.core.windows.net/logos/clown.png',
                (error: any, image: any) => {
                    if (error) throw error;

                    // Add the image to the map style.
                    map.current.addImage('clown', image);

                    // Add a data source containing one point feature.
                    map.current.addSource('clown-towns', {
                        'type': 'geojson',
                        'data': {
                            'type': 'FeatureCollection',
                            'features': [
                                {
                                    'type': 'Feature',
                                    'geometry': {
                                        'type': 'Point',
                                        'coordinates': [7.672850, 46.729440]
                                    }
                                }
                            ]
                        }
                    });

                    // Add a layer to use the image to represent the data.
                    map.current.addLayer({
                        'id': 'clowning',
                        'type': 'symbol',
                        'source': 'clown-towns', // reference the data source
                        'layout': {
                            'icon-image': 'clown', // reference the image
                            "icon-size": [
                                'interpolate',
                                // Set the exponential rate of change to 1.5
                                ['linear'],
                                ['zoom'],
                                // When zoom is 10, icon will be 50% size.
                                10,
                                0.01,
                                // When zoom is 22, icon will be 10% size.
                                22,
                                2
                            ], // Use the 'iconSize' property to specify the icon size for each feature
                        }
                    });
                }
            );

            if (!props.isNative) {
                map.current.addSource('idx-nation', {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'properties': {},
                        'geometry': {
                            'type': 'Polygon',
                            'coordinates': [
                                [
                                    [-180, -90],
                                    [180, -90],
                                    [180, -60],
                                    [-180, -60],
                                    [-180, -90]
                                ]
                            ]
                        }
                    }
                });

                map.current.loadImage(
                    'https://corked.blob.core.windows.net/logos/ID_logowithname-04.png',
                    (err: any, image: any) => {
                        // Throw an error if something goes wrong.
                        if (err) throw err;

                        // Add the image to the map style.
                        map.current.addImage('idx-logo', image);

                        // Create a new layer and style it using `fill-pattern`.
                        map.current.addLayer({
                            'id': 'idx-layer',
                            'type': 'fill',
                            'source': 'idx-nation',
                            'paint': {
                                'fill-pattern': 'idx-logo'
                            }
                        });
                    }
                );
            }

            if (props.isNative) {
                try {
                    const accessToLocation = await getGeoLocationMobile();
                    if (accessToLocation) {
                        const coordinates = await Geolocation.getCurrentPosition();
                        const uel = document.createElement('div')
                        uel.classList.add('map-usermarker__border')
                        uel.innerHTML = '<div class="map-usermarker__core"></div>'

                        const userMarker = new mapboxgl.Marker({
                            element: uel,
                        })
                            .setLngLat([coordinates.coords.longitude, coordinates.coords.latitude])
                            .addTo(map.current)
                        await Geolocation.watchPosition({}, (position, err) => {
                            userMarker.setLngLat([
                                position.coords.longitude,
                                position.coords.latitude,
                            ])
                        });

                        // remove flyto when user location is found
                        // map.current.flyTo({
                        //   center: [
                        //     position.coords.longitude,
                        //     position.coords.latitude,
                        //   ],
                        //   essential: true, // this animation is considered essential with respect to prefers-reduced-motion
                        //   zoom: 4
                        // });
                    }
                } catch (e) {
                    console.error(e)
                }
            } else {
                try {
                    navigator.geolocation.getCurrentPosition(
                        async function (position) {
                            const uel = document.createElement('div')
                            uel.classList.add('map-usermarker__border')
                            uel.innerHTML = '<div class="map-usermarker__core"></div>'

                            const userMarker = new mapboxgl.Marker({
                                element: uel,
                            })
                                .setLngLat([position.coords.longitude, position.coords.latitude])
                                .addTo(map.current)

                            await Geolocation.watchPosition({}, (position, err) => {
                                userMarker.setLngLat([
                                    position.coords.longitude,
                                    position.coords.latitude,
                                ])
                            });

                            // remove flyto when user location is found
                            // map.current.flyTo({
                            //   center: [
                            //     position.coords.longitude,
                            //     position.coords.latitude,
                            //   ],
                            //   essential: true, // this animation is considered essential with respect to prefers-reduced-motion
                            //   zoom: 4
                            // });
                        },
                        function (error) {
                            console.error("Error Code = " + error.code + " - " + error.message);
                            return;
                        }
                    );
                } catch (e) {
                    console.error(e);
                }

            }
        });
    }, []);

    useEffect(() => {
        if (props.geojson) {
            if (props.geojson['features'] && props.geojson['features'].length > 0) {
                map.current.resize();
                const sourceId = 'tricks';

                // Check if the source exists
                if (map.current.getSource(sourceId)) {
                    // Remove dependent layers before removing the source
                    map.current.getStyle().layers.forEach((layer: { source: string; id: any; }) => {
                        if (layer.source === sourceId) {
                            map.current.removeLayer(layer.id);
                        }
                    });

                    // Remove the source
                    map.current.removeSource(sourceId);
                }

                // Add the source again
                map.current.addSource(sourceId, {
                    type: 'geojson',
                    data: props.geojson,
                    cluster: true,
                    clusterMaxZoom: 14,
                    clusterRadius: 50
                });

                map.current.addLayer({
                    id: 'clusters',
                    type: 'circle',
                    source: 'tricks',
                    filter: ['has', 'point_count'],
                    paint: {
                        // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
                        // with three steps to implement three types of circles:
                        //   * Blue, 20px circles when point count is less than 10
                        //   * Yellow, 30px circles when point count is between 10 and 25
                        //   * Pink, 40px circles when point count is between 25 and 40
                        //   * Purple, 50px circles when point count is greater than or equal to 40
                        'circle-color': [
                            'step',
                            ['get', 'point_count'],
                            '#51bbd6',
                            10,
                            '#f1f075',
                            30,
                            '#f28cb1',
                            50,
                            '#5861AC'
                        ],
                        'circle-radius': [
                            'step',
                            ['get', 'point_count'],
                            15,
                            10,
                            25,
                            30,
                            35,
                            50,
                            45
                        ]
                    }
                });

                map.current.addLayer({
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'tricks',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': ['get', 'point_count_abbreviated'],
                        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                        'text-size': 12
                    }
                });

                props.geojson['features'].forEach((feature: any) => {
                    // Add the image to the map
                    map.current.loadImage(feature.properties.image, (error: any, image: any) => {
                        if (error) throw error;
                        map.current.addImage(feature.properties.image, image);
                    });
                });

                map.current.addLayer({
                    id: 'unclustered-point',
                    type: 'symbol',
                    source: 'tricks',
                    filter: ['!', ['has', 'point_count']],
                    layout: {
                        'icon-image': ['get', 'image'], // Use the 'image' property to specify the image for each feature
                        "icon-size": [
                            'interpolate',
                            // Set the exponential rate of change to 1.5
                            ['linear'],
                            ['zoom'],
                            // When zoom is 10, icon will be 50% size.
                            10,
                            0.05,
                            // When zoom is 22, icon will be 10% size.
                            22,
                            0.3
                        ], // Use the 'iconSize' property to specify the icon size for each feature
                        'icon-allow-overlap': true, // Allow icons to overlap if necessary
                        'icon-padding': 10,
                    }
                });

                map.current.on('click', 'clusters', (e: any) => {
                    const features = map.current.queryRenderedFeatures(e.point, {
                        layers: ['clusters']
                    });
                    const clusterId = features[0].properties.cluster_id;
                    map.current.getSource('tricks').getClusterExpansionZoom(
                        clusterId,
                        (err: any, zoom: any) => {
                            if (err) return;

                            map.current.easeTo({
                                center: features[0].geometry.coordinates,
                                zoom: zoom
                            });
                        }
                    );
                });

                // When a click event occurs on a feature in
                // the unclustered-point layer, open a popup at
                // the location of the feature, with
                // description HTML from its properties.
                map.current.on('click', 'unclustered-point', (e: any) => {

                    const flyToClick = () => {
                        map.current.flyTo({
                            center: e.lngLat,
                            essential: true, // this animation is considered essential with respect to prefers-reduced-motion
                            zoom: 15
                        });
                        trickPopup.remove()
                    }

                    // Create a DOM element for each marker.
                    // need this placeholder or else renders with everything else
                    const placeholderContainer = document.createElement('div');
                    ReactDOM.render(<MapPopup trick={JSON.parse(e.features[0].properties.trick)} onClick={onTrickClick}
                                              onClickFlyTo={flyToClick}
                                              loggedInId={state.user.id}/>, placeholderContainer)

                    const trickPopup = new mapboxgl.Popup()
                        .setLngLat(e.features[0].geometry.coordinates)
                        .setHTML(null)
                        .setDOMContent(placeholderContainer)
                        .addTo(map.current)

                });

                map.current.on('mouseenter', 'clusters', () => {
                    map.current.getCanvas().style.cursor = 'pointer';
                });
                map.current.on('mouseleave', 'clusters', () => {
                    map.current.getCanvas().style.cursor = '';
                });
            }
        }
    }, [props.geojson]);

    useEffect(() => {
        setGooglePlacesSessionToken(uuidv4());
    }, []);

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize

        map.current.on('move', () => {
            setLong(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    });

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize

        if (props.flyTo) {
            map.current.flyTo({
                center: props.flyToCoords,
                essential: true, // this animation is considered essential with respect to prefers-reduced-motion
                zoom: 15
            });
        }
    }, [props.flyTo])


    const onTrickClick = (trickId: string, userId: string) => {
        map.current.fire('closeAllPopups');
        props.onClick(trickId, userId);
    }

    const onToggle3D = () => {
        if (is3D) {
            setIs3D(false);
            map.current.resize();
            map.current.easeTo({pitch: 0, bearing: 0});
        } else {
            setIs3D(true);
            map.current.resize();
            map.current.easeTo({pitch: 70, bearing: -30});
        }
    }

    const onToggleSearch = () => {
        setShowSearch(!showSearch);
    }

    const goUpgrade = () => {
        history.push('/shop')
    }

    const onLocationSearch = async (query: string) => {
        const res = await LocationService.getLocationFromTextPlacesAPI(query);
        setLocationSearch("");
        setShowSearch(false);
        if (res) {
            const searched_location = res.result.geometry.location;
            map.current.flyTo({
                center: [parseFloat(searched_location.lng), parseFloat(searched_location.lat)],
                essential: true, // this animation is considered essential with respect to prefers-reduced-motion
                zoom: 15
            });
        }
    }

    return (
        <div>
            {props.blurred ? <></> :
                <>
                    <div className="z-100 fixed top-52 right-1" style={{zIndex: 100}}>
                        <div
                            className="w-[40px] h-[40px] rounded-full flex justify-center items-center circle-button text-sm text-black"
                            onClick={onToggle3D}>
                            <div>
                                {is3D ? "2D" : "3D"}
                            </div>
                        </div>
                    </div>
                    <div className="z-100 fixed top-64 right-1" style={{zIndex: 100}}>
                        <div
                            className="w-[40px] h-[40px] rounded-full flex justify-center items-center circle-button text-sm text-black"
                            onClick={onToggleSearch}>
                            <IonIcon slot="icon-only" icon={searchOutline}></IonIcon>
                        </div>
                    </div>
                    {
                        (showShop) &&
                        <div className="z-100 fixed top-[304px] right-1" style={{zIndex: 100}}>
                            <div
                                className="w-[40px] h-[40px] rounded-full flex justify-center items-center circle-button text-sm text-black"
                                onClick={goUpgrade}>
                                <IonIcon slot="icon-only" icon={layersOutline}></IonIcon>
                            </div>
                        </div>
                    }
                    <div className="z-100 fixed bottom-4" style={{zIndex: 100}}>
                        <div className="grid w-screen place-items-center">
                            <MapSearchBar
                                placeholder='Search for a location'
                                webPlaceholder={'Search for a location'}
                                onSearch={setLocationSearch}
                                onSubmit={onLocationSearch}
                                searchPlaceholder={locationSearch}
                                showOnMap={showSearch}
                            />
                        </div>
                    </div>
                </>
            }
            <div ref={mapContainer} className={`map-container ${props.blurred ? "blurred_map" : ""}`}/>
        </div>
    );
};

export default WorldWeb;
