import React from "react";
import {IonButton, IonIcon, IonRippleEffect} from "@ionic/react";
import {storefrontOutline} from "ionicons/icons";

interface ContainerProps {
    title: string;
    shopRedirect: () => void;
}

const ShopRedirect: React.FC<ContainerProps> = (props: ContainerProps) => {
    return (
        <div>
            {
                <div className="flex flex-col gap-y-1">
                    {props.title &&
                        <div className="ion-activatable ripple-parent rounded-rectangle">
                            <IonRippleEffect></IonRippleEffect>
                            <IonButton text-wrap className="ion-text-wrap" expand="block"
                                       color="favorite"
                                       size="large"
                                       onClick={() => props.shopRedirect()}
                            >{props.title}
                            </IonButton>
                        </div>
                    }
                    <div className="ion-activatable ripple-parent rounded-rectangle">
                        <IonRippleEffect></IonRippleEffect>
                        <IonButton color="favorite" expand="block" size="large" onClick={() => props.shopRedirect()}>
                            Buy Now
                            <IonIcon slot="end" icon={storefrontOutline}></IonIcon>
                        </IonButton>
                    </div>
                </div>
            }
        </div>
    );
};

export default ShopRedirect;