import {IonButton, IonHeader, IonIcon, IonToolbar, useIonToast} from "@ionic/react";
import {cogOutline, notificationsOutline, searchOutline, storefrontOutline} from "ionicons/icons";
import React from "react";
import {useHistory} from "react-router-dom";
import {useAuth} from "../../../AuthProvider";
import useAuthService from "../../../hooks/useAuthService";

const ToolbarSearchBar: React.FC = () => {
    const history = useHistory();
    const {isAuthenticated} = useAuth();
    const {login} = useAuthService()
    const [present] = useIonToast();

    const openSettings = () => {
        history.push("/settings");
    }
    const openSearch = () => {
        history.push("/search");
    }

    const presentToast = (message: string, duration: number, icon: string) => {
        present({
            message: message,
            duration: duration,
            position: 'top',
            icon: icon,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                        console.log('Dismiss clicked');
                    },
                },
            ],
            onDidDismiss: (e: CustomEvent) => console.log(`Dismissed with role: ${e.detail.role}`)
        });
    };

    // TODO: animate placeholder for search bar when clicked on but nothing typed in

    const handleSmokeShack = () => {
        history.push("/shop");
    }

    return (
        <>
            <IonHeader className="bg-grey">
                <IonToolbar>
                    <div className="flex flex-row justify-between items-center">
                        <div className="flex order-first">
                            <div>
                                <IonButton fill="clear" size="small" color="dark" onClick={openSearch}>
                                    <IonIcon slot="icon-only" icon={searchOutline}/>
                                </IonButton>
                            </div>
                            <div>
                                <IonButton fill="clear" size="small" color="dark" onClick={() => handleSmokeShack()}>
                                    <IonIcon slot="icon-only" icon={storefrontOutline}/>
                                </IonButton>
                            </div>
                        </div>
                        <div className="flex-1 flex justify-center">
                            <a href="/"><img alt="ID Logo" src="/assets/photos/logo_white.png"></img></a>
                        </div>
                        <div className="flex order-last">
                            <div className="opacity-0">
                                <IonButton fill="clear" size="small" color="dark"
                                           onClick={() => presentToast("Keeping track of alerts is almost here!", 3500, notificationsOutline)}>
                                    <IonIcon slot="icon-only" icon={notificationsOutline}/>
                                </IonButton>
                            </div>
                            <div>
                                <IonButton fill="clear" size="small" color="dark"
                                           onClick={() => isAuthenticated ? openSettings() : login()}>
                                    <IonIcon slot="icon-only" icon={cogOutline}/>
                                </IonButton>
                            </div>
                        </div>
                    </div>
                </IonToolbar>
            </IonHeader>
        </>
    );
};

export default ToolbarSearchBar;
