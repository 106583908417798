import React, {useState} from 'react';
import {IonButton, IonIcon, IonInput, IonItem, IonLabel} from '@ionic/react';
import biskLogo from "../../../assets/icons/bisk/BISK_ICON_NO_DEGREE.svg";
import {WithdrawalPlatformType} from "../../../models/withdrawalPlatformType";

interface ContainerProps {
    onWithdraw: (amount: number, platform: string, id: string) => void,
    currBisk: number,
    minBisk: number
    platform: string,
    email: string,
}

const WithdrawalModal: React.FC<ContainerProps> = ({onWithdraw, currBisk, minBisk, platform, email}) => {
    const [platformId, setPlatformId] = useState<string>('');
    const [numericValue, setNumericValue] = useState<number>(minBisk);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>('');

    const resetModalWithdrawalAlert = () => {
        setPlatformId('');
        setNumericValue(minBisk);
        setIsError(false);
        setErrorText('');
    }

    const handleNumericChange = (e: string) => {
        if (/^\d*$/.test(e)) {
            console.log("Numeric Value: ", e);
            let parsedValue;
            if (e === '') {
                parsedValue = 0;
            } else {
                parsedValue = parseInt(e);
            }

            setNumericValue(parsedValue);

            if (parsedValue >= minBisk && parsedValue <= currBisk) {
                setIsError(false);
            } else if (parsedValue > currBisk) {
                setIsError(true);
                setErrorText("You don't have this much Bisk to withdraw");
            } else if (parsedValue < minBisk) {
                setIsError(true);
                setErrorText("You can't withdraw less than 2500 Bisk");
            } else {
                setIsError(false);
            }
        } else {
            setIsError(true);
            setErrorText("You can only enter numbers");
        }
    }

    const handleIdChange = (value: string) => {
        if (platform == WithdrawalPlatformType.PAYPAL) {
            setPlatformId(value);
            // Simple email regex validation
            if (/^\S+@\S+\.\S+$/.test(value)) {
                setPlatformId(value);
                setIsError(false);
            } else {
                setErrorText('Invalid email format');
                setIsError(true);
            }
        } else {
            setPlatformId(value);
        }
    };

    const handleWithdrawForBisk = () => {
        onWithdraw(numericValue, platform, platformId);
        resetModalWithdrawalAlert();
    };

    return (
        <div className="flex flex-col space-y-4">
            <div className="flex flex-col space-y-1 justify-center items-center">
                <div className="text-lg">
                    Withdraw Bisk to {platform}
                </div>
                <div className="text-md flex flex-row justify-center items-center">
                    Total Amount of Bisk available to withdraw: <IonIcon src={biskLogo} size="small"
                                                                         className="mx-2"/>{currBisk}
                </div>
            </div>
            <IonItem>
                <IonLabel
                    position="stacked">{platform == WithdrawalPlatformType.PAYPAL ? "Paypal Email" : "Venmo Username"}</IonLabel>
                <IonInput value={platformId}
                          placeholder={platform == WithdrawalPlatformType.PAYPAL ? email : "@your-venmo-username"}
                          clearInput={true} onIonInput={e => handleIdChange(e.detail.value as string)}></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked">Bisk</IonLabel>
                <IonInput type="number" value={numericValue} clearInput={true}
                          onIonInput={(e) => handleNumericChange(e.detail.value as string)}></IonInput>
            </IonItem>
            {
                isError &&
                <div className="text-red-500 text-md text-center">
                    {errorText}
                </div>
            }
            <IonButton
                expand="block"
                color="favorite"
                onClick={handleWithdrawForBisk}
                disabled={isError || platformId === ''}>
                Withdraw ${numericValue / 100} to {platform}
            </IonButton>
        </div>
    );
};

export default WithdrawalModal;